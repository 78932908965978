import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Dialog,
  DialogHeight,
  DialogWidth,
  Icon,
  IconGlyph,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  Text,
} from "@qbit/react";
import {
  EMIT_TOAST_DURATION,
  type TrackingComponent,
} from "@quantium-enterprise/common-ui";
import {
  ddLog,
  GenericTrackingProperties,
  GroupsTrackingProperty,
  TrackingEvent,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import { GreySpinner } from "components-ui/src/loader/GreySpinner";
import { forwardRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import styles from "./DeleteDialog.module.css";
import {
  getDeletedMessage,
  getDeleteFailedMessage,
  getDeleteWarningMessage,
  isSingleDelete,
} from "./deleteHelper";

export type DeleteItems = {
  folderIds?: string[];
  groupIds?: string[];
  reportIds?: string[];
  savedParametersIds?: string[];
};

export type DeleteDialogProps = {
  deleteItem: (itemIds: DeleteItems) => Promise<unknown>;
  itemIds: DeleteItems;
  onClose: () => void;
  onDelete?: () => void;
  show: boolean;
  trackingComponent: TrackingComponent;
};

export const DeleteDialog = forwardRef(
  (
    {
      itemIds,
      onClose,
      onDelete,
      show,
      deleteItem,
      trackingComponent,
    }: DeleteDialogProps,
    ref
  ) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const eventTrackingService = useEventTrackingServiceContext();

    const onDeleteClick = () => {
      if (
        itemIds.folderIds?.length === 0 &&
        itemIds.groupIds?.length === 0 &&
        itemIds.reportIds?.length === 0 &&
        itemIds.savedParametersIds?.length === 0
      ) {
        return;
      }

      setIsProcessing(true);
      deleteItem(itemIds)
        .then(() => {
          const message = getDeletedMessage(itemIds);

          QbitEmitToast(
            <QbitToastMessage
              content={<span />}
              heading={<h5>{message}</h5>}
              showCloseButton
              showIcon
              variant={MessageVariant.Success}
            />,
            {
              autoClose: EMIT_TOAST_DURATION,
            }
          );
          eventTrackingService.trackEvent(
            trackingComponent,
            TrackingEvent.Deleted,
            new GenericTrackingProperties({
              [GroupsTrackingProperty.DeletionType]: isSingleDelete(itemIds)
                ? "Individual"
                : "Bulk",
            })
          );
          onDelete?.();
        })
        .catch((error: Error | undefined) => {
          const message = getDeleteFailedMessage(itemIds);
          ddLog(message, {}, "error", error);
          QbitEmitToast(
            <QbitToastMessage
              content={<p>{message}.</p>}
              heading={<h5>An error occurred during delete operation.</h5>}
              showCloseButton
              showIcon
              variant={MessageVariant.Danger}
            />,
            {
              autoClose: EMIT_TOAST_DURATION,
            }
          );
        })
        .finally(() => {
          setIsProcessing(false);
          onClose();
        });
    };

    return (
      <Dialog
        className={styles.dialog}
        footer={
          <ButtonGroup>
            <Button
              disabled={isProcessing}
              id={styles.deleteButton}
              onClick={onDeleteClick}
              variant={ButtonVariant.Danger}
            >
              {isProcessing ? (
                <GreySpinner className={styles.deleteProcessing} />
              ) : (
                <Text>Delete</Text>
              )}
            </Button>
            <Button
              className={styles.cancelButton}
              disabled={isProcessing}
              onClick={onClose}
              variant={ButtonVariant.Secondary}
            >
              <Text>Cancel</Text>
            </Button>
          </ButtonGroup>
        }
        header={
          <div className={styles.closeHeader}>
            <h5>Delete</h5>
            <Button
              className={styles.close}
              onClick={onClose}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
            </Button>
          </div>
        }
        height={DialogHeight.XSmall}
        onClose={onClose}
        show={show}
        titleId="close-report-wizard-dialog"
        triggeredBy={ref as React.RefObject<never>}
        width={DialogWidth.XXXSmall}
      >
        <div className={styles.closeModalP}>
          <ReactMarkdown>{getDeleteWarningMessage(itemIds)}</ReactMarkdown>
          <p>This cannot be undone.</p>
        </div>
      </Dialog>
    );
  }
);

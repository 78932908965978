import tokens from "@qbit/core/dist/tokens.json";
import { type PointOptionsObject } from "highcharts";
import { INTERACTIONS_VENN_CHART_COLOUR_MAP } from "../constants/interactions-venn-chart-colour-map";
import { type InteractionsVennMetric } from "../models/InteractionsVennMetric";
import { type InteractionsVennMetricColumnHeaderKey } from "../models/InteractionsVennMetricColumnHeaderKey";

export enum PRODUCT_INTERACTION_SELECTED_DISPLAY_NAME_MAP {
  CustomersL = "% of Customers of ",
  SalesL = "% of Sales of ",
}

/**
 * getVennChartSeries
 *
 * @param vennChartData
 * @param productInteraction
 * @param selectedZone
 * @param metrics
 * @returns a venn chart series of type PointOptionsObject[]
 *
 * NOTE: highcharts/PointOptionsObject type provides `custom` as a Dictionary
 * that allows you to add additional data passed along, useful for Tooltips
 */
export const getVennChartSelectedSeries = (
  vennChartData: PointOptionsObject[],
  productInteraction: string,
  selectedZone: string,
  metrics?: InteractionsVennMetric[]
): PointOptionsObject[] => {
  if (!metrics) {
    return [];
  }

  const displayName = `${
    PRODUCT_INTERACTION_SELECTED_DISPLAY_NAME_MAP[
      productInteraction as keyof typeof PRODUCT_INTERACTION_SELECTED_DISPLAY_NAME_MAP
    ]
  }${selectedZone}`;

  // Filter metrics to grab the right values for the given selection
  const metricValues = metrics
    .filter(
      (metric) =>
        metric.productInteraction === productInteraction &&
        metric.displayName === displayName
    )
    .flatMap((metric) => metric.metricValues);

  const vennChartSeries = vennChartData.map((cd) => {
    if (cd.sets?.includes(selectedZone)) {
      cd.color =
        INTERACTIONS_VENN_CHART_COLOUR_MAP[
          selectedZone as keyof typeof InteractionsVennMetricColumnHeaderKey
        ];

      if (cd.sets.includes(selectedZone)) {
        const key = cd.sets.join("");
        const value = metricValues.find((metric) => metric.key === key)?.value;
        cd.custom = {
          ...cd.custom,
          value,
        };
      }
    } else {
      cd.color = tokens.colour["shade-4"];
      delete cd.custom?.value;
    }

    return cd;
  });

  return vennChartSeries;
};

import { FormBlockStatus } from "@qbit/react";
import { type DropdownOption } from "./shared/time-dropdown/TimeDropdown";
import { TimeDropdown } from "./shared/time-dropdown/TimeDropdown";

export type RollingPeriodProps = {
  disabled?: boolean;
  errors: Record<string, boolean>;
  isVisited: boolean;
  onChange: (newRollingPeriod: string) => void;
  options: DropdownOption[];
  rollingPeriod: string;
};

export const RollingPeriod = ({
  disabled,
  errors,
  isVisited,
  onChange,
  options,
  rollingPeriod,
}: RollingPeriodProps) => {
  const defaultRollingPeriod: DropdownOption = {
    disabled: false,
    label: "Select rolling period",
    value: "",
  };

  const blockStatus =
    isVisited && errors.value ? FormBlockStatus.Error : FormBlockStatus.Default;

  return (
    <TimeDropdown
      blockStatus={blockStatus}
      defaultOption={defaultRollingPeriod}
      disabled={disabled}
      label="Rolling period"
      onChange={onChange}
      options={options}
      selectedValue={rollingPeriod}
    />
  );
};

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
} from "@qbit/react";
import classNames from "classnames/bind";
import * as React from "react";
import styles from "./CollapsiblePanel.module.scss";

type CollapsiblePanelProps = {
  children: {
    content: JSX.Element | string;
    summary: JSX.Element | string;
  };
  isCollapsed?: boolean;
  isDisabled?: boolean;
  title: string;
  toggleCollapsed?: () => void;
};

const defaultToggleCollapsed = () => {};

export const CollapsiblePanel = ({
  title,
  isCollapsed = false,
  isDisabled = false,
  toggleCollapsed = defaultToggleCollapsed,
  children,
}: CollapsiblePanelProps) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!isDisabled && (event.key === "Enter" || event.keyCode === 13)) {
      toggleCollapsed();
    }
  };

  const boundClassnames = classNames.bind(styles);

  return (
    <div
      className={boundClassnames({
        [styles.collapsed]: isCollapsed,
        [styles.disabled]: isDisabled,
      })}
    >
      <div
        className={styles.panel}
        onKeyDown={handleKeyPress}
        role="menu"
        tabIndex={isDisabled ? -1 : 0}
      >
        <div className={styles.titlePanel}>
          <p className={styles.title}>{title}</p>
          <div>
            <Button
              aria-hidden={!isDisabled && isCollapsed}
              aria-label="Collapse panel"
              className={boundClassnames(
                styles.qbitButtonNoMargin,
                styles.collapseButton
              )}
              data-testid="arrow-chevron-up"
              height={ButtonHeight.Small}
              onClick={toggleCollapsed}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.ArrowsChevronUp} text="Collapse panel" />
            </Button>
            <Button
              aria-hidden={!isDisabled && !isCollapsed}
              aria-label="Open panel"
              className={boundClassnames(
                styles.qbitButtonNoMargin,
                styles.openButton
              )}
              data-testid="arrow-chevron-down"
              height={ButtonHeight.Small}
              onClick={toggleCollapsed}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.ArrowsChevronDown} text="Open panel" />
            </Button>
          </div>
        </div>
        <div
          aria-hidden={isCollapsed}
          className={styles.openContent}
          data-testid="open-content"
        >
          {children.content}
        </div>
        <div
          aria-hidden={!isCollapsed}
          className={styles.collapsedSummary}
          data-testid="collapsed-summary"
        >
          {children.summary}
        </div>
      </div>
    </div>
  );
};

export default CollapsiblePanel;

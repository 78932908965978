import {
  Button,
  ButtonVariant,
  Dialog,
  DialogHeight,
  DialogWidth,
  Icon,
  IconGlyph,
  QbitEmitToast,
  QbitToastMessage,
  MessageVariant,
  Spinner,
  SpinnerSize,
  Input,
  Text,
  TextInput,
  ButtonWidth,
} from "@qbit/react";
import {
  useEventTrackingServiceContext,
  TrackingComponent,
  TrackingEvent,
  GenericTrackingProperties,
  ddLog,
  useCreateCustomerGroupMutation,
  useLazyGetParameterConfigsQuery,
  useGetExistingGroupParameterConfigsQuery,
  useUpdateCustomerGroupMutation,
  useGetCustomerGroupQuery,
  GROUPS_PATH,
  GroupType,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { CustomerGroupIcon } from "components-ui/src/icons";
import { GreySpinner } from "components-ui/src/loader/GreySpinner";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGroupsValidator } from "../../../parameters/groups/GroupValidator";
import {
  onConfigReceived,
  resetParametersState,
  setRerunConfig,
} from "../../../states/report-wizard-slice";
import { getGroupIsValid } from "../../../states/validation-utils";
import { type RootState } from "../../../store";
import { buildParameterGroupSelectionDtos } from "../../services/SelectionDtoBuilder";
import { SummaryPanelBody } from "../../summary/SummaryPanelBody";
import { SummaryPanel } from "../../summary-panel/SummaryPanel";
import { Wizard } from "../Wizard";
import ParameterGroupTab from "../parameter-groups/ParameterGroupTab";
import { resetAndSetTabs, resetTabs, setTabs } from "../wizard-slice";
import styles from "./ParametersBody.module.css";

const icon = (
  <Icon
    className={styles.summaryIcon}
    glyph={IconGlyph.AccountAndUserAccount}
    text="Group Summary"
  />
);

export type CustomerGroupParametersBodyProps = {
  customerGroupUri: string;
};

type BaseDialogProps = {
  customerGroupUri: string;
  groupType: string;
  inputValue: string;
  setInputValue: Function;
  setShowDialog: Function;
  showDialog: boolean;
};

type CommitDialogProps = BaseDialogProps & {
  initialInputValue: string;
  primaryActionButton: React.ReactElement<HTMLButtonElement>;
  secondaryActionButton?: React.ReactElement<HTMLButtonElement>;
  showProcessing: boolean;
};

type CreateGroupDialogProps = BaseDialogProps & {
  handleCreateButtonClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  showProcessing: boolean;
};

type EditGroupDialogProps = BaseDialogProps & {
  handleSaveAsButtonClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  handleSaveButtonClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  initialInputValue: string;
  showSaveAsProcessing: boolean;
  showSaveProcessing: boolean;
};

const CommitDialog = ({
  customerGroupUri,
  groupType,
  primaryActionButton,
  secondaryActionButton,
  initialInputValue,
  inputValue,
  setInputValue,
  setShowDialog,
  showDialog,
  showProcessing,
}: CommitDialogProps) => {
  const GroupIcon = useMemo(
    () => (
      <CustomerGroupIcon className={styles.titleIcon} type={customerGroupUri} />
    ),
    [customerGroupUri]
  );

  const handleCloseDialogExit = useCallback(() => {
    setInputValue(initialInputValue);
    setShowDialog(false);
  }, [setInputValue, setShowDialog, initialInputValue]);

  return (
    <div className={styles.closeContainer}>
      <Dialog
        className={classNames(styles.dialog, styles.createGroupDialog)}
        footer={
          <div className={styles.dialogFooterContent}>
            {primaryActionButton}
            <Button
              disabled={showProcessing}
              onClick={handleCloseDialogExit}
              variant={ButtonVariant.Secondary}
            >
              <Text>Cancel</Text>
            </Button>
            {secondaryActionButton}
          </div>
        }
        header={
          <div className={styles.closeHeader}>
            <h5>Name your group</h5>
            <Button
              className={styles.createCloseButton}
              onClick={handleCloseDialogExit}
              variant={ButtonVariant.Secondary}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
            </Button>
          </div>
        }
        height={DialogHeight.Large}
        onClose={handleCloseDialogExit}
        show={showDialog}
        titleId="close-wizard-dialog"
        triggeredBy={useRef(null)}
        width={DialogWidth.XXXSmall}
      >
        <div className={styles.closeModalP}>
          <h6>
            Group type: <span className={styles.groupIcon}>{GroupIcon}</span>
            <Text className={styles.groupName}>{groupType}</Text>
          </h6>
          <h6>Group name</h6>
          <Input>
            <TextInput
              autofocus
              id="create-group-input"
              onChange={(event) => setInputValue(event.target.value)}
              placeholder="Give your group a unique name"
              value={inputValue}
            />
          </Input>
          <br />
        </div>
      </Dialog>
    </div>
  );
};

export const CreateGroupDialog = ({
  handleCreateButtonClick,
  showProcessing,
  inputValue,
  ...passThroughProps
}: CreateGroupDialogProps) => (
  <CommitDialog
    {...passThroughProps}
    initialInputValue=""
    inputValue={inputValue}
    primaryActionButton={
      <Button
        className={styles.createButton}
        disabled={showProcessing || !inputValue}
        onClick={handleCreateButtonClick}
        variant={ButtonVariant.Primary}
      >
        {showProcessing ? (
          <GreySpinner className={styles.loadingIcon} />
        ) : (
          <Text>Create new group</Text>
        )}
      </Button>
    }
    showProcessing={showProcessing}
  />
);

export const EditGroupDialog = ({
  handleSaveButtonClick,
  handleSaveAsButtonClick,
  showSaveProcessing,
  showSaveAsProcessing,
  initialInputValue,
  inputValue,
  ...passThroughProps
}: EditGroupDialogProps) => (
  <CommitDialog
    {...passThroughProps}
    initialInputValue={initialInputValue}
    inputValue={inputValue}
    primaryActionButton={
      <Button
        className={styles.saveButton}
        disabled={showSaveProcessing || showSaveAsProcessing || !inputValue}
        onClick={handleSaveButtonClick}
        variant={ButtonVariant.Primary}
      >
        {showSaveProcessing ? (
          <GreySpinner className={styles.loadingIcon} />
        ) : (
          <Text>Save changes</Text>
        )}
      </Button>
    }
    secondaryActionButton={
      <Button
        className={styles.copyButton}
        disabled={
          showSaveProcessing ||
          showSaveAsProcessing ||
          !inputValue ||
          initialInputValue === inputValue
        }
        onClick={handleSaveAsButtonClick}
        variant={ButtonVariant.Stealth}
        width={ButtonWidth.XSmall}
      >
        {showSaveAsProcessing ? (
          <GreySpinner className={styles.loadingIcon} />
        ) : (
          <>
            <Icon glyph={IconGlyph.TextAndEditContentCopy} text="Edit group" />
            <Text>Save as copy</Text>
          </>
        )}
      </Button>
    }
    showProcessing={showSaveProcessing || showSaveAsProcessing}
  />
);

export const CustomerGroupParametersBody = ({
  customerGroupUri,
}: CustomerGroupParametersBodyProps) => {
  const eventTrackingService = useEventTrackingServiceContext();
  const dispatch = useDispatch();

  const { groupId } = useParams();
  const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);

  const { name: divisionName } = useDivision();

  const { data: existingGroup } = useGetCustomerGroupQuery(
    {
      divisionName,
      groupId: groupId as string,
    },
    { skip: !divisionName || !groupId }
  );

  const existingGroupParameterConfig = useGetExistingGroupParameterConfigsQuery(
    {
      divisionName,
      groupId: groupId as string,
    },
    { skip: !divisionName || !groupId }
  ).currentData;

  const { parameterGroups, parameters, transactionSources, wizardSubTitle } =
    useSelector((state: RootState) => ({
      parameterGroups: state.reportParameter.parameterGroups,
      parameters: state.reportParameter.parameters,
      transactionSources: state.reportParameter.transactionSources,
      wizardSubTitle: state.wizard.subTitle,
    }));

  useEffect(() => {
    if (existingGroupParameterConfig?.parameterGroups) {
      dispatch(
        resetAndSetTabs(
          existingGroupParameterConfig.parameterGroups.map(
            (parameter) => parameter.label
          )
        )
      );
      dispatch(
        setRerunConfig({
          parameterConfig: existingGroupParameterConfig,
        })
      );
    }
  }, [dispatch, existingGroupParameterConfig]);

  const [triggerQuery, { data: emptyParameterConfig, isSuccess }] =
    useLazyGetParameterConfigsQuery();

  const fetchParameterConfigurations = useCallback(async () => {
    if (!groupId) {
      const requestDto = {
        divisionName,
        customerGroupType: customerGroupUri,
      };

      await triggerQuery(requestDto);
    }
  }, [divisionName, customerGroupUri, triggerQuery, groupId]);

  useEffect(() => {
    if (customerGroupUri && divisionName) {
      dispatch(resetTabs());
      dispatch(resetParametersState());
      fetchParameterConfigurations().catch((error) => {
        // FIXME: throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [divisionName, fetchParameterConfigurations, customerGroupUri, dispatch]);

  useEffect(() => {
    if (
      isSuccess &&
      emptyParameterConfig &&
      emptyParameterConfig.parameterGroups
    ) {
      dispatch(
        setTabs(
          emptyParameterConfig.parameterGroups.map(
            (parameterGroup) => parameterGroup.label
          )
        )
      );

      dispatch(
        onConfigReceived({
          parameterConfig: emptyParameterConfig,
        })
      );
    }
  }, [isSuccess, emptyParameterConfig, dispatch]);

  useEffect(() => {
    if (wizardSubTitle !== "" && isSuccess) {
      eventTrackingService.trackEvent(
        [TrackingComponent.GroupCreator, TrackingComponent.Wizard],
        TrackingEvent.Opened,
        new GenericTrackingProperties({
          wizardTitle: wizardSubTitle,
        })
      );
    }
  }, [eventTrackingService, isSuccess, wizardSubTitle]);

  useGroupsValidator();

  const parameterConfig = existingGroupParameterConfig ?? emptyParameterConfig;
  const isParametersSuccess =
    Boolean(existingGroupParameterConfig) || isSuccess;

  const selectedTransactionSourceIds = useMemo(
    () =>
      transactionSources
        .filter((source) => source.isSelected)
        .map((source) => source.idOverwrite ?? source.id),
    [transactionSources]
  );

  const disableCreateButton = Object.keys(parameterGroups).some((key) => {
    const keyState = parameterGroups[key];

    if (!keyState) {
      return true;
    }

    return !getGroupIsValid(keyState);
  });

  const [showServerError, setShowServerError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [showSaveProcessing, setShowSaveProcessing] = useState(false);
  const [showSaveAsProcessing, setShowSaveAsProcessing] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (existingGroup) {
      setInputValue(existingGroup.name);
    }
  }, [existingGroup, setInputValue]);

  const { groupType } = useSelector((state: RootState) => ({
    groupType: state.wizard.subTitle,
  }));

  const [createParameters] = useCreateCustomerGroupMutation();
  const [updateParameters] = useUpdateCustomerGroupMutation();
  const navigate = useNavigate();

  const handleServerErrorClose = useCallback(() => {
    setShowServerError(false);
  }, []);

  const createCustomerGroup = useCallback(async () => {
    if (!parameterConfig) {
      throw new Error("Parameter configs cannot be undefined.");
    }

    setShowCreateGroupDialog(true);
  }, [parameterConfig]);

  const handleCreateButtonClick = useCallback(async () => {
    setShowSaveAsProcessing(true);
    if (!parameterConfig) {
      throw new Error("Parameter configs cannot be undefined.");
    }

    const parameterGroupSelections = buildParameterGroupSelectionDtos(
      parameters,
      parameterConfig
    );

    let customerGroupId = "";
    let groupCreateSuccess = false;

    try {
      const customerGroupDto = await createParameters({
        divisionName,
        customerGroupType: customerGroupUri,
        groupName: inputValue,
        parameterGroupSelections,
      }).unwrap();

      if (!customerGroupDto.id) {
        throw new Error("Created group ID cannot be undefined or empty.");
      }

      const groupsPath = `/${divisionName}/${GROUPS_PATH}`;
      navigate(`${groupsPath}/${GroupType.Customer}/${customerGroupDto.id}`);
      QbitEmitToast(
        <QbitToastMessage
          content={
            groupId ? <span /> : <p>Customer group creation in progress.</p>
          }
          heading={
            groupId ? (
              <h5>A new copy has been saved</h5>
            ) : (
              <h5>Customer group</h5>
            )
          }
          showCloseButton
          showIcon
          variant={MessageVariant.Notification}
        />
      );
      groupCreateSuccess = true;
      customerGroupId = customerGroupDto.id;
    } catch (error) {
      if (error) {
        try {
          const jsonError = JSON.stringify(error);
          if (
            jsonError.includes("Group") &&
            jsonError.includes("already exists")
          ) {
            setServerErrorMessage(
              "Group name already exists, please try another unique name"
            );
          }
        } catch {
          setShowServerError(true);
        }
      }

      setShowServerError(true);
    }

    eventTrackingService.trackEvent(
      [TrackingComponent.Groups, TrackingComponent.CustomerGroup],
      TrackingEvent.Submitted,
      new GenericTrackingProperties({
        customerGroupId,
        wizardTitle: wizardSubTitle,
        customerGroupGlobalParameters: parameterGroupSelections,
        customerGroupSubmitStatus: groupCreateSuccess ? "Success" : "Fail",
      })
    );
    setShowSaveAsProcessing(false);
  }, [
    createParameters,
    groupId,
    parameterConfig,
    divisionName,
    inputValue,
    navigate,
    parameters,
    customerGroupUri,
    eventTrackingService,
    setShowSaveAsProcessing,
    setShowServerError,
    setServerErrorMessage,
    wizardSubTitle,
  ]);

  const handleSaveButtonClick = useCallback(async () => {
    if (!existingGroup) {
      throw new Error("Tried to save a group that doesn't exist!");
    }

    setShowSaveProcessing(true);
    if (!parameterConfig) {
      throw new Error("Parameter configs cannot be undefined.");
    }

    const parameterGroupSelections = buildParameterGroupSelectionDtos(
      parameters,
      parameterConfig
    );

    let groupCreateSuccess = false;

    try {
      const customerGroupDto = await updateParameters({
        groupId: existingGroup.id,
        divisionName,
        customerGroupType: existingGroup.type,
        groupName: inputValue,
        parameterGroupSelections,
      }).unwrap();

      if (!customerGroupDto.id) {
        throw new Error("Created group ID cannot be undefined or empty.");
      }

      const groupsPath = `/${divisionName}/${GROUPS_PATH}`;
      navigate(`${groupsPath}/${GroupType.Customer}/${customerGroupDto.id}`);
      QbitEmitToast(
        <QbitToastMessage
          content={<span />}
          heading={<h5>Customer group has been saved</h5>}
          showCloseButton
          showIcon
          variant={MessageVariant.Notification}
        />
      );
      groupCreateSuccess = true;
    } catch {
      setShowServerError(true);
    }

    eventTrackingService.trackEvent(
      [TrackingComponent.Groups, TrackingComponent.CustomerGroup],
      TrackingEvent.Submitted,
      new GenericTrackingProperties({
        customerGroupId: existingGroup.id,
        wizardTitle: wizardSubTitle,
        customerGroupGlobalParameters: parameterGroupSelections,
        customerGroupSubmitStatus: groupCreateSuccess ? "Success" : "Fail",
      })
    );
    setShowSaveProcessing(false);
  }, [
    updateParameters,
    divisionName,
    inputValue,
    existingGroup,
    parameterConfig,
    parameters,
    eventTrackingService,
    navigate,
    wizardSubTitle,
  ]);

  return (
    <>
      {isParametersSuccess && parameterConfig ? (
        <>
          {showCreateGroupDialog &&
            (existingGroup ? (
              <EditGroupDialog
                customerGroupUri={customerGroupUri}
                groupType={groupType}
                handleSaveAsButtonClick={handleCreateButtonClick}
                handleSaveButtonClick={handleSaveButtonClick}
                initialInputValue={existingGroup.name}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setShowDialog={setShowCreateGroupDialog}
                showDialog={showCreateGroupDialog}
                showSaveAsProcessing={showSaveAsProcessing}
                showSaveProcessing={showSaveProcessing}
              />
            ) : (
              <CreateGroupDialog
                customerGroupUri={customerGroupUri}
                groupType={groupType}
                handleCreateButtonClick={handleCreateButtonClick}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setShowDialog={setShowCreateGroupDialog}
                showDialog={showCreateGroupDialog}
                showProcessing={showSaveAsProcessing}
              />
            ))}
          <Wizard.Content>
            {parameterConfig.parameterGroups?.map((parameterGroup) => (
              <ParameterGroupTab
                key={parameterGroup.label}
                parameterGroupDto={parameterGroup}
              />
            ))}
          </Wizard.Content>

          <Wizard.Sidebar>
            <SummaryPanel>
              <SummaryPanel.Header
                icon={icon}
                title="Group summary"
                transactionSources={selectedTransactionSourceIds}
              />
              <SummaryPanelBody
                parameterGroupsConfiguration={parameterConfig.parameterGroups}
              />
              <SummaryPanel.Footer
                createText={groupId ? "Save changes" : "Create group"}
                isDisabled={disableCreateButton}
                isProcessing={false}
                onCreateClick={createCustomerGroup}
              />
            </SummaryPanel>
          </Wizard.Sidebar>
        </>
      ) : (
        <div className={styles.loading}>
          <Spinner size={SpinnerSize.Medium} text="Loading" />
        </div>
      )}

      <Dialog
        className={styles.serverErrorDialog}
        header={
          <div className={styles.dialogHeader}>
            <Icon
              className={styles.dialogIcon}
              glyph={IconGlyph.AlertsAndNotificationsWarning}
              text="Error"
            />
            {serverErrorMessage === "" ? (
              <h2>
                Something went wrong,
                <br />
                please try again.
              </h2>
            ) : (
              <h2>{serverErrorMessage}</h2>
            )}
          </div>
        }
        onClose={handleServerErrorClose}
        show={showServerError}
        titleId="dialog-top-right-control"
        topRightControls={
          <Button
            className={styles.dialogClose}
            onClick={handleServerErrorClose}
            variant={ButtonVariant.Stealth}
          >
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
          </Button>
        }
        triggeredBy={useRef(null)}
        width={DialogWidth.XXXSmall}
      >
        <p>Contact support if error persists.</p>
      </Dialog>
    </>
  );
};

/* eslint-disable complexity */
import {
  Nav,
  NavButton,
  NavVariant,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  type FolderOrGroupDto,
  useGetGroupSummaryQuery,
  FeatureFlag,
  useGetGroupSharingActivitiesQuery,
  useGetUsersByIdQuery,
  AppContext,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { HierarchyGroupSummary } from "components-ui/src/hierarchy-group-summary/HierarchyGroupSummary";
import { HierarchyGroupIcon } from "components-ui/src/icons";
import { InfoPanelActivity } from "components-ui/src/info-panel/info-panel-body/InfoPanelActivity";
import { ViewAccessModal } from "components-ui/src/modals";
import { PanelWithSideDrawer } from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import { useContext, useState, type PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import styles from "./GroupInfoPanel.module.css";
import { GroupInfoPanelHeader } from "./group-info-panel-header/GroupInfoPanelHeader";

export type HierarchyGroupInfoPanelProps = {
  focalGroup: FolderOrGroupDto | undefined;
  onClose: () => void;
  onMove: () => void;
  showPanel: boolean;
};

export const HierarchyGroupInfoPanel = ({
  children,
  onClose,
  onMove,
  showPanel,
  focalGroup,
}: PropsWithChildren<HierarchyGroupInfoPanelProps>) => {
  const { userState } = useContext(AppContext);
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const [viewAccessModal, setViewAccessModal] = useState(false);
  const flags = useFlags();
  const { name: divisionName } = useDivision();
  const { groupId, groupType } = useParams();

  const { currentData: summary, isFetching: isSummaryFetching } =
    useGetGroupSummaryQuery(
      {
        divisionName,
        groupId: groupId ?? "",
        maxItemsInSubset: 32,
      },
      {
        skip:
          !divisionName || !groupId || (focalGroup?.isFolder ?? !focalGroup),
      }
    );
  const { data: sharingActivities, isLoading: isActivityLoading } =
    useGetGroupSharingActivitiesQuery(
      {
        divisionName,
        groupId: groupId ?? "",
      },
      {
        skip: !(
          Boolean(divisionName) &&
          Boolean(groupId) &&
          flags[FeatureFlag.SharingGroups] &&
          !focalGroup?.isFolder
        ),
      }
    );
  const { data: reportSharedUsers, isLoading: isReportSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      {
        skip: !(
          Boolean(divisionName) &&
          Boolean(groupId) &&
          Boolean(sharingActivities) &&
          Boolean(sharingActivities?.length) &&
          flags[FeatureFlag.SharingGroups]
        ),
      }
    );

  const label = (
    <div className={styles.infoPanelLabel}>
      <HierarchyGroupIcon hierarchyType={groupType} />
      <Text>{`${groupType} group`}</Text>
    </div>
  );

  const infoPanelTabs = [
    {
      content: (
        <>
          {isSummaryFetching ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <div className={styles.groupInfoPanelBodyContent}>
              <HierarchyGroupSummary group={summary} title="Group info" />
            </div>
          )}
        </>
      ),
      title: "Info",
    },
  ];
  const showViewAccess =
    flags[FeatureFlag.ProductGroupsTrueShare] &&
    userState.currentUser?.salesForceId !== focalGroup?.userId
      ? () => setViewAccessModal(true)
      : undefined;
  if (flags[FeatureFlag.SharingGroups]) {
    const activityTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          {isActivityLoading || isReportSharedUsersLoading ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <InfoPanelActivity
              header="Group activity"
              resourceType="group"
              sharedUsers={reportSharedUsers}
              sharingActivities={sharingActivities}
              showViewAccess={showViewAccess}
            />
          )}
        </div>
      ),
      title: "Activity",
    };
    infoPanelTabs.push(activityTab);
  }

  const header = (
    <>
      <GroupInfoPanelHeader
        focalGroup={focalGroup}
        groupType={groupType}
        onClose={onClose}
        onMove={onMove}
      />
      <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
        {infoPanelTabs.map((tab, index) => (
          <NavButton key={tab.title} onClick={() => setCurrentNavIndex(index)}>
            {tab.title}
          </NavButton>
        ))}
      </Nav>
    </>
  );

  const body = infoPanelTabs[currentNavIndex].content;

  return (
    <>
      {showViewAccess && (
        <ViewAccessModal
          onClose={() => setViewAccessModal(false)}
          ownerId={focalGroup?.userId}
          sharedWithUserIds={focalGroup?.sharedWithUserIds}
          showModal={viewAccessModal}
        />
      )}
      <PanelWithSideDrawer
        closeFunc={onClose}
        drawerContent={{
          body,
          header,
          label,
        }}
        isOverlay={false}
        showDrawer={showPanel}
      >
        {children}
      </PanelWithSideDrawer>
    </>
  );
};

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  ButtonWidth,
  Icon,
  IconGlyph,
  IconSize,
} from "@qbit/react";
import classNames from "classnames";
import { type TooltipFormatterContextObject } from "highcharts";
import type Highcharts from "highcharts";
import { type PropsWithChildren } from "react";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import Draggable from "react-draggable";
import useResizeObserver from "use-resize-observer";
import styles from "./HighChartsWithPinnableTooltips.module.css";
import { HighchartsReact } from "./HighchartsReact";
import { type HighchartsReactProps } from "./HighchartsReact";

type PinnedTooltipPortalProps = PropsWithChildren & {
  destination?: HTMLElement;
};
const PinnedTooltipPortal = ({
  children,
  destination,
}: PinnedTooltipPortalProps) => {
  if (destination) {
    return createPortal(children, destination);
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment -- needed
  return <></>;
};

type PinnedTooltipProps = {
  chart: Highcharts.Chart;
  onClose: () => void;
  onHoverChanged: (isHovering: boolean) => void;
  options: HighchartsReactProps;
  pointIndex: number;
  posx: string;
  posy: string;
};
const PinnedTooltip = ({
  options,
  chart,
  pointIndex,
  posx,
  posy,
  onClose,
  onHoverChanged,
}: PinnedTooltipProps) => {
  // This is a bit of a guess at what the values in the context should be, but not certain.
  // Works for the current tooltip HTML for now
  const tooltipFormatterContext = useMemo(() => {
    const firstSeries = chart.series[0];

    const points = chart.series.map((series) => {
      const point = series.points[pointIndex];
      const pointContext: TooltipFormatterContextObject = {
        colorIndex: point.colorIndex ?? 0,
        percentage: point.percentage ?? 0,
        point,
        series: point.series,
        color: point.color,
        total: point.total,
        x: point.category,
        y: point.y,
      };
      return pointContext;
    });

    const context: TooltipFormatterContextObject = {
      colorIndex: 0,
      percentage: 0,
      series: firstSeries,
      point: points[0].point,
      points,
      x: points[0].x,
      y: points[0].y,
      color: points[0].color,
      total: points[0].total,
    };
    return context;
  }, [chart, pointIndex]);

  const renderedTooltip = useMemo(
    () => options.tooltip.ReactFormatter(tooltipFormatterContext),
    [options, tooltipFormatterContext]
  );

  const { ref, width, height } = useResizeObserver({ box: "border-box" });

  return (
    <Draggable
      bounds={{
        top: 0,
        left: 0,
        bottom: chart.chartHeight - (height ?? 0),
        right: chart.chartWidth - (width ?? 0),
      }}
      defaultPosition={{
        x: Number.parseInt(posx, 10),
        y: Number.parseInt(posy, 10),
      }}
      onMouseDown={(event) => event.stopPropagation()}
    >
      <div
        className={classNames(
          "highcharts-label",
          "highcharts-tooltip",
          styles.pinnedTooltip
        )}
        onMouseEnter={() => onHoverChanged(true)}
        onMouseLeave={() => onHoverChanged(false)}
        ref={ref}
      >
        {renderedTooltip}
        <div className={styles.closeButtonContainer}>
          <Button
            height={ButtonHeight.XSmall}
            onClick={onClose}
            variant={ButtonVariant.Stealth}
            width={ButtonWidth.Fit}
          >
            <Icon
              glyph={IconGlyph.DeleteAndCloseClose}
              size={IconSize.Small}
              text="Close"
            />
          </Button>
        </div>
      </div>
    </Draggable>
  );
};

export type HighChartsWithPinnableTooltipsProps = {
  options: HighchartsReactProps;
};

export const HighChartsWithPinnableTooltips = ({
  options,
}: HighChartsWithPinnableTooltipsProps) => {
  const [chart, setChart] = useState<Highcharts.Chart>();

  const [portalDestination, setPortalDestination] = useState<HTMLElement>();

  // Create a container in the highcharts dom where we can place pinned tooltips through a portal
  useEffect(() => {
    const containerTemplate = document.createElement("div");
    containerTemplate.className = styles.pinnedTooltipContainer;

    const pinnedTooltipRoot = document.createElement("div");
    pinnedTooltipRoot.id = `pinned-tooltip-container-${chart?.index}`;
    pinnedTooltipRoot.style.position = "relative";
    containerTemplate.appendChild(pinnedTooltipRoot);

    const container = chart?.container.appendChild(containerTemplate);
    setPortalDestination(container?.children[0] as HTMLElement);

    return () => {
      setPortalDestination(undefined);
      return container?.remove();
    };
  }, [chart]);

  const [pinnedTooltipOptions, setPinnedTooltipOptions] = useState<
    Array<
      Omit<
        PinnedTooltipProps,
        "chart" | "onClose" | "onHoverChanged" | "options"
      >
    >
  >([]);

  const onClickHandler: React.MouseEventHandler = (event) => {
    // If clicked tooltip already pinned, do nothing
    const pinnedTooltip = (event.target as HTMLElement).closest(
      `.${styles.pinnedTooltip}`
    ) as HTMLElement | undefined;
    if (pinnedTooltip) {
      return;
    }

    const tooltip = (event.target as HTMLElement).closest(
      `.highcharts-label`
    ) as HTMLElement | undefined;
    if (tooltip) {
      const pointIndex = chart?.hoverPoints?.[0].index;
      if (pointIndex !== undefined) {
        setPinnedTooltipOptions((current) => [
          ...current,
          {
            pointIndex,
            posx: tooltip.style.left,
            posy: tooltip.style.top,
          },
        ]);
      }
    }
  };

  const [pinnedTooltipHover, setPinnedTooltipHover] = useState(false);

  const modifiedOptions: HighchartsReactProps = {
    ...options,
    tooltip: {
      ...options.tooltip,
      // eslint-disable-next-line react/no-unstable-nested-components -- not a component
      ReactFormatter: (context) => {
        // if we are hovering over a pinned tooltip, show nothing
        if (pinnedTooltipHover) {
          // eslint-disable-next-line react/jsx-no-useless-fragment -- needed
          return <></>;
        }

        // If the point we are hovering over has already been pinned, show nothing
        if (
          pinnedTooltipOptions.some(
            (tooltip) => tooltip.pointIndex === context.points?.[0].point.index
          )
        ) {
          // eslint-disable-next-line react/jsx-no-useless-fragment -- needed
          return <></>;
        }

        return options.tooltip.ReactFormatter(context);
      },
    },
    chart: {
      ...options.chart,
      events: {
        ...options.chart?.events,
        load() {
          setChart(this);
        },
      },
    },
  };

  // If the series data passed in changes, clear all pinned tooltips
  // I was unable to make the values in pinned tooltips dynamically update
  // this was due to being unable to find an event emitted by highcharts that happens late enough that the values in the chart object are up to date
  useEffect(() => {
    setPinnedTooltipOptions([]);
    setPinnedTooltipHover(false);
  }, [options.series]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- No way to do pinned tooltips with keyboard only
    <div onClick={onClickHandler}>
      <PinnedTooltipPortal destination={portalDestination}>
        {chart &&
          pinnedTooltipOptions.map((opt) => (
            <PinnedTooltip
              key={opt.pointIndex}
              {...opt}
              chart={chart}
              onClose={() => {
                setPinnedTooltipOptions((current) =>
                  current.filter(
                    (tooltip) => tooltip.pointIndex !== opt.pointIndex
                  )
                );
                setPinnedTooltipHover(false);
              }}
              onHoverChanged={setPinnedTooltipHover}
              options={options}
            />
          ))}
      </PinnedTooltipPortal>
      <HighchartsReact options={modifiedOptions} />
    </div>
  );
};

export default HighChartsWithPinnableTooltips;

import { uniqueId } from "@qbit/react/dist/common";
import classNames from "classnames";
import { ColorRankType } from "../common/models/ColorRankType";
import { getHeatMapColor } from "../common/utils/getHeatMapColor";
import styles from "./HeatMap.module.css";

export type HeatMapProps = {
  colorRange: string[];
  colorRankMethod: ColorRankType;
  formatter: (value: number | string | null) => string;
  leftColumnHeaders: string[];
  tableData: number[][];
  topCornerHeader: string;
  topRowAlignRight?: boolean;
  topRowHeaders: string[];
};

// use NoDataChartWrapper in reportlet to avoid no data scenario
export const HeatMap = ({
  topRowHeaders,
  topRowAlignRight = false,
  leftColumnHeaders,
  topCornerHeader = "",
  tableData,
  formatter,
  colorRange,
  colorRankMethod = ColorRankType.LINEAR,
}: HeatMapProps) => {
  if (
    tableData.length !== leftColumnHeaders.length ||
    tableData[0].length !== topRowHeaders.length
  ) {
    throw new Error(
      "Invalid data shape - header length does not match inner table data."
    );
  }

  const sortedValues = tableData
    .flat()
    .filter((a) => Number.isFinite(a))
    .sort((a: number, b: number) => a - b);

  return (
    <div className={styles.heatMapContainer}>
      <table>
        <tbody>
          <tr>
            {[topCornerHeader, ...topRowHeaders].map((header, headerNumber) => (
              <th
                className={classNames(styles.topRow, {
                  [styles.leftColumn]: headerNumber === 0,
                })}
                key={uniqueId()}
              >
                <div
                  className={classNames({
                    [styles.alignRight]: topRowAlignRight && headerNumber !== 0,
                  })}
                >
                  {header}
                </div>
              </th>
            ))}
          </tr>
          {tableData.map((row, rn) => (
            <tr key={uniqueId()}>
              <th className={styles.leftColumn} key={uniqueId()}>
                <div>{leftColumnHeaders[rn]}</div>
              </th>

              {row.map((cell) => (
                <td key={uniqueId()}>
                  <div
                    className={classNames({
                      [styles.alignCenter]: formatter(cell) === "-",
                    })}
                    style={{
                      backgroundColor: getHeatMapColor(
                        colorRankMethod,
                        cell,
                        colorRange,
                        sortedValues
                      ),
                    }}
                  >
                    {formatter(cell)}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

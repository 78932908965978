import {
  Checkbox,
  FormBlock,
  FormBlockEditability,
  FormBlockType,
  Group,
  Input,
  Item,
  ItemHalign,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import {
  type CustomerGroupWithSharingDto,
  type CustomerGroupTypeDto,
  type SegmentationParameterOption,
  CustomerGroupStatus,
  type ParameterDto,
  useGetCustomerGroupTypesQuery,
  useGetCustomerGroupsQuery,
  SegmentationType,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { type ColumnDef, type CellContext } from "@tanstack/react-table";
import { CustomerGroupStatusTag } from "components-ui/src/customer-group-status-tag/CustomerGroupStatusTag";
import { CustomerGroupIcon } from "components-ui/src/icons";
import { CgDetailsModalTrigger } from "components-ui/src/information-modal/customer-group-details-modal/CgDetailsModalTrigger";
import { SegmentationModal } from "components-ui/src/information-modal/segmentation-modal/SegmentationModal";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSegmentationModalShown } from "../../common/wizard/wizard-slice";
import { useAppSelector } from "../../states/hooks";
import { segmentationOptionSelected } from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./SegmentationParameter.module.css";
import { type SegmentationSelection } from "./SegmentationParameterState";
import { getSegmentationParameterState } from "./SegmentationParameterState";

const CUSTOMER_GROUPS_GROUP_NAME = "Customer groups";
const NO_CUSTOMER_GROUPS_MESSAGE =
  "You do not have any customer groups available";

type SegmentationParameterProps = {
  parameterDto: ParameterDto;
};

export const SegmentationParameter = ({
  parameterDto,
}: SegmentationParameterProps) => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();

  const selectedValues: SegmentationSelection[] = useAppSelector(
    getSegmentationParameterState(parameterDto.id)
  ).selections;

  const { modalVisible } = useSelector((state: RootState) => ({
    modalVisible: state.wizard.segmentationModalShown,
  }));

  const setModalVisible = useCallback(
    (isVisible: boolean) => dispatch(setSegmentationModalShown(isVisible)),
    [dispatch]
  );

  const modalVisiblityState = useMemo(
    () => ({
      isShown: modalVisible,
      setIsShown: setModalVisible,
    }),
    [modalVisible, setModalVisible]
  );

  const showCustomerGroups = parameterDto.enableCustomerGroups ?? true;

  const { data: customerGroupTypes } = useGetCustomerGroupTypesQuery(
    divisionName,
    { skip: !showCustomerGroups }
  );

  const { data: customerGroups } = useGetCustomerGroupsQuery(
    { divisionName },
    { skip: !showCustomerGroups, pollingInterval: 10_000 }
  );

  const customGroupsParameterDto = customerGroups
    ?.filter((group) => group.status === CustomerGroupStatus.Valid)
    .sort(
      (g1, g2) =>
        Date.parse(g1.refreshDateUtc ?? g1.createDateUtc) -
        Date.parse(g2.refreshDateUtc ?? g2.createDateUtc)
    )
    .reverse()
    .map(
      (group) =>
        ({
          groupName: CUSTOMER_GROUPS_GROUP_NAME,
          isAggregate: false,
          isDefault: false,
          isDisabled: false,
          label: group.name,
          value: group.id,
          status: group.status,
          type: group.type,
          segmentationType: SegmentationType.CustomerGroup,
          description: "",
          segments: group.segments,
        } as SegmentationParameterOption)
    );

  const onChange = useCallback(
    (option: SegmentationParameterOption) => {
      dispatch(
        segmentationOptionSelected({
          parameter: parameterDto.id,
          selectedValue: {
            id: option.value,
            name: option.label,
            segmentationType: option.segmentationType,
            segments: option.segments,
          } as SegmentationSelection,
        })
      );
    },
    [parameterDto.id, dispatch]
  );

  const getTooltipText = useCallback(
    (show: boolean) => {
      if (show) {
        return `A maximum of ${parameterDto.attributes.maxSelections} ${parameterDto.name} may be selected`;
      }

      return undefined;
    },
    [parameterDto]
  );

  const checkboxCell = useCallback(
    (info: CellContext<SegmentationParameterOption, unknown>) => {
      const isChecked = selectedValues
        .map((selection) => selection.id)
        .includes(info.row.original.value);

      const isDisabled =
        selectedValues.length >= parameterDto.attributes.maxSelections &&
        !isChecked;

      const tooltipText = getTooltipText(isDisabled);

      const rowFormBlock = (
        <div>
          <FormBlock
            blockType={FormBlockType.Checkbox}
            className={styles.formBlock}
          >
            <Input className={styles.input}>
              <Checkbox
                checked={isChecked}
                className={styles.checkbox}
                editability={
                  isDisabled
                    ? FormBlockEditability.Disabled
                    : FormBlockEditability.Editable
                }
                id={info.row.original.value}
                label=""
                name={info.row.original.label}
                onChange={() => onChange(info.row.original)}
              />
            </Input>
          </FormBlock>
        </div>
      );

      return (
        <>
          {tooltipText ? (
            <Tooltip
              placement={TooltipPlacement.RightCentre}
              spaceInside={TooltipSpaceInside.Medium}
              trigger={rowFormBlock}
              variant={TooltipVariant.ArrowDark}
            >
              <div className={styles.checkboxRowTooltip}>{tooltipText}</div>
            </Tooltip>
          ) : (
            rowFormBlock
          )}
        </>
      );
    },
    [
      selectedValues,
      onChange,
      getTooltipText,
      parameterDto.attributes.maxSelections,
    ]
  );

  const headerCell = (groupName: string) => (
    <div className={styles.headerCell}>
      {groupName}
      {groupName === "Standard customer segmentation" && (
        <SegmentationModal
          className={styles.modalButton}
          modalVisbilityState={modalVisiblityState}
          segmentations={parameterDto.options.map(
            (segmentation) => segmentation.label
          )}
        />
      )}
    </div>
  );

  const nameCell = (
    info: CellContext<SegmentationParameterOption, unknown>
  ) => {
    const isChecked = selectedValues
      .map((selection) => selection.id)
      .includes(info.row.original.value);
    const isDisabled =
      selectedValues.length >= parameterDto.attributes.maxSelections &&
      !isChecked;
    return (
      <div className={isDisabled ? styles.disabledName : undefined}>
        {info.getValue<string>()}
      </div>
    );
  };

  const statusCell = (
    info: CellContext<SegmentationParameterOption, unknown>
  ) => (
    <span className={styles.status}>
      <CustomerGroupStatusTag
        status={info.getValue<string>() as CustomerGroupStatus}
      />
    </span>
  );

  const typeCell = (
    info: CellContext<SegmentationParameterOption, unknown>
  ) => (
    <span className={styles.customerGroupType}>
      <CustomerGroupIcon
        className={styles.customerGroupIcon}
        type={info.getValue<string>()}
      />
      {
        customerGroupTypes?.find(
          (type) => type.customerGroupType === info.getValue<string>()
        )?.displayName
      }
    </span>
  );

  const detailsCell = useCallback(
    (info: CellContext<SegmentationParameterOption, unknown>) => (
      <CgDetailsModalTrigger
        customerGroupId={info.row.original.value}
        customerGroupTypes={customerGroupTypes as CustomerGroupTypeDto[]}
        customerGroups={customerGroups as CustomerGroupWithSharingDto[]}
      />
    ),
    [customerGroups, customerGroupTypes]
  );

  const getColumns = (
    groupName: string,
    showStatusTypeAndDetails: boolean
  ): Array<ColumnDef<SegmentationParameterOption>> => [
    {
      accessorFn: (row: SegmentationParameterOption) => row.value,
      cell: checkboxCell,
      header: "",
      id: "selectColumn",
      minSize: 40,
      maxSize: 40,
      size: 40,
    },
    {
      accessorFn: (row: SegmentationParameterOption) => row.label,
      cell: nameCell,
      header: () => headerCell(groupName),
      id: "name",
      minSize: 400,
      maxSize: 400,
      size: 400,
    },
    {
      accessorFn: (row: SegmentationParameterOption) => row.status,
      cell: showStatusTypeAndDetails ? statusCell : () => {},
      header: showStatusTypeAndDetails ? "Status" : "",
      id: "status",
      minSize: 150,
      maxSize: 150,
      size: 150,
    },
    {
      accessorFn: (row: SegmentationParameterOption) => row.type,
      cell: showStatusTypeAndDetails ? typeCell : () => {},
      header: showStatusTypeAndDetails ? "Type" : "",
      id: "type",
      minSize: 250,
      maxSize: 250,
      size: 250,
    },
    {
      accessorFn: () => {},
      cell:
        showStatusTypeAndDetails &&
        flags[FeatureFlag.ReportWizardCustomerGroupDetails]
          ? detailsCell
          : () => {},
      header:
        showStatusTypeAndDetails &&
        flags[FeatureFlag.ReportWizardCustomerGroupDetails]
          ? "Details"
          : "",
      id: "details",
      minSize: 80,
      maxSize: 80,
      size: 80,
    },
  ];

  return (
    <Group className={styles.tableContainer}>
      <Item halign={ItemHalign.Left}>
        <>
          <BasicTable
            className={styles.segmentationTableHeader}
            columns={getColumns(parameterDto.name, false)}
            data={[]}
            enableColumnResizing={false}
            key="table-segmentation-header"
            onRowClick={undefined}
          />
          <BasicTable
            className={styles.segmentationTable}
            columns={getColumns(parameterDto.options[0].groupName, false)}
            data={parameterDto.options as SegmentationParameterOption[]}
            enableColumnResizing={false}
            key="table-segmentation-standard"
            onRowClick={undefined}
          />
          {showCustomerGroups && (
            <BasicTable
              className={styles.segmentationTable}
              columns={getColumns(CUSTOMER_GROUPS_GROUP_NAME, true)}
              data={customGroupsParameterDto ?? []}
              enableColumnResizing={false}
              key="table-segmentation-custom"
              onRowClick={undefined}
            />
          )}
          {showCustomerGroups && customGroupsParameterDto?.length === 0 && (
            <div className={styles.noCustomerGroups}>
              {NO_CUSTOMER_GROUPS_MESSAGE}
            </div>
          )}
        </>
      </Item>
    </Group>
  );
};

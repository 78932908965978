import {
  Button,
  ButtonHeight,
  ButtonVariant,
  FormBlock,
  FormBlockDesignVariant,
  FormBlockType,
  Group,
  Icon,
  IconGlyph,
  Input,
  Item,
  ItemHalign,
  ItemValign,
  Menu,
  MenuItemButton,
  MenuModule,
  MenuSection,
  Nav,
  NavButton,
  NavSize,
  Text,
  Toggle,
  ToggleLabelPosition,
  ToggleLayout,
  ToggleSize,
  ToggleVariant,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import {
  type CustomerGroupWithSharingDto,
  type FolderOrGroupDto,
  TrackingComponent,
  useDeleteCustomerGroupsMutation,
  useDeleteGroupsMutation,
  useMoveGroupFolderMutation,
  useMoveGroupMutation,
  type CustomerGroupDtoWithDisplayName,
  type HierarchyGroupDto,
  type MoveGroupRequestDto,
  type MoveFolderRequestDto,
  HierarchyType,
  useCreateGroupFolderMutation,
  FeatureFlag,
  useDeleteGroupFolderMutation,
  formatDate,
  searchGroupFolderDto,
  mapFolderDto,
  AppContext,
} from "@quantium-enterprise/common-ui";
import { isEnumValue } from "@quantium-enterprise/common-ui";
import { type CreateGroupFolderRequest } from "@quantium-enterprise/common-ui/src/models/create-group-folder-dto";
import { type UserModuleAccess } from "@quantium-enterprise/common-ui/src/models/division-dto";
import {
  useDivision,
  useFlags,
  useModuleAccessProtection,
} from "@quantium-enterprise/hooks-ui";
import { DownloadIcon } from "components-ui/src/assets/icons/DownloadIcon";
import {
  DeleteDialog,
  type DeleteItems,
} from "components-ui/src/delete-dialog/DeleteDialog";
import {
  cleanFilename,
  downloadFile,
  toCSV,
} from "components-ui/src/export/export-functions";
import { AppFooter } from "components-ui/src/footer/AppFooter";
import {
  type Folder,
  MoveItemModal,
  type MoveItemModalSubmitProps,
} from "components-ui/src/modals/move-item-modal/MoveItemModal";
import { ShareModal } from "components-ui/src/modals/share-modal/ShareModal";
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { SearchBox } from "../../../../components/src/search-box/SearchBox";
import { GroupInfoPanel } from "../../components/group-info-panel/GroupInfoPanel";
import { type CustomerGroupListTableProps } from "../../components/group-list-table/CustomerGroupListTable/CustomerGroupListTable";
import { CustomerGroupListTable } from "../../components/group-list-table/CustomerGroupListTable/CustomerGroupListTable";
import { type HierarchyGroupListTableProps } from "../../components/group-list-table/HierarchyGroupListTable/HierarchyGroupListTable";
import { HierarchyGroupListTable } from "../../components/group-list-table/HierarchyGroupListTable/HierarchyGroupListTable";
import { type SegmentLibraryTableProps } from "../../components/segment-library-table/SegmentLibraryTable";
import { SegmentLibraryTable } from "../../components/segment-library-table/SegmentLibraryTable";
import { type SegmentRow } from "../../components/segment-library-table/SegmentLibraryTableCells";
import { CustomerGroupTab } from "../../enums/customer-group-tab-ids";
import { GroupType } from "../../enums/group-type";
import {
  clearSelectedSegments,
  resetAllScrollStates,
  selectActivatedGroupIds,
  selectFoldersRaw,
  selectSelectedSegments,
  selectShowActivatedSegments,
  setEditingState,
  setFolderExpandedState,
  setRenameId,
  setSearchText,
  setShowActiveSegments,
} from "../../states/group-list-slice";
import {
  isFolder,
  findAncestors,
  isHierarchyGroupDto,
  isGroupFolderDto,
} from "../../utilities/folder-helper";
import {
  getDefaultGroupsPath,
  getGroupCreatorPath,
  getGroupListPath,
} from "../../utilities/route-path-formats";
import { toCSVData } from "../../utilities/segment-library-csv-utils";
import {
  isFolderOrHierarchyGroup,
  isWithSharingDto,
} from "../../utilities/util";
import styles from "./GroupList.module.css";

type TableProps = CustomerGroupListTableProps &
  HierarchyGroupListTableProps &
  SegmentLibraryTableProps;

type CustomerGroupTabWithDisplayName = {
  displayName: string;
  id: CustomerGroupTab;
  key: number;
};

const customerGroupTabs: CustomerGroupTabWithDisplayName[] = [
  {
    displayName: "Customer groups",
    id: CustomerGroupTab.CustomerGroups,
    key: 0,
  },
  {
    displayName: "Segment library",
    id: CustomerGroupTab.SegmentLibrary,
    key: 1,
  },
];

type GroupListProps = {
  customerGroupTab: CustomerGroupTab;
};

// eslint-disable-next-line complexity
export const GroupList = ({ customerGroupTab }: GroupListProps) => {
  const { userState } = useContext(AppContext);
  const navigate = useNavigate();
  const division = useDivision();
  const divisionName = division.name;
  const { groupType, groupId } = useParams();
  const location = useLocation();
  const flags = useFlags();
  const dispatch = useDispatch();
  const showActivatedSegments = useSelector(selectShowActivatedSegments);

  const moduleAccessAccessor = useCallback(
    (moduleAccess: UserModuleAccess) => {
      if (groupType === GroupType.Product) {
        return moduleAccess.groups.productGroups;
      }

      if (groupType === GroupType.Location) {
        return moduleAccess.groups.locationGroups;
      }

      if (groupType === GroupType.Customer) {
        return moduleAccess.groups.customerGroups;
      }

      return { hasAccess: false };
    },
    [groupType]
  );

  useModuleAccessProtection(groupType ? moduleAccessAccessor : null);

  const tabs = useMemo(() => {
    const result = [];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Causes crashes if not checked
    if (division.moduleAccess) {
      if (division.moduleAccess.groups.productGroups.hasAccess) {
        result.push({
          groupType: GroupType.Product,
          key: 0,
        });
      }

      if (division.moduleAccess.groups.locationGroups.hasAccess) {
        result.push({
          groupType: GroupType.Location,
          key: 1,
        });
      }

      if (division.moduleAccess.groups.customerGroups.hasAccess) {
        result.push({
          groupType: GroupType.Customer,
          key: 2,
        });
      }
    }

    return result;
  }, [division]);

  const folder = useSelector(selectFoldersRaw);

  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [groupIdsToDelete, setGroupIdsToDelete] = useState<string[]>([]);
  const [folderIdsToDelete, setFolderIdsToDelete] = useState<string[]>([]);
  const [focalGroup, setFocalGroup] = useState<
    CustomerGroupWithSharingDto | FolderOrGroupDto | SegmentRow | undefined
  >();
  const [selectedMoveFolder, setSelectedMoveFolder] =
    useState<FolderOrGroupDto>();
  const [selectedMoveGroup, setSelectedMoveGroup] =
    useState<FolderOrGroupDto>();
  const [selectedCustomerGroupTab, setSelectedCustomerGroupTab] = useState(
    customerGroupTabs.find((tab) => tab.id === customerGroupTab)
  );
  const setSelectedCustomerGroupTabAndOtherState = useCallback(
    (newTab: CustomerGroupTabWithDisplayName | undefined) => {
      if (selectedCustomerGroupTab?.id !== newTab?.id) {
        dispatch(clearSelectedSegments());
        setSelectedGroupIds([]);
      }

      setSelectedCustomerGroupTab(newTab);
    },
    [dispatch, setSelectedCustomerGroupTab, selectedCustomerGroupTab?.id]
  );
  const [createFolderTrigger] = useCreateGroupFolderMutation();

  const rootFolder = useSelector(selectFoldersRaw);

  const activatedGroupsIds = useSelector(selectActivatedGroupIds);
  const selectedSegments = useSelector(selectSelectedSegments);
  const multipleSegmentsSelected = selectedSegments.length > 0;

  const showSegmentBuilder =
    groupType === GroupType.Customer &&
    flags[FeatureFlag.SegmentBuilder] &&
    division.moduleAccess.groups.customerGroups.segmentBuilder.hasAccess;

  const Table = useCallback(
    (properties: TableProps) => {
      switch (groupType) {
        case GroupType.Product:
        case GroupType.Location:
          return <HierarchyGroupListTable {...properties} />;

        case GroupType.Customer:
          switch (selectedCustomerGroupTab?.id) {
            case CustomerGroupTab.CustomerGroups:
              return <CustomerGroupListTable {...properties} />;
            case CustomerGroupTab.SegmentLibrary:
              return <SegmentLibraryTable {...properties} />;
            default:
              return <> </>;
          }

        default:
          return <> </>;
      }
    },
    [groupType, selectedCustomerGroupTab?.id]
  );

  useEffect(() => {
    const isSegmentBuilderTab = location.pathname
      .split("/")
      .includes("segment-library");
    if (groupType === GroupType.Customer && isSegmentBuilderTab) {
      setSelectedCustomerGroupTabAndOtherState(
        customerGroupTabs.find(
          (tab) => tab.id === CustomerGroupTab.SegmentLibrary
        )
      );
    } else if (groupType === GroupType.Customer && !isSegmentBuilderTab) {
      setSelectedCustomerGroupTabAndOtherState(
        customerGroupTabs.find(
          (tab) => tab.id === CustomerGroupTab.CustomerGroups
        )
      );
    }
  }, [groupType, location, setSelectedCustomerGroupTabAndOtherState]);

  const mappedGroupFolders = useMemo(
    (): Folder[] =>
      // map only folders, ignore groups, ignore sharing
      rootFolder
        ? mapFolderDto(
            rootFolder,
            undefined,
            undefined,
            (f2, depth, ancestorIds, parentId): Folder => ({
              parentId,
              id: f2.id ?? "",
              name: f2.name ?? "",
              creationDateUTC: f2.creationDateUtc ?? "",
            }),
            undefined
          )
        : [],
    [rootFolder]
  );

  useEffect(() => {
    if (!isEnumValue(GroupType)(groupType)) {
      navigate(getGroupCreatorPath(divisionName, GroupType.Product));
    }
  }, [divisionName, groupType, navigate]);

  const getDefaultGroupsPathInternal = useCallback(
    (customerGroupTabInternal: CustomerGroupTab) =>
      getDefaultGroupsPath(
        divisionName,
        groupType ?? "",
        customerGroupTabInternal
      ),
    [divisionName, groupType]
  );

  const [moveFolder] = useMoveGroupFolderMutation();
  const [moveGroup] = useMoveGroupMutation();
  const [deleteCustomerGroups] = useDeleteCustomerGroupsMutation();
  const [deleteHierarchyGroups] = useDeleteGroupsMutation();
  const [deleteFolder] = useDeleteGroupFolderMutation();
  const deleteGroups =
    groupType === GroupType.Customer
      ? deleteCustomerGroups
      : deleteHierarchyGroups;

  const deleteButtonRef = useRef(null);

  const showDeleteDialog = useMemo(
    () => groupIdsToDelete.length > 0 || folderIdsToDelete.length > 0,
    [folderIdsToDelete.length, groupIdsToDelete.length]
  );

  const handleDeleteDialogExit = useCallback(() => {
    setGroupIdsToDelete([]);
    setFolderIdsToDelete([]);
  }, []);

  const handleDeleteGroups = useCallback(() => {
    setSelectedGroupIds(
      selectedGroupIds.filter(
        (id) =>
          !groupIdsToDelete.includes(id) && !folderIdsToDelete.includes(id)
      )
    );
    setGroupIdsToDelete([]);
    setFolderIdsToDelete([]);
    navigate(
      getDefaultGroupsPathInternal(
        selectedCustomerGroupTab?.id ?? customerGroupTab
      )
    );
  }, [
    customerGroupTab,
    folderIdsToDelete,
    getDefaultGroupsPathInternal,
    groupIdsToDelete,
    navigate,
    selectedCustomerGroupTab,
    selectedGroupIds,
  ]);

  const [contextMenuGroup, setContextMenuGroup] = useState<
    CustomerGroupDtoWithDisplayName | FolderOrGroupDto | undefined
  >();

  const handleShareDialogExit = useCallback(() => {
    setContextMenuGroup(undefined);
  }, []);

  const itemsToDelete = useMemo(
    () =>
      ({
        groupIds: groupIdsToDelete,
        folderIds: folderIdsToDelete,
      } as DeleteItems),
    [groupIdsToDelete, folderIdsToDelete]
  );

  useEffect(() => {
    setFolderIdsToDelete([]);
    setGroupIdsToDelete([]);
    setSelectedGroupIds([]);
  }, [groupType]);

  useEffect(() => {
    dispatch(resetAllScrollStates());
  }, [groupType, dispatch, selectedCustomerGroupTab]);

  const onCheckboxSelect = useCallback(
    (selectedGroupId: string) => {
      setSelectedGroupIds((ids) =>
        ids.includes(selectedGroupId)
          ? ids.filter((id) => id !== selectedGroupId)
          : [...ids, selectedGroupId]
      );
    },
    [setSelectedGroupIds]
  );

  const createFolderPayload: CreateGroupFolderRequest = useMemo(
    () => ({
      hierarchyType: groupType as GroupType,
      parentFolderGuid: isFolder(focalGroup) ? focalGroup.id : undefined,
    }),
    [focalGroup, groupType]
  );

  const moveRootFolderName = useMemo(() => {
    if (groupType === GroupType.Customer) {
      return "Customer groups";
    } else if (groupType === GroupType.Location) {
      return "Location groups";
    } else {
      return "Product groups";
    }
  }, [groupType]);

  const createFolderButton = useMemo(
    () =>
      flags[FeatureFlag.FoldersForGroups] &&
      groupType !== GroupType.Customer && (
        <Button
          height={ButtonHeight.XSmall}
          id={styles.createFolderButton}
          onClick={async () => {
            navigate(getGroupListPath(divisionName, groupType ?? ""));
            const response = await createFolderTrigger({
              divisionName,
              payload: createFolderPayload,
            }).unwrap();
            const id = response.folderId;
            const parentFolderGuid = createFolderPayload.parentFolderGuid;

            if (parentFolderGuid && folder) {
              // it doesn't like it when id is used
              const ancestorFoldersGuid = findAncestors(
                parentFolderGuid,
                folder
              );
              // expand all the parents ancestors if there are any
              for (const ancestorsGuid of ancestorFoldersGuid) {
                dispatch(
                  setFolderExpandedState({
                    currentUserId: userState.currentUser?.salesForceId,
                    id: ancestorsGuid,
                    isExpanded: true,
                  })
                );
              }

              // expand the parent of the new folder
              dispatch(
                setFolderExpandedState({
                  currentUserId: userState.currentUser?.salesForceId,
                  id: parentFolderGuid,
                  isExpanded: true,
                })
              );
            }

            dispatch(setRenameId({ id }));
            dispatch(setEditingState({ editing: true }));
          }}
          variant={ButtonVariant.Secondary}
        >
          <Icon
            glyph={IconGlyph.AddAndPlusFolderPlus}
            id={styles.createFolderIcon}
            text="New folder"
          />
          <Text>New folder</Text>
        </Button>
      ),
    [
      flags,
      groupType,
      navigate,
      divisionName,
      createFolderTrigger,
      createFolderPayload,
      folder,
      dispatch,
      userState,
    ]
  );

  const onMoveFolder = async ({
    newParentFolderId,
  }: MoveItemModalSubmitProps): Promise<void> => {
    const returnPromise = new Promise<void>((resolve, reject) => {
      if (!selectedMoveFolder) {
        reject(new Error("Move Folder is undefined"));
        return;
      }

      const hierarchyType =
        groupType === "Location"
          ? HierarchyType.Location
          : HierarchyType.Product;

      const moveRequest: MoveFolderRequestDto = {
        folderId: selectedMoveFolder.id ?? "",
        hierarchyType,
        targetParentFolderId: newParentFolderId,
      };

      moveFolder({ divisionName, moveRequest })
        .unwrap()
        .then(async () => {
          resolve();
        })
        .catch(async (error) => {
          reject(new Error(error));
        })
        .finally(() => {
          setSelectedMoveGroup(undefined);
        });
    });
    await returnPromise;
  };

  const onMoveGroup = async ({
    newParentFolderId,
  }: MoveItemModalSubmitProps): Promise<void> => {
    const returnPromise = new Promise<void>((resolve, reject) => {
      if (!selectedMoveGroup) {
        reject(new Error("Move Group is undefined"));
        return;
      }

      const moveRequest: MoveGroupRequestDto = {
        groupId: selectedMoveGroup.id ?? "",
        hierarchyType: (selectedMoveGroup as HierarchyGroupDto).hierarchyType,
        targetFolderId: newParentFolderId,
      };

      moveGroup({ divisionName, moveRequest })
        .unwrap()
        .then(async () => {
          resolve();
        })
        .catch(async (error) => {
          reject(new Error(error));
        })
        .finally(() => {
          setSelectedMoveGroup(undefined);
        });
    });
    await returnPromise;
  };

  const onDeleteFolder = (folderId: string) => {
    setFolderIdsToDelete([folderId]);
    setGroupIdsToDelete([]);
  };

  const onDeleteGroup = (groupId_: string) => {
    setFolderIdsToDelete([]);
    setGroupIdsToDelete([groupId_]);
  };

  const onSearch = useCallback(
    (searchTerm: string) => {
      dispatch(setSearchText(searchTerm));
    },
    [dispatch]
  );

  const onBulkDeleteGroupsAndFolders = useCallback(() => {
    if (!rootFolder) return;

    const selectedGroupsAndFolders = searchGroupFolderDto(
      rootFolder,
      (groupOrFolder) =>
        Boolean(groupOrFolder.id) &&
        selectedGroupIds.includes(groupOrFolder.id as string)
    );

    setGroupIdsToDelete(
      selectedGroupsAndFolders
        .filter(isHierarchyGroupDto)
        .map((x) => x.id as string)
    );
    setFolderIdsToDelete(
      selectedGroupsAndFolders
        .filter(isGroupFolderDto)
        .map((x) => x.id as string)
    );
  }, [rootFolder, selectedGroupIds]);

  // the actual deleting of items on clicking delete in the popup
  const deleteItems = async (items: DeleteItems) => {
    const promises: Array<Promise<unknown>> = [];

    if (items.groupIds?.length) {
      promises.push(
        deleteGroups({
          divisionName,
          itemIds: items.groupIds,
        }).unwrap()
      );
    }

    for (const folderId of items.folderIds ?? []) {
      promises.push(
        deleteFolder({
          divisionName,
          dto: {
            folderId,
          },
        }).unwrap()
      );
    }

    return await Promise.all(promises);
  };

  const isDeleteButtonDisabled = selectedGroupIds.some((selectedGroupId) =>
    activatedGroupsIds.includes(selectedGroupId)
  );

  const DeleteButton = useMemo(
    () => (
      <span>
        <Button
          disabled={isDeleteButtonDisabled}
          height={ButtonHeight.XSmall}
          id={styles.deleteButton}
          onClick={onBulkDeleteGroupsAndFolders}
          variant={ButtonVariant.Danger}
        >
          <Icon
            glyph={IconGlyph.DeleteAndCloseDelete}
            id={styles.deleteIcon}
            text="Delete"
          />
          <Text>Delete</Text>
        </Button>
      </span>
    ),
    [onBulkDeleteGroupsAndFolders, isDeleteButtonDisabled]
  );

  const onBulkDownloadSegmentDetails = useCallback(() => {
    const data = toCSVData(selectedSegments);
    const csvContent = toCSV(undefined, undefined, undefined, data);
    const encodedUri = encodeURI(csvContent);
    const filename = cleanFilename(
      `Multi_segment_details_${formatDate(new Date())}`
    );
    downloadFile(encodedUri, `${filename}.csv`);
  }, [selectedSegments]);

  const bulkDownloadSegmentDetailsButton = useMemo(
    () => (
      <span>
        <Button
          data-testid="download-segment-details"
          height={ButtonHeight.XSmall}
          id="download-segment-details"
          onClick={onBulkDownloadSegmentDetails}
          variant={ButtonVariant.Secondary}
        >
          <DownloadIcon />
          <Text className={styles.bulkDownloadSegmentDetailsButtonText}>
            Download segment details
          </Text>
        </Button>
      </span>
    ),
    [onBulkDownloadSegmentDetails]
  );

  const MemoisedSearchBox = useMemo(
    () => (
      <>
        <FormBlock
          blockType={FormBlockType.Search}
          designvariant={FormBlockDesignVariant.Default}
        >
          <Input>
            <SearchBox
              debounceTimeMs={500}
              enableDebounce
              onChange={onSearch}
              placeholder="Search"
            />
          </Input>
        </FormBlock>
        {selectedGroupIds.length === 0 && createFolderButton}
        {selectedGroupIds.length !== 0 &&
          (isDeleteButtonDisabled ? (
            <Tooltip
              placement={TooltipPlacement.BottomCentre}
              spaceInside={TooltipSpaceInside.Small}
              trigger={DeleteButton}
              variant={TooltipVariant.ArrowDark}
            >
              <div className={styles.tooltipText}>
                Some selected groups can't be deleted
                <br /> as they're used in the Segment Library
              </div>
            </Tooltip>
          ) : (
            DeleteButton
          ))}
        {multipleSegmentsSelected &&
          selectedCustomerGroupTab?.id === CustomerGroupTab.SegmentLibrary &&
          bulkDownloadSegmentDetailsButton}
      </>
    ),
    [
      createFolderButton,
      onSearch,
      selectedGroupIds.length,
      DeleteButton,
      isDeleteButtonDisabled,
      multipleSegmentsSelected,
      bulkDownloadSegmentDetailsButton,
      selectedCustomerGroupTab?.id,
    ]
  );

  return (
    <div className={styles.groupListContainer}>
      <GroupInfoPanel
        customerGroupTab={selectedCustomerGroupTab?.id ?? customerGroupTab}
        focalGroup={focalGroup}
        onClose={() => {
          navigate(
            getDefaultGroupsPathInternal(
              selectedCustomerGroupTab?.id ?? customerGroupTab
            )
          );
          setFocalGroup(undefined);
        }}
        onMove={() => {
          if (isFolderOrHierarchyGroup(focalGroup) && !focalGroup.isFolder) {
            setSelectedMoveGroup(focalGroup);
          }
        }}
        showPanel={
          Boolean(focalGroup) && Boolean(groupId) && !isFolder(focalGroup)
        }
      >
        <div className={styles.page}>
          <h3 className={styles.title}>Groups</h3>
          <Group className={styles.sectionTabNav}>
            <Item halign={ItemHalign.Centre}>
              <Nav
                activeIndex={tabs.findIndex((tab) =>
                  location.pathname.includes(tab.groupType)
                )}
                size={NavSize.Medium}
              >
                {tabs.map((tab) => (
                  <Link
                    key={tab.key}
                    to={getGroupListPath(divisionName, tab.groupType)}
                  >
                    <NavButton>{`${tab.groupType} groups`}</NavButton>
                  </Link>
                ))}
              </Nav>
            </Item>
          </Group>
          {groupType === GroupType.Product ||
          groupType === GroupType.Location ||
          !showSegmentBuilder ? (
            <Group className={styles.sectionSearchCreate}>
              <Item halign={ItemHalign.Left}>
                <div className={styles.leftButtonGroup}>
                  {MemoisedSearchBox}
                </div>
              </Item>
              <Item halign={ItemHalign.Right}>
                <Link to={getGroupCreatorPath(divisionName, groupType)}>
                  <Button
                    height={ButtonHeight.XSmall}
                    variant={ButtonVariant.Primary}
                  >
                    <Icon
                      glyph={IconGlyph.AddAndPlusAddPlus}
                      text={`Create a ${groupType?.toLowerCase()} group`}
                    />
                    <Text>{`Create a ${groupType?.toLowerCase()} group`}</Text>
                  </Button>
                </Link>
              </Item>
            </Group>
          ) : undefined}
          <Group
            className={
              showSegmentBuilder
                ? styles.customerGroupTableContainer
                : styles.tableContainer
            }
          >
            {showSegmentBuilder ? (
              <Group className={styles.sectionSearchCreate}>
                <Item halign={ItemHalign.Left}>
                  <div className={styles.leftButtonGroup}>
                    {MemoisedSearchBox}
                  </div>
                </Item>
                {selectedCustomerGroupTab?.id ===
                CustomerGroupTab.SegmentLibrary ? (
                  <div className={styles.activatedSegmentsToggle}>
                    <Item halign={ItemHalign.Right} valign={ItemValign.Middle}>
                      <Toggle
                        checked={showActivatedSegments}
                        label="Active segments only"
                        labelPosition={ToggleLabelPosition.Start}
                        layout={ToggleLayout.Together}
                        onClick={() =>
                          dispatch(
                            setShowActiveSegments(!showActivatedSegments)
                          )
                        }
                        size={ToggleSize.Small}
                        variant={ToggleVariant.TickAndCross}
                      />
                    </Item>
                  </div>
                ) : (
                  <Item halign={ItemHalign.Right}>
                    <Link to={getGroupCreatorPath(divisionName, groupType)}>
                      <Button
                        height={ButtonHeight.XSmall}
                        variant={ButtonVariant.Primary}
                      >
                        <Icon
                          glyph={IconGlyph.AddAndPlusAddPlus}
                          text={`Create a ${groupType.toLowerCase()} group`}
                        />
                        <Text>{`Create a ${groupType.toLowerCase()} group`}</Text>
                      </Button>
                    </Link>
                  </Item>
                )}
              </Group>
            ) : undefined}
            {showSegmentBuilder && (
              <MenuModule className={styles.customerGroupListMenuArea}>
                <Menu>
                  <MenuSection>
                    {customerGroupTabs.map((tab) => (
                      <MenuItemButton
                        key={`customer-group-tab-${tab.id}`}
                        onClick={() => {
                          setFocalGroup(undefined);
                          setSelectedCustomerGroupTabAndOtherState(tab);
                          navigate(getDefaultGroupsPathInternal(tab.id));
                        }}
                        selected={selectedCustomerGroupTab?.id === tab.id}
                        text={<Text>{tab.displayName}</Text>}
                      />
                    ))}
                  </MenuSection>
                </Menu>
              </MenuModule>
            )}
            <Item
              className={styles.customerGroupTable}
              halign={ItemHalign.Left}
            >
              <ErrorBoundary>
                {groupType && (
                  <Table
                    groupType={groupType}
                    handleDeleteFolder={onDeleteFolder}
                    handleDeleteGroup={onDeleteGroup}
                    handleMoveFolder={setSelectedMoveFolder}
                    handleMoveGroup={setSelectedMoveGroup}
                    onCheckboxSelect={onCheckboxSelect}
                    setContextMenuGroup={setContextMenuGroup}
                    setFocalGroup={(group) => {
                      setFocalGroup(group);
                    }}
                  />
                )}
              </ErrorBoundary>
            </Item>
          </Group>
        </div>
      </GroupInfoPanel>
      <AppFooter />
      <DeleteDialog
        deleteItem={deleteItems}
        itemIds={itemsToDelete}
        onClose={handleDeleteDialogExit}
        onDelete={handleDeleteGroups}
        ref={deleteButtonRef}
        show={showDeleteDialog}
        trackingComponent={
          groupType === GroupType.Product
            ? TrackingComponent.ProductGroup
            : groupType === GroupType.Location
            ? TrackingComponent.LocationGroup
            : TrackingComponent.CustomerGroup
        }
      />
      <ShareModal.Groups
        entityType={isFolder(contextMenuGroup) ? "Folder" : "Group"}
        groupId={contextMenuGroup?.id}
        groupName={contextMenuGroup?.name}
        groupType={groupType as GroupType}
        onClose={handleShareDialogExit}
        ownerId={
          isWithSharingDto(contextMenuGroup)
            ? contextMenuGroup.userId
            : undefined
        }
        sharedWithUserIds={
          isWithSharingDto(contextMenuGroup)
            ? contextMenuGroup.sharedWithUserIds
            : undefined
        }
        showModal={Boolean(contextMenuGroup)}
      />
      <MoveItemModal
        folders={mappedGroupFolders}
        key={selectedMoveFolder?.id}
        onClose={() => setSelectedMoveFolder(undefined)}
        onSubmit={onMoveFolder}
        rootFolderName={moveRootFolderName}
        selectedItemFolderId={selectedMoveFolder?.parentId}
        selectedItemId={selectedMoveFolder?.id ?? ""}
        show={Boolean(selectedMoveFolder)}
      />
      <MoveItemModal
        folders={mappedGroupFolders}
        key={selectedMoveGroup?.id}
        onClose={() => setSelectedMoveGroup(undefined)}
        onSubmit={onMoveGroup}
        rootFolderName={moveRootFolderName}
        selectedItemFolderId={selectedMoveGroup?.parentId}
        selectedItemId={selectedMoveGroup?.id ?? ""}
        show={Boolean(selectedMoveGroup)}
      />
    </div>
  );
};

import {
  InputStatus,
  Item,
  Tag,
  TagColour,
  TagVariant,
  Text,
} from "@qbit/react";
import styles from "./RequiredFieldError.module.css";

export const RequiredFieldError = () => (
  <Item className={styles.errorContainer}>
    <Tag
      className={styles.errorIcon}
      colour={TagColour.Bad}
      text="required field"
      variant={TagVariant.Badge}
    />
    <InputStatus id="error-status">
      <Text className={styles.errorText}>Required field </Text>
    </InputStatus>
  </Item>
);

export default RequiredFieldError;

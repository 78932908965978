import { Spinner, SpinnerSize } from "@qbit/react";
import { differenceInWeeks, format } from "date-fns";
import { useEffect, useState } from "react";
import { type WatchlistFocusPeriodDto } from "../services/dtos/WatchlistDto";
import styles from "./FocusPeriodDescription.module.css";

export type FocusPeriodDescriptionProps = {
  endDate?: string;
  selectedFocusPeriod: WatchlistFocusPeriodDto;
  startDate?: string;
};

export const FocusPeriodDescription = ({
  selectedFocusPeriod: selectedFocalPeriod,
  startDate,
  endDate,
}: FocusPeriodDescriptionProps) => {
  let numberWeeks = selectedFocalPeriod.focusPeriod;
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    numberWeeks = differenceInWeeks(end, start, { roundingMethod: "ceil" });
  }

  const durationText = numberWeeks === 1 ? "week" : numberWeeks + "w";

  const [mostRecentEndDate, setMostRecentEndDate] = useState<string>();
  useEffect(() => {
    if (endDate) {
      setMostRecentEndDate(endDate);
    }
  }, [endDate]);
  let endDateText: JSX.Element | string = <Spinner size={SpinnerSize.XSmall} />;
  if (mostRecentEndDate) {
    endDateText = format(new Date(mostRecentEndDate), "dd MMM yyyy");
  }

  return (
    <span className={styles.focusPeriodDescription}>
      Last {durationText} ending {endDateText}
    </span>
  );
};

export default FocusPeriodDescription;

import {
  Button,
  ButtonVariant,
  Group,
  Icon,
  IconGlyph,
  Text,
} from "@qbit/react";
import {
  TrackingComponent,
  TrackingEvent,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import {
  isSelectableAttributeItem,
  isSelectableAttributeLevelItem,
  isSelectableHierarchyItem,
  isSelectableProductGroupItem,
  type SelectableItem,
} from "@quantium-enterprise/fast-reporting-ui";
import { type HierarchySearchLevel } from "@quantium-enterprise/fast-reporting-ui/src/focal-item-search/HierarchySearchLevel";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { useState, useCallback } from "react";
import EmptyWatchlistBackground from "../assets/empty-watchlist-background.svg";
import EmptyFolderImage from "../assets/empty-watchlist-folder.svg";
import {
  useAddWatchlistItemsMutation,
  useCreateWatchlistMutation,
} from "../services/DashboardService";
import { trackWatchlistItemEvent } from "../services/WatchlistItemEventTracking";
import {
  type AddWatchlistProductGroupItemDto,
  type AddWatchlistItemDto,
  WatchlistItemType,
} from "../services/dtos/WatchlistItemDto";
import styles from "./EmptyWatchlist.module.css";
import HierarchySearchAddItemsLayout from "./hierarchy-search-add-items/HierarchySearchAddItemsLayout";

type EmptyWatchlistProps = {
  availableSearchLevels: HierarchySearchLevel[];
  dashboardId: string;
  maxWatchlistItems: number;
  onSearchLevelChanged: (level: HierarchySearchLevel) => void;
  onWatchlistUpsertFailed: () => void;
  onWatchlistUpsertSuccess: () => void;
  searchLevel?: HierarchySearchLevel;
  watchlistId: string | undefined;
};

export const EmptyWatchlist = ({
  dashboardId,
  onSearchLevelChanged,
  onWatchlistUpsertFailed,
  onWatchlistUpsertSuccess,
  watchlistId,
  availableSearchLevels,
  searchLevel,
  maxWatchlistItems,
}: EmptyWatchlistProps) => {
  const eventTrackingService = useEventTrackingServiceContext();

  const { name: division } = useDivision();

  const [showHierarchySearch, setShowHierarchySearch] = useState(false);

  const handleShowHierarchySearch = useCallback(() => {
    eventTrackingService.trackEvent(
      TrackingComponent.WatchlistAddItemsModal,
      TrackingEvent.Opened
    );
    setShowHierarchySearch(true);
  }, [eventTrackingService]);

  const handleCloseHierarchySearch = useCallback(() => {
    setShowHierarchySearch(false);
  }, []);

  const [createWatchlist] = useCreateWatchlistMutation();
  const [addWatchlistItems] = useAddWatchlistItemsMutation();

  const handleAddItems = (items: SelectableItem[]) => {
    const watchlistItems = items.map((item) => {
      if (isSelectableAttributeItem(item)) {
        return {
          code: item.code,
          hierarchyCode: item.additionalHierarchyFilter?.code,
          hierarchyLevel: item.additionalHierarchyFilter?.shortName,
          shortName: item.shortName,
          type: WatchlistItemType.Attribute,
        } as AddWatchlistItemDto;
      } else if (isSelectableAttributeLevelItem(item)) {
        return {
          code: item.parent.code,
          hierarchyCode: item.code,
          hierarchyLevel: item.shortName,
          shortName: item.parent.shortName,
          type: WatchlistItemType.Attribute,
        } as AddWatchlistItemDto;
      } else if (isSelectableHierarchyItem(item)) {
        return {
          code: item.code,
          shortName: item.shortName,
          type: WatchlistItemType.Hierarchy,
        } as AddWatchlistItemDto;
      } else if (isSelectableProductGroupItem(item)) {
        return {
          type: WatchlistItemType.ProductGroup,
          productGroupId: item.productGroupId,
        } as AddWatchlistProductGroupItemDto;
      } else {
        throw new Error("Unknown watchlist item type");
      }
    }) as AddWatchlistItemDto[];

    if (watchlistId) {
      addWatchlistItems({ dashboardId, division, watchlistId, watchlistItems })
        .unwrap()
        .then(() => {
          onWatchlistUpsertSuccess();
          for (const watchlistItem of watchlistItems) {
            trackWatchlistItemEvent(
              eventTrackingService,
              watchlistItem,
              TrackingEvent.Added
            );
          }
        })
        .catch(onWatchlistUpsertFailed)
        .finally(handleCloseHierarchySearch);
    } else {
      createWatchlist({ dashboardId, division, watchlistItems })
        .unwrap()
        .then(() => {
          onWatchlistUpsertSuccess();
          for (const watchlistItem of watchlistItems) {
            trackWatchlistItemEvent(
              eventTrackingService,
              watchlistItem,
              TrackingEvent.Added
            );
          }
        })
        .catch(onWatchlistUpsertFailed)
        .finally(handleCloseHierarchySearch);
    }
  };

  return (
    <Group
      data-cy="EmptyWatchlist"
      data-dashboard-id={dashboardId}
      data-watchlist-id={watchlistId}
    >
      <div className={styles.emptyWatchlistContainer}>
        <img
          alt="Empty Watchlist"
          className={styles.emptyWatchlistBackground}
          data-cy="EmptyWatchlistGraphic"
          src={EmptyWatchlistBackground}
        />
        <div className={styles.emptyWatchlistContent}>
          <img alt="Empty Folder" src={EmptyFolderImage} />
          <h1>Supercharge your analysis with a Watchlist.</h1>
          <h2>Easily track your favourite items in one place.</h2>
          <Button
            data-cy="CreateWatchlistItemButton"
            onClick={handleShowHierarchySearch}
            variant={ButtonVariant.Primary}
          >
            <Icon glyph={IconGlyph.AddAndPlusAddPlus} text="Create watchlist" />
            <Text>Create Watchlist</Text>
          </Button>
        </div>
      </div>
      <HierarchySearchAddItemsLayout
        availableSearchLevels={availableSearchLevels}
        existingSelectedItemCount={0}
        maxWatchlistItems={maxWatchlistItems}
        onAddItems={handleAddItems}
        onClose={handleCloseHierarchySearch}
        onSearchLevelChanged={onSearchLevelChanged}
        searchLevel={searchLevel}
        show={showHierarchySearch}
      />
    </Group>
  );
};

export default EmptyWatchlist;

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  IconSize,
} from "@qbit/react";
import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { ContextMenuType } from "components-ui/src/tables/common/table-cell/ContextMenu";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../../../../components/src/search-box/SearchBox";
import {
  onSearchChange,
  onShowAdvancedSearch,
  resetSearch,
  selectAdvancedSearchEnableState,
  selectFilterRules,
  selectHierarchyItems,
  selectHierarchySelectedItemsFiltered,
  selectIsSelectedItemsShown,
  selectSearchHasNextPage,
  selectSearchString,
  selectTriggerSearchState,
  setIsProductHierarchyRunOnLowestLevel,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import { AdvancedSearchEditor } from "../advanced-search/AdvancedSearchEditor";
import { isFilterComplete } from "../utils/isFilterComplete";
import styles from "./ProductHierarchyParameter.module.css";
import { ProductHierarchyParameterQuery } from "./ProductHierarchyParameterQuery";

export const PRODUCT_HIERARCHY_SEARCH_MAX_PAGE_SIZE = 500;
export const PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE = 500;
export const PRODUCT_HIERARCHY_EXPAND_QUERY_PAGE_SIZE = 500;
export const SEARCH_BOX_DEBOUNCE_TIME = 500;

type ProductHierarchyParameterProps = {
  parameterDto: ParameterDto;
};

export const ProductHierarchyParameter = ({
  parameterDto,
}: ProductHierarchyParameterProps) => {
  const dispatch = useDispatch();

  const contextMenuType =
    ContextMenuType[parameterDto.contextMenuType ?? "None"];

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(ParameterId.ProductHierarchy, state)
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(ParameterId.ProductHierarchy, state)
  );

  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectIsSelectedItemsShown(ParameterId.ProductHierarchy, state)
  );

  const selectedRowsFiltered = useSelector((state: RootState) =>
    selectHierarchySelectedItemsFiltered(ParameterId.ProductHierarchy, state)
  );

  const isAdvancedSearchEnabled = useSelector((state: RootState) =>
    selectAdvancedSearchEnableState(ParameterId.ProductHierarchy, state)
  );

  const searchHasNextPage = useSelector((state: RootState) =>
    selectSearchHasNextPage(ParameterId.ProductHierarchy, state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(ParameterId.ProductHierarchy, state)
  );

  const triggerSearch = useSelector((state: RootState) =>
    selectTriggerSearchState(ParameterId.ProductHierarchy, state)
  );

  const handleShowAdvancedSearch = () =>
    dispatch(onShowAdvancedSearch(ParameterId.ProductHierarchy));

  const handleSearchChange = useCallback(
    (searchText: string) => {
      dispatch(
        onSearchChange({
          parameterType: ParameterId.ProductHierarchy,
          searchString: searchText,
          filterRules,
        })
      );
    },
    [dispatch, filterRules]
  );

  const showSearchResultMessage = useMemo(
    () =>
      !triggerSearch &&
      (searchString !== "" ||
        (isAdvancedSearchEnabled &&
          filterRules.every((rule) => isFilterComplete(rule)))),
    [filterRules, isAdvancedSearchEnabled, searchString, triggerSearch]
  );

  const resultCountText = useMemo(() => {
    const amount = isSelectedItemsShown
      ? selectedRowsFiltered.length
      : items.length;
    if (searchHasNextPage && !isSelectedItemsShown) {
      return `${PRODUCT_HIERARCHY_SEARCH_MAX_PAGE_SIZE}+ matches found. Showing first ${PRODUCT_HIERARCHY_SEARCH_QUERY_PAGE_SIZE}, please refine your search further`;
    } else {
      return `${amount} match${amount === 1 ? "" : "es"} found`;
    }
  }, [
    searchHasNextPage,
    items.length,
    selectedRowsFiltered.length,
    isSelectedItemsShown,
  ]);

  useEffect(() => {
    dispatch(
      setIsProductHierarchyRunOnLowestLevel(
        contextMenuType === ContextMenuType.Tooltip
      )
    );
  }, [contextMenuType, dispatch]);

  /**
   * Reset the search state when the component is unmounted
   */
  useEffect(
    () => () => {
      dispatch(resetSearch(ParameterId.ProductHierarchy));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.productHierarchyParameter}>
      <div
        className={
          showSearchResultMessage
            ? styles.searchFilterRow
            : styles.emptySearchFilterRow
        }
      >
        <div className={styles.searchBox}>
          <SearchBox
            debounceTimeMs={SEARCH_BOX_DEBOUNCE_TIME}
            enableDebounce
            onChange={handleSearchChange}
          />
        </div>
        <Button
          height={ButtonHeight.XSmall}
          onClick={handleShowAdvancedSearch}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            glyph={IconGlyph.SortAndViewFilter}
            size={IconSize.Small}
            text="Filter"
          />
          <span>Filter</span>
        </Button>
        {showSearchResultMessage && (
          <div className={styles.resultCount}>{resultCountText}</div>
        )}
      </div>
      <div className={styles.advancedSearchFilterRow}>
        {isAdvancedSearchEnabled && (
          <AdvancedSearchEditor
            enableAdvancedSearch={handleShowAdvancedSearch}
          />
        )}
      </div>
      <div className={styles.contentRow}>
        <ProductHierarchyParameterQuery contextMenuType={contextMenuType} />
      </div>
    </div>
  );
};

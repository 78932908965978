import {
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  IconSize,
  InlineIconGlyph,
  StealthInlineIconButton,
} from "@qbit/react";
import { type HierarchyValueDto } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import React, { type PropsWithChildren, useState } from "react";
import { InfoIconDropdown } from "../../info-icon-dropdown/InfoIconDropdown";
import styles from "./SwitchingBreakdownTable.module.css";
import { type SwitchingBreakdownType } from "./models/SwitchingBreakdownType";
import {
  getIndexColourClass,
  getImpactColourClass,
  getMaxAbsoluteValueInArray,
  getImpactBarWidth,
} from "./switching-breakdown-utils/utils";

export type SwitchingBreakdownTableProps = {
  dataArray: SwitchingBreakdownType[];
  formatImpactValue?: (impact: number) => string;
  handleOnRowClick?: (item: HierarchyValueDto) => void;
  impactColumnHeader?: string;
  integerFormatter: Function;
  showSwitchingBreakdownChartDataLabels: boolean;
};

export const SwitchingBreakdownTable = ({
  dataArray,
  integerFormatter,
  formatImpactValue,
  handleOnRowClick,
  impactColumnHeader = "Impact of switching",
  children,
  showSwitchingBreakdownChartDataLabels,
}: PropsWithChildren<SwitchingBreakdownTableProps>) => {
  if (new Set(dataArray).size !== dataArray.length)
    throw new Error("item names must be unique");

  const maxImpactValue = getMaxAbsoluteValueInArray(dataArray);

  const [expanded, setExpanded] = useState<HierarchyValueDto>({
    itemCode: "",
    name: "",
    shortName: "",
  });
  const isExpanded = (item: SwitchingBreakdownType) => expanded === item.item;

  const handleExpanded = (item: SwitchingBreakdownType) => {
    if (isExpanded(item)) {
      setExpanded({
        itemCode: "",
        name: "",
        shortName: "",
      });
    } else {
      setExpanded(item.item);
      handleOnRowClick?.(item.item);
    }
  };

  return (
    <div data-testid="table">
      <Table
        aria-rowcount={dataArray.length || 0}
        className={styles.switchingBreakdownTable}
        thead={
          <Thead>
            <Tr>
              <Th>
                <div className={styles.switchingBreakdownTableFlexContent}>
                  Switching item
                </div>
              </Th>
              <Th />
              <Th>
                <div className={styles.switchingBreakdownTableFlexContent}>
                  {impactColumnHeader}
                </div>
              </Th>
              <Th>
                <div
                  className={classNames(
                    styles.switchingBreakdownTableFlexContent,
                    styles.fairShareIndex
                  )}
                >
                  <div className={styles.fairShareIndexHeaderText}>
                    Fair share index
                  </div>
                  <InfoIconDropdown>
                    <div className={styles.fairShareInfoTitle}>
                      Fair share index
                    </div>
                    <div className={styles.fairShareInfo}>
                      An index of 100 indicates the switching amount is exactly
                      as expected given relative share.
                    </div>
                  </InfoIconDropdown>
                </div>
              </Th>
              <Th />
            </Tr>
          </Thead>
        }
      >
        <Tbody>
          {dataArray.map((item, index) => (
            <React.Fragment key={item.item.itemCode}>
              <tr
                aria-rowindex={index}
                className={classNames(
                  isExpanded(item) && styles.itemIsExpanded
                )}
                onClick={() => {
                  handleExpanded(item);
                }}
              >
                <Td className={styles.brandCell}>
                  <div>{item.item.name}</div>
                </Td>
                <Td className={styles.expandTrigger}>
                  {isExpanded(item) ? (
                    <div className={styles.hideTriggerIconContent}>
                      <StealthInlineIconButton
                        data-testid="switching-breakdown-table-hide-row-button"
                        iconGlyph={InlineIconGlyph.ArrowsChevronUp}
                        iconSize={IconSize.Small}
                        iconText="Collapse"
                      />
                    </div>
                  ) : (
                    <div className={styles.expandTriggerIconContent}>
                      <StealthInlineIconButton
                        data-testid="switching-breakdown-table-expand-row-button"
                        iconGlyph={InlineIconGlyph.ArrowsChevronDown}
                        iconSize={IconSize.Small}
                        iconText="Expand"
                      />
                    </div>
                  )}
                </Td>
                <Td className={styles.switchingImpact}>
                  <div className={styles.flexContainer}>
                    <div
                      className={`${
                        styles[getImpactColourClass(item.impact)]
                      } ${styles.switchingImpact}`}
                      style={{
                        width: `${getImpactBarWidth(
                          maxImpactValue,
                          item.impact
                        )}`,
                      }}
                    />

                    <div
                      className={classNames(styles.switchingImpactValue, {
                        [styles.valueHidden]:
                          !showSwitchingBreakdownChartDataLabels,
                      })}
                    >
                      {formatImpactValue
                        ? formatImpactValue(item.impact)
                        : item.impact}
                    </div>
                  </div>
                </Td>
                <Td className={classNames(styles.fairShareIndexValue)}>
                  <div
                    className={classNames(
                      styles.switchingBreakdownTableFlexContent,
                      styles.fairShareIndex
                    )}
                  >
                    <div className={styles.fairShareIndexValue}>
                      {integerFormatter(item.index)}
                    </div>
                    <div
                      className={classNames(
                        styles[getIndexColourClass(item.index)],
                        styles.fairShareIndexBar
                      )}
                    />
                  </div>
                </Td>
                {/* Empty column for the info plus match UX design alignment and whole row hovering */}
                <Td />
              </tr>
              {isExpanded(item) && (
                <Tr data-testid="switching-breakdown-table-expanded-row">
                  <Td className={styles.itemExpandedCell} colSpan={5}>
                    <div>{children}</div>
                  </Td>
                </Tr>
              )}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

import {
  Checkbox,
  FormBlockEditability,
  Tag,
  TagVariant,
  Toggle,
  ToggleSize,
} from "@qbit/react";
import {
  ddLog,
  HierarchyType,
  type NodeExpansionRequestDto,
  type HierarchyItem,
  useLazyExpandQuery,
  getHierarchyItemTransactionSources,
  getPreferredTransactionSource,
  LocationHierarchy,
  ParameterId,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  type Updater,
  type RowSelectionState,
  type Row,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import classNames from "classnames";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHierarchyNodeExpanding,
  hierarchyNodeExpanded,
  onHierarchyChildNodesReceived,
  selectHierarchyItems,
  selectHierarchyExpandedRows,
  selectHierarchySelectedRows,
  selectHierarchyDisabledLevelShortNames,
  onHierarchyNodeSelection,
  selectIsDataEntitlementsShown,
  selectTransactionSources,
  selectSearchString,
  selectHierarchySelectedItemsFiltered,
  selectIsSelectedItemsShown,
  toggleShowSelectedItems,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./LocationHierarchyParameterTable.module.scss";

const PAGE_SIZE = 500;
const hierarchyType = HierarchyType.Location;
const parameterType = ParameterId.LocationHierarchy;

export type LocationHierarchyParameterItem = HierarchyItem & {
  isExpanding?: boolean;
  isMoreRow?: boolean;
  loadMoreParentRow?: LocationHierarchyParameterItem;
  parentRow?: LocationHierarchyParameterItem;
  subRows?: LocationHierarchyParameterItem[];
};

export type LocationHierarchyParameterTableProps = {
  isSuccess: boolean;
};

export const LocationHierarchyParameterTable = ({
  isSuccess,
}: LocationHierarchyParameterTableProps) => {
  const dispatch = useDispatch();
  const { name: division, transactionSources: availableTransactionSources } =
    useDivision();
  const [rowsPagination, setRowsPagination] = useState<Record<string, number>>(
    {}
  );

  const featureFlags = useFlags();

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(parameterType, state)
  );

  const selectedRows = useSelector((state: RootState) =>
    selectHierarchySelectedRows(parameterType, state)
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(parameterType, state)
  );

  const selectedRowsFiltered = useSelector((state: RootState) =>
    selectHierarchySelectedItemsFiltered(parameterType, state)
  );

  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectIsSelectedItemsShown(parameterType, state)
  );

  const expandedRows = useSelector((state: RootState) =>
    selectHierarchyExpandedRows(parameterType, state)
  );

  const disabledLevelShortNames = useSelector((state: RootState) =>
    selectHierarchyDisabledLevelShortNames(parameterType, state)
  );

  const isDataEntitlementsShown = useSelector((state: RootState) =>
    selectIsDataEntitlementsShown(state)
  );

  const reportTransactionSources = useSelector((state: RootState) =>
    selectTransactionSources(state)
  );

  const getNodeId = useMemo(
    () => (shortName: string, code: string) => `${shortName}.${code}`,
    []
  );

  const someSelectedItemsMandatory = useMemo(
    () =>
      selectedRowsFiltered.some((item) =>
        disabledLevelShortNames.includes(item.shortName)
      ),
    [disabledLevelShortNames, selectedRowsFiltered]
  );

  const createRowId = useMemo(
    () => (row: LocationHierarchyParameterItem) =>
      row.loadMoreParentRow?.parent
        ? `${getNodeId(
            row.loadMoreParentRow.parent.shortName,
            row.loadMoreParentRow.parent.code
          )}-load-more`
        : getNodeId(row.shortName, row.code),
    [getNodeId]
  );

  const updatePaginationState = useCallback(
    (shortName: string, code: string) => {
      setRowsPagination((pageState) => {
        const rowId = getNodeId(shortName, code);
        const newPageState = { ...pageState };
        newPageState[rowId] = newPageState[rowId] ? newPageState[rowId] + 1 : 1;

        return newPageState;
      });
    },
    [getNodeId]
  );

  const [triggerExpandQuery] = useLazyExpandQuery();

  const fetchChildNodesData = useCallback(
    async (payload: NodeExpansionRequestDto) => {
      try {
        const result = await triggerExpandQuery({
          division,
          hierarchyType,
          payload,
        }).unwrap();

        if (result.count > 0) {
          dispatch(
            onHierarchyChildNodesReceived({
              childNodes: result,
              parameterType,
            })
          );

          const parentNodeShortName = result.results[0].parent.shortName;
          const parentNodeCode = result.results[0].parent.code;

          updatePaginationState(parentNodeShortName, parentNodeCode);

          dispatch(
            setHierarchyNodeExpanding({
              nodeCode: parentNodeCode,
              nodeShortName: parentNodeShortName,
              parameterType,
            })
          );
        }
      } catch (error) {
        ddLog("Error retrieving children nodes", {}, "error", error as Error);
      }
    },
    [dispatch, division, triggerExpandQuery, updatePaginationState]
  );

  const expandAndFetchChildNodes = useCallback(
    (nodeCode: string, nodeShortName: string) => {
      dispatch(
        setHierarchyNodeExpanding({
          nodeCode,
          nodeShortName,
          parameterType,
        })
      );

      fetchChildNodesData({
        page: 0,
        pageSize: PAGE_SIZE,
        parent: {
          code: nodeCode,
          shortName: nodeShortName,
        },
      }).catch((error) => {
        ddLog("Error retrieving children nodes", {}, "error", error);
      });
    },
    [dispatch, fetchChildNodesData]
  );

  useEffect(() => {
    if (isSuccess && items.length === 1 && items[0].subRows === undefined) {
      expandAndFetchChildNodes(items[0].code, items[0].shortName);
    }
    // configure dependency array to trigger auto expansion only once on the top node
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, items.length, items[0]?.subRows, expandAndFetchChildNodes]);

  const getNewExpandedRows = useMemo(
    () => (row: Row<LocationHierarchyParameterItem>) => {
      const expandedRowIndex = expandedRows.findIndex(
        (expandedRow) =>
          expandedRow.code === row.original.code &&
          expandedRow.shortName === row.original.shortName
      );

      let newExpandedRows = [...expandedRows];

      if (expandedRowIndex === -1) {
        newExpandedRows = [...expandedRows, row.original];
      } else {
        newExpandedRows = expandedRows.filter(
          (expandedRow) =>
            getNodeId(expandedRow.shortName, expandedRow.code) !==
            getNodeId(row.original.shortName, row.original.code)
        );
      }

      return newExpandedRows;
    },
    [expandedRows, getNodeId]
  );

  const onToggleExpansionHandler = useCallback(
    (row: Row<LocationHierarchyParameterItem>) => {
      row.toggleExpanded();

      const newExpandedRows = getNewExpandedRows(row);

      dispatch(
        hierarchyNodeExpanded({
          expandedRows: newExpandedRows,
          parameter: parameterType,
        })
      );

      if (row.subRows.length === 0) {
        expandAndFetchChildNodes(row.original.code, row.original.shortName);
      }
    },
    [dispatch, expandAndFetchChildNodes, getNewExpandedRows]
  );

  const getNewSelectedRows = useMemo(
    () => (row: Row<LocationHierarchyParameterItem>) => {
      // row.getIsSelected provides the opposite state for checkbox
      const isChecked = !row.getIsSelected();

      // Filter out the current row add it back in if checked later
      let newSelectedRows: HierarchyItem[] = selectedRows.filter(
        (selectedRow) =>
          getNodeId(selectedRow.shortName, selectedRow.code) !==
          getNodeId(row.original.shortName, row.original.code)
      );

      if (isChecked) {
        newSelectedRows = [...newSelectedRows, row.original];
      }

      return newSelectedRows;
    },
    [getNodeId, selectedRows]
  );

  const rowSelectionState = useMemo(() => {
    const newRowSelectionState: Record<string, boolean> = {};

    for (const selectedRow of selectedRows) {
      newRowSelectionState[getNodeId(selectedRow.shortName, selectedRow.code)] =
        true;
    }

    return newRowSelectionState;
  }, [getNodeId, selectedRows]);

  const onToggleSelectionHandler = useCallback(
    (row: Row<LocationHierarchyParameterItem>) => {
      row.getToggleSelectedHandler();

      const newSelectedRows = getNewSelectedRows(row);

      dispatch(
        onHierarchyNodeSelection({
          nodeSelection: newSelectedRows,
          parameterType,
        })
      );
    },
    [dispatch, getNewSelectedRows]
  );

  const handleToggleAllRowsSelected = useCallback(() => {
    const allDisplayedItems = isSelectedItemsShown
      ? selectedRowsFiltered
      : items;

    const selectedDisplayedItems = allDisplayedItems.filter(
      (item) => getNodeId(item.shortName, item.code) in rowSelectionState
    );

    let newSelectedRows: HierarchyItem[];
    if (selectedDisplayedItems.length > 0) {
      newSelectedRows = selectedRows.filter(
        (row) =>
          !selectedDisplayedItems.map((item) => item.code).includes(row.code)
      );
    } else {
      newSelectedRows = selectedRows.concat(allDisplayedItems);
    }

    dispatch(
      onHierarchyNodeSelection({
        nodeSelection: newSelectedRows,
        parameterType,
      })
    );
  }, [
    isSelectedItemsShown,
    selectedRowsFiltered,
    items,
    dispatch,
    getNodeId,
    rowSelectionState,
    selectedRows,
  ]);

  const onMoreClickedHandler = async (
    row: Row<LocationHierarchyParameterItem>
  ) => {
    if (row.original.loadMoreParentRow?.parent) {
      const parentNodeCode = row.original.loadMoreParentRow.parent.code;
      const parentNodeShortName =
        row.original.loadMoreParentRow.parent.shortName;
      const nextPage =
        rowsPagination[getNodeId(parentNodeShortName, parentNodeCode)];

      await fetchChildNodesData({
        page: nextPage,
        pageSize: PAGE_SIZE,
        parent: {
          code: parentNodeCode,
          shortName: parentNodeShortName,
        },
      });
    }
  };

  const hierarchyItemHeader = useCallback(() => {
    const allDisplayedItems = isSelectedItemsShown
      ? selectedRowsFiltered
      : items;

    const selectedDisplayedItems = allDisplayedItems.filter(
      (item) => getNodeId(item.shortName, item.code) in rowSelectionState
    );
    const checkboxChecked =
      selectedDisplayedItems.length === allDisplayedItems.length &&
      selectedDisplayedItems.length !== 0;

    const checkboxIndeterminate = selectedDisplayedItems.length > 0;

    const checkboxEditable =
      allDisplayedItems.length > 0
        ? FormBlockEditability.Editable
        : FormBlockEditability.Disabled;

    return (
      <div className={styles.locationHierarchyParameterTableHeader}>
        <div className={styles.headerTextAndCheckbox}>
          {(isSelectedItemsShown || searchString !== "") && (
            <Checkbox
              checked={checkboxChecked}
              editability={checkboxEditable}
              indeterminate={checkboxIndeterminate && !checkboxChecked}
              label=""
              name="allItemsSelectedCheckbox"
              onChange={() => handleToggleAllRowsSelected()}
            />
          )}
          <div className={styles.headerText}>Location</div>
        </div>
        {Boolean(
          featureFlags[FeatureFlag.LocationHierarchySelectedItemsToggle]
        ) &&
          !someSelectedItemsMandatory && (
            <div className={styles.selectedItemsToggle}>
              <Toggle
                checked={isSelectedItemsShown}
                disabled={selectedRows.length === 0 && !isSelectedItemsShown}
                label="Show selected item(s)"
                onClick={() =>
                  dispatch(toggleShowSelectedItems({ parameterType }))
                }
                size={ToggleSize.XSmall}
              />
              <Tag
                className={classNames(styles.selectedItemsCount, {
                  [styles.selectedItemsCountDisabled]:
                    selectedRows.length === 0,
                  [styles.selectedItemsCountEnabled]: selectedRows.length,
                })}
                text={selectedRows.length.toString()}
                variant={TagVariant.Lozenge}
              />
            </div>
          )}
      </div>
    );
  }, [
    isSelectedItemsShown,
    selectedRowsFiltered,
    items,
    searchString,
    featureFlags,
    someSelectedItemsMandatory,
    selectedRows.length,
    getNodeId,
    rowSelectionState,
    handleToggleAllRowsSelected,
    dispatch,
  ]);

  const hierarchyItemCell = useCallback(
    ({
      row,
      getValue,
    }: CellContext<LocationHierarchyParameterItem, unknown>) => {
      const itemTransactionSources = getHierarchyItemTransactionSources(
        row.original
      );
      const displayedTransactionSource = getPreferredTransactionSource(
        reportTransactionSources,
        itemTransactionSources
      );
      // prepend code for stores only
      const displayValue =
        row.original.shortName === LocationHierarchy.Store
          ? `${row.original.code} - ${String(getValue())}`
          : String(getValue());

      return (
        <ExpandableNameCell
          canExpand={
            row.subRows.length >= 0 &&
            !row.original.isLeaf &&
            searchString === ""
          }
          depth={row.depth}
          handleToggleExpanded={() => onToggleExpansionHandler(row)}
          handleToggleSelected={() => onToggleSelectionHandler(row)}
          hideTooltip={
            disabledLevelShortNames.includes(row.original.shortName) &&
            row.getIsSelected()
          }
          isCheckboxDisabled={
            displayedTransactionSource === null ||
            disabledLevelShortNames.includes(row.original.shortName)
          }
          isCompact
          isExpanded={row.subRows.length > 0 && row.getIsExpanded()}
          isExpanding={row.original.isExpanding}
          isSelected={row.getIsSelected()}
          name={row.original.name}
          shortName={row.original.shortName}
          type={row.original.type}
          value={displayValue}
        />
      );
    },
    [
      disabledLevelShortNames,
      onToggleExpansionHandler,
      onToggleSelectionHandler,
      reportTransactionSources,
      searchString,
    ]
  );

  const transactionSourceHeader = useCallback(() => <span>Dataset</span>, []);

  const transactionSourceCell = useCallback(
    ({ row }: CellContext<LocationHierarchyParameterItem, unknown>) => {
      const itemTransactionSources = getHierarchyItemTransactionSources(
        row.original
      );
      const displayedTransactionSource = getPreferredTransactionSource(
        reportTransactionSources,
        itemTransactionSources
      )?.[0];

      return (
        <span className={styles.transactionSourceCell}>
          <TransactionSourceIcon
            availableTransactionSources={availableTransactionSources}
            greyedOut={!row.getIsSelected()}
            transactionSource={displayedTransactionSource}
          />
        </span>
      );
    },
    [availableTransactionSources, reportTransactionSources]
  );

  const columns = useMemo<Array<ColumnDef<LocationHierarchyParameterItem>>>(
    () =>
      isDataEntitlementsShown
        ? [
            {
              accessorKey: "name",
              cell: hierarchyItemCell,
              header: hierarchyItemHeader,
            },
            {
              accessorKey: "transactionSource",
              cell: transactionSourceCell,
              header: transactionSourceHeader,
            },
          ]
        : [
            {
              accessorKey: "name",
              cell: hierarchyItemCell,
              header: hierarchyItemHeader,
            },
          ],
    [
      transactionSourceCell,
      transactionSourceHeader,
      hierarchyItemCell,
      hierarchyItemHeader,
      isDataEntitlementsShown,
    ]
  );

  const handleRowSelectionsChange = (
    newRowSelections: Updater<RowSelectionState>
  ) => {
    const selectionIds = Object.keys(newRowSelections);

    const newSelectedRows = items.filter((item) =>
      selectionIds.includes(item.code)
    );

    dispatch(
      onHierarchyNodeSelection({
        nodeSelection: newSelectedRows,
        parameterType,
      })
    );
  };

  const rowExpansionState = useMemo(() => {
    const newRowExpansionState: Record<string, boolean> = {};

    for (const expandedRow of expandedRows) {
      newRowExpansionState[getNodeId(expandedRow.shortName, expandedRow.code)] =
        true;
    }

    return newRowExpansionState;
  }, [expandedRows, getNodeId]);

  return (
    <div className={styles.locationHierarchyParameter}>
      <ReportHierarchyTableWrapper isSuccess={isSuccess}>
        <ReportHierarchyTable
          // FIXME: should actually be optional and should not allow resizing for single column
          // https://jira.quantium.com.au/browse/CO3-1855
          className={styles.table}
          columnResizeMode="onChange"
          columns={columns}
          compactRows
          data={isSelectedItemsShown ? selectedRowsFiltered : items}
          depthPadding={50}
          disableSorting
          getRowId={(row: LocationHierarchyParameterItem) => createRowId(row)}
          getSubRows={(row) => row.subRows}
          // FIXME: should actually be optional and should not be passed in if not needed
          // https://jira.quantium.com.au/browse/CO3-1856
          handleMoreClicked={async (row: Row<LocationHierarchyParameterItem>) =>
            await onMoreClickedHandler(row)
          }
          moreText="Load More..."
          rowExpandedState={rowExpansionState}
          rowSelectionState={rowSelectionState}
          setRowSelection={handleRowSelectionsChange}
        />
        {searchString !== "" &&
          (isSelectedItemsShown
            ? selectedRowsFiltered.length === 0
            : items.length === 0) && (
            <div className={styles.noResults}>
              <EmptySearch />
            </div>
          )}
      </ReportHierarchyTableWrapper>
    </div>
  );
};

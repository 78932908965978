import { InlineIcon, IconSize, InlineIconGlyph } from "@qbit/react";
import { useState } from "react";
import { TestId } from "../constants";
import { type Item } from "../models/Item";
import { DraggableItem } from "./DraggableItem";
import { HierarchyItem } from "./HierarchyItem";
import styles from "./ItemList.module.css";

type DraggableListItemProps = {
  item: Item;
};

const DraggableListItem = ({ item }: DraggableListItemProps) => (
  <li
    className={styles.draggableItemWrapper}
    data-testid={TestId.DraggableItem}
  >
    <DraggableItem item={item} />
  </li>
);

export type ItemListProps = {
  heading: string;
  items: Item[];
  max?: number;
  tooltipText: string;
};

export const ItemList = ({
  heading,
  items,
  max = 5,
  tooltipText,
}: ItemListProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.itemList} data-testid={TestId.ItemList}>
      <div className={styles.subheading} data-testid={TestId.ListHeading}>
        <div className={styles.itemListHeader}>
          <span className={styles.itemListHeading}>{heading}</span>
          <InlineIcon
            glyph={InlineIconGlyph.AlertsAndNotificationsInform}
            size={IconSize.Small}
            text={tooltipText}
          />
        </div>
      </div>
      {items.length === 0 && (
        <div className={styles.noItemsMessage}>
          No available {heading.toLowerCase()}
        </div>
      )}
      <ul className={styles.items}>
        {items.map((item, itemIndex) => {
          if (item.isDisabled) {
            return (
              <HierarchyItem
                {...item}
                isDisabled
                isDraggable={false}
                key={item.id}
                tooltipText={item.disabledToolTip}
              />
            );
          } else if (expanded) {
            return <DraggableListItem item={item} key={item.id} />;
          } else {
            if (itemIndex === max) {
              return (
                <button
                  className={styles.hierarchyListExpand}
                  data-testid={TestId.ShowMoreButton}
                  key={item.id}
                  onClick={() => {
                    setExpanded(true);
                  }}
                  type="button"
                >
                  Show {items.length - max} more
                </button>
              );
            }

            if (itemIndex > max - 1) {
              return null;
            }

            return <DraggableListItem item={item} key={item.id} />;
          }
        })}
        {expanded && items.length > max && (
          <button
            className={styles.hierarchyListExpand}
            data-testid={TestId.ShowLessButton}
            onClick={() => {
              setExpanded(false);
            }}
            type="button"
          >
            Show less
          </button>
        )}
      </ul>
    </div>
  );
};

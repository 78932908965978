import { Button, ButtonVariant, Icon, IconGlyph } from "@qbit/react";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FastReportingFocalItemSearch } from "./FastReportingFocalItemSearch";
import FastReportingFocalItemSearchState from "./FastReportingFocalItemSearchState";
import styles from "./SearchModal.module.css";
import { useReportConfigurationPrefetch } from "./fast-report/api/fastReportConfigurationApi";
import { type HierarchySearchLevel } from "./focal-item-search/HierarchySearchLevel";
import {
  selectableItemToFocalItem,
  type SelectableItem,
} from "./focal-item-search/SelectableItem";
import {
  closeSearchModal,
  selectIsOpen,
  selectSearchQuery,
  setSearchQuery,
} from "./focal-item-search/fastReportingSearchSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import useOpenFastReportingTabs from "./useOpenFastReportingTabs";

export const SearchModal = () => {
  const { name: divisionName } = useDivision();
  const reportConfigurationPrefetch = useReportConfigurationPrefetch();
  const isOpen = useAppSelector(selectIsOpen);
  const classes = isOpen
    ? [styles.searchModal, styles.active]
    : [styles.searchModal];

  const dispatch = useAppDispatch();
  const onClose = () => dispatch(closeSearchModal());

  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>([]);
  const [isSelectionValid, setIsSelectionValid] = useState(false);
  const handleSelectionChanged = (
    selection: SelectableItem[],
    selectionValid: boolean
  ) => {
    const isValid = selectionValid && selection.length > 0;
    setIsSelectionValid(isValid);
    setSelectedItems(selection);
  };

  const openTabs = useOpenFastReportingTabs();

  const handleViewReports = () => {
    openTabs(selectedItems.map((item) => selectableItemToFocalItem(item)));
    dispatch(closeSearchModal());
  };

  const handleViewSingleReport = useCallback(
    (item: SelectableItem) => {
      const focalItem = selectableItemToFocalItem(item, true);
      openTabs([focalItem]);
      dispatch(closeSearchModal());
    },
    [openTabs, dispatch]
  );

  const [searchLevel, setSearchLevel] = useState<HierarchySearchLevel>();
  const [availableSearchLevels, setAvailableSearchLevels] = useState<
    HierarchySearchLevel[]
  >([]);

  const onSearchQueryChangedHandler = (query: string) => {
    dispatch(setSearchQuery(query));
  };

  const savedSearchQuery = useAppSelector(selectSearchQuery);

  useEffect(() => {
    if (divisionName && isOpen) {
      reportConfigurationPrefetch({ division: divisionName });
    }
  }, [divisionName, isOpen, reportConfigurationPrefetch]);

  // Close the modal if we try to navigate to other pages
  const location = useLocation();
  useEffect(() => {
    dispatch(closeSearchModal());
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We do not want the search modal to close when "dispatch" changes
  }, [location]);

  return (
    <>
      <FastReportingFocalItemSearchState
        divisionName={divisionName}
        onSearchLevelsChanged={setAvailableSearchLevels}
      />
      {isOpen && (
        <div
          className={classNames(classes)}
          data-testid="fastReportingSearchModal"
        >
          <div className={styles.searchModalWrapper}>
            <div className={styles.closeButtonContainer}>
              <Button
                className={styles.tabCloseButton}
                data-testid="searchModalCloseButtonModal"
                onClick={onClose}
                variant={ButtonVariant.Stealth}
              >
                <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close tab" />
              </Button>
            </div>
            <div className={styles.searchModalContent}>
              <h3>Search for products, brands, product groups, etc.</h3>
              {divisionName && availableSearchLevels.length > 0 && (
                <div className={styles.search}>
                  <FastReportingFocalItemSearch
                    availableSearchLevels={availableSearchLevels}
                    onFocalItemClicked={handleViewSingleReport}
                    onSearchLevelChanged={setSearchLevel}
                    onSearchQueryChanged={onSearchQueryChangedHandler}
                    onSelectionChanged={handleSelectionChanged}
                    searchLevel={searchLevel}
                    searchQuery={savedSearchQuery}
                  />
                </div>
              )}
              <div className={styles.actionBar}>
                <Link
                  className={classNames({
                    [styles.disabledLink]: !isSelectionValid,
                  })}
                  to={`/${divisionName}/fast-reporting`}
                >
                  <Button
                    className={styles.viewReportsButton}
                    disabled={!isSelectionValid}
                    onClick={() => handleViewReports()}
                    text="View quick reports"
                    variant={ButtonVariant.Primary}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchModal;

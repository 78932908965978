import { Item, InlineIcon, InlineIconGlyph, Text } from "@qbit/react";
import styles from "./ErrorBanner.module.css";

export type ErrorBannerProps = {
  text: string;
};

export const ErrorBanner = ({ text }: ErrorBannerProps) => (
  <Item className={styles.errorBannerContainer}>
    <InlineIcon
      colour="bad"
      glyph={InlineIconGlyph.AlertsAndNotificationsAlertCircle}
      text={text}
    />
    <Text>{text}</Text>
  </Item>
);

export default ErrorBanner;

import {
  Text,
  Icon,
  IconColour,
  IconGlyph,
  TextVariant,
  IconSize,
  Input,
  FormBlock,
  FormBlockType,
  Checkbox,
} from "@qbit/react";
import { type SegmentDto } from "@quantium-enterprise/common-ui";
import { getTimeAgoString } from "@quantium-enterprise/common-ui";
import { type CellContext } from "@tanstack/react-table";
import classNames from "classnames";
import styles from "./SegmentLibraryTable.module.css";

export type SegmentRow = SegmentDto;

// This is used in the table to identify elements to
// prevent row click handler preventing checkbox selection.
export const CheckboxClassName = "segment-library-row-checkbox";

export type CheckboxCellProps = {
  checked: boolean;
  id: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxCell = ({
  checked,
  id,
  name,
  onChange,
}: CheckboxCellProps) => (
  <FormBlock blockType={FormBlockType.Checkbox}>
    <Input>
      <Checkbox
        checked={checked}
        className={CheckboxClassName}
        data-testid={id}
        id={id}
        label=""
        name={name}
        onChange={onChange}
      />
    </Input>
  </FormBlock>
);

export type NameCellProps = {
  name: string;
  type: string;
};

export const NameCell = (info: CellContext<SegmentRow, unknown>) => {
  const { name, type } = info.getValue<NameCellProps>();

  return (
    <div className={styles.groupCell}>
      <Icon
        className={styles.groupCellIcon}
        colour={IconColour.Notification}
        glyph={IconGlyph.AccountAndUserAccount}
        text="Segment"
      />
      <Text className={styles.groupCellName} title={name}>
        {name}
      </Text>
      <Text className={styles.groupCellSegments}>{type}</Text>
    </div>
  );
};

export type SegmentCellProps = {
  key: string;
};

export const SegmentCell = (info: CellContext<SegmentRow, unknown>) => {
  const { key } = info.getValue<SegmentCellProps>();

  return (
    <div className={styles.row}>
      <Text>{key}</Text>
    </div>
  );
};

export type StatusLabelProps = {
  activationDateUtc?: string;
  showInactiveLabel?: boolean;
};

export const StatusLabel = ({
  activationDateUtc,
  showInactiveLabel,
}: StatusLabelProps) => (
  <div
    className={classNames(styles.statusCell, {
      [styles.statusCellActivated]: activationDateUtc,
      [styles.statusCellInactive]: showInactiveLabel && !activationDateUtc,
    })}
  >
    {(activationDateUtc || showInactiveLabel) && (
      <Icon
        className={styles.statusIcon}
        colour={activationDateUtc ? "#8F73BE" : "#333333"}
        glyph={IconGlyph.SelectionCheckboxBlankCircle}
        size={IconSize.Small}
        text="Activated"
      />
    )}
    <Text className={styles.statusText} variant={TextVariant.GroupLabel}>
      {activationDateUtc ? "Active" : showInactiveLabel ? "Inactive" : "-"}
    </Text>
  </div>
);

export type StatusCellProps = {
  activationDateUtc?: string;
};

export const StatusCell = (info: CellContext<SegmentRow, unknown>) => {
  const { activationDateUtc } = info.getValue<StatusCellProps>();

  return <StatusLabel activationDateUtc={activationDateUtc} />;
};

export type CustomerCountCellProps = {
  count: number;
};

export const CustomerCountCell = (info: CellContext<SegmentRow, unknown>) => {
  const { count } = info.getValue<CustomerCountCellProps>();

  return (
    <div className={styles.row}>
      <Text>{count.toLocaleString()}</Text>
    </div>
  );
};

export type DateCellProps = {
  date?: string;
};

export const DateCell = (info: CellContext<SegmentRow, unknown>) => {
  const { date } = info.getValue<DateCellProps>();

  return (
    <div>
      <Text>{date ? getTimeAgoString(date) : ""}</Text>
    </div>
  );
};

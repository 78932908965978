/* eslint-disable complexity */
import {
  type GroupFolderWithDepth,
  type HierarchyGroupDto,
  type FolderOrGroupDto,
  type HierarchyGroupWithSharingDtoWithDepth,
  type GroupFolderDto,
} from "../models";

export const ShareRootFolderName = "Shared with me";
export const ShareRootFolderId = "VirtualShareRootFolder";
export const ShareRootFolder: GroupFolderWithDepth = {
  id: ShareRootFolderId,
  // always 1 because the null root starts at 0
  depth: 1,
  hasChildren: true,
  isFolder: true,
  name: ShareRootFolderName,
};

export const isHierarchyGroup = (
  object: FolderOrGroupDto | undefined
): object is HierarchyGroupWithSharingDtoWithDepth =>
  object !== undefined && Object.hasOwn(object, "evaluationType");

const sortByName = (a: { name?: string }, b: { name?: string }) => {
  if (!a.name || a.name.trim() === "") {
    return -1;
  }

  if (!b.name || b.name.trim() === "") {
    return 1;
  }

  return a.name.localeCompare(b.name);
};

type LatestUpdatedState = {
  updatedDateTimeValue?: number;
  updatedDateUtc?: string;
};

export const mapFolderDto = <T>(
  dto: GroupFolderDto,
  currentUserId: string | undefined,
  sharedRootFolder: T | undefined,
  transformFolder: (
    folder: GroupFolderDto,
    depth: number,
    ancestorIds: string[],
    parentId?: string
  ) => T,
  transformGroup:
    | ((
        group: HierarchyGroupDto,
        depth: number,
        ancestorIds: string[],
        parentId?: string
      ) => T)
    | undefined,
  depth: number = 0,
  ancestorIds?: string[],
  parentId?: string,
  sharedLatestUpdatedState?: LatestUpdatedState
): T[] => {
  const updateLatestUpdatedState = (group: HierarchyGroupDto) => {
    if (sharedLatestUpdatedState && group.userId !== currentUserId) {
      const updatedDateTimeValue = group.updateDateUtc
        ? new Date(group.updateDateUtc).getTime()
        : undefined;
      if (
        updatedDateTimeValue &&
        (!sharedLatestUpdatedState.updatedDateTimeValue ||
          sharedLatestUpdatedState.updatedDateTimeValue < updatedDateTimeValue)
      ) {
        sharedLatestUpdatedState.updatedDateTimeValue = updatedDateTimeValue;
        sharedLatestUpdatedState.updatedDateUtc = group.updateDateUtc;
      }
    }
  };

  const result: T[] = [
    transformFolder(dto, depth, ancestorIds ?? [], parentId),
  ];

  const newAncestorIds = ancestorIds ? [...ancestorIds] : [];
  if (dto.id) {
    newAncestorIds.push(dto.id);
  }

  const trueShareEnabled = depth === 0 && currentUserId && sharedRootFolder;

  // At root level, shared folders and groups always go first
  if (trueShareEnabled) {
    // 2 because we are inserting the SharedRoot between the null root and this node.
    const sharedStartingDepth = depth + 2;
    const sharedAncestors = newAncestorIds.concat(ShareRootFolderId);
    const sharedFolders =
      dto.folders?.filter((f2) => f2.userId !== currentUserId) ?? [];
    const sharedGroups =
      dto.groups?.filter((g2) => g2.userId !== currentUserId) ?? [];
    sharedFolders.sort(sortByName);
    sharedGroups.sort(sortByName);
    if (sharedFolders.length > 0 || sharedGroups.length > 0) {
      result.push(sharedRootFolder);
    }

    for (const folder of sharedFolders) {
      result.push(
        ...mapFolderDto(
          folder,
          currentUserId,
          sharedRootFolder,
          transformFolder,
          transformGroup,
          sharedStartingDepth,
          sharedAncestors,
          ShareRootFolderId,
          sharedLatestUpdatedState
        )
      );
    }

    if (transformGroup) {
      for (const group of sharedGroups) {
        updateLatestUpdatedState(group);
        result.push(
          transformGroup(
            group,
            sharedStartingDepth,
            sharedAncestors,
            ShareRootFolderId
          )
        );
      }
    }
  }

  const newDepth = depth + 1;

  const foldersCopy =
    (trueShareEnabled
      ? dto.folders?.filter((f2) => f2.userId === currentUserId)
      : dto.folders?.slice()) ?? [];
  const groupsCopy =
    (trueShareEnabled
      ? dto.groups?.filter((g2) => g2.userId === currentUserId)
      : dto.groups?.slice()) ?? [];
  foldersCopy.sort(sortByName);
  groupsCopy.sort(sortByName);

  for (const folder of foldersCopy) {
    result.push(
      ...mapFolderDto(
        folder,
        currentUserId,
        sharedRootFolder,
        transformFolder,
        transformGroup,
        newDepth,
        newAncestorIds,
        dto.id,
        sharedLatestUpdatedState
      )
    );
  }

  if (transformGroup) {
    for (const group of groupsCopy) {
      updateLatestUpdatedState(group);
      result.push(transformGroup(group, newDepth, newAncestorIds, dto.id));
    }
  }

  return result;
};

// Convert the hierarchical folder/group DTO to a flat array
export const mapFolderDtoToFolderOrGroupDto = (
  dto: GroupFolderDto,
  currentUserId: string | undefined,
  trueShareEnabled: boolean | undefined
): FolderOrGroupDto[] => {
  const shareRootFolder = trueShareEnabled
    ? {
        ...ShareRootFolder,
        parentId: dto.id,
        ancestorIds: dto.id ? [dto.id] : [],
        userId: currentUserId,
      }
    : undefined;
  const latestUpdatedState: LatestUpdatedState = {};
  const result = mapFolderDto<FolderOrGroupDto>(
    dto,
    currentUserId,
    shareRootFolder,
    (folder, depth, ancestorIds, parentId) => {
      const hasChildren =
        Boolean(folder.folders?.length) || Boolean(folder.groups?.length);
      const dtoWithoutGroupsOrFolders = {
        ...folder,
        ancestorIds,
        depth,
        parentId,
        isFolder: true,
        hasChildren,
      };

      delete dtoWithoutGroupsOrFolders.groups;
      delete dtoWithoutGroupsOrFolders.folders;

      return dtoWithoutGroupsOrFolders;
    },
    (group, depth, ancestorIds, parentId) => ({
      ...group,
      ancestorIds,
      depth,
      parentId,
      isFolder: false,
      hasChildren: false,
    }),
    0,
    [],
    undefined,
    latestUpdatedState
  );

  if (shareRootFolder) {
    shareRootFolder.updateDateUtc = latestUpdatedState.updatedDateUtc;
  }

  return result;
};

export const searchGroupFolderDto = (
  folder: GroupFolderDto,
  predicate: (groupOrFolder: GroupFolderDto | HierarchyGroupDto) => boolean
): Array<GroupFolderDto | HierarchyGroupDto> => {
  const results: Array<GroupFolderDto | HierarchyGroupDto> = [];
  if (folder.folders) {
    for (const subFolder of folder.folders) {
      if (predicate(subFolder)) {
        results.push(subFolder);
      }

      results.push(...searchGroupFolderDto(subFolder, predicate));
    }
  }

  if (folder.groups) {
    for (const group of folder.groups) {
      if (predicate(group)) {
        results.push(group);
      }
    }
  }

  return results;
};

export const searchGroupsCount = (
  folder: GroupFolderDto,
  ...predicates: Array<(group: HierarchyGroupDto) => boolean>
): Array<{
  matched: number;
  total: number;
}> => {
  const results = predicates.map(() => ({
    matched: 0,
    total: 0,
  }));
  if (folder.folders) {
    for (const subFolder of folder.folders) {
      const subResults = searchGroupsCount(subFolder, ...predicates);
      for (const [index, subResult] of subResults.entries()) {
        const result = results[index];
        result.matched += subResult.matched;
        result.total += subResult.total;
      }
    }
  }

  if (folder.groups) {
    for (const group of folder.groups) {
      for (const [index, predicate] of predicates.entries()) {
        const result = results[index];
        ++result.total;
        if (predicate(group)) {
          ++result.matched;
        }
      }
    }
  }

  return results;
};

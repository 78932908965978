import { Text } from "@qbit/react";

const DEFAULT_DISPLAY_VALUE = "None selected";

export type ListSelectionSummaryWithDefaultProps = {
  displayText?: string;
  label: string;
};

export const ListSelectionSummaryWithDefault = ({
  displayText = DEFAULT_DISPLAY_VALUE,
  label = "",
}: ListSelectionSummaryWithDefaultProps) =>
  // eslint-disable-next-line no-negated-condition
  label !== "" ? (
    <div>
      <Text data-testid="summary-display-list-selections">{`${label}: ${displayText}`}</Text>
    </div>
  ) : null;

import {
  InlineIconGlyph,
  IconSize,
  StealthInlineIconButton,
} from "@qbit/react";
import classNames from "classnames";
import {
  useContext,
  type PropsWithChildren,
  useMemo,
  useCallback,
} from "react";
import React, { createContext } from "react";
import styles from "./ReportExpandableLeftContentLayout.module.css";

export const DEFAULT_WIDTH = "50%";
export const LEFT_CONTENT_WIDTH = "30%";
export const EXPANDED_RIGHT_CONTENT_WIDTH = `calc(100% - ${LEFT_CONTENT_WIDTH})`;

type ExpandedValuesType = {
  expanded: boolean;
  setExpanded: (isExpanded: boolean) => void;
};

const ExpandableLeftContentContext = createContext<ExpandedValuesType>({
  expanded: false,
  setExpanded: () => {},
});

const LeftExpandableContainer = ({ children }: PropsWithChildren) => {
  const { expanded, setExpanded } = useContext(ExpandableLeftContentContext);

  const resize = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={styles.leftContentContainer}
      style={{
        width: expanded ? DEFAULT_WIDTH : LEFT_CONTENT_WIDTH,
      }}
    >
      {children}
      <div className={styles.expandButtonContainer}>
        <StealthInlineIconButton
          className={styles.expandButton}
          iconGlyph={
            expanded ? InlineIconGlyph.ArrowsBack : InlineIconGlyph.ArrowsNext
          }
          iconSize={IconSize.Medium}
          iconText={expanded ? "Shrink" : "Expand"}
          onClick={resize}
        />
      </div>
    </div>
  );
};

const RightResizableContainer = ({ children }: PropsWithChildren) => {
  const { expanded } = useContext(ExpandableLeftContentContext);

  return (
    <div
      className={styles.rightContentContainer}
      style={{
        width: expanded ? DEFAULT_WIDTH : EXPANDED_RIGHT_CONTENT_WIDTH,
      }}
    >
      {children}
    </div>
  );
};

const LeftTopContent = ({ children }: PropsWithChildren) => (
  <div className={styles.leftTopContent}>{children}</div>
);

const LeftBottomContent = ({ children }: PropsWithChildren) => (
  <div className={styles.leftBottomContent}>{children}</div>
);

export type ReportExpandableLeftContentLayoutProps = {
  className?: string;
  expanded: boolean;
  onExpandedChange: (isExpanded: boolean) => void;
};

const ReportExpandableLeftContentLayout = ({
  children,
  className,
  expanded,
  onExpandedChange,
}: PropsWithChildren<ReportExpandableLeftContentLayoutProps>) => {
  const handleExpandedChange = useCallback(
    (isExpanded: boolean) => {
      onExpandedChange(isExpanded);
    },
    [onExpandedChange]
  );

  const memoisedValues = useMemo(
    () => ({
      expanded,
      setExpanded: handleExpandedChange,
    }),
    [expanded, handleExpandedChange]
  );

  return (
    <ExpandableLeftContentContext.Provider value={memoisedValues}>
      <div
        className={classNames(
          styles.reportExpandableLeftContentLayoutContainer,
          className
        )}
      >
        {children}
      </div>
    </ExpandableLeftContentContext.Provider>
  );
};

ReportExpandableLeftContentLayout.LeftTopContent = LeftTopContent;
ReportExpandableLeftContentLayout.LeftBottomContent = LeftBottomContent;
ReportExpandableLeftContentLayout.LeftExpandableContainer =
  LeftExpandableContainer;
ReportExpandableLeftContentLayout.RightResizableContainer =
  RightResizableContainer;

export default ReportExpandableLeftContentLayout;

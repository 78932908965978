import { type Option } from "@qbit/react/dist/typeahead";
import {
  TransactionSource,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import {
  type PanelOption,
  type Panel,
  SidePanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { LocalParameters } from "../../common/models/LocalParameterId";
import {
  isSingleSelectionParameter,
  isMultiSelectionParameter,
  isPlainTextContentParameter,
  isTimePeriodParameter,
  isLocationSelectionParameter,
  isSegmentationGroupParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import {
  buildPlainTextContentPanel,
  buildSingleSelectPanel,
  buildMultiSelectPanel,
  buildTimePeriodPanel,
  buildLocationHierarchyPanel,
  buildSegmentationGroupPanel,
} from "../../common/utils/local-parameters/PanelBuilderUtils";
import { type RootState } from "../../store";
import {
  onChangeOnChange,
  onChannelChange,
  onDatasetChange,
  onLocationChange,
  onMetricsChange,
  onPromotionChange,
  onSegmentationChange,
  resetHiddenSelections,
  selectChangeOn,
  selectChannel,
  selectLocationHierarchy,
  selectMetrics,
  selectPromotion,
  selectSegmentation,
  selectDataset,
} from "../services/key-measure-trends-slice";

export const KeyMeasureTrendsSidePanel = ({
  eventTrackingService,
}: {
  eventTrackingService: Function;
}) => {
  const dispatch = useDispatch();

  const changeOn = useSelector(selectChangeOn);
  const channel = useSelector(selectChannel);
  const locationHierarchy = useSelector(selectLocationHierarchy);
  const metrics = useSelector(selectMetrics);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const dataset = useSelector(selectDataset);

  const { localParametersConfig } = useSelector((state: RootState) => ({
    localParametersConfig: state.keyMeasureTrends.localParameterConfig,
  }));

  const panels: Panel[] = [];
  for (const parameter of localParametersConfig) {
    // TIME
    if (
      parameter.id === LocalParameters.Time &&
      isTimePeriodParameter(parameter)
    ) {
      panels.push(buildTimePeriodPanel(parameter));
    }

    // CHANGE ON
    else if (
      parameter.id === LocalParameters.ChangeOn &&
      isSingleSelectionParameter(parameter)
    ) {
      const onChangeOnSelect = (value: PanelOption) => {
        dispatch(onChangeOnChange(value.value as string));
        dispatch(resetHiddenSelections());
      };

      panels.push(
        buildSingleSelectPanel(parameter, onChangeOnSelect, changeOn)
      );
    }

    // METRICS
    else if (
      parameter.id === LocalParameters.Metrics &&
      isMultiSelectionParameter(parameter)
    ) {
      const onMetricsSelect = (newValue: Option[]) => {
        dispatch(onMetricsChange(newValue));
        dispatch(resetHiddenSelections());
      };

      const selectedDataset = dataset.value as string | null | undefined;
      panels.push(
        buildMultiSelectPanel(
          {
            ...parameter,
            selections: parameter.selections.filter(
              (ds) =>
                !selectedDataset || ds.dataSource?.includes(selectedDataset)
            ),
          },
          onMetricsSelect,
          Object.values(metrics).map((option) => option.value.toString())
        )
      );
    }

    // CHANNEL
    else if (
      parameter.id === LocalParameters.Channel &&
      isSingleSelectionParameter(parameter)
    ) {
      const onChannelSelect = (value: PanelOption) => {
        dispatch(onChannelChange(value));
        dispatch(resetHiddenSelections());
      };

      panels.push(
        buildSingleSelectPanel(
          parameter,
          onChannelSelect,
          channel.value as string
        )
      );
    }

    // PROMOTION
    else if (
      parameter.id === LocalParameters.Promotion &&
      isSingleSelectionParameter(parameter)
    ) {
      const onPromotionSelect = (value: PanelOption) => {
        dispatch(onPromotionChange(value));
        dispatch(resetHiddenSelections());
      };

      panels.push(
        buildSingleSelectPanel(
          parameter,
          onPromotionSelect,
          promotion.value as string
        )
      );
    }

    // SEGMENTATION
    else if (parameter.id === LocalParameters.Segmentation) {
      const onSegmentSelection = (value: PanelOption) => {
        dispatch(onSegmentationChange(value));
      };

      if (isSegmentationGroupParameter(parameter)) {
        const disabledSelections = parameter.selections.map((selection) => ({
          ...selection,
          optionDisabled: true,
        }));

        panels.push(
          buildSegmentationGroupPanel(
            dataset.value === TransactionSource.Total.toString()
              ? { ...parameter, selections: disabledSelections }
              : parameter,
            onSegmentSelection,
            segmentation.value as string
          )
        );
      } else if (isPlainTextContentParameter(parameter)) {
        panels.push(buildPlainTextContentPanel(parameter));
      }
    }

    // Transaction Set
    else if (
      parameter.id === LocalParameters.Dataset &&
      isSingleSelectionParameter(parameter)
    ) {
      panels.push(
        buildSingleSelectPanel(
          parameter,
          (value: PanelOption) => {
            dispatch(onDatasetChange(value));
            dispatch(resetHiddenSelections());
          },
          dataset.value.toString()
        )
      );
    }
    // LOCATION
    else if (
      parameter.id === LocalParameters.LocationHierarchy &&
      isLocationSelectionParameter(parameter)
    ) {
      const onLocationSelection = (selection: LocalHierarchyNodeSelection) => {
        dispatch(onLocationChange(selection));
        dispatch(resetHiddenSelections());
      };

      panels.push(
        buildLocationHierarchyPanel(
          parameter,
          onLocationSelection,
          locationHierarchy
        )
      );
    }
  }

  return (
    <SidePanel eventTrackingService={eventTrackingService} panels={panels} />
  );
};

export default KeyMeasureTrendsSidePanel;

import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { ENVIRONMENT } from "../config/environment-config";

export const normaliseBaseUrl = (url: string): string => {
  // If the URL is empty or just a slash, return an empty string
  if (url === "" || url === "/" || url === "./") {
    return "";
  }

  const normalisedUrl = url.replace(/\/$/iu, "");

  const hasProtocol = /^https?:\/\//iu.test(normalisedUrl);

  if (hasProtocol) {
    return normalisedUrl;
  }

  return normalisedUrl.startsWith("/") ? normalisedUrl : `/${normalisedUrl}`;
};

export const getBaseQuery = (baseUrl: string) => {
  if (!baseUrl.startsWith("/")) {
    throw new Error("RTK query api baseUrl must start with a forward slash");
  }

  return fetchBaseQuery({
    baseUrl: normaliseBaseUrl(String(ENVIRONMENT.BASE_URL)) + baseUrl,
  });
};

// This function is used to get the base path for the API, divisions other than WOW will have /checkout in the base path
export const getBasePath = (baseUrl: string): string => {
  if (!baseUrl.startsWith("/")) {
    throw new Error("RTK query api baseUrl must start with a forward slash");
  }

  const fullUrl = normaliseBaseUrl(String(ENVIRONMENT.BASE_URL));

  // If BASE_URL normalizes to empty string or just "/", return only the baseUrl
  if (!fullUrl || fullUrl === "/") {
    return baseUrl;
  }

  // Only try to create URL object if we have a full URL - to handle local development where BASE_URL "/"
  if (/^https?:\/\//iu.test(fullUrl)) {
    const urlObject = new URL(fullUrl);
    const pathFromBaseUrl = urlObject.pathname;
    return pathFromBaseUrl + baseUrl;
  }

  return fullUrl + baseUrl;
};

import tokens from "@qbit/core/dist/tokens.json";
import React from "react";
import styles from "./IconListSearch.module.css";

export type IconListSearchProps = {
  color?: string;
};

export const IconListSearch: React.FC<IconListSearchProps> = ({
  color = tokens.colour["brand-blue-palette"]["200"],
}) => (
  <div className={styles.container}>
    <svg
      fill="none"
      height="11"
      viewBox="0 0 18 11"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83464 2.5H0.667969V0.833333H4.83464V2.5ZM4.83464 5H0.667969V6.66667H4.83464V5ZM16.1596 10.8333L12.968 7.64167C12.3013 8.075 11.518 8.33333 10.668 8.33333C8.36797 8.33333 6.5013 6.46667 6.5013 4.16667C6.5013 1.86667 8.36797 0 10.668 0C12.968 0 14.8346 1.86667 14.8346 4.16667C14.8346 5.01667 14.5763 5.8 14.143 6.45833L17.3346 9.65833L16.1596 10.8333ZM13.168 4.16667C13.168 2.79167 12.043 1.66667 10.668 1.66667C9.29297 1.66667 8.16797 2.79167 8.16797 4.16667C8.16797 5.54167 9.29297 6.66667 10.668 6.66667C12.043 6.66667 13.168 5.54167 13.168 4.16667ZM0.667969 10.8333H9.0013V9.16667H0.667969V10.8333Z"
        fill={color}
      />
    </svg>
  </div>
);

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Spinner,
  SpinnerSize,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import {
  type ColumnFiltersState,
  type TableOptions,
  type Table,
  type Updater,
  type VisibilityState,
  type OnChangeFn,
  type RowSelectionState,
  type Row,
  type GroupColumnDef,
  type ExpandedState,
  type SortingState,
  type ColumnResizeMode,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  getSortedRowModel,
  type ColumnDef,
  getFilteredRowModel,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import commonStyles from "../common/GridTable.module.css";
import {
  DEFAULT_COLUMN_MAX_WIDTH,
  DEFAULT_COLUMN_MIN_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  FIRST_COLUMN_WIDTH_MULTIPLY_BY_5,
} from "../common/constants";
import { DELETED_LINE_FILTER_COLUMN_ID } from "../common/filters/excludeDeletedLineFilter";
import { SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID } from "../common/filters/selectedItemsOnlyFilter";
import { HeaderCell } from "../common/table-cell/HeaderCell";
import { TestId } from "./constants";

const DEFAULT_ROW_SELECTION_STATE = {};
export const AUTO_WIDTH = "auto";
export const PERCENT_WIDTH = "100%";

export type ReportHierarchyTableProps<T> = {
  className?: string;
  columnFilters?: ColumnFiltersState;
  columnResizeMode?: ColumnResizeMode;
  columnVisibility?: VisibilityState;
  columns: Array<ColumnDef<T>> | Array<GroupColumnDef<T>>;
  compactRows?: boolean;
  data: T[];
  defaultColumnWidthOverride?:
    | Partial<ColumnDef<T>>
    | Partial<GroupColumnDef<T>>;
  depthPadding?: number;
  depthPaddingOffset?: number;
  disableSorting?: boolean;
  getIsRowDisabled?: (row: Row<T>, table: Table<T>) => boolean;
  getRowId?: (row: T, relativeIndex?: number, parent?: Row<T>) => string;
  getSubRows: (row: T) => T[] | undefined;
  handleMoreClicked?: (row: Row<T>) => Promise<void>;
  isRowHighlighted?: (row: T) => boolean;
  lazyLoadEnabled?: boolean;
  moreText?: string;
  onExpandedChange?: (rowExpansion: Updater<ExpandedState>) => void;
  onRowClick?: Function;
  onRowDoubleClick?: Function;
  pinFirstColumn?: boolean;
  refreshingData?: boolean;
  rowExpandedState: ExpandedState;
  rowSelectionState?: RowSelectionState;
  setColumnVisibility?: OnChangeFn<VisibilityState>;
  setRowSelection?: (rowSelection: Updater<RowSelectionState>) => void;
  showCheckboxesOnlyOnHover?: boolean;
  tableWidth?: number | string;
};

export type LoadMoreRowParent<T> = T & {
  isMoreRow: boolean;
};

export type LoadMoreRow<T> = T & {
  loadMoreParentRow: LoadMoreRowParent<T>;
  moreLabel: string;
};

export type HierarchicalRow<T> = T & {
  subRows?: T[];
};

export type ExpandingRow<T> = T & {
  isExpanding: boolean;
};

export type LazyLoadingRow<T> = T & {
  hasLoadableChildRows: boolean;
  isExpanding: boolean;
  subRows?: Array<LazyLoadingRow<T>>;
};

export type LazyExpandingRow<T> = LazyLoadingRow<T> & LoadMoreRowParent<T> & T;

// a row in the initial data can have a "isLoadMore" property which means it is the last row with a load more after it
export const isMoreRow = <T extends { isMoreRow?: boolean }>(data: T) =>
  typeof data === "object" && "isMoreRow" in data && data.isMoreRow === true;

// a virtual row inserted after the last isLoadMore row with the button for loading more
export const isLoadMoreRow = <T extends { isLoadMoreRow?: boolean }>(data: T) =>
  typeof data === "object" && "moreLabel" in data;

// a row has subRows. This is needed to display hierarchical more data
export const isHierarchical = <T extends { subRows?: T[] }>(data: T) =>
  typeof data === "object" && "subRows" in data && data.subRows;

// recursively check if a row is either a lazy loading parent row with children already, or there are none with loadmore
export const isLazyLoadSortable = <T,>(
  data: Array<LazyExpandingRow<T>>
): boolean => {
  let canExpand = true;

  for (const row of data) {
    // if anything has an isMoreRow, meaning it has siblings that can be loaded, filtering is disabled (as we cannot filter what we do not have)
    if (isMoreRow(row)) canExpand = false;

    // if it has child loadable rows that have not been expanded, prevent filtering
    if (
      row.hasLoadableChildRows &&
      (!row.subRows || row.subRows.length === 0)
    ) {
      canExpand = false;
    }

    // if its already failed, prevent sorting
    if (!canExpand) return false;

    if (row.subRows) {
      // check its subrows
      canExpand = isLazyLoadSortable(row.subRows as Array<LazyExpandingRow<T>>);
    }
  }

  return canExpand;
};

export const ReportHierarchyTable = <T,>({
  data,
  className,
  columns,
  columnVisibility,
  compactRows = false,
  getSubRows,
  handleMoreClicked,
  rowExpandedState,
  lazyLoadEnabled = false,
  moreText,
  columnResizeMode,
  defaultColumnWidthOverride,
  pinFirstColumn,
  depthPadding = 0,
  depthPaddingOffset = 0,
  rowSelectionState = DEFAULT_ROW_SELECTION_STATE,
  setColumnVisibility,
  showCheckboxesOnlyOnHover = false,
  // can be a value in px, or a string value to set the table width
  tableWidth = PERCENT_WIDTH,
  getIsRowDisabled,
  getRowId,
  setRowSelection,
  isRowHighlighted,
  disableSorting,
  onExpandedChange,
  refreshingData,
  columnFilters,
  onRowClick,
  onRowDoubleClick,
}: ReportHierarchyTableProps<T>) => {
  // TODO: reset to initial state when sorting is toggled off
  // https://jira.quantium.com.au/browse/CO3-457
  const [sorting, setSorting] = useState<SortingState>([]);
  const [tableData, setTableData] = useState<T[]>(() => []);
  const [isLoadingMore, setIsLoadingMore] = useState<Record<string, boolean>>(
    {}
  );

  const getLoadingMoreId = useCallback(
    (row: T) => (getRowId ? getRowId(row) : "load-more"),
    [getRowId]
  );

  // loop through data, and for each time you find a isMoreRow, add a new row at the same depth level at the end.
  const addLoadMoreRows = useCallback(
    (rows: Array<HierarchicalRow<T>>) => {
      const newRows: Array<LoadMoreRow<T> | T> = [];

      for (const row of rows) {
        const temporaryRow = { ...row };
        // clear the subRows first
        temporaryRow.subRows = [];

        // recursively update its children
        if (isHierarchical(row as object)) {
          temporaryRow.subRows = [
            ...addLoadMoreRows(row.subRows as Array<HierarchicalRow<T>>),
          ];
        }

        newRows.push(temporaryRow);

        if (isMoreRow(row as object)) {
          newRows.push({
            moreLabel: moreText,
            loadMoreParentRow: row,
          } as unknown as LoadMoreRow<T>);
          isLoadingMore[getLoadingMoreId(row)] = false;
        }
      }

      return newRows;
    },
    [getLoadingMoreId, isLoadingMore, moreText]
  );

  const canRowsExpand = useCallback(
    (row: Row<T>): boolean => lazyLoadEnabled || Boolean(row.subRows.length),
    [lazyLoadEnabled]
  );

  // if a column has columns, its a group column table.
  const columnGroupings = columns.map((col) =>
    "columns" in col ? col.columns?.length : undefined
  );

  // if the table Data changes, rerender
  useEffect(
    () => setTableData(addLoadMoreRows(data as Array<HierarchicalRow<T>>)),
    [addLoadMoreRows, data]
  );

  const isSortingEnabled = useCallback(
    () =>
      // see https://jira.quantium.com.au/browse/CO3-941 for rules around when sorting is possible.
      isLazyLoadSortable(data as Array<LazyExpandingRow<T>>),
    [data]
  );

  const baseTableConfigurations: TableOptions<T> = {
    columnResizeMode,
    columns,
    data: tableData,
    defaultColumn: defaultColumnWidthOverride
      ? defaultColumnWidthOverride
      : {
          minSize: DEFAULT_COLUMN_MIN_WIDTH,
          size: DEFAULT_COLUMN_WIDTH,
          maxSize: DEFAULT_COLUMN_MAX_WIDTH,
        },
    enableColumnResizing: Boolean(columnResizeMode),
    enableSubRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row: Row<T>) => canRowsExpand(row),
    getRowId,
    getSubRows,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility,
      expanded: rowExpandedState,
      rowSelection: rowSelectionState,
    },
  };

  let tableConfigurations: TableOptions<T> = { ...baseTableConfigurations };

  if (isSortingEnabled() || !disableSorting) {
    const sortingConfigurations = {
      enableSorting: isSortingEnabled(),
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: setSorting,
      state: {
        sorting,
      },
    };

    tableConfigurations = {
      ...tableConfigurations,
      ...sortingConfigurations,
      state: {
        ...tableConfigurations.state,
        ...sortingConfigurations.state,
      },
    };
  }

  if (columnFilters) {
    tableConfigurations = {
      ...tableConfigurations,
      getFilteredRowModel: getFilteredRowModel(),
    };
  }

  const table: Table<T> = useReactTable(tableConfigurations);

  const firstColumnMinWidth: number = useMemo(
    () => DEFAULT_COLUMN_MIN_WIDTH * FIRST_COLUMN_WIDTH_MULTIPLY_BY_5,
    []
  );

  const onMoreClicked = (row: Row<T>) => {
    setIsLoadingMore({
      ...isLoadingMore,
      [getLoadingMoreId(row.original)]: true,
    });
    if (handleMoreClicked) {
      void handleMoreClicked(row)
        .then(() =>
          setIsLoadingMore({
            ...isLoadingMore,
            [getLoadingMoreId(row.original)]: false,
          })
        )
        .finally(() =>
          setIsLoadingMore({
            ...isLoadingMore,
            [getLoadingMoreId(row.original)]: false,
          })
        );
    }
  };

  useEffect(() => {
    if (pinFirstColumn) {
      table.getHeaderGroups()[0].headers[0].column.pin("left");
    }
  }, [table, pinFirstColumn]);

  // set the filter values for the columns to trigger filters to be applied
  useEffect(() => {
    if (columnFilters) {
      const deletedLinesFilter = columnFilters.find(
        (filter) => filter.id === DELETED_LINE_FILTER_COLUMN_ID
      );

      const selectedItemsOnlyFilter = columnFilters.find(
        (filter) => filter.id === SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID
      );

      for (const headerGroup of table.getHeaderGroups()) {
        for (const header of headerGroup.headers) {
          if (
            deletedLinesFilter &&
            header.column.id === DELETED_LINE_FILTER_COLUMN_ID
          ) {
            header.column.setFilterValue(deletedLinesFilter.value);
          }

          if (
            selectedItemsOnlyFilter &&
            header.column.id === SELECTED_ITEMS_ONLY_FILTER_COLUMN_ID
          ) {
            header.column.setFilterValue(selectedItemsOnlyFilter.value);
          }
        }
      }
    }
  }, [columnFilters, table]);

  return (
    <>
      <table
        className={classNames(commonStyles.table, className)}
        style={{
          width:
            typeof tableWidth === "string" ? tableWidth : `${tableWidth}px`,
        }}
      >
        {columnGroupings.filter(Boolean).map(
          (group) =>
            group && (
              <colgroup
                className={commonStyles.columnGroup}
                data-testid={TestId.ColumnGroup}
                key={uniqueId()}
              >
                <col span={group} />
              </colgroup>
            )
        )}
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              className={classNames(commonStyles.tableRow)}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => (
                <th
                  className={classNames(commonStyles.tableHeader, {
                    [commonStyles.pinned]: header.column.getIsPinned(),
                  })}
                  colSpan={header.colSpan}
                  key={header.id}
                  style={{
                    minWidth:
                      header.index === 0 && pinFirstColumn
                        ? firstColumnMinWidth
                        : header.getSize(),
                    maxWidth:
                      header.index === 0 && pinFirstColumn
                        ? firstColumnMinWidth
                        : header.getSize(),
                  }}
                >
                  <HeaderCell
                    columnResizeMode={columnResizeMode}
                    deltaOffset={table.getState().columnSizingInfo.deltaOffset}
                    depth={headerGroup.depth}
                    disableSorting={disableSorting}
                    header={header}
                    pinFirstColumn={pinFirstColumn}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className={classNames(commonStyles.tableBody, {
            [commonStyles.refreshDataTbody]: refreshingData,
          })}
        >
          {table.getRowModel().rows.map((row) =>
            isLoadMoreRow(row.original as Object) ? (
              <tr className={commonStyles.moreRow} key={row.id}>
                <td colSpan={table.getRowModel().rows.length}>
                  {handleMoreClicked &&
                    !isLoadingMore[getLoadingMoreId(row.original)] && (
                      <div
                        className={classNames(commonStyles.more)}
                        style={{
                          paddingLeft:
                            row.depth * depthPadding + depthPaddingOffset,
                        }}
                      >
                        <Button
                          data-testid="load-more"
                          height={ButtonHeight.XSmall}
                          onClick={() => onMoreClicked(row)}
                          text={moreText}
                          variant={ButtonVariant.Link}
                        />
                      </div>
                    )}
                  {handleMoreClicked &&
                    isLoadingMore[getLoadingMoreId(row.original)] && (
                      <div className={classNames(commonStyles.more)}>
                        <div
                          className={commonStyles.loadingMoreSpinner}
                          style={{
                            paddingLeft:
                              row.depth * depthPadding + depthPaddingOffset,
                          }}
                        >
                          <Spinner
                            size={SpinnerSize.Small}
                            text="Loading more..."
                          />
                        </div>
                      </div>
                    )}
                </td>
              </tr>
            ) : (
              <tr
                className={classNames(commonStyles.tableRow, {
                  [commonStyles.selected]: isRowHighlighted
                    ? isRowHighlighted(row.original)
                    : row.getIsSelected(),
                  [commonStyles.isMoreRow]: isMoreRow(row.original as Object),
                  [commonStyles.checkboxesOnHover]: showCheckboxesOnlyOnHover,
                  [commonStyles.greyedOut]: getIsRowDisabled?.(row, table),
                })}
                key={row.id}
                onClick={(event: React.MouseEvent<HTMLTableRowElement>) => {
                  onRowClick?.(event, row.id, row.original);
                }}
                onDoubleClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                  onRowDoubleClick?.(event, row.id, row.original)
                }
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td
                    className={classNames(commonStyles.tableData, {
                      [commonStyles.selected]: isRowHighlighted
                        ? isRowHighlighted(row.original)
                        : row.getIsSelected(),
                      [commonStyles.pinned]: cell.column.getIsPinned(),
                    })}
                    key={cell.id}
                    style={{
                      minWidth:
                        cellIndex === 0 && pinFirstColumn
                          ? firstColumnMinWidth
                          : cell.column.getSize(),
                      maxWidth:
                        cellIndex === 0 && pinFirstColumn
                          ? firstColumnMinWidth
                          : cell.column.getSize(),
                    }}
                  >
                    <div
                      className={classNames(commonStyles.cellContainer, {
                        [commonStyles.compact]: compactRows,
                      })}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
      {refreshingData && (
        <div className={commonStyles.refreshDataSpinnerOverlay}>
          <Spinner size={SpinnerSize.Large} />
        </div>
      )}
    </>
  );
};

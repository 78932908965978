import { IconSize, InlineIcon, InlineIconGlyph } from "@qbit/react";
import { type ParameterDto } from "@quantium-enterprise/common-ui";
import ListHeader from "../list-header/ListHeader";
import { CheckboxGroup } from "./CheckboxGroup";
import styles from "./CheckboxList.module.css";

export type CheckboxListProps = {
  className?: string;
  disabledValuesWithReason: Record<string, string>;
  mainTooltipMessage?: string;
  onChange: (clickedValue: string) => void;
  parameter: ParameterDto;
  selectedValues: string[];
};

export const CheckboxList = ({
  className,
  onChange,
  parameter,
  selectedValues,
  disabledValuesWithReason,
  mainTooltipMessage,
}: CheckboxListProps) => {
  const isGroupingRequired = parameter.options.some(
    (option) => option.groupName
  );
  const groupNames = [
    ...new Set(parameter.options.map((option) => option.groupName)),
  ];
  return (
    <div className={className}>
      <ListHeader headerText={parameter.name} />
      {!isGroupingRequired && (
        <div data-testid="not-grouped">
          <CheckboxGroup
            childData={parameter.options}
            disabledValuesWithReason={disabledValuesWithReason}
            mainTooltipMessage={mainTooltipMessage}
            maxSelections={parameter.attributes.maxSelections}
            onChange={onChange}
            parameterName={parameter.name}
            selectedValues={selectedValues}
          />
        </div>
      )}

      {isGroupingRequired && (
        <div data-testid="grouped">
          {groupNames.map((groupName) => (
            <div key={parameter.name + " " + groupName}>
              <div className={styles.subheader}>
                <div className={styles.title}>{groupName} </div>
                <InlineIcon
                  className={styles.infoIcon}
                  data-testid="info-icon"
                  glyph={InlineIconGlyph.AlertsAndNotificationsInform}
                  size={IconSize.Small}
                  text={parameter.name}
                />
              </div>
              <CheckboxGroup
                childData={parameter.options.filter(
                  (option) => option.groupName === groupName
                )}
                disabledValuesWithReason={disabledValuesWithReason}
                mainTooltipMessage={mainTooltipMessage}
                maxSelections={parameter.attributes.maxSelections}
                onChange={onChange}
                parameterName={parameter.name}
                selectedValues={selectedValues}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckboxList;

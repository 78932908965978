import {
  FormBlockStatus,
  Tag,
  TagColour,
  TagTextTransform,
  TagVariant,
} from "@qbit/react";
import { useEffect } from "react";
import { dateToString } from "../utilities";
import {
  CustomTimePeriod,
  type CustomTimePeriodOption,
} from "./CustomTimePeriod";
import styles from "./TimePeriod.module.css";
import { TimePeriodOptions } from "./TimePeriodConstants";
import { WithMinWeeksRestriction } from "./WithMinWeeksRestriction";
import TimeDropdown from "./shared/time-dropdown/TimeDropdown";
import { type TimePeriodOption } from "./time-period-option";

export type TimePeriodProps = {
  customTimePeriodOption?: TimePeriodOption;
  customTimePeriodOptions: CustomTimePeriodOption[];
  endDate: Date | undefined;
  endDateHandler: (startDate: string) => void;
  errors: Record<string, boolean>;
  infoIcon?: string;
  isDisabled: boolean;
  isDropdownVisible?: boolean;
  isEndDateSelectionVisible?: boolean;
  isNumberOfWeeksTagVisible?: boolean;
  isVisited: boolean;
  label: string;
  maxEndDate: Date | undefined;
  maxStartDate: Date | undefined;
  minEndDate: Date | undefined;
  minStartDate: Date | undefined;
  onChange: (newTimePeriod: string) => void;
  options: TimePeriodOption[];
  startDate: Date | undefined;
  startDateHandler: (startDate: string) => void;
  timePeriod: string;
  weeks: number | undefined;
};

export const TimePeriod = ({
  customTimePeriodOptions,
  customTimePeriodOption,
  endDate,
  endDateHandler,
  errors,
  infoIcon,
  isDisabled,
  isVisited,
  label,
  maxEndDate,
  maxStartDate,
  minEndDate,
  minStartDate,
  onChange,
  options,
  startDate,
  startDateHandler,
  timePeriod,
  weeks,
  isDropdownVisible = true,
  isEndDateSelectionVisible = true,
  isNumberOfWeeksTagVisible = true,
}: TimePeriodProps) => {
  const defaultTimePeriod = {
    disabled: false,
    label: `Select ${label.toLowerCase()}`,
    value: "",
  };

  const blockStatus =
    isVisited && errors.value ? FormBlockStatus.Error : FormBlockStatus.Default;

  useEffect(() => {
    if (!isDropdownVisible && timePeriod !== TimePeriodOptions.CUSTOM_PERIOD)
      onChange(TimePeriodOptions.CUSTOM_PERIOD);
  }, [isDropdownVisible, onChange, timePeriod]);

  const customPeriod = (beginningOfEndDate?: Date, endOfStartDate?: Date) => (
    <CustomTimePeriod
      beginningOfEndDate={beginningOfEndDate}
      endDate={endDate}
      endDateHandler={endDateHandler}
      endOfStartDate={endOfStartDate}
      errors={errors}
      isEndDateSelectionVisible={isEndDateSelectionVisible}
      isVisited={isVisited}
      maxEndDate={maxEndDate}
      maxStartDate={maxStartDate}
      minEndDate={minEndDate}
      minStartDate={minStartDate}
      options={customTimePeriodOptions}
      startDate={startDate}
      startDateHandler={startDateHandler}
      weeks={weeks}
    />
  );

  return (
    <div className={styles.timePeriodContainer}>
      <div className={styles.timePeriodDropdownWrapper}>
        {isDropdownVisible && (
          <TimeDropdown
            blockStatus={blockStatus}
            defaultOption={defaultTimePeriod}
            disabled={isDisabled}
            infoIcon={infoIcon}
            label={label}
            onChange={onChange}
            options={options}
            selectedValue={timePeriod}
          />
        )}

        {isNumberOfWeeksTagVisible &&
          timePeriod &&
          timePeriod !== TimePeriodOptions.CUSTOM_PERIOD && (
            <Tag
              className={styles.timePeriodDateTag}
              colour={TagColour.Neutral}
              text={`${dateToString(startDate)} - ${dateToString(endDate)}`}
              textTransform={TagTextTransform.None}
              variant={TagVariant.Default}
            />
          )}
      </div>

      {timePeriod === TimePeriodOptions.CUSTOM_PERIOD && (
        <WithMinWeeksRestriction
          customTimePeriodBuilder={customPeriod}
          customTimePeriodOption={customTimePeriodOption}
          customTimePeriodOptions={customTimePeriodOptions}
          endDate={endDate}
          startDate={startDate}
        />
      )}
    </div>
  );
};

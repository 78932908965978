import { Text } from "@qbit/react";
import { ParameterId, type ParameterDto } from "@quantium-enterprise/common-ui";
import { SummaryPanelBands } from "./SummaryPanelBands";
import { SummaryPanelBuffer } from "./SummaryPanelBuffer";
import { SummaryPanelDropdown } from "./SummaryPanelDropdown";
import { SummaryPanelGroups } from "./SummaryPanelGroups";
import { SummaryPanelHierarchy } from "./SummaryPanelHierarchy";
import { SummaryPanelLeadPeriod } from "./SummaryPanelLeadPeriod";
import { SummaryPanelLevelOfAnalysis } from "./SummaryPanelLevelOfAnalysis";
import { SummaryPanelList } from "./SummaryPanelList";
import { SummaryPanelMeasureThreshold } from "./SummaryPanelMeasureThreshold";
import { SummaryPanelRollingPeriod } from "./SummaryPanelRollingPeriod";
import { SummaryPanelSegmentFilter } from "./SummaryPanelSegmentFilter";
import { SummaryPanelSegmentation } from "./SummaryPanelSegmentations";
import { SummaryPanelStructure } from "./SummaryPanelStructure";
import { SummaryPanelTimePeriod } from "./SummaryPanelTimePeriod";

export type SummaryPanelItemProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelItem = ({ parameterDto }: SummaryPanelItemProps) => {
  const properties = {
    parameterDto,
  };

  const ParameterMap: Record<NonNullable<string>, JSX.Element> = {
    [ParameterId.Channel]: <SummaryPanelList {...properties} />,
    [ParameterId.ComparisonPeriod]: <SummaryPanelTimePeriod {...properties} />,
    [ParameterId.Dataset]: <SummaryPanelList {...properties} />,
    [ParameterId.FocusPeriod]: <SummaryPanelTimePeriod {...properties} />,
    [ParameterId.LeadPeriod]: <SummaryPanelLeadPeriod {...properties} />,
    [ParameterId.LevelOfAnalysis]: (
      <SummaryPanelLevelOfAnalysis {...properties} />
    ),
    [ParameterId.LocationHierarchy]: <SummaryPanelHierarchy {...properties} />,
    [ParameterId.LocationGroups]: <SummaryPanelGroups {...properties} />,
    [ParameterId.LocationStructure]: <SummaryPanelStructure {...properties} />,
    [ParameterId.ProductHierarchy]: <SummaryPanelHierarchy {...properties} />,

    [ParameterId.ProductGroups]: <SummaryPanelGroups {...properties} />,
    [ParameterId.ProductStructure]: <SummaryPanelStructure {...properties} />,
    [ParameterId.Promotion]: <SummaryPanelList {...properties} />,
    [ParameterId.RollingPeriod]: <SummaryPanelRollingPeriod {...properties} />,
    [ParameterId.Segmentation]: (
      <SummaryPanelSegmentation {...properties} defaultText="None selected" />
    ),
    [ParameterId.SegmentFilter]: <SummaryPanelSegmentFilter {...properties} />,
    [ParameterId.PurchasingBehaviour]: <SummaryPanelList {...properties} />,
    [ParameterId.PurchasingTrends]: <SummaryPanelList {...properties} />,
    [ParameterId.NewLapsedContinuous]: <SummaryPanelList {...properties} />,
    [ParameterId.RetailerCustomers]: <SummaryPanelList {...properties} />,
    [ParameterId.Measure]: (
      <SummaryPanelDropdown {...properties} label="Measure" />
    ),
    [ParameterId.Bands]: <SummaryPanelBands {...properties} />,
    [ParameterId.Buffer]: <SummaryPanelBuffer {...properties} />,
    [ParameterId.MeasureThreshold]: (
      <SummaryPanelMeasureThreshold {...properties} />
    ),
  };

  return (
    <>
      {Object.keys(ParameterMap).includes(parameterDto.id) ? (
        ParameterMap[parameterDto.id]
      ) : (
        <Text>Unhandled summary line</Text>
      )}
    </>
  );
};

export default SummaryPanelItem;

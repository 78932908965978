import tokens from "@qbit/core/dist/tokens.json";
import { type HierarchyItemType } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import React from "react";
import { IconListSearch } from "../assets/icons/IconListSearch";
import { HierarchyLevelIcon } from "../hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./FocalItem.module.scss";

export type AttributeItem = {
  code: string;
  displayName: string;
  type: HierarchyItemType;
};

export type FocalItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive: boolean;
  itemCount?: number;
  items: AttributeItem[];
};

export const FocalItem: React.FC<FocalItemProps> = ({
  isActive = false,
  itemCount,
  items,
  ...buttonProperties
}: FocalItemProps) => {
  const getFeatured = () => {
    if (itemCount) {
      return (
        <>
          <div className={styles.itemCount}>{itemCount}</div>
          {` item${itemCount > 1 ? "s" : ""} selected`}
        </>
      );
    } else if (!items.length) {
      return <span>[Select item]</span>;
    } else if (items.length === 1) {
      return (
        <>
          <HierarchyLevelIcon
            disabled={buttonProperties.disabled ?? false}
            shortName={items[0].code}
            type={items[0].type}
          />
          <div className={styles.attributeType}>{items[0].displayName}</div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.itemCount}>{items.length}</div> items selected
        </>
      );
    }
  };

  const iconColor = buttonProperties.disabled
    ? tokens.colour["shade-7"]
    : tokens.colour["brand-blue-palette"]["500"];

  return (
    <button
      {...buttonProperties}
      className={classNames(styles.focalItem, {
        [styles.active]: isActive,
      })}
      type="button"
    >
      <div className={styles.flexContainer}>
        <span className={styles.itemLabel}>Focal item</span>
        {getFeatured()}
        <div className={styles.iconWrapper}>
          <IconListSearch color={iconColor} />
        </div>
      </div>
    </button>
  );
};

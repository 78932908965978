import { Text } from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import styles from "./ScaleLegend.module.css";

export type ScaleLegendProps = {
  colors?: string[];
  title: string;
};

export const ScaleLegendColors = [
  "#eb5757",
  "#f39c26",
  "#fec941",
  "#b1b422",
  "#219653",
];

export const ScaleLegendColorsReversed = ScaleLegendColors.slice().reverse();

const PERCENTILE_STEP = 20;
const DECIMAL_STEP = 0.2;

export const calculatePercentile = (rank: number, totalRows: number) => {
  if (totalRows <= 0) {
    throw new Error("Total rows must be greater than 0");
  }

  const expectedTotalRows = Math.max(rank, totalRows);
  if (rank < 1) {
    throw new Error(
      "Rank must be greater than 1 and less than or equal to total rows"
    );
  }

  if (totalRows === 1) {
    return ScaleLegendColors.length - 1;
  }

  return Math.max(
    Math.ceil(
      ((expectedTotalRows - rank) / (expectedTotalRows - 1)) *
        ScaleLegendColors.length
    ) - 1,
    0
  );
};

export const PercentileScaleLegend = ({
  colors = ScaleLegendColors,
  title,
}: ScaleLegendProps) => (
  <div className={styles.legend}>
    <Text>{title}</Text>
    {colors.map((color, index) => (
      <div className={styles.legendItem} key={uniqueId()}>
        <div className={styles.legendIcon} style={{ backgroundColor: color }} />
        <Text>{`${(index > 0 ? ">" : "") + index * PERCENTILE_STEP} - ${
          (index + 1) * PERCENTILE_STEP
        }%`}</Text>
      </div>
    ))}
  </div>
);

export const PercentileScaleLegendReverse = ({
  colors = ScaleLegendColors,
  title,
}: ScaleLegendProps) => {
  const reversedColors = colors.slice().reverse();

  return (
    <div className={styles.legend}>
      <Text>{title}</Text>
      {reversedColors.map((color, index) => {
        if (index === 0) {
          return (
            <div className={styles.legendItem} key={uniqueId()}>
              <div
                className={styles.legendIcon}
                style={{ backgroundColor: color }}
              />
              <Text>{`Top ${(index + 1) * PERCENTILE_STEP}%`}</Text>
            </div>
          );
        } else if (index === reversedColors.length - 1) {
          return (
            <div className={styles.legendItem} key={uniqueId()}>
              <div
                className={styles.legendIcon}
                style={{ backgroundColor: color }}
              />
              <Text>{`Bottom ${100 - index * PERCENTILE_STEP}%`}</Text>
            </div>
          );
        }

        return (
          <div className={styles.legendItem} key={uniqueId()}>
            <div
              className={styles.legendIcon}
              style={{ backgroundColor: color }}
            />
            <Text>{`>${100 - (index + 1) * PERCENTILE_STEP} - ${
              100 - index * PERCENTILE_STEP
            }%`}</Text>
          </div>
        );
      })}
    </div>
  );
};

export const DecimalScaleLegend = ({
  colors = ScaleLegendColors,
  title,
}: ScaleLegendProps) => (
  <div className={styles.legend}>
    <Text>{title}</Text>
    {colors.map((color, index) => (
      <div className={styles.legendItem} key={uniqueId()}>
        <div className={styles.legendIcon} style={{ backgroundColor: color }} />
        <Text>{`${
          (index > 0 ? ">" : "") + (index * DECIMAL_STEP).toFixed(2)
        } - ${((index + 1) * DECIMAL_STEP).toFixed(2)}`}</Text>
      </div>
    ))}
  </div>
);

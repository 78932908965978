import tokens from "@qbit/core/dist/tokens.json";
import { IconGlyph } from "@qbit/react";
import {
  type FolderOrReportDto,
  type FolderDto,
  type SharedUserDto,
} from "@quantium-enterprise/common-ui";
import { FeatureFlag, TrackingComponent } from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import { RenameIcon } from "components-ui/src/assets/icons/RenameIcon";
import { ShareIcon } from "components-ui/src/assets/icons/ShareIcon";
import { ButtonDropdown } from "components-ui/src/button-dropdown/ButtonDropdown";
import { type ButtonContent } from "components-ui/src/button-list/ButtonList";
import {
  DeleteDialog,
  type DeleteItems,
} from "components-ui/src/delete-dialog/DeleteDialog";
import {
  type Folder,
  MoveItemModal,
  type MoveItemModalSubmitProps,
} from "components-ui/src/modals/move-item-modal/MoveItemModal";
import { ShareModal } from "components-ui/src/modals/share-modal/ShareModal";
import { useCallback, useMemo, useRef, useState } from "react";
import { isReportRow } from "./MyReportsGridWithFolders";

export const RenameButton: ButtonContent = {
  buttonIcon: <RenameIcon />,
  name: "rename",
  text: "Rename",
};

export const DeleteButton: ButtonContent = {
  buttonQbitIcon: IconGlyph.DeleteAndCloseDelete,
  buttonColour: tokens.colour["status-bad-text"],
  buttonTextColour: tokens.colour["status-bad-text"],
  name: "delete",
  text: "Delete",
};

export const ShareButton: ButtonContent = {
  buttonIcon: <ShareIcon />,
  name: "share",
  text: "Share",
};

export const MoveButton: ButtonContent = {
  buttonQbitIcon: IconGlyph.FilesAndFoldersFolderMove,
  buttonColour: tokens.colour["text-primary"],
  buttonTextColour: tokens.colour["text-primary"],
  name: "move",
  text: "Move",
};

type QuickActionsProps = {
  className?: string;
  deleteItem?: (itemId: string) => Promise<unknown>;
  folders: FolderDto[];
  handleRename?: (id: string) => void;
  item: FolderOrReportDto;
  itemName: string | undefined;
  moveItem: (submitProps: MoveItemModalSubmitProps) => Promise<unknown>;
  organisationUsers: SharedUserDto[] | undefined;
};

export const QuickActions = ({
  className,
  item,
  itemName,
  deleteItem,
  handleRename,
  organisationUsers,
  moveItem,
  folders,
}: QuickActionsProps) => {
  const flags = useFlags();
  const deleteButtonRef = useRef(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const handleDeleteDialogExit = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const handleDeleteReport = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const [showShareDialog, setShowShareDialog] = useState(false);
  const handleShareDialogExit = useCallback(() => {
    setShowShareDialog(false);
  }, []);

  const buttons: ButtonContent[][] = [];

  buttons.push([
    {
      ...MoveButton,
      handleClick: () => {
        setShowMoveDialog(true);
      },
    },
  ]);

  if (flags[FeatureFlag.SharingReports] && handleRename) {
    buttons[0] = buttons[0].concat([
      {
        ...RenameButton,
        handleClick: () => handleRename(item.id),
      },
    ]);
  } else if (handleRename) {
    buttons.push([
      {
        ...RenameButton,
        handleClick: () => handleRename(item.id),
      },
    ]);
  }

  if (flags[FeatureFlag.SharingReports] && organisationUsers) {
    buttons[0] = buttons[0].concat([
      {
        ...ShareButton,
        handleClick: () => setShowShareDialog(true),
      },
    ]);
  }

  if (deleteItem) {
    buttons.push([
      {
        ...DeleteButton,
        handleClick: () => {
          setShowDeleteDialog(true);
        },
      },
    ]);
  }

  const mappedFoldersToMoveFolders = useMemo(
    () =>
      folders.map(
        (folder) =>
          ({
            id: folder.id,
            name: folder.name,
            parentId: folder.parentId,
            color: folder.color,
            creationDateUTC: folder.creationDateUTC.toString(),
          } as Folder)
      ),
    [folders]
  );

  const itemIds: DeleteItems = item.id
    ? {
        reportIds: [item.id],
      }
    : ([] as DeleteItems);

  return (
    <>
      <ButtonDropdown buttons={buttons} className={className} />
      <DeleteDialog
        deleteItem={
          deleteItem ? async () => await deleteItem(item.id) : async () => {}
        }
        itemIds={itemIds}
        onClose={handleDeleteDialogExit}
        onDelete={handleDeleteReport}
        ref={deleteButtonRef}
        show={showDeleteDialog}
        trackingComponent={TrackingComponent.Report}
      />
      <MoveItemModal
        folders={mappedFoldersToMoveFolders}
        onClose={() => {
          setShowMoveDialog(false);
        }}
        onSubmit={moveItem}
        selectedItemFolderId={
          isReportRow(item)
            ? item.folderId
            : // eslint-disable-next-line eqeqeq
              folders.find((folder) => folder.id == item.id)?.parentId
        }
        selectedItemId={item.id}
        show={showMoveDialog}
      />
      <ShareModal.Reports
        onClose={handleShareDialogExit}
        organisationUsers={organisationUsers}
        reportId={item.id}
        reportName={itemName}
        showModal={showShareDialog}
      />
    </>
  );
};

import {
  Checkbox,
  StealthInlineIconButton,
  InlineIconGlyph,
  IconSize,
  FormBlockEditability,
  Tooltip,
  TooltipVariant,
  TooltipSpaceInside,
} from "@qbit/react";
import {
  ProductHierarchy,
  type HierarchyItemType,
  type TransactionSource,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { type PropsWithChildren } from "react";
import {
  HierarchyLevelIcon,
  isLeaf,
} from "../hierarchy-level-icon/HierarchyLevelIcon";
import { TransactionSourceIcon } from "../icons/transaction-source-icon/TransactionSourceIcon";
import styles from "./HierarchySelectGrid.module.css";
import { productLevelsNamesMap } from "./models/hierarchy";

type CellProps = {
  canExpand: boolean;
  depth: number;
  handleToggleExpanded: () => void;
  handleToggleSelected: (event: Event) => void;
  hideCheckbox?: boolean;
  id: string;
  isDisabled: boolean;
  isExpanded: boolean;
  isSelected: boolean;
  name: string;
  shortName: string;
  type: HierarchyItemType;
  value: unknown;
};

export const Cell = ({
  canExpand,
  children,
  depth,
  handleToggleExpanded,
  handleToggleSelected,
  hideCheckbox,
  id,
  isExpanded,
  isDisabled,
  isSelected,
  name,
  shortName,
  type,
  value,
}: PropsWithChildren<CellProps>) => {
  const longName = productLevelsNamesMap[shortName];
  const iconType = ProductHierarchy[longName as keyof typeof ProductHierarchy];

  const checkbox = (
    <Checkbox
      assistiveLabel
      checked={isSelected}
      className={styles.marginLeft}
      data-testid="row-selection-checkbox"
      editability={
        isDisabled
          ? FormBlockEditability.Disabled
          : FormBlockEditability.Editable
      }
      label={`Toggle row ${name}`}
      name={`togglerow${id}`}
      onChange={(event: Event) => handleToggleSelected(event)}
    />
  );

  const tooltipCheckbox = (
    <Tooltip
      id={`${name}-checkbox-tooltip`}
      spaceInside={TooltipSpaceInside.Medium}
      trigger={<span>{checkbox}</span>}
      variant={TooltipVariant.ArrowDark}
    >
      <span className={styles.checkboxTooltip}>
        The hierarchy level is not available for selection in this report.
      </span>
    </Tooltip>
  );

  const checkboxRender = isDisabled ? tooltipCheckbox : checkbox;

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleToggleExpanded();
  };

  return (
    <div className={classNames(styles.flex, styles.hierarchyCellContainer)}>
      {!hideCheckbox && checkboxRender}
      {children}
      <div
        className={classNames(styles.flex, {
          [styles.isLeaf]: isLeaf(iconType),
        })}
        style={{
          paddingLeft: `${Number(hideCheckbox ? depth + 1 : depth)}rem`,
        }}
      >
        {canExpand ? (
          <StealthInlineIconButton
            iconGlyph={
              isExpanded
                ? InlineIconGlyph.ArrowsChevronDown
                : InlineIconGlyph.ArrowsChevronRight
            }
            iconSize={IconSize.Medium}
            iconText={isExpanded ? "Close" : "Expand"}
            onClick={onClick}
          />
        ) : (
          <span
            className={hideCheckbox ? styles.ghostMargin : styles.marginLeft}
          />
        )}
        <span className={`${styles.flex} ${styles.paddingLeft}`}>
          <HierarchyLevelIcon shortName={shortName} type={type} />
          <span className={styles.rowValue}>{`${value}`}</span>
        </span>
      </div>
    </div>
  );
};

type EntitlementsCellProps = {
  entitlement?: TransactionSource | null;
  greyedOut?: boolean;
};

export const EntitlementsCell = ({
  entitlement,
  greyedOut,
}: EntitlementsCellProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  const classNameEntitlements = classNames(
    styles.marginRight,
    styles.cellContainer
  );
  return (
    <div className={classNameEntitlements}>
      <TransactionSourceIcon
        availableTransactionSources={availableTransactionSources}
        greyedOut={greyedOut ?? false}
        transactionSource={entitlement}
      />
    </div>
  );
};

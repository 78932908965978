import {
  Button,
  ButtonVariant,
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  Icon,
  IconGlyph,
  Input,
  InputStatus,
  NumberInput,
  Tag,
  TagColour,
  TagVariant,
} from "@qbit/react";
import styles from "./Band.module.css";

export type BandProps = {
  errors: string[];
  id: string;
  index: number;
  maximum: number;
  minimum: number;
  removeBand?: (index: number) => void;
  updateMaximum: (index: number, value: number) => void;
};

export const Band = ({
  errors,
  id,
  index,
  maximum,
  minimum,
  removeBand,
  updateMaximum,
}: BandProps) => {
  const onChangeMaximum = (event: React.FormEvent<HTMLInputElement>) => {
    updateMaximum(index, Number(event.currentTarget.value));
  };

  return (
    <FormBlock
      blockStatus={
        errors.length === 0 ? FormBlockStatus.Default : FormBlockStatus.Error
      }
      blockType={FormBlockType.Number}
    >
      <div className={styles.band}>
        <span>Range {index + 1}:</span>
        <span>{index === 0 ? "Top" : `> ${minimum}%`}</span>
        <span>{index === 0 ? "" : `to`}</span>
        <span>
          <Input>
            <NumberInput
              className={styles.maximumInput}
              id={String(index)}
              max={100}
              min={0}
              onChange={onChangeMaximum}
              step={1}
              value={String(maximum)}
            />
          </Input>
        </span>
        <span>%</span>
        <span>
          {removeBand && (
            <Button
              onClick={() => removeBand(index)}
              variant={ButtonVariant.Link}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Remove rule" />
            </Button>
          )}
        </span>
      </div>
      <div className={styles.bandErrorsContainer}>
        {errors.map((error) => (
          <div className={styles.bandError} key={error}>
            <Tag
              className={styles.errorIcon}
              colour={TagColour.Bad}
              text={error}
              variant={TagVariant.Badge}
            />
            <InputStatus id={`${id}-${error}`} text={error} />
          </div>
        ))}
      </div>
    </FormBlock>
  );
};

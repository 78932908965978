import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
  Text,
} from "@qbit/react";
import {
  type UserDto,
  ActivityType,
  getTimeAgoString,
  isSharingActivity,
  type SharedUserDto,
  type SharingActivity,
  type Activity,
} from "@quantium-enterprise/common-ui";
import { useMemo } from "react";
import { ShareIcon, ShareIconColours } from "../../assets/icons/ShareIcon";
import { SegmentBuilderActivityText } from "../../segment-builder-activity-text/SegmentBuilderActivityText";
import { SharedByText } from "../../share-text/SharedByText";
import { SharedWithText } from "../../share-text/SharedWithText";
import styles from "./InfoPanelActivity.module.css";

const MAX_DISPLAYED_USERS = 3;

export type filteredSharingActivity = {
  activityType: ActivityType;
  sharedDate: string;
  sharingNotes?: string;
  users: SharedUserDto[];
};

export type InfoPanelActivityProps = {
  currentUser?: UserDto;
  header?: string;
  resourceType: string;
  sharedUsers: SharedUserDto[] | undefined;
  sharingActivities: Array<Activity | SharingActivity> | undefined;
  showViewAccess?: () => void;
};

export const InfoPanelActivity = ({
  currentUser,
  header,
  sharedUsers,
  sharingActivities,
  showViewAccess,
  resourceType,
}: InfoPanelActivityProps) => {
  const filteredSharingActivities = useMemo(
    (): filteredSharingActivity[] | undefined =>
      sharingActivities
        ?.map((sharingActivity) => ({
          activityType: sharingActivity.activityType,
          sharedDate: isSharingActivity(sharingActivity)
            ? sharingActivity.sharedDate
            : sharingActivity.activityDate,
          sharingNotes: isSharingActivity(sharingActivity)
            ? sharingActivity.sharingNotes
            : sharingActivity.activityData,
          users:
            sharedUsers?.filter((user) =>
              sharingActivity.salesforceUserIds.includes(user.salesforceUserId)
            ) ?? [],
        }))
        .filter((sharingActivity) => sharingActivity.users.length !== 0)
        .reverse(),
    [sharedUsers, sharingActivities]
  );

  return (
    <>
      <div className={styles.header}>
        {header && <h6>{header}</h6>}
        {showViewAccess && (
          <Button
            height={ButtonHeight.XSmall}
            onClick={showViewAccess}
            variant={ButtonVariant.Stealth}
          >
            <Icon
              glyph={IconGlyph.AccountAndUserAccountMultiple}
              text="View access"
            />
            <Text>View access</Text>
          </Button>
        )}
      </div>
      {filteredSharingActivities?.map((sharingActivity, index) => (
        <div
          className={styles.activityWrapper}
          // eslint-disable-next-line react/no-array-index-key
          key={`sharing-activity-wrapper-${index}`}
        >
          <div className={styles.activityContent}>
            <ShareIcon color={ShareIconColours.blue} />
            <div className={styles.leftMargin}>
              <span className={styles.activityBody}>
                {sharingActivity.activityType ===
                  ActivityType.SegmentBuilderActivate ||
                sharingActivity.activityType ===
                  ActivityType.SegmentBuilderDeactivate ? (
                  <SegmentBuilderActivityText
                    activity={sharingActivity.activityType}
                    currentUser={currentUser}
                    user={sharingActivity.users.at(0)}
                  />
                ) : sharingActivity.activityType === ActivityType.SharedBy ? (
                  <SharedByText
                    resourceType={resourceType}
                    user={sharingActivity.users.at(0)}
                  />
                ) : (
                  <SharedWithText
                    maxDisplayedUsers={MAX_DISPLAYED_USERS}
                    resourceType={resourceType}
                    users={sharingActivity.users}
                  />
                )}
              </span>
              <Text className={styles.sharedDate}>
                {getTimeAgoString(sharingActivity.sharedDate)}
              </Text>
            </div>
          </div>
          {sharingActivity.sharingNotes && (
            <div className={styles.sharingNotes}>
              {sharingActivity.sharingNotes}
            </div>
          )}
          {index !== filteredSharingActivities.length - 1 && (
            <hr className={styles.contentSeparator} />
          )}
        </div>
      ))}
    </>
  );
};

import { Text } from "@qbit/react";
import { type SharedUserDto } from "@quantium-enterprise/common-ui";
import styles from "./ShareText.module.css";

export type SharedByTextProps = {
  resourceType: string;
  user: SharedUserDto | undefined;
};

export const SharedByText = ({ user, resourceType }: SharedByTextProps) => {
  if (!user) {
    return null;
  }

  return (
    <>
      <Text className={styles.boldText}>
        {`${user.firstName} ${user.lastName}`}
      </Text>
      <Text>{` shared this ${resourceType}`}</Text>
    </>
  );
};

import { ddLog, HierarchyItemType } from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { type ReportTabItemType } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabItem } from "../../common/components/ReportTabComponents/ReportTabItem";
import { ReportTabs } from "../../common/components/ReportTabComponents/ReportTabs";
import { EMPTY_NODE_NUMBER } from "../../common/constants";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type RootState } from "../../store";
import { GainAndLossFeatureFlags } from "../constants/gain-and-loss-feature-flags";
import { type GainsAndLossReportTableRequestDto } from "../models/GainsAndLossReportTableRequestDto";
import { useLazyGetReportDataTableQuery } from "../services/gains-and-loss-data-table-api-slice";
import { useLazyDownloadTableQuery } from "../services/gains-and-loss-data-table-export-slice";
import {
  onTopDrawerTableSuccess,
  selectFocalItem,
  selectLocalSelections,
  selectReportId,
  selectTopDrawerActiveTab,
  setTopDrawerActiveTab,
} from "../services/gains-and-loss-slice";
import { getParameterSummaryValueStrings } from "../utils/getParameterSummaryValueStrings";
import { getTableRequestDto } from "../utils/getTableData";
import { GainsAndLossTopDrawerProductTable } from "./GainsAndLossTopDrawerProductTable";

export type GainsAndLossTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportTabItems: ReportTabItemType[];
  reportType: string;
};

export const GainsAndLossTopDrawer = ({
  renameReport,
  reportType,
  reportName,
  reportTabItems,
  eventTrackingService,
}: GainsAndLossTopDrawerProps) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { name: divisionName } = useDivision();
  const flags = useFlags();
  const isExportEnabled = flags[GainAndLossFeatureFlags.ReportExport] ?? false;

  const reportId = useSelector(selectReportId);
  const activeTab = useSelector(selectTopDrawerActiveTab);
  const focalItem = useSelector(selectFocalItem);
  const localSelections = useSelector(selectLocalSelections);

  const { searchQuery } = useSelector((state: RootState) => ({
    searchQuery: state.gainsAndLoss.searchQuery,
  }));
  const handleTabClick = useCallback(
    (value: string) => {
      dispatch(setTopDrawerActiveTab(value));
    },
    [dispatch]
  );

  const [getReportDataTable, { isFetching: isTableFetching }] =
    useLazyGetReportDataTableQuery();

  const fetchData = useCallback(
    async (division: string, payload: GainsAndLossReportTableRequestDto) => {
      await getReportDataTable({ division, payload })
        .unwrap()
        .then((response) => {
          dispatch(onTopDrawerTableSuccess(response));
        })
        .catch((error) => {
          dispatch(
            onTopDrawerTableSuccess({
              data: [],
              hasNextPage: false,
              levelOfAnalysis: activeTab ?? "",
              measures: [],
              totalResults: 0,
            })
          );
          ddLog("ERROR", {}, "error", error);
        });
    },
    [getReportDataTable, dispatch, activeTab]
  );

  useEffect(() => {
    if (
      id &&
      id === reportId &&
      activeTab &&
      localSelections.location.nodeNumber !== EMPTY_NODE_NUMBER
    ) {
      const payload = getTableRequestDto(
        activeTab,
        localSelections,
        0,
        id,
        searchQuery
      );

      fetchData(divisionName, payload).catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [
    id,
    divisionName,
    activeTab,
    localSelections,
    fetchData,
    searchQuery,
    localSelections.location.nodeNumber,
    reportId,
  ]);

  const [downloadTableTrigger] = useLazyDownloadTableQuery();
  const fileName = useMemo(
    () =>
      cleanFilename(
        `Gain_and_loss_${localSelections.timePeriodLength}_${localSelections.location.name}`
      ),
    [localSelections]
  );

  const exportToCsvCallback = useCallback(async () => {
    if (localSelections.location.nodeNumber === EMPTY_NODE_NUMBER) {
      return;
    }

    await downloadTableTrigger({
      divisionName,
      payload: {
        localParametersSummary: getParameterSummaryValueStrings(
          localSelections.channel,
          localSelections.location,
          localSelections.metricSet,
          localSelections.segment,
          localSelections.segmentation,
          localSelections.time,
          focalItem
        ),
        localParameterSelections: [
          {
            id: LocalParameters.Channel,
            values: [localSelections.channel.value as string],
          },
          {
            id: LocalParameters.LocationHierarchy,
            values: [localSelections.location.nodeNumber.toString()],
          },
          {
            id: LocalParameters.Segmentation,
            values: [
              localSelections.segmentation.value.toString(),
              localSelections.segment.value.toString(),
            ],
          },
          {
            id: LocalParameters.MetricSet,
            values: [localSelections.metricSet.value as string],
          },
        ],
        fileName,
        reportId: id ?? "",
        loaShortName: activeTab ?? "",
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    fileName,
    id,
    localSelections,
    focalItem,
    activeTab,
  ]);

  return (
    <ReportTopDrawer
      controls={[
        <DataTableOptions
          filename={fileName}
          invokeCSVDownload={exportToCsvCallback}
          isFeatureEnabled={isExportEnabled}
          isInFocalItemHeader
          key={id}
        />,
      ]}
      items={
        focalItem
          ? [
              {
                code: focalItem.shortName,
                displayName: focalItem.name,
                type: HierarchyItemType.Hierarchy,
              },
            ]
          : []
      }
      renameReport={renameReport}
      reportId={id}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportTabs activeTab={activeTab ?? ""} onClickTabItem={handleTabClick}>
          {reportTabItems.map((item) => (
            <ReportTabItem
              key={item.value}
              label={item.label}
              value={item.value}
            >
              <GainsAndLossTopDrawerProductTable
                eventTrackingService={eventTrackingService}
                isQuerySuccess={!isTableFetching}
              />
            </ReportTabItem>
          ))}
        </ReportTabs>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  DialogHeight,
  DialogLayout,
  Group,
  IconSize,
  InlineIconGlyph,
  Item,
  ItemHalign,
  StealthInlineIconButton,
  Text,
} from "@qbit/react";
import { type SelectableItem } from "@quantium-enterprise/fast-reporting-ui";
import { FastReportingFocalItemSearch } from "@quantium-enterprise/fast-reporting-ui";
import { type HierarchySearchLevel } from "@quantium-enterprise/fast-reporting-ui/src/focal-item-search/HierarchySearchLevel";
import { useCallback, useRef, useState } from "react";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import styles from "./HierarchySearchAddItemsLayout.module.css";

type HierarchySearchAddItemsLayoutProps = {
  availableSearchLevels: HierarchySearchLevel[];
  existingSelectedItemCount: number;
  maxWatchlistItems: number;
  onAddItems: (items: SelectableItem[]) => void;
  onClose: () => void;
  onSearchLevelChanged: (level: HierarchySearchLevel) => void;
  searchLevel?: HierarchySearchLevel;
  show: boolean;
};
const HierarchySearchAddItemsLayout = ({
  onAddItems,
  onClose,
  onSearchLevelChanged,
  show,
  searchLevel,
  availableSearchLevels,
  existingSelectedItemCount,
  maxWatchlistItems,
}: HierarchySearchAddItemsLayoutProps) => {
  const ref = useRef(null);

  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>([]);

  const handleClose = useCallback(() => {
    setSelectedItems([]);
    onClose();
  }, [onClose]);

  const handleAddItems = useCallback(() => {
    setSelectedItems([]);
    onAddItems(selectedItems);
  }, [selectedItems, onAddItems]);

  const [isAddItemsEnabled, setIsAddItemsEnabled] = useState<boolean>(false);

  const handleSelectionChanged = (
    selection: SelectableItem[],
    isSelectionValid: boolean
  ) => {
    setSelectedItems(selection);
    setIsAddItemsEnabled(isSelectionValid && selection.length > 0);
  };

  return (
    <Dialog
      className={styles.addItemDialog}
      data-testid="hierarchy-search-add-dialog"
      footer={
        <Group className={styles.addItemsSearchAdd}>
          <Item halign={ItemHalign.Right}>
            <Button
              className={styles.addItemsSearchAddBtn}
              disabled={!isAddItemsEnabled}
              height={ButtonHeight.XSmall}
              onClick={handleAddItems}
              variant={ButtonVariant.Primary}
            >
              <Text>Save and Close</Text>
            </Button>
          </Item>
        </Group>
      }
      header={
        <>
          <Text className={styles.addItemsTitle}>Add item(s) to Watchlist</Text>
          <p className={styles.addItemsSubtitle}>
            Track up to <b>{maxWatchlistItems} items</b> in your Watchlist for
            quick and easy performance monitoring.
          </p>
        </>
      }
      height={DialogHeight.Medium}
      layout={DialogLayout.Fixed}
      onClose={onClose}
      show={show}
      title="Add item(s) to Watchlist"
      titleId="hierarchy-search-add-dialog"
      topRightControls={
        <StealthInlineIconButton
          iconGlyph={InlineIconGlyph.DeleteAndCloseClose}
          iconSize={IconSize.Large}
          iconText="Close"
          onClick={handleClose}
        />
      }
      triggeredBy={ref}
    >
      <div className={styles.searchContainer}>
        <div className={styles.search}>
          <ErrorBoundary>
            <FastReportingFocalItemSearch
              availableSearchLevels={availableSearchLevels}
              disableSelecting={
                selectedItems.length + existingSelectedItemCount >=
                maxWatchlistItems
              }
              disableSelectingTooltip={`${maxWatchlistItems} item limit reached. Remove an item to add a new one.`}
              onSearchLevelChanged={onSearchLevelChanged}
              onSelectionChanged={handleSelectionChanged}
              searchLevel={searchLevel}
            />
          </ErrorBoundary>
        </div>
      </div>
    </Dialog>
  );
};

export default HierarchySearchAddItemsLayout;

import { FormInputHeight } from "@qbit/react";
import {
  ReportType,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  getNumberFormat,
  useDivision,
  useFlags,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { ScatterChart } from "components-ui/src/charts/scatter-chart/ScatterChart";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { type PointOptionsObject } from "highcharts";
import { useState, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportletFilterWrapper } from "../../../common/components/ReportletFilterWrapper";
import { getFocalItemSummary } from "../../../common/utils/export-parameter-summary-utils";
import { type RootState } from "../../../store";
import { CustomerLoyaltyFeatureFlags } from "../../constants/customer-loyalty-feature-flags";
import {
  onMetricChange,
  selectChartData,
  selectFocalItems,
  selectLocalSelections,
  selectMetric,
  selectMetricList,
  selectPrimaryMetricLabel,
  selectSegment,
  selectSegmentation,
} from "../../services/customer-loyalty-slice";
import styles from "./CustomerLoyaltyScatterChart.module.css";
import RangeTextInput from "./RangeTextInput";
import { csvTransformation } from "./csvTransformation";
import {
  getCustomerLoyaltyChartData,
  getCustomerLoyaltyBrandGroupedChartData,
  getMinMaxDataValues,
  MAX_VALUE,
  MIN_VALUE,
  TICK_INTERVAL,
} from "./utils";

export const CustomerLoyaltyScatterChart = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const dispatch = useDispatch();

  const chartData = useSelector(selectChartData);
  const metric = useSelector(selectMetric);
  const metricList = useSelector(selectMetricList);
  const primaryMetricLabel = useSelector(selectPrimaryMetricLabel);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const localSelections = useSelector(selectLocalSelections);
  const focalItems = useSelector(selectFocalItems);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CustomerLoyaltyFeatureFlags.ReportExport] ?? false;

  const formatter = useFormatter();

  const isValidRange = (min: number, max: number) =>
    min < max && min >= MIN_VALUE && max <= MAX_VALUE;

  const [minLoyaltyValue, setMinLoyaltyValue] = useState<number | null>(
    MIN_VALUE
  );
  const [maxLoyaltyValue, setMaxLoyaltyValue] = useState<number | null>(
    MAX_VALUE
  );

  const metricDropdownSelectionHandler = useCallback(
    (value: PanelOption) => {
      const selectedMetric = metricList.find(
        (metricItem) => metricItem.value === value.value
      );
      dispatch(onMetricChange(selectedMetric));
    },
    [dispatch, metricList]
  );

  const segmentIndex = useMemo(() => {
    if (!segmentation.value || !segment.value || chartData.segments.length < 1)
      return 0;

    return chartData.segments.indexOf(segment.value as string);
  }, [chartData, segmentation, segment]);

  const primaryMetricIndex = useMemo(
    () =>
      metricList
        .map((metricItem) => metricItem.label)
        .indexOf(primaryMetricLabel),
    [metricList, primaryMetricLabel]
  );

  const metricIndex = useMemo(
    () => metricList.findIndex((target) => target.value === metric.value),
    [metric, metricList]
  );

  const chartDefaultMinMaxLoyaltyValue = useMemo(() => {
    const minMaxValues = getMinMaxDataValues(
      chartData.items,
      primaryMetricIndex,
      segmentIndex
    );
    setMinLoyaltyValue(minMaxValues[0]);
    setMaxLoyaltyValue(minMaxValues[1]);

    return minMaxValues;
  }, [chartData, primaryMetricIndex, segmentIndex]);

  const primaryMetric = useMemo(() => {
    if (primaryMetricIndex < 0) {
      return {
        format: "",
        formatter: formatter(MetricTypes.None),
        label: "",
        name: "",
      };
    }

    return {
      format: metricList[primaryMetricIndex].format,
      formatter: formatter(metricList[primaryMetricIndex].format),
      label: metricList[primaryMetricIndex].label,
      name: metricList[primaryMetricIndex].value,
    };
  }, [formatter, metricList, primaryMetricIndex]);

  const secondaryMetric = useMemo(
    () => ({
      format: metric.format,
      formatter: formatter(metric.format),
      label: metric.label,
      name: metric.value,
    }),
    [formatter, metric]
  );

  const series = useMemo(
    () =>
      chartData.brands.length < 1
        ? getCustomerLoyaltyChartData(
            chartData.items,
            metricIndex,
            primaryMetricIndex,
            segmentIndex
          )
        : getCustomerLoyaltyBrandGroupedChartData(
            chartData.brands,
            chartData.items,
            metricIndex,
            primaryMetricIndex,
            segmentIndex
          ),
    [chartData, metricIndex, primaryMetricIndex, segmentIndex]
  );

  const [minAxis, maxAxis] = useMemo(() => {
    if (
      minLoyaltyValue !== null &&
      maxLoyaltyValue !== null &&
      isValidRange(minLoyaltyValue, maxLoyaltyValue)
    ) {
      return [minLoyaltyValue, maxLoyaltyValue];
    }

    return [
      chartDefaultMinMaxLoyaltyValue[0],
      chartDefaultMinMaxLoyaltyValue[1],
    ];
  }, [minLoyaltyValue, maxLoyaltyValue, chartDefaultMinMaxLoyaltyValue]);
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);
  const CsvTransformationCallback = useCallback(
    () =>
      csvTransformation(
        series.map((value) => value.data as PointOptionsObject[]),
        currencySymbol,
        primaryMetric.label,
        secondaryMetric.format,
        secondaryMetric.label
      ),
    [series, primaryMetric, currencySymbol, secondaryMetric]
  );

  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Customer_Loyalty_Chart_${localSelections.timePeriod}_${localSelections.locationHierarchy.name}`
      ),
    [localSelections]
  );
  const parameterSummary = useMemo(
    () => [
      { name: "Focal item(s)", value: getFocalItemSummary(focalItems) },
      { name: "Time", value: localSelections.time },
      { name: "Metric", value: localSelections.metric.label },
      { name: "Segmentation", value: segmentation.label },
      { name: "Customer segment", value: segment.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localSelections.locationHierarchy.shortName
        )}) ${localSelections.locationHierarchy.name}`,
      },
    ],

    [localSelections, segmentation, segment, focalItems]
  );

  const { reportName } = useSelector((state: RootState) => ({
    reportName: state.customerLoyalty.reportName,
  }));

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();

  const xAxisExtendedConfig = useMemo(
    () => ({
      max: maxAxis / 100,
      min: minAxis / 100,
    }),
    [maxAxis, minAxis]
  );

  return (
    <>
      <div className={styles.loyaltyChartFiltersContainer}>
        <ReportletFilterWrapper label="Y-axis metric">
          <SingleSelectDropdown
            height={FormInputHeight.XSmall}
            onSelection={metricDropdownSelectionHandler}
            selectOptions={metricList.slice(1)}
            selectedValue={metric.value}
            title="focalItem"
          />
        </ReportletFilterWrapper>
        <ReportletFilterWrapper label="Loyalty % range">
          <RangeTextInput
            absoluteMax={MAX_VALUE}
            absoluteMin={MIN_VALUE}
            defaultMaxValue={chartDefaultMinMaxLoyaltyValue[1]}
            defaultMinValue={chartDefaultMinMaxLoyaltyValue[0]}
            id="loyalty-chart"
            maxValue={maxLoyaltyValue}
            minValue={minLoyaltyValue}
            onMaxChange={setMaxLoyaltyValue}
            onMinChange={setMinLoyaltyValue}
          />
        </ReportletFilterWrapper>
        <div className={styles.chartOptions}>
          <ChartOptions
            downloadWizardOptions={
              currentOptions
                ? {
                    chartOptions: currentOptions,
                    reportIcon: (
                      <ReportIcon type={ReportType.CustomerLoyalty} />
                    ),
                    chartTitle: `Customer loyalty - ${reportName}`,
                    reportTitle: reportName,
                  }
                : undefined
            }
            filename={exportFilename}
            getCSVData={CsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isExportEnabled}
            localParameters={parameterSummary}
            reportParameters={reportParameters}
          />
        </div>
      </div>
      <ChartFooterWrapper
        height="400px"
        parameters={parameterSummary}
        ref={chartContainerRef}
      >
        {series.length > 0 && (
          <ScatterChart
            onOptionsChanged={setCurrentOptions}
            primaryMetric={primaryMetric}
            secondaryMetric={secondaryMetric}
            series={series}
            tickInterval={TICK_INTERVAL}
            xAxisExtendedConfig={xAxisExtendedConfig}
          />
        )}
      </ChartFooterWrapper>
    </>
  );
};

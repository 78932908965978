import { type FormBlockEditability, RangeInput } from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import styles from "./RangeSlider.module.scss";

export type RangeSliderProps = {
  editability: FormBlockEditability;
  formatter: FormatterFunction;
  inverseSliderColor?: boolean;
  onChange: (value: number) => void;
  percentageThresholds: { maximum: number; minimum: number };
  rangeValues?: { maximum?: number; minimum?: number };
  step: number;
  value?: number;
};

export const RangeSlider = ({
  editability,
  rangeValues,
  step,
  value,
  percentageThresholds,
  inverseSliderColor,
  onChange,
  formatter,
}: RangeSliderProps) => {
  const id = uniqueId();

  const [displayedSliderValue, setDisplayedSliderValue] = useState(value);

  const [range, setRange] = useState<{
    max: number | undefined;
    min: number | undefined;
  }>({ max: 0, min: 0 });

  // when the range of the slider is changed, reset the slider position to the minimum value
  useEffect(() => {
    if (rangeValues) {
      setRange({ max: rangeValues.maximum, min: rangeValues.minimum });
    }

    setDisplayedSliderValue(value);
  }, [rangeValues, value]);

  const handleOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any type comes from qbit
    (event: any) => {
      setDisplayedSliderValue(event.target.value);
    },
    []
  );

  const handleOnMouseUp = useCallback(() => {
    onChange(displayedSliderValue ?? 0);
  }, [displayedSliderValue, onChange]);

  // round to 3 decimal places so that we can put it in CSS easier
  const percentage =
    displayedSliderValue && range.max && range.min
      ? (Math.round(
          ((displayedSliderValue - range.min) / (range.max - range.min) +
            Number.EPSILON) *
            1_000
        ) /
          1_000) *
        100
      : 100;

  const displayedValue = displayedSliderValue
    ? formatter(displayedSliderValue)
    : "";

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onMouseUp={handleOnMouseUp}>
      <RangeInput
        className={classNames({
          [styles.inverseSliderColor]: inverseSliderColor,
        })}
        editability={editability}
        id={id}
        max={range.max}
        min={range.min}
        onChange={handleOnChange}
        step={step}
        value={displayedSliderValue?.toString()}
      />
      <div className={styles.sliderLabels}>
        <div className={styles.minMaxLabelsContainer}>
          {percentage > percentageThresholds.minimum && (
            <span className={styles.minLabel}>
              {range.min === undefined ? "" : formatter(range.min)}
            </span>
          )}
          {percentage < percentageThresholds.maximum && (
            <span className={styles.maxLabel}>
              {range.max === undefined ? "" : formatter(range.max)}
            </span>
          )}
        </div>
        <div className={styles.valueLabelsContainer}>
          <span
            className={styles.valueLabel}
            style={{ left: `${percentage}%` }}
          >
            {displayedValue}
          </span>
        </div>
      </div>
    </div>
  );
};

import { FormInputHeight } from "@qbit/react";
import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { useEffect, useMemo } from "react";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import CollapsiblePanel from "../CollapsiblePanel";
import SingleSelectDropdown from "../SingleSelectDropdown";
import SingleSelectRadio from "../SingleSelectRadio";
import styles from "./IndexedMetricFilter.module.css";
import {
  convertToPanelOption,
  convertToSelectOption,
  getIndexedAgainstDisplayName,
  getIndexedAgainstMetricValue,
  getMetricDisplayName,
  isIndexedAgainstMetric,
} from "./utils";

export type MetricOption = {
  enableIndexAgainst?: boolean;
  label: string;
  value: string;
};
export type IndexAgainstOption = {
  hierarchyLabel: string;
  label: string;
  shortName: string;
  value: string;
};
export type Option = IndexAgainstOption | MetricOption;

export type IndexedMetricFilterProps = {
  filterIndexAgainst?: (option: IndexAgainstOption) => boolean;
  focalItems: HierarchySliceNodeDto[];
  indexAgainstOptions: IndexAgainstOption[];
  isCollapsed: boolean;
  isDisabled?: boolean;
  metricOptions: MetricOption[];
  onSelection?: (metric: PanelOption, indexedAgainst: PanelOption) => unknown;
  secondaryTitle?: string;
  selectedIndexedAgainstValue: string;
  selectedMetricValue: PanelOption;
  title?: string;
  toggleCollapsed: () => void;
};

const defaultMetricValue = {
  value: "",
  label: "",
};

const defaultOnSelection = () => {};
const defaultFilterIndexAgainst = () => true;

export const IndexedMetricFilter = ({
  focalItems,
  isCollapsed,
  isDisabled,
  onSelection = defaultOnSelection,
  selectedIndexedAgainstValue = "",
  selectedMetricValue = defaultMetricValue,
  filterIndexAgainst = defaultFilterIndexAgainst,
  metricOptions,
  indexAgainstOptions,
  title = "Metric",
  secondaryTitle = "Index against",
  toggleCollapsed,
}: IndexedMetricFilterProps) => {
  const isIndexAgainstEnabled = isIndexedAgainstMetric(
    indexAgainstOptions,
    selectedMetricValue.value as string
  );
  const selectedMetricDisplayName = isIndexAgainstEnabled
    ? getMetricDisplayName(
        selectedMetricValue.value as string,
        indexAgainstOptions
      )
    : getMetricDisplayName(selectedMetricValue.value as string, metricOptions);

  const filteredOptions = useMemo(() => {
    const options = indexAgainstOptions.filter(filterIndexAgainst);
    return options.length === 0 ? [indexAgainstOptions[0]] : options;
  }, [indexAgainstOptions, filterIndexAgainst]);

  useEffect(() => {
    if (
      isIndexedAgainstMetric(
        indexAgainstOptions,
        selectedMetricValue.value as string
      )
    ) {
      const indexOfLastFilteredOption = filteredOptions.length - 1;
      const indexOfPreviouslySelectedIndexedAgainst =
        indexAgainstOptions.findIndex(
          (option) => option.shortName === selectedIndexedAgainstValue
        );
      const defaultIndexOption =
        indexOfLastFilteredOption >= indexOfPreviouslySelectedIndexedAgainst
          ? filteredOptions[indexOfPreviouslySelectedIndexedAgainst]
          : filteredOptions[indexOfLastFilteredOption];

      onSelection(
        getIndexedAgainstMetricValue(
          indexAgainstOptions,
          selectedMetricDisplayName,
          defaultIndexOption.shortName
        ) ?? selectedMetricValue,
        convertToPanelOption(defaultIndexOption)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only listen to focal items to update selections to default on focal item change
  }, [focalItems]);

  const handleMetricSelection = (option: PanelOption) => {
    const value = option.value as string;
    const defaultIndexOption = filteredOptions[0];
    return indexAgainstOptions.some((option2) => option2.label === value)
      ? onSelection(
          getIndexedAgainstMetricValue(
            indexAgainstOptions,
            value,
            defaultIndexOption.shortName
          ) ?? option,
          convertToPanelOption(defaultIndexOption)
        )
      : onSelection(option, {} as PanelOption);
  };

  const handleIndexAgainstSelection = (option: PanelOption) => {
    const value = option.value as string;
    onSelection(
      getIndexedAgainstMetricValue(
        indexAgainstOptions,
        selectedMetricDisplayName,
        value
      ) ?? option,
      option
    );
  };

  const summaryPanel: JSX.Element | string = isIndexAgainstEnabled ? (
    <div className={styles.collapsedSummary}>
      {`${selectedMetricDisplayName} against`}
      <br />
      {getIndexedAgainstDisplayName(
        filteredOptions,
        selectedIndexedAgainstValue
      )}
    </div>
  ) : (
    selectedMetricDisplayName
  );
  const indexedAgainst = isIndexAgainstEnabled ? (
    <>
      <p className={styles.title}>{secondaryTitle}</p>
      <SingleSelectRadio
        isDisabled={isDisabled}
        onSelection={handleIndexAgainstSelection}
        selectOptions={convertToSelectOption(
          filteredOptions.filter(
            (option) => option.label === selectedMetricDisplayName
          )
        )}
        selectedValue={selectedIndexedAgainstValue}
        title={title}
      />
    </>
  ) : null;

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content: (
          <div>
            <SingleSelectDropdown
              height={FormInputHeight.XSmall}
              isDisabled={isDisabled}
              onSelection={handleMetricSelection}
              selectOptions={metricOptions}
              selectedValue={
                isIndexAgainstEnabled
                  ? selectedMetricDisplayName
                  : (selectedMetricValue.value as string)
              }
              title={title}
            />
            {indexedAgainst}
          </div>
        ),
        summary: summaryPanel,
      }}
    </CollapsiblePanel>
  );
};

export default IndexedMetricFilter;

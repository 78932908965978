import {
  Button,
  ButtonVariant,
  Group,
  GroupGutters,
  GroupRowspace,
  Icon,
  IconGlyph,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  CustomerGroupType,
  type CustomerGroupTypeDto,
  HierarchyGroupEvaluationType,
  isEnumValue,
  useGetCustomerGroupTypesQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import { CreatorTile } from "components-ui/src/creator-tile/CreatorTile";
import { AppFooter } from "components-ui/src/footer/AppFooter";
import { CustomerGroupIcon, HierarchyGroupIcon } from "components-ui/src/icons";
import { Link, Navigate, useParams } from "react-router-dom";
import { GroupType } from "../../enums/group-type";
import {
  getGroupCreatorPath,
  getGroupListPath,
  getCreateGroupPath,
} from "../../utilities/route-path-formats";
import styles from "./GroupCreator.module.css";
import { GroupDescriptions, learnMoreLinks } from "./extended-descriptions";

type TileInfo = {
  description?: string;
  disabled: boolean;
  displayName: string;
  evaluationType: string;
  groupType: string;
  learnMoreLink?: string;
};

const productGroupTiles: TileInfo[] = [
  {
    description: GroupDescriptions.ProductGroups.Static,
    disabled: false,
    displayName: "Static product group",
    evaluationType: HierarchyGroupEvaluationType.Static,
    groupType: GroupType.Product,
    learnMoreLink: learnMoreLinks.ProductAndLocationGroups,
  },
  {
    description: GroupDescriptions.ProductGroups.Dynamic,
    displayName: "Dynamic product group",
    disabled: false,
    evaluationType: HierarchyGroupEvaluationType.Dynamic,
    groupType: GroupType.Product,
    learnMoreLink: learnMoreLinks.ProductAndLocationGroups,
  },
];

const locationGroupTiles: TileInfo[] = [
  {
    description: GroupDescriptions.LocationGroups.Static,
    disabled: false,
    displayName: "Static location group",
    evaluationType: HierarchyGroupEvaluationType.Static,
    groupType: GroupType.Location,
    learnMoreLink: learnMoreLinks.ProductAndLocationGroups,
  },
  {
    description: GroupDescriptions.LocationGroups.Dynamic,
    disabled: false,
    displayName: "Dynamic location group",
    evaluationType: HierarchyGroupEvaluationType.Dynamic,
    groupType: GroupType.Location,
    learnMoreLink: learnMoreLinks.ProductAndLocationGroups,
  },
];

const customerGroupDtoToTile = (dto: CustomerGroupTypeDto) => ({
  ...dto,
  evaluationType: dto.customerGroupType,
  groupType: GroupType.Customer,
  learnMoreLink: learnMoreLinks.CustomerGroups,
});

const menuOptions = [
  {
    groupType: GroupType.Product,
    key: 0,
  },
  {
    groupType: GroupType.Location,
    key: 1,
  },
  {
    groupType: GroupType.Customer,
    key: 2,
  },
];

const getIcon = (tile: TileInfo) =>
  tile.groupType === GroupType.Customer ? (
    <CustomerGroupIcon coloured={!tile.disabled} type={tile.evaluationType} />
  ) : (
    <HierarchyGroupIcon
      evaluationType={tile.evaluationType}
      hierarchyType={tile.groupType}
    />
  );

export default () => {
  const division = useDivision();
  const { groupType } = useParams();

  if (!isEnumValue(GroupType)(groupType)) {
    return (
      <Navigate to={getGroupCreatorPath(division.name, GroupType.Product)} />
    );
  }

  const { data: customerGroupTypeDtos, isSuccess } =
    useGetCustomerGroupTypesQuery(division.name, {
      skip: !division.name,
    });

  const tiles: TileInfo[] =
    groupType === GroupType.Product
      ? productGroupTiles
      : groupType === GroupType.Location
      ? locationGroupTiles
      : customerGroupTypeDtos
      ? customerGroupTypeDtos.map(customerGroupDtoToTile)
      : [];

  return (
    <div className={styles.groupCreatorContainer}>
      <div className={styles.page}>
        <div className={styles.groupCreatorMenu}>
          <Link to={getGroupListPath(division.name, groupType)}>
            <Button variant={ButtonVariant.Stealth}>
              <Icon
                colour="var(--back-to-groups-colour)"
                glyph={IconGlyph.ArrowsBack}
                text="Go back"
              />
              <Text
                className={styles.backToGroups}
              >{`Back to ${groupType.toLowerCase()} groups`}</Text>
            </Button>
          </Link>
          <h3 className={styles.title}>Group creator</h3>
          <nav className={styles.menu}>
            {menuOptions.map((menuOption) => (
              <Link
                className={
                  location.pathname.endsWith(menuOption.groupType)
                    ? styles.activeLink
                    : ""
                }
                key={menuOption.key}
                to={getGroupCreatorPath(division.name, menuOption.groupType)}
              >
                <Text>{`${menuOption.groupType}`}</Text>
              </Link>
            ))}
          </nav>
        </div>
        <div className={styles.pageBody}>
          <h5
            className={styles.availableGroupsHeader}
          >{`${groupType} groups`}</h5>
          <div className={styles.groupCreatorTileContainer}>
            <Group
              gutters={GroupGutters.XLarge}
              rowspace={GroupRowspace.XLarge}
              wrap
            >
              {groupType === GroupType.Customer && !isSuccess ? (
                <Spinner size={SpinnerSize.Large} />
              ) : (
                tiles
                  .filter(
                    (tile) =>
                      !(
                        tile.disabled &&
                        groupType === GroupType.Customer &&
                        tile.evaluationType ===
                          CustomerGroupType.CustomSegmentation
                      )
                  )
                  .map((tile) => (
                    <CreatorTile
                      buttonText="Create group"
                      detailedDescription={tile.description}
                      disabled={tile.disabled}
                      icon={getIcon(tile)}
                      infoHeader={
                        tile.groupType === GroupType.Customer
                          ? tile.displayName
                          : `${tile.groupType} Group`
                      }
                      key={`${tile.groupType}-${tile.evaluationType}`}
                      label={tile.displayName}
                      learnMore={tile.learnMoreLink}
                      path={
                        tile.disabled
                          ? undefined
                          : getCreateGroupPath(
                              division.name,
                              tile.groupType,
                              tile.evaluationType
                            )
                      }
                      showGradient
                    />
                  ))
              )}
            </Group>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

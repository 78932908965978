export type FolderIconProps = {
  folderColour?: string;
};

export const FolderIcon = ({ folderColour }: FolderIconProps) => (
  <svg
    data-testid="folder-or-report-icon"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33854 3.32812H3.33854C2.42188 3.32812 1.68021 4.07812 1.68021 4.99479L1.67188 14.9948C1.67188 15.9115 2.42188 16.6615 3.33854 16.6615H16.6719C17.5885 16.6615 18.3385 15.9115 18.3385 14.9948V6.66146C18.3385 5.74479 17.5885 4.99479 16.6719 4.99479H10.0052L8.33854 3.32812Z"
      fill={folderColour ?? "var(--folder-icon-colour)"}
    />
  </svg>
);

// normal size by default
export const ShareRootFolderIcon = ({ size }: { size?: "small" }) => (
  <svg
    fill="none"
    height={size === "small" ? "15" : "17"}
    viewBox="0 0 20 17"
    width={size === "small" ? "18" : "20"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 16.5C1.45 16.5 0.979167 16.3042 0.5875 15.9125C0.195833 15.5208 0 15.05 0 14.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H8L10 2.5H18C18.55 2.5 19.0208 2.69583 19.4125 3.0875C19.8042 3.47917 20 3.95 20 4.5V14.5C20 15.05 19.8042 15.5208 19.4125 15.9125C19.0208 16.3042 18.55 16.5 18 16.5H2ZM9 13.5H17V12.95C17 12.2 16.6333 11.6042 15.9 11.1625C15.1667 10.7208 14.2 10.5 13 10.5C11.8 10.5 10.8333 10.7208 10.1 11.1625C9.36667 11.6042 9 12.2 9 12.95V13.5ZM13 9.5C13.55 9.5 14.0208 9.30417 14.4125 8.9125C14.8042 8.52083 15 8.05 15 7.5C15 6.95 14.8042 6.47917 14.4125 6.0875C14.0208 5.69583 13.55 5.5 13 5.5C12.45 5.5 11.9792 5.69583 11.5875 6.0875C11.1958 6.47917 11 6.95 11 7.5C11 8.05 11.1958 8.52083 11.5875 8.9125C11.9792 9.30417 12.45 9.5 13 9.5Z"
      fill="#4A4A4A"
    />
  </svg>
);

import { Spinner, SpinnerSize } from "@qbit/react";
import { type PropsWithChildren } from "react";
import styles from "./NoDataChartWrapper.module.css";

export type NoDataChartWrapperProps = {
  isLoading: boolean;
  minHeight?: string;
  noData: boolean;
};

export const NoDataChartWrapper = ({
  children,
  minHeight,
  noData,
  isLoading,
}: PropsWithChildren<NoDataChartWrapperProps>) => {
  if (isLoading) {
    return (
      <div className={styles.loading} style={{ minHeight }}>
        <Spinner size={SpinnerSize.Large} text="Loading more..." />
      </div>
    );
  }

  if (noData) {
    return (
      <div className={styles.message} style={{ minHeight }}>
        No data available.
      </div>
    );
  }

  return <>{children}</>;
};

import {
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  FormInputHeight,
  Input,
  NumberInput,
} from "@qbit/react";
import styles from "./LoyaltyThresholdForm.module.css";

export type TopDrawerInputProps = {
  errorMessage: string;
  handleOnChange: (value: string) => void;
  inputValue?: string;
  isErrorState: boolean;
  metric: string;
};

export const LoyaltyThresholdForm = ({
  inputValue,
  handleOnChange,
  metric,
  isErrorState,
  errorMessage,
}: TopDrawerInputProps) => (
  <div className={styles.inputContainer}>
    <span>Auto-select focal items with at least</span>
    <FormBlock
      blockStatus={
        isErrorState ? FormBlockStatus.Error : FormBlockStatus.Default
      }
      blockType={FormBlockType.Number}
    >
      <Input>
        <NumberInput
          className={styles.inputBox}
          height={FormInputHeight.XSmall}
          id="top-drawer-input"
          min={0}
          onChange={(event) => {
            handleOnChange(event.target.value);
          }}
          // @ts-expect-error -onKeyDown property works, but undefined in NumberInput type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onKeyDown={(event: any) => {
            // eslint-disable-next-line unicorn/no-unsafe-regex
            const exceptedKeys = /^(\d+(\.\d{0,3})?|)$/u;
            if (event?.key === "Enter") event.target.blur();
            // prevent non-numeric input
            else if (!exceptedKeys.test(inputValue + event?.keyCode))
              event.preventDefault();
          }}
          placeholder="N/A"
          value={inputValue}
        />
      </Input>
    </FormBlock>
    <span>{`${metric.toLowerCase()}.`}</span>
    {isErrorState && (
      <span className={styles.errorMessage}>{errorMessage}</span>
    )}
  </div>
);

export default LoyaltyThresholdForm;

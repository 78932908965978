import { ddLog, HierarchyItemType } from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { LocalParameters } from "../../common/models/LocalParameterId";
import { type RootState } from "../../store";
import { CustomerProfilingFeatureFlags } from "../constants/customer-profiling-feature-flags";
import { type ReportDataTableRequestDto } from "../models/CustomerProfilingReportTableRequestDto";
import {
  useGetInitialTableQuery,
  useLazyGetReportDataTableQuery,
  useLazyDownloadTableQuery,
} from "../services/customer-profiling-data-table-api-slice";
import {
  onTableResponseReceived,
  selectChannel,
  selectFocalItems,
  selectLocalSelections,
  selectLocation,
  selectMetric,
  selectPromotion,
  selectSegmentation,
  selectTime,
  selectTimePeriodLength,
} from "../services/customer-profiling-slice";
import { getParameterSummaryValueStrings } from "../utils/getParameterSummaryValueStrings";
import CustomerProfilingTopDrawerProductTable from "./TopDrawerProductTable/CustomerProfilingTopDrawerProductTable";
import {
  convertPanelOptionToString,
  getLocalSelectionPayload,
} from "./TopDrawerProductTable/utils";

export type CustomerProfilingTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const CustomerProfilingTopDrawer = ({
  renameReport,
  reportType,
  reportName,
  eventTrackingService,
}: CustomerProfilingTopDrawerProps) => {
  const { name: divisionName } = useDivision();
  const { id: reportId } = useParams();
  const dispatch = useDispatch();

  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const metric = useSelector(selectMetric);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const focalItems = useSelector(selectFocalItems);
  const localSelections = useSelector(selectLocalSelections);

  const {
    focalItemTableInitialised,
    localParametersInitialised,
    tableResponse,
  } = useSelector((state: RootState) => ({
    focalItemTableInitialised:
      state.customerProfiling.focalItemTableInitialised,
    localParametersInitialised:
      state.customerProfiling.localParametersInitialised,
    tableResponse: state.customerProfiling.tableResponse,
  }));

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CustomerProfilingFeatureFlags.ReportExport] ?? false;

  // Initial Table Query - gets called only once.
  const { data: initialTableData, isFetching: isInitialTableFetching } =
    useGetInitialTableQuery(
      {
        divisionName,
        payload: {
          localSelectedValues: getLocalSelectionPayload(localSelections),
          reportId: reportId ?? "",
        },
      },
      {
        skip:
          !localParametersInitialised ||
          !divisionName ||
          focalItemTableInitialised,
      }
    );

  // Set the initial displayed nodes
  useEffect(() => {
    if (initialTableData) {
      dispatch(onTableResponseReceived(initialTableData));
    }
  }, [initialTableData, dispatch]);

  // Lazy query for refetch of the full table
  const [refetchTableQueryTrigger, { isFetching: refetchTableFetching }] =
    useLazyGetReportDataTableQuery({
      refetchOnFocus: true,
    });

  const fetchTable = useCallback(async () => {
    const payload: ReportDataTableRequestDto = {
      localSelectedValues: getLocalSelectionPayload(localSelections),
      nodeNumbers:
        tableResponse.metricValues
          .find((x) => x.metricName === (metric.value as string))
          ?.items.map((index) => index.hierarchyItem.nodeNumber) ?? [],
      reportId: reportId ?? "",
    };

    const { data } = await refetchTableQueryTrigger({
      divisionName,
      payload,
    });

    if (data !== undefined) {
      dispatch(onTableResponseReceived(data));
    }
  }, [
    localSelections,
    metric,
    tableResponse.metricValues,
    divisionName,
    reportId,
    refetchTableQueryTrigger,
    dispatch,
  ]);

  useEffect(() => {
    if (focalItemTableInitialised && localParametersInitialised) {
      fetchTable().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, promotion, location, segmentation]);

  const [downloadTableTrigger] = useLazyDownloadTableQuery();

  const fileName = useMemo(
    () =>
      cleanFilename(`Customer_Profiling_${timePeriodLength}_${location.name}`),
    [timePeriodLength, location]
  );

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        localParametersSummary: getParameterSummaryValueStrings(
          channel,
          location,
          metric,
          segmentation,
          promotion,
          time
        ),
        localParameterSelections: [
          {
            id: LocalParameters.Channel,
            values: [channel.value as string],
          },
          {
            id: LocalParameters.Promotion,
            values: [promotion.value as string],
          },
          {
            id: LocalParameters.LocationHierarchy,
            values: [location.nodeNumber.toString()],
          },
          {
            id: LocalParameters.Segmentation,
            values: convertPanelOptionToString(segmentation),
          },
          {
            id: LocalParameters.Metric,
            values: [metric.value as string],
          },
        ],
        fileName,
        reportId: reportId ?? "",
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    fileName,
    reportId,
    channel,
    location,
    metric,
    promotion,
    segmentation,
    time,
  ]);

  return (
    <ReportTopDrawer
      controls={[
        <DataTableOptions
          filename={fileName}
          invokeCSVDownload={handleDownloadButtonClick}
          isFeatureEnabled={isExportEnabled}
          isInFocalItemHeader
          key={reportId}
        />,
      ]}
      items={focalItems.map((row) => ({
        code: row.shortName,
        displayName: row.name,
        // FIXME: need a type of this Hierarchy
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <CustomerProfilingTopDrawerProductTable
          eventTrackingService={eventTrackingService}
          isQuerySuccess={
            !isInitialTableFetching &&
            !refetchTableFetching &&
            focalItemTableInitialised
          }
        />
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default CustomerProfilingTopDrawer;

import {
  Dropdown,
  Group,
  GroupGutters,
  GroupRowspace,
  Item,
  DropdownPlacement,
  Button,
  ButtonVariant,
  ItemValign,
  ItemHalign,
  MenuItemButton,
  Menu,
  MenuSection,
  Text,
} from "@qbit/react";
import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useCallback } from "react";
import { DownloadIcon } from "../assets/icons/DownloadIcon";
import { downloadFile, toCSV } from "../export/export-functions";
import styles from "./DataTableOptions.module.scss";

export type DataTableOptionsProps = {
  disabled?: boolean;
  filename: string;
  /**
   * either return string[][] representing the data, which will automatically start the export in browser, or use this function to invoke the backend API to start the export/download of the CSV data from the backend directly
   */
  invokeCSVDownload: (() => string[][]) | (() => void);
  invokeExcelDownload?: () => void;
  isFeatureEnabled?: boolean;
  isInFocalItemHeader?: boolean;
  localParameters?: Array<{
    name: string;
    value: string;
  }>;
  reportParameters?: ReportParametersDto;
};

export const DataTableOptions = ({
  filename,
  localParameters,
  reportParameters,
  invokeCSVDownload,
  invokeExcelDownload,
  isInFocalItemHeader = false,
  disabled,
  isFeatureEnabled,
}: DataTableOptionsProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  const downloadAsCsvCallback = useCallback(() => {
    try {
      const data = invokeCSVDownload();
      if (Array.isArray(data)) {
        const csvContent = toCSV(
          reportParameters,
          localParameters,
          availableTransactionSources,
          data
        );
        const encodedUri = encodeURI(csvContent);
        downloadFile(encodedUri, `${filename}.csv`);
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  }, [
    reportParameters,
    localParameters,
    filename,
    availableTransactionSources,
    invokeCSVDownload,
  ]);

  const downloadAsExcelCallback = useCallback(() => {
    try {
      if (invokeExcelDownload) {
        invokeExcelDownload();
      }
    } catch (error) {
      ddLog("ERROR", {}, "error", error as Error);
    }
  }, [invokeExcelDownload]);

  return (
    <>
      {isFeatureEnabled && (
        <Group
          gutters={GroupGutters.None}
          rowspace={GroupRowspace.None}
          wrap={false}
        >
          <Item halign={ItemHalign.Right} valign={ItemValign.Middle}>
            <Dropdown
              className={classNames(styles.downloadDataTableDropdown, {
                [styles.isInFocalItemHeader]: isInFocalItemHeader,
              })}
              placement={DropdownPlacement.BottomRight}
              trigger={
                <Button
                  className={styles.iconButton}
                  disabled={disabled}
                  variant={ButtonVariant.Stealth}
                >
                  <DownloadIcon />
                </Button>
              }
            >
              <Menu>
                <MenuSection>
                  <>
                    {invokeExcelDownload ? (
                      <MenuItemButton
                        disabled={disabled}
                        onClick={downloadAsExcelCallback}
                        text={<Text>Download as Excel</Text>}
                      />
                    ) : undefined}
                    <MenuItemButton
                      disabled={disabled}
                      onClick={downloadAsCsvCallback}
                      text={<Text>Download as CSV</Text>}
                    />
                  </>
                </MenuSection>
              </Menu>
            </Dropdown>
          </Item>
        </Group>
      )}
    </>
  );
};

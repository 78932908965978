import {
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  Input,
  Select,
} from "@qbit/react";
import classNames from "classnames";
import { ParameterLabel } from "../../../../parameter-label/ParameterLabel";
import { RequiredFieldError } from "../required-field-error/RequiredFieldError";
import styles from "./TimeDropdown.module.css";

export type DropdownOption = {
  disabled: boolean;
  label: string;
  value: string;
};

// Props
export type TimeDropdownProps = {
  blockStatus: FormBlockStatus;
  defaultOption: DropdownOption;
  disabled?: boolean;
  infoIcon?: string;
  label: string;
  onChange: (value: string) => void;
  options: DropdownOption[];
  selectedValue?: string;
};

export const TimeDropdown = ({
  blockStatus,
  defaultOption,
  disabled,
  infoIcon,
  label,
  onChange,
  options,
  selectedValue,
}: TimeDropdownProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    onChange(newValue);
  };

  const dropdownOptions =
    options.length === 1 ? options : [defaultOption].concat(options);

  return (
    <FormBlock
      blockStatus={blockStatus}
      blockType={FormBlockType.Select}
      className={styles.dropdownFormBlock}
    >
      <ParameterLabel description={infoIcon} heading={label} htmlFor={label} />
      <Input>
        <Select
          className={classNames({
            [styles.dropdownSelectSingleOption]: dropdownOptions.length === 1,
          })}
          disabled={disabled}
          id={label}
          onChange={handleChange}
          value={selectedValue}
        >
          {dropdownOptions.map((option: DropdownOption) => (
            <option
              disabled={option.disabled}
              key={option.value}
              title={
                option.disabled
                  ? "There is no data available for this date."
                  : ""
              }
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Select>
        <>{blockStatus === FormBlockStatus.Error && <RequiredFieldError />}</>
      </Input>
    </FormBlock>
  );
};

export default TimeDropdown;

import { Spinner, SpinnerSize, Button, ButtonVariant } from "@qbit/react";
import { type MouseEvent } from "react";
import ArrowsChevronDownIcon from "../../assets/common/arrows-chevron-down-icon.svg";
import ArrowsChevronRightIcon from "../../assets/common/arrows-chevron-right-icon.svg";
import styles from "./ExpandChevron.module.css";

export const ExpandChevronTestId = "expand-icon";

export type ExpandChevronProps = {
  isCompact: boolean;
  isExpanded: boolean;
  isLoading: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

export const ExpandChevron = ({
  isCompact,
  isExpanded,
  isLoading,
  onClick,
}: ExpandChevronProps) => {
  if (isLoading) {
    const spinnerSize = isCompact ? SpinnerSize.XSmall : SpinnerSize.Small;
    return (
      <div className={styles.loadingSpinner}>
        <Spinner size={spinnerSize} text="Loading more..." />
      </div>
    );
  }

  const iconGlyph = isExpanded ? ArrowsChevronDownIcon : ArrowsChevronRightIcon;
  const iconText = isExpanded ? "Close" : "Expand";
  return (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        if (onClick) {
          onClick(event);
        }
      }}
      // @ts-expect-error: button component does not have style prop
      style={{ padding: 0, height: "22px", width: "22px" }}
      variant={ButtonVariant.Stealth}
    >
      <img
        alt={iconText}
        className={styles.transactionSourceIcon}
        data-testid="expand-icon"
        src={iconGlyph}
        title={iconText}
      />
    </Button>
  );
};

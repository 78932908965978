import { FormInputHeight } from "@qbit/react";
import { type HierarchySliceNodeDto } from "@quantium-enterprise/common-ui";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { useMemo } from "react";

type ChartFilterDropdownProps = {
  defaultOption?: {
    label: string;
    value: string;
  };
  isEnabled: boolean;
  onChangeHandler: (value: string) => unknown;
  options:
    | Array<{ label: string; value: string }>
    | HierarchySliceNodeDto[]
    | string[];
  selected: number | string | undefined;
};

export const ChartFilterDropdown = ({
  defaultOption,
  isEnabled,
  onChangeHandler,
  options,
  selected,
}: ChartFilterDropdownProps) => {
  const disabledOption = defaultOption ?? { value: "-1", label: "All" };
  const dropdownOptions = useMemo(
    () =>
      (defaultOption ? [defaultOption] : []).concat(
        options.map((item) => {
          if (typeof item === "string") {
            return {
              label: item,
              value: item,
            };
          } else if ("nodeNumber" in item) {
            return {
              label: `(${hierarchyLevelDisplayLabel(item.shortName)}) ${
                item.name
              }`,
              value: item.nodeNumber.toString(),
            };
          } else {
            return item;
          }
        })
      ),
    [options, defaultOption]
  );

  return (
    <SingleSelectDropdown
      height={FormInputHeight.XSmall}
      isDisabled={!isEnabled}
      onSelection={(value) => onChangeHandler(value.value as string)}
      selectOptions={isEnabled ? dropdownOptions : [disabledOption]}
      selectedValue={isEnabled ? selected?.toString() : disabledOption.value}
    />
  );
};

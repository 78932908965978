import { FormInputHeight, FormInputWidth, QSearchInput } from "@qbit/react";
import classNames from "classnames";
import { ToggleSelectTable } from "components-ui/src/tables/toggle-select-table/ToggleSelectTable";
import { useState } from "react";
import { type Attribute } from "../../models/Attribute";
import styles from "./ProductSubstitutabilityModal.module.css";

export const ProductSubstitutabilityAttributesContent = ({
  attributes,
  selectedAttributes,
  handleOnToggle,
}: {
  attributes: Attribute[];
  handleOnToggle: (value: string) => void;
  selectedAttributes: string[];
}) => {
  const [searchString, setSearchString] = useState("");

  return (
    <>
      <div className={styles.attributesSearchBar}>
        <QSearchInput
          height={FormInputHeight.XSmall}
          id="searchinput"
          onChange={(event) => {
            setSearchString(event.target.value.toLowerCase());
          }}
          placeholder="Search attributes"
          width={FormInputWidth.Large}
        />
      </div>
      <div
        className={classNames(styles.modalContentContainer, styles.overflow)}
      >
        <ToggleSelectTable
          onToggle={handleOnToggle}
          options={attributes
            .filter((x) => x.displayName.toLowerCase().includes(searchString))
            .map((attribute) => ({
              value: attribute.shortName,
              label: attribute.displayName,
            }))}
          selectedValues={selectedAttributes}
          title="Attributes"
        />
      </div>
    </>
  );
};

export default ProductSubstitutabilityAttributesContent;

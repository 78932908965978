import {
  Button,
  ButtonGroup,
  ButtonGroupVariant,
  ButtonHeight,
  ButtonVariant,
  Checkbox,
  FormBlock,
  FormBlockEditability,
  FormBlockType,
  Input,
  Spinner,
  SpinnerSize,
} from "@qbit/react";
import {
  createRowNameMatchesSearchPredicate,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import {
  type RowSelectionState,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import classNames from "classnames";
import EmptyGroupImage from "components-ui/src/assets/common/empty-group.svg";
import { CustomerGroupIcon } from "components-ui/src/icons/customer-group-icon/CustomerGroupIcon";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { type BasicTableRowWithMetadata } from "components-ui/src/tables/basic-table/BasicTable";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { type ReactElement } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { createUniqueGroupSegmentId } from "../combined-group-editor/CombinedGroupEditor";
import styles from "./CombinedGroupSegmentsTable.module.css";

export type CombinedGroupSegmentRow = BasicTableRowWithMetadata & {
  groupId: string;
  name: string;
  segmentKey: string;
  standardSegmentationId?: string;
  type: string;
};

const nameCell = (info: CellContext<CombinedGroupSegmentRow, unknown>) => {
  const value = info.getValue<{ name: string; type: string }>();
  return (
    <div className={styles.typeIcon}>
      <CustomerGroupIcon type={value.type} />
      <div className={styles.name}>{value.name}</div>
    </div>
  );
};

enum QuickFilter {
  All = "All",
  Custom = "Custom",
  Standard = "Standard",
}

export type CombinedGroupSegmentsTableProps = {
  data: CombinedGroupSegmentRow[];
  disableCheckboxes?: boolean;
  isProcessing?: boolean;
  isRHS?: boolean;
  isSuccess?: boolean;
  movedGroupIds?: string[];
  searchText?: string;
  selectedGroupIds: string[];
  setSelectedGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const CombinedGroupSegmentsTable = ({
  data,
  disableCheckboxes = false,
  isProcessing = false,
  isRHS = false,
  isSuccess = true,
  movedGroupIds = undefined,
  searchText = undefined,
  selectedGroupIds,
  setSelectedGroupIds,
}: CombinedGroupSegmentsTableProps) => {
  const flags = useFlags();
  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
    {}
  );
  const [quickFilter, setQuickFilter] = useState<QuickFilter>(QuickFilter.All);

  const filteredItems = useMemo(() => {
    let filteredData = data;
    switch (quickFilter) {
      case QuickFilter.Standard:
        filteredData = filteredData.filter((item) =>
          Boolean(item.standardSegmentationId)
        );
        break;
      case QuickFilter.Custom:
        filteredData = filteredData.filter(
          (item) => !item.standardSegmentationId
        );
        break;
      default:
        break;
    }

    if (searchText && searchText !== "") {
      const rowNameMatchesSearch = createRowNameMatchesSearchPredicate(
        searchText.toLowerCase()
      );
      filteredData = filteredData.filter((item) =>
        rowNameMatchesSearch(item.name.toLowerCase())
      );
    }

    return filteredData;
  }, [data, searchText, quickFilter]);

  const updateSelectedGroups = useCallback(
    (selectedGroupId: string, isSelected: boolean) => {
      if (isSelected) {
        setSelectedGroupIds((ids) => [...ids, selectedGroupId]);
      } else {
        setSelectedGroupIds((ids) =>
          ids.filter((id) => id !== selectedGroupId)
        );
      }
    },
    [setSelectedGroupIds]
  );

  const checkboxCell = useCallback(
    (info: CellContext<CombinedGroupSegmentRow, unknown>) => {
      // if we have metadata defined, we are the group row and thus won't have a checkbox
      if (info.row.original.meta) {
        return undefined;
      }

      const uniqueID = createUniqueGroupSegmentId(info.row.original, isRHS);

      return (
        <FormBlock
          blockType={FormBlockType.Checkbox}
          className={styles.checkboxCell}
        >
          <Input>
            <Checkbox
              checked={selectedGroupIds.includes(uniqueID)}
              data-testid={uniqueID}
              editability={
                (disableCheckboxes && !selectedGroupIds.includes(uniqueID)) ||
                movedGroupIds?.includes(uniqueID)
                  ? FormBlockEditability.Disabled
                  : FormBlockEditability.Editable
              }
              id={uniqueID}
              label=""
              name={info.row.original.name}
              onChange={(event) => {
                const isInSelectedList = selectedGroupIds.includes(
                  event.target.id
                );
                updateSelectedGroups(uniqueID as string, !isInSelectedList);
              }}
            />
          </Input>
        </FormBlock>
      );
    },
    [
      isRHS,
      selectedGroupIds,
      disableCheckboxes,
      movedGroupIds,
      updateSelectedGroups,
    ]
  );

  const columns: Array<ColumnDef<CombinedGroupSegmentRow>> = [
    {
      accessorFn: (row) => row.groupId,
      cell: checkboxCell,
      header: "",
      id: "selectColumn",
      // this is the minimum size we can set as the th & td padding for the basic table requires min 20px
      minSize: 20,
      maxSize: 20,
      size: 20,
    },
    {
      accessorFn: (row) => ({
        name: row.name,
        type: row.type,
      }),
      cell: nameCell,
      header: "Name",
      id: "name",
      minSize: 100,
      maxSize: 900,
      size: 200,
    },
  ];

  const alternateEmptyContent: ReactElement | undefined = useMemo(
    () => (
      <div
        className={classNames(
          styles.emptyGroupSection,
          styles.groupsTableOverlay
        )}
      >
        <img
          alt="No groups selected"
          className={styles.emptyGroupImage}
          src={EmptyGroupImage}
        />
        <p className={styles.emptyGroupMessage}>
          There are currently no groups {isRHS ? "selected" : "created"}.
        </p>
      </div>
    ),
    [isRHS]
  );

  return (
    <div className={styles.groupsTableContainer}>
      {flags[FeatureFlag.CombineStandardSegments] && data.length > 0 && (
        <div className={styles.groupTableFilters}>
          <ButtonGroup variant={ButtonGroupVariant.Split}>
            <Button
              height={ButtonHeight.XSmall}
              onClick={() => setQuickFilter(QuickFilter.All)}
              pressed={quickFilter === QuickFilter.All}
              text="All"
              variant={ButtonVariant.Secondary}
            />
            <Button
              height={ButtonHeight.XSmall}
              onClick={() => setQuickFilter(QuickFilter.Standard)}
              pressed={quickFilter === QuickFilter.Standard}
              text="Standard"
              variant={ButtonVariant.Secondary}
            />
            <Button
              height={ButtonHeight.XSmall}
              onClick={() => setQuickFilter(QuickFilter.Custom)}
              pressed={quickFilter === QuickFilter.Custom}
              text="Custom"
              variant={ButtonVariant.Secondary}
            />
          </ButtonGroup>
        </div>
      )}
      {data.length > 0 && (
        <VirtuosoTableComponent
          className={styles.groupsTable}
          columns={columns}
          data={filteredItems}
          onRowSelectionChange={setRowSelectionState}
          rowSelectionState={rowSelectionState}
        />
      )}
      {!isSuccess ||
      isProcessing ||
      filteredItems.length > 0 ? undefined : data.length > 0 ? (
        <div className={styles.groupsTableOverlay}>
          <EmptySearch />
        </div>
      ) : (
        alternateEmptyContent
      )}
      {isProcessing && (
        <div className={classNames(styles.loading, styles.groupsTableOverlay)}>
          <Spinner size={SpinnerSize.Medium} text="Loading" />
        </div>
      )}
    </div>
  );
};

import { uniqueId } from "@qbit/react/dist/common";
import {
  type ReportParametersDto,
  type FocalItem,
} from "@quantium-enterprise/common-ui";
import { type PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  type FastReportingTabMetadata,
  type ReportTab,
} from "../models/ReportTab";
import { ReportTabType } from "../models/ReportTabType";
import { type RootState } from "../store";
import { isFastReportingTabMetadata } from "../utils/isFastReportingTabMetadata";

export type ReportTabsState = {
  activeTab?: ReportTab;
  activeTabIndexOnActivate?: number;
  activeTabIndexOnActivateSessionStorageKey?: string;
  activeTabSessionStorageKey?: string;
  navigateToDashboard: boolean;
  reportTabsSessionStorageKey?: string;
  tabs: ReportTab[];
};

export const reportTabsReducerPath = "reportTabs";

const initialState: ReportTabsState = {
  navigateToDashboard: false,
  tabs: [],
};

const persistToSessionStorage = (key: string | undefined, value: unknown) => {
  if (key) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const reportTabsSlice = createSlice({
  initialState,
  name: reportTabsReducerPath,
  reducers: {
    addFastReportingTabs: (
      state: ReportTabsState,
      action: PayloadAction<
        Array<{
          focalItem: FocalItem;
          initialState?: { [key: string]: string | undefined };
        }>
      >
    ) => {
      const newTabs = action.payload
        .map(
          (item) =>
            ({
              id: uniqueId(),
              reportTabType: ReportTabType.FastReportingTab,
              metadata: {
                item: item.focalItem,
              },
              state: item.initialState ?? {},
            } as ReportTab)
        )
        .reverse();

      state.tabs = [...newTabs, ...state.tabs];
      state.activeTab = newTabs[0];

      persistToSessionStorage(state.reportTabsSessionStorageKey, state.tabs);
      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
    },
    addAdvancedReportingTab: (
      state: ReportTabsState,
      action: PayloadAction<{ path: string; report: ReportParametersDto }>
    ) => {
      const newTab = {
        id: action.payload.report.id,
        reportTabType: ReportTabType.AdvancedReportingTab,
        metadata: {
          path: action.payload.path,
          name: action.payload.report.reportName,
          type: action.payload.report.reportType,
          reportDisplayName: action.payload.report.reportTypeDisplayName,
          dataSource: action.payload.report.dataSource,
        },
        state: {},
      };

      const newTabs = [newTab, ...state.tabs];

      state.tabs = [...newTabs];
      state.activeTab = newTab;

      persistToSessionStorage(state.reportTabsSessionStorageKey, state.tabs);
      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
    },
    replaceFastReportingActiveTab: (
      state: ReportTabsState,
      action: PayloadAction<{
        focalItem: FocalItem;
        state?: { [key: string]: string | undefined };
      }>
    ) => {
      const activeTab = state.tabs.find(
        (tab) => tab.id === state.activeTab?.id
      );
      if (activeTab && isFastReportingTabMetadata(activeTab.metadata)) {
        activeTab.metadata.item = action.payload.focalItem;
        activeTab.state = action.payload.state ?? {};
        state.activeTab = activeTab;
      }

      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
    },
    setActiveTab: (
      state: ReportTabsState,
      action: PayloadAction<string | undefined>
    ) => {
      if (action.payload) {
        state.activeTabIndexOnActivate = state.tabs.findIndex(
          (tb) => tb.id === action.payload
        );
        state.activeTab = state.tabs[state.activeTabIndexOnActivate];
      } else {
        state.activeTab = undefined;
        state.activeTabIndexOnActivate = undefined;
      }

      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
      persistToSessionStorage(
        state.activeTabIndexOnActivateSessionStorageKey,
        state.activeTabIndexOnActivate
      );
    },
    setActiveTabPosition: (
      state: ReportTabsState,
      action: PayloadAction<number>
    ) => {
      const tabIndex = state.tabs.findIndex(
        (tab) => tab.id === state.activeTab?.id
      );

      if (tabIndex >= 0) {
        const reorderedTabs = [...state.tabs];

        const tabToMove = reorderedTabs.splice(tabIndex, 1)[0];
        reorderedTabs.splice(action.payload, 0, tabToMove);

        state.tabs = reorderedTabs;
      }

      persistToSessionStorage(state.reportTabsSessionStorageKey, state.tabs);
    },
    navigatingToDashboard: (state: ReportTabsState) => {
      state.navigateToDashboard = false;
    },
    removeTab: (state: ReportTabsState, action: PayloadAction<string>) => {
      const indexToRemove = state.tabs.findIndex(
        (tb) => tb.id === action.payload
      );

      if (indexToRemove >= 0) {
        state.tabs = state.tabs.filter((tb) => tb.id !== action.payload);

        if (state.tabs.length > 0) {
          // We only change the active tab if its the tab being removed
          if (state.activeTab?.id === action.payload) {
            // if first tab then select the next tab to the right, else select the tab to the left
            state.activeTabIndexOnActivate =
              indexToRemove === 0 ? indexToRemove : indexToRemove - 1;
            state.activeTab = state.tabs[state.activeTabIndexOnActivate];
          }
        } else {
          if (state.activeTab?.id === action.payload) {
            state.navigateToDashboard = true;
          }

          state.activeTab = undefined;
          state.activeTabIndexOnActivate = undefined;
        }
      }

      persistToSessionStorage(state.reportTabsSessionStorageKey, state.tabs);
      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
      persistToSessionStorage(
        state.activeTabIndexOnActivateSessionStorageKey,
        state.activeTabIndexOnActivate
      );
    },
    replaceActiveTab: (
      state: ReportTabsState,
      action: PayloadAction<FocalItem>
    ) => {
      const activeTab = state.tabs.find(
        (tab) => tab.id === state.activeTab?.id
      );
      if (activeTab && isFastReportingTabMetadata(activeTab.metadata)) {
        activeTab.metadata.item = action.payload;
        state.activeTab = activeTab;
      }

      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
    },
    setTabState: (
      state: ReportTabsState,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is type safe when used with useReportTabState with the Zod schema
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const activeTab = state.tabs.find(
        (tab) => tab.id === state.activeTab?.id
      );
      if (activeTab) {
        activeTab.state[action.payload.key] = action.payload.value;
        state.activeTab = activeTab;
      }

      persistToSessionStorage(state.reportTabsSessionStorageKey, state.tabs);
      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
    },
    resetTabState: (
      state: ReportTabsState,
      action: PayloadAction<{ key: string }>
    ) => {
      const activeTab = state.tabs.find(
        (tab) => tab.id === state.activeTab?.id
      );
      if (activeTab) {
        activeTab.state[action.payload.key] = undefined;
        state.activeTab = activeTab;
      }

      persistToSessionStorage(state.reportTabsSessionStorageKey, state.tabs);
      persistToSessionStorage(
        state.activeTabSessionStorageKey,
        state.activeTab
      );
    },
    loadTabsFromSessionStorage: (
      state: ReportTabsState,
      action: PayloadAction<string>
    ) => {
      state.activeTabSessionStorageKey = `active-tab-${action.payload}`;
      state.reportTabsSessionStorageKey = `report-tabs-${action.payload}`;
      state.activeTabIndexOnActivateSessionStorageKey = `active-tab-index-${action.payload}`;

      const storedActiveTab = sessionStorage.getItem(
        state.activeTabSessionStorageKey
      );
      const storedReportTabs = sessionStorage.getItem(
        state.reportTabsSessionStorageKey
      );
      const storedActiveTabIndex = sessionStorage.getItem(
        state.activeTabIndexOnActivateSessionStorageKey
      );

      try {
        if (storedReportTabs) {
          state.tabs = JSON.parse(storedReportTabs);
        }

        // Only try and load active tab if we have some report tabs
        if (state.tabs.length && storedActiveTab) {
          state.activeTab = JSON.parse(storedActiveTab);
        }

        // Only load active tab index if active tab was loaded
        if (state.activeTab && storedActiveTabIndex) {
          state.activeTabIndexOnActivate = JSON.parse(storedActiveTabIndex);
        }
      } catch {
        // Fall back to defaults on any error when loading from storage
      }
    },
  },
});

export const selectReportTabs = createSelector(
  (state: RootState) => state.reportTabs,
  (ReportTabsState: ReportTabsState) => ReportTabsState.tabs
);

export const selectActiveTab = createSelector(
  (state: RootState) => state.reportTabs,
  (ReportTabsState: ReportTabsState) => ReportTabsState.activeTab
);

export const selectActiveFastReportingTabItem = createSelector(
  (state: RootState) => state.reportTabs,
  (ReportTabsState: ReportTabsState) =>
    (
      ReportTabsState.activeTab?.metadata as
        | FastReportingTabMetadata
        | undefined
    )?.item
);

export const selectActiveTabIndexOnActivate = createSelector(
  (state: RootState) => state.reportTabs,
  (ReportTabsState: ReportTabsState) => ReportTabsState.activeTabIndexOnActivate
);

export const selectNavigateToDashboard = createSelector(
  (state: RootState) => state.reportTabs,
  (ReportTabsState: ReportTabsState) => ReportTabsState.navigateToDashboard
);

export const {
  addFastReportingTabs,
  addAdvancedReportingTab,
  replaceFastReportingActiveTab,
  setActiveTab,
  setActiveTabPosition,
  removeTab,
  navigatingToDashboard,
  replaceActiveTab,
  loadTabsFromSessionStorage,
  setTabState,
  resetTabState,
} = reportTabsSlice.actions;
export default reportTabsSlice.reducer;

import { MessageVariant, QbitEmitToast, QbitToastMessage } from "@qbit/react";
import {
  type UserDto,
  type RenameSavedParametersDto,
  type SavedParametersDto,
  type SharedUserDto,
  EMIT_TOAST_DURATION,
  FeatureFlag,
  getTimeAgoString,
  TIME_ELAPSED_UPDATE_INTERVAL_MS,
  TrackingComponent,
  useGetUserQuery,
  useRenameSavedParametersMutation,
  convertUserDtoToSharedUserDto,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  type RowSelectionState,
  type ColumnDef,
  type CellContext,
} from "@tanstack/react-table";
import classNames from "classnames";
import { ButtonDropdown } from "components-ui/src/button-dropdown/ButtonDropdown";
import { type DeleteItems } from "components-ui/src/delete-dialog/DeleteDialog";
import { DeleteDialog } from "components-ui/src/delete-dialog/DeleteDialog";
import { SharedUserIconDisplay } from "components-ui/src/shared-user-icon-display/SharedUserIconDisplay";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { MyReportsNameCell } from "components-ui/src/tables/common/table-cell/MyReportsNameCell";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import BookmarkSvg from "../images/bookmark.svg";
import {
  DeleteButton,
  RenameButton,
} from "../my-reports-grid/MyReportsGridQuickActions";
import styles from "./SavedAndScheduledGrid.module.css";

const defaultMyReportsColumnWidthOverride = {
  minSize: 80,
  maxSize: 640,
};

export type SavedAndScheduledGridProps = {
  allSavedParametersSharedUsers: SharedUserDto[];
  deleteItem: (itemIds: DeleteItems) => Promise<void>;
  savedAndScheduledNavigator: (reportId: string) => void;
  savedParameters: SavedParametersDto[];
};

const NameHeader = () => <span className={styles.nameHeader}>Name</span>;
const CreatedHeader = () => <span className={styles.sortedBy}>Created</span>;
const CreatedCell = ({
  info,
}: {
  info: CellContext<SavedParametersDto, unknown>;
}) => {
  const [timeAgoString, setTimeAgoString] = useState(
    getTimeAgoString(info.getValue<string>())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgoString(getTimeAgoString(info.getValue<string>()));
    }, TIME_ELAPSED_UPDATE_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [info]);

  return <span className={styles.createdDate}>{timeAgoString}</span>;
};

const SharedHeader = () => <span>Shared</span>;
const SharedCell = (
  allSavedParametersSharedUsers: SharedUserDto[],
  currentUser: UserDto | undefined,
  info: CellContext<SavedParametersDto, unknown>
) => (
  <SharedUserIconDisplay
    owner={
      allSavedParametersSharedUsers.find(
        (user) => user.salesforceUserId === info.row.original.sharedByUserId
      ) ?? convertUserDtoToSharedUserDto(currentUser)
    }
    users={allSavedParametersSharedUsers.filter((user) =>
      info.row.original.sharedWithUserIds.includes(user.salesforceUserId)
    )}
  />
);

export const SavedAndScheduledGrid = ({
  allSavedParametersSharedUsers,
  deleteItem,
  savedAndScheduledNavigator,
  savedParameters,
}: SavedAndScheduledGridProps) => {
  const division = useDivision();
  const flags = useFlags();
  const { data: user } = useGetUserQuery();
  const { id: selectedSavedParametersId } = useParams();
  // This existence of this ref is required to get the dialog to render.
  // Avoids a null ref exception in the modal API.
  const deleteButtonRef = useRef(null);
  const renameRowRef = useRef(null);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [quickActionRow, setQuickActionRow] = useState<SavedParametersDto>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [renameState, setRenameState] = useState<string | undefined>(undefined);
  const [preventBlur, setPreventBlur] = useState(false);

  const [renameTrigger] = useRenameSavedParametersMutation();

  const handleRename = useCallback(
    async (value: string) => {
      const payload: RenameSavedParametersDto = {
        newName: value,
        savedParametersId: renameState ?? "",
      };

      try {
        await renameTrigger({ divisionName: division.name, payload }).unwrap();
      } catch {
        const content = "An unknown error has occurred";
        const heading = "Unknown error";
        QbitEmitToast(
          <QbitToastMessage
            content={<p>{content}</p>}
            heading={<h5>{heading}</h5>}
            showIcon
            variant={MessageVariant.Danger}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
        return false;
      }

      setRenameState(undefined);
      return true;
    },
    [division.name, renameState, renameTrigger]
  );

  const handleSetRename = useCallback((id: string | undefined) => {
    // Prevent early exit of EditableField after change of editing state
    setPreventBlur(true);
    setRenameState(id);
    setTimeout(() => setPreventBlur(false), 100);
  }, []);

  const NameCell = useCallback(
    (info: CellContext<SavedParametersDto, unknown>) => (
      <div
        className={styles.row}
        onClick={(event) => {
          if (renameState === info.row.original.id) {
            event.stopPropagation();
          }
        }}
        // onKeyDown added for accessibility to  satisfy
        // the jsx-a11y/click-events-have-key-events linter rule.
        // It doesn't change  functionality or user experience.
        onKeyDown={(event) => {
          if (event.key === "Enter" && renameState === info.row.original.id) {
            setRenameState(undefined);
            event.stopPropagation();
          }
        }}
        ref={renameRowRef}
        role="button"
        style={{ display: "flex", alignItems: "center" }}
        tabIndex={0}
      >
        <MyReportsNameCell
          canExpand={info.row.getCanExpand()}
          depth={info.row.depth}
          editableFieldState={{
            isEditing: info.row.original.id === renameState,
            toggleEditing: () => {},
          }}
          handleToggleExpanded={info.row.getToggleExpandedHandler()}
          handleToggleSelected={info.row.getToggleSelectedHandler()}
          icon={
            <img
              alt="Saved selection"
              className={styles.bookmarkIcon}
              src={BookmarkSvg}
            />
          }
          id={info.row.original.id}
          isExpanded={info.row.getIsExpanded()}
          isSelected={info.row.getIsSelected()}
          itemType="Saved selection"
          minWidth={defaultMyReportsColumnWidthOverride.minSize}
          nameCellClassName={classNames(styles.nameColumn, styles.name)}
          preventBlur={preventBlur}
          renameItem={handleRename}
          stopEditing={() => {
            setRenameState(undefined);
          }}
          value={info.getValue<string>()}
        />
      </div>
    ),
    [handleRename, preventBlur, renameState]
  );

  useEffect(() => {
    const preSelectedReport: RowSelectionState = {};

    for (const [index, report] of savedParameters.entries()) {
      if (
        selectedSavedParametersId &&
        report.id === selectedSavedParametersId
      ) {
        const indexAsString = index.toString();
        preSelectedReport[indexAsString] = true;
      }
    }

    setRowSelection(preSelectedReport);
  }, [savedParameters, selectedSavedParametersId]);

  const quickActionsCell = useCallback(
    (info: CellContext<SavedParametersDto, unknown>) => (
      <ButtonDropdown
        buttons={[
          [
            {
              ...RenameButton,
              handleClick: () => handleSetRename(info.row.original.id),
            },
          ],
          [
            {
              ...DeleteButton,
              handleClick: () => {
                setQuickActionRow(info.row.original);
                setShowDeleteDialog(true);
              },
            },
          ],
        ]}
        className={classNames(styles.quickActionsColumn, "quick-actions")}
      />
    ),
    [handleSetRename]
  );

  const columns: Array<ColumnDef<SavedParametersDto>> = [
    {
      accessorFn: (row) => row.name,
      cell: NameCell,
      footer: (properties) => properties.column.id,
      header: NameHeader,
      id: "name",
      size: 720,
    },
    {
      accessorFn: (row) => row.id,
      cell: quickActionsCell,
      header: "",
      id: "quickActions",
      minSize: 20,
      size: 60,
      enableSorting: false,
    },
    {
      accessorFn: (row) => row.creationDateUtc,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => <CreatedCell info={info} />,
      footer: (properties) => properties.column.id,
      header: CreatedHeader,
      id: "created",
      sortingFn: (a, b): number =>
        new Date(a.original.creationDateUtc).getTime() -
        new Date(b.original.creationDateUtc).getTime(),
    },
  ];

  if (flags[FeatureFlag.SharingReports]) {
    columns.push({
      accessorFn: (row) => row.sharedWithUserIds,
      cell: (info) => SharedCell(allSavedParametersSharedUsers, user, info),
      footer: (properties) => properties.column.id,
      header: SharedHeader,
      id: "shared",
    });
  }

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    rowId: number,
    rowData: SavedParametersDto
  ) => {
    if ((event.target as Element).closest(".quick-actions")) {
      return;
    }

    savedAndScheduledNavigator(rowData.id);
  };

  const itemIds = useMemo<DeleteItems>(() => {
    if (!quickActionRow?.id) return {};
    return { savedParametersIds: [quickActionRow.id] };
  }, [quickActionRow]);

  const onDelete = useCallback(
    async (itemIds2: DeleteItems) => {
      const response = deleteItem(itemIds2);

      savedAndScheduledNavigator("");
      setShowDeleteDialog(false);
      await response;
    },
    [deleteItem, savedAndScheduledNavigator, setShowDeleteDialog]
  );

  return (
    <>
      <BasicTable
        className={styles.myReportsTable}
        columns={columns}
        data={savedParameters}
        defaultColumnWidthOverride={defaultMyReportsColumnWidthOverride}
        enableRowSelection
        onRowClick={onRowClick}
        rowSelectionState={rowSelection}
        sorting={
          savedParameters.length > 0 ? [{ desc: true, id: "created" }] : []
        }
      />
      {showDeleteDialog && quickActionRow && (
        <DeleteDialog
          deleteItem={onDelete}
          itemIds={itemIds}
          onClose={() => setShowDeleteDialog(false)}
          onDelete={() => setShowDeleteDialog(false)}
          ref={deleteButtonRef}
          show
          trackingComponent={TrackingComponent.SavedSelections}
        />
      )}
    </>
  );
};

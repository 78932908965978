import { Group, Item, ItemHalign, Text } from "@qbit/react";
import { REPORT_CREATOR_PATH } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { Link } from "react-router-dom";
import AddChartWhiteImage from "../images/add-chart-white.svg";
import EmptySavedSelectionsImage from "../images/empty-saved-selections.svg";
import EmptyStateImage from "../images/empty-state.svg";
import styles from "./NoContent.module.css";

export enum Variant {
  GeneratedReports = "generated-reports",
  SavedSelections = "saved-selections",
}

export const NoContent = ({
  activeDivisionName,
  variant,
}: {
  activeDivisionName: string;
  variant: Variant;
}) => (
  <Group>
    <Item halign={ItemHalign.Centre}>
      <Group>
        <Item halign={ItemHalign.Centre}>
          <img
            alt=""
            className={classNames({
              [styles.extraImagePadding]: variant === Variant.SavedSelections,
            })}
            src={
              variant === Variant.GeneratedReports
                ? EmptyStateImage
                : EmptySavedSelectionsImage
            }
          />
        </Item>
      </Group>
      <Group>
        <Item halign={ItemHalign.Centre}>
          {variant === Variant.GeneratedReports ? (
            <p>
              Reports you create and reports that are shared with you, will
              appear here.
              <br />
              You don't currently have any reports to view.
            </p>
          ) : (
            <p>
              Saved report selections will appear here.
              <br />
              You don't currently have any saved selections.
            </p>
          )}
        </Item>
      </Group>
      {variant === Variant.GeneratedReports ? (
        <Group>
          <Item halign={ItemHalign.Centre}>
            <Link
              className="q-button q-button-primary"
              to={`/${activeDivisionName}/${REPORT_CREATOR_PATH}`}
            >
              <span className="q-button-content">
                <span className="q-icon q-icon-medium">
                  <img alt="" src={AddChartWhiteImage} />
                </span>
                <Text>Create a report</Text>
              </span>
            </Link>
          </Item>
        </Group>
      ) : (
        // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers, react/jsx-no-useless-fragment -- <Item> complains about {condition && (<ReactComponent />)}
        <></>
      )}
    </Item>
  </Group>
);

import {
  type ReportParametersDto,
  HierarchyItemType,
  ddLog,
} from "@quantium-enterprise/common-ui";
import { getNumberFormat, useDivision } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { isfeatureEnable } from "../../common/utils/feature-flag-utils";
import { type RootState } from "../../store";
import { CrossShopFeatureFlags } from "../constants/cross-shop-feature-flags";
import { type CrossShopDataTableRequestDto } from "../models/CrossShopDataTableRequestDto";
import { type CrossShopDataTableResponseDto } from "../models/CrossShopDataTableResponseDto";
import { useLazyGetTableRowsQuery } from "../services/customer-cross-shop-data-table-api-slice";
import {
  onGetTableQueryStarted,
  onTopDrawerTableResponseReceived,
  selectChannel,
  selectFocalItems,
  selectLocalParametersInitialised,
  selectLocalSelections,
  selectLocation,
  selectReportId,
  selectSegmentation,
  selectTimePeriodLength,
} from "../services/customer-cross-shop-slice";
import getLocalParametersSummary from "../utils/getLocalParametersSummary";
import { CrossShopInteractionsVennFocalItemCsvTransformation } from "./CrossShopInteractionsReportlet/CrossShopInteractionsVennCsvTransformation";
import { CrossShopTopDrawerProductTable } from "./CrossShopTopDrawerProductTable/CrossShopTopDrawerProductTable";

export type CustomerCrossShopTopDrawerProps = {
  infoPanelSummary: ReportParametersDto | undefined;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const CustomerCrossShopTopDrawer = ({
  renameReport,
  reportType,
  reportName,
  infoPanelSummary,
}: CustomerCrossShopTopDrawerProps) => {
  const { id } = useParams();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();

  const focalItems = useSelector(selectFocalItems);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const segmentation = useSelector(selectSegmentation);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const localSelections = useSelector(selectLocalSelections);
  const reportId = useSelector(selectReportId);

  const { tableRows, topDrawerTableMetrics } = useSelector(
    (state: RootState) => ({
      tableRows: state.customerCrossShop.topDrawerTableData.tableRows,
      topDrawerTableMetrics: state.customerCrossShop.topDrawerTableData.metrics,
    })
  );

  const isfeatureEnabled = isfeatureEnable(CrossShopFeatureFlags.ReportExport);
  const [
    getTableRows,
    { isSuccess: isTableSuccess, isFetching: isTableFetching },
  ] = useLazyGetTableRowsQuery();

  const fetchData = useCallback(
    async (division: string, payload: CrossShopDataTableRequestDto) => {
      dispatch(onGetTableQueryStarted());
      getTableRows({ division, payload })
        .unwrap()
        .then((result: CrossShopDataTableResponseDto) => {
          dispatch(onTopDrawerTableResponseReceived(result));
        })
        .catch((error: Error) =>
          ddLog(
            "Error retrieving focal item data table data in customer cross shop",
            undefined,
            "error",
            error
          )
        );
    },
    [getTableRows, dispatch]
  );

  useEffect(() => {
    if (id && localParametersInitialised && reportId === id) {
      const payload: CrossShopDataTableRequestDto = {
        parameterSelections: {
          channel: channel.value as string,
          segmentation: segmentation.map((item) => item.value as string),
          locationNodeNumber: location.nodeNumber,
        },
        reportId,
      };

      void fetchData(divisionName, payload);
    }
  }, [
    id,
    reportId,
    divisionName,
    localParametersInitialised,
    channel,
    segmentation,
    fetchData,
    location.nodeNumber,
  ]);

  const parameterSummary = useMemo(
    () => getLocalParametersSummary(localSelections, focalItems),
    [localSelections, focalItems]
  );

  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const focalItemCsvTransformationCallback = useCallback(
    () =>
      CrossShopInteractionsVennFocalItemCsvTransformation(
        topDrawerTableMetrics,
        currencySymbol,
        tableRows
      ),
    [topDrawerTableMetrics, currencySymbol, tableRows]
  );

  const exportChartFilename = useMemo(
    () =>
      cleanFilename(`Customer_Cross_Shop_${timePeriodLength}_${location.name}`),
    [timePeriodLength, location]
  );

  return (
    <ReportTopDrawer
      controls={[
        <div key={reportId} style={{ width: "30px", overflow: "hidden" }}>
          <DataTableOptions
            filename={exportChartFilename}
            invokeCSVDownload={focalItemCsvTransformationCallback}
            isFeatureEnabled={isfeatureEnabled}
            isInFocalItemHeader
            localParameters={parameterSummary}
            reportParameters={infoPanelSummary}
          />
        </div>,
      ]}
      items={focalItems.map((item) => ({
        code: item.shortName,
        displayName: item.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <CrossShopTopDrawerProductTable
          isQuerySuccess={!isTableFetching && isTableSuccess}
        />
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default CustomerCrossShopTopDrawer;

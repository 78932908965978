import { Checkbox, FormBlockEditability, Text, TextVariant } from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import classNames from "classnames";
import { type PanelOption } from "../../local-parameters-panel/FixedSidePanel";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { type SegmentOption } from "../segmentFilter/SegmentFilter";
import styles from "./MultiSelectSegmentFilter.module.css";

type SegmentationFilterProps = {
  onDropdownSelectionChange: (value: PanelOption) => void;
  segmentationOptions: PanelOption[];
  selectedOption: PanelOption;
};

const SegmentationFilter = ({
  segmentationOptions,
  selectedOption,
  onDropdownSelectionChange,
}: SegmentationFilterProps) =>
  segmentationOptions.length === 1 ? (
    <p className={styles.singleSegmentationText}>{selectedOption.label}</p>
  ) : (
    <SingleSelectDropdown
      onSelection={onDropdownSelectionChange}
      selectOptions={segmentationOptions}
      selectedValue={selectedOption.value.toString()}
    />
  );

export type MultiSelectSegmentFilterProps = {
  onCheckboxSelection: (segmentation: string, segment: string) => void;
  onDropdownSelectionChange: (value: PanelOption) => void;
  segmentOptions: SegmentOption[];
  segmentationOptions: PanelOption[];
  selectedSegmentation: string;
  selectedSegments: string[];
};

export const MultiSelectSegmentFilter = ({
  onCheckboxSelection,
  onDropdownSelectionChange,
  segmentationOptions,
  segmentOptions,
  selectedSegmentation,
  selectedSegments,
}: MultiSelectSegmentFilterProps) => (
  <>
    <SegmentationFilter
      onDropdownSelectionChange={onDropdownSelectionChange}
      segmentationOptions={segmentationOptions}
      selectedOption={
        segmentationOptions.find(
          (segmentation) => segmentation.value === selectedSegmentation
        ) ?? segmentationOptions[0]
      }
    />

    <h6 className={styles.title}>Customer segment</h6>
    {segmentOptions.map((segmentOption) => (
      <Checkbox
        checked={selectedSegments.includes(segmentOption.segmentValue)}
        className={classNames(styles.checkboxLabel, {
          [styles.disabled]: segmentOption.segmentValue === "All",
        })}
        data-testid="segment-option"
        editability={
          segmentOption.segmentValue === "All" &&
          selectedSegments.includes("All")
            ? FormBlockEditability.Disabled
            : FormBlockEditability.Editable
        }
        key={`${segmentOption.segmentValue}-${uniqueId()}`}
        label={
          <Text className={styles.checkboxItem} variant={TextVariant.Default}>
            {segmentOption.segmentLabel}
          </Text>
        }
        name={segmentOption.segmentLabel}
        onChange={() =>
          onCheckboxSelection(
            segmentOption.segmentationValue,
            segmentOption.segmentValue
          )
        }
      />
    ))}
  </>
);

import { FormInputHeight } from "@qbit/react";
import {
  ButtonToggleGroup,
  type ToggleButton,
} from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartIcon from "../../assets/tab-icons/bar-chart-black.svg";
import ChartIconSelected from "../../assets/tab-icons/bar-chart-white.svg";
import TableIcon from "../../assets/tab-icons/table-black.svg";
import TableIconSelected from "../../assets/tab-icons/table-white.svg";
import {
  onChartAdditionalMetricChange,
  onChartTypeChange,
  selectChartAdditionalMetric,
  onReportletTabChange,
  selectReportletTabActive,
  selectChartTypeActive,
} from "../services/pricing-ladders-slice";
import styles from "./PricingLaddersMetrics.module.css";

export type PricingLaddersMetricsProps = {
  additionalMetricDropdownValues: string[];
};

export const PricingLaddersMetrics = ({
  additionalMetricDropdownValues,
}: PricingLaddersMetricsProps) => {
  const additionalMetricOptions = additionalMetricDropdownValues.map(
    (value) => ({ value, label: value })
  );

  const defaultOption = {
    value: "No additional metric selected",
    label: "No additional metric selected",
  };

  const additionalMetricState = useSelector(selectChartAdditionalMetric);

  const dispatch = useDispatch();

  const onAdditionalMetricChange = useCallback(
    (additionalMetric: PanelOption) => {
      dispatch(onChartAdditionalMetricChange(additionalMetric.value as string));
    },
    [dispatch]
  );

  const reportletactiveTab = useSelector(selectReportletTabActive);
  const chartActiveTab = useSelector(selectChartTypeActive);

  const reportletButtons: Array<ToggleButton<string>> = [
    {
      displayText: "Chart",
      icon: {
        iconDefault: ChartIcon,
        iconSelected: ChartIconSelected,
      },
      id: "Chart",
    },
    {
      displayText: "Table",
      icon: {
        iconDefault: TableIcon,
        iconSelected: TableIconSelected,
      },
      id: "Table",
    },
  ];

  const chartTypeButtons: Array<ToggleButton<string>> = [
    {
      displayText: "Standard",
      id: "Standard",
    },
    {
      displayText: "Good, better, best",
      id: "Good, better, best",
    },
  ];

  return (
    <div className={styles.summaryMetricsGrid}>
      <ButtonToggleGroup
        buttonSelected={reportletactiveTab}
        buttons={reportletButtons}
        setButtonSelected={(id: string) => dispatch(onReportletTabChange(id))}
      />

      {reportletactiveTab === "Chart" && (
        <>
          <ButtonToggleGroup
            buttonSelected={chartActiveTab}
            buttons={chartTypeButtons}
            setButtonSelected={(id: string) => dispatch(onChartTypeChange(id))}
          />
          <SingleSelectDropdown
            height={FormInputHeight.XSmall}
            onSelection={onAdditionalMetricChange}
            selectOptions={[defaultOption].concat(additionalMetricOptions)}
            selectedValue={additionalMetricState}
            title="Additional metric dropdown"
          />
        </>
      )}
    </div>
  );
};

import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import { downloadFileFromResponse } from "components-ui/src/export/export-functions";
import { type TransactionSource } from "../enums";
import {
  type SharingActivity,
  type HierarchyGroupDto,
  type HierarchyGroupWithSharingDto,
} from "../models";
import {
  type CopyGroupResponseDto,
  type CopyGroupOrFolderRequestDto,
} from "../models/copy-group-folder-dto";
import {
  type CreateGroupFolderRequest,
  type CreateGroupFolderResponse,
} from "../models/create-group-folder-dto";
import {
  type MoveFolderRequestDto,
  type MoveFolderResponseDto,
  type MoveGroupRequestDto,
  type MoveGroupResponseDto,
  type RenameGroupRequestDto,
  type RenameGroupResponseDto,
  type GroupFolderDto,
  type HierarchyGroupSummaryDto,
  type DeleteFolderRequestDto,
} from "../models/group-dto";
import {
  type RenameGroupFolderRequest,
  type RenameGroupFolderResponse,
} from "../models/rename-group-folder-dto";
import { type SharedGroupRequestDto } from "../models/sharing-dto";

const tags = {
  Groups: "Groups",
  SharingActivities: "sharing-activities",
};

// This slice handles the fetching, updating and caching of Groups data
export const groupsApi = createApi({
  baseQuery: getBaseQuery(`/api/group-service`),
  endpoints: (builder) => ({
    createGroup: builder.mutation<
      HierarchyGroupDto,
      {
        divisionName: string;
        group: HierarchyGroupDto;
      }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, group }) => ({
        body: group,
        method: "POST",
        url: `/${divisionName}/Groups/CreateGroup`,
      }),
    }),

    deleteGroups: builder.mutation<
      unknown,
      {
        divisionName: string;
        itemIds: string[];
      }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, itemIds }) => ({
        method: "DELETE",
        url: `/${divisionName}/Groups/DeleteGroups?groupIds=${itemIds.join(
          "&groupIds="
        )}`,
      }),
    }),

    downloadGroup: builder.query<
      Blob,
      {
        divisionName: string;
        groupId: string;
      }
    >({
      providesTags: ["Groups"],
      query: ({ divisionName, groupId }) => ({
        method: "GET",
        url: `/${divisionName}/Groups/DownloadGroup?groupId=${groupId}`,
        responseHandler: async (response: Response) =>
          await downloadFileFromResponse(
            response,
            `Data for groupId ${groupId}`
          ),
        cache: "no-cache",
      }),
    }),

    deleteGroupFolder: builder.mutation<
      unknown,
      {
        divisionName: string;
        dto: DeleteFolderRequestDto;
      }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, dto }) => ({
        method: "POST",
        body: dto,
        url: `/${divisionName}/Folders/DeleteFolder`,
      }),
    }),

    getGroup: builder.query<
      HierarchyGroupDto,
      {
        divisionName: string;
        groupId: string;
      }
    >({
      providesTags: [tags.Groups],
      query: ({ divisionName, groupId }) => ({
        method: "GET",
        url: `/${divisionName}/Groups/GetGroup?groupId=${groupId}`,
      }),
    }),

    getGroups: builder.query<
      HierarchyGroupWithSharingDto[],
      {
        divisionName: string;
        hierarchyType: string;
        includeRules?: boolean;
        searchTerm?: string;
      }
    >({
      providesTags: [tags.Groups],
      query: ({ divisionName, hierarchyType, includeRules, searchTerm }) => ({
        method: "GET",
        url: `/${divisionName}/Groups/GetUserGroups?hierarchyType=${hierarchyType}&includeRules=${
          includeRules ?? false
        }${searchTerm ? `&searchTerm=${encodeURIComponent(searchTerm)}` : ``}`,
      }),
    }),

    getGroupsAndFolders: builder.query<
      GroupFolderDto,
      {
        divisionName: string;
        hierarchyType: string;
        includeRules?: boolean;
      }
    >({
      providesTags: [tags.Groups],
      query: ({ divisionName, hierarchyType, includeRules }) => ({
        method: "GET",
        url: `/${divisionName}/Groups/GetUserGroupsAndFolders?hierarchyType=${hierarchyType}&includeRules=${
          includeRules ?? false
        }`,
      }),
    }),

    getGroupSummary: builder.query<
      HierarchyGroupSummaryDto,
      {
        divisionName: string;
        groupId: string;
        maxItemsInSubset: Number;
      }
    >({
      providesTags: [tags.Groups],
      query: ({ divisionName, groupId, maxItemsInSubset }) => ({
        method: "GET",
        url: `/${divisionName}/Groups/GetGroupSummary?groupId=${groupId}&maxItemsInSubset=${maxItemsInSubset}`,
      }),
    }),

    getValidTransactionSources: builder.query<
      TransactionSource[],
      {
        divisionName: string;
        groupId: string;
      }
    >({
      providesTags: [tags.Groups],
      query: ({ divisionName, groupId }) => ({
        method: "GET",
        url: `/${divisionName}/Groups/GetValidTransactionSources?groupId=${groupId}`,
      }),
    }),

    updateGroup: builder.mutation<
      HierarchyGroupDto,
      {
        divisionName: string;
        group: HierarchyGroupDto;
      }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, group }) => ({
        body: group,
        method: "POST",
        url: `/${divisionName}/Groups/UpdateGroup`,
      }),
    }),

    renameGroup: builder.mutation<
      RenameGroupResponseDto,
      {
        divisionName: string;
        renameRequest: RenameGroupRequestDto;
      }
    >({
      invalidatesTags: ["Groups"],
      query: ({ divisionName, renameRequest }) => ({
        body: renameRequest,
        method: "POST",
        url: `/${divisionName}/Groups/RenameGroup`,
      }),
    }),

    moveGroup: builder.mutation<
      MoveGroupResponseDto,
      {
        divisionName: string;
        moveRequest: MoveGroupRequestDto;
      }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, moveRequest }) => ({
        body: moveRequest,
        method: "POST",
        url: `/${divisionName}/Groups/MoveGroup`,
      }),
    }),

    moveGroupFolder: builder.mutation<
      MoveFolderResponseDto,
      {
        divisionName: string;
        moveRequest: MoveFolderRequestDto;
      }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, moveRequest }) => ({
        body: moveRequest,
        method: "POST",
        url: `/${divisionName}/Folders/MoveFolder`,
      }),
    }),

    shareGroup: builder.mutation<
      unknown,
      { divisionName: string; payload: SharedGroupRequestDto }
    >({
      invalidatesTags: [tags.SharingActivities, tags.Groups],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/Sharing/ShareGroup`,
      }),
    }),

    unshareGroup: builder.mutation<
      unknown,
      { divisionName: string; payload: SharedGroupRequestDto }
    >({
      invalidatesTags: [tags.SharingActivities, tags.Groups],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/Sharing/UnshareGroup`,
      }),
    }),

    getGroupSharingActivities: builder.query<
      SharingActivity[],
      { divisionName: string; groupId: string }
    >({
      providesTags: [tags.SharingActivities],
      query: ({ divisionName, groupId }) =>
        `/${divisionName}/Sharing/GetSharingActivities?groupId=${groupId}`,
    }),

    createGroupFolder: builder.mutation<
      CreateGroupFolderResponse,
      { divisionName: string; payload: CreateGroupFolderRequest }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/Folders/CreateFolder`,
      }),
    }),

    renameGroupFolder: builder.mutation<
      RenameGroupFolderResponse,
      { divisionName: string; payload: RenameGroupFolderRequest }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/Folders/RenameFolder`,
      }),
    }),

    copyGroup: builder.mutation<
      CopyGroupResponseDto,
      { divisionName: string; payload: CopyGroupOrFolderRequestDto }
    >({
      invalidatesTags: [tags.Groups],
      query: ({ divisionName, payload }) => ({
        body: payload,
        method: "POST",
        url: `/${divisionName}/Copy/CopyGroup`,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
  reducerPath: "groupApi",
  tagTypes: [tags.Groups, tags.SharingActivities],
});

export const {
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupsMutation,
  useRenameGroupMutation,
  useMoveGroupMutation,
  useMoveGroupFolderMutation,
  useGetGroupQuery,
  useGetGroupsQuery,
  useGetGroupsAndFoldersQuery,
  useGetGroupSummaryQuery,
  useGetValidTransactionSourcesQuery,
  useLazyDownloadGroupQuery,
  useShareGroupMutation,
  useUnshareGroupMutation,
  useGetGroupSharingActivitiesQuery,
  useCreateGroupFolderMutation,
  useRenameGroupFolderMutation,
  useDeleteGroupFolderMutation,
  useCopyGroupMutation,
} = groupsApi;

export type groupsApiType = typeof groupsApi;

import { ParameterId } from "@quantium-enterprise/common-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../../../../components/src/search-box/SearchBox";
import {
  onSearchChange,
  resetSearch,
  selectHierarchyItems,
  selectHierarchySelectedItemsFiltered,
  selectIsSelectedItemsShown,
  selectSearchHasNextPage,
  selectSearchString,
  selectTriggerSearchState,
  selectHierarchyDisabledLevelShortNames,
  selectFilterRules,
} from "../../states/report-wizard-slice";
import { type RootState } from "../../store";
import styles from "./LocationHierarchyParameter.module.scss";
import { LocationHierarchyParameterQuery } from "./LocationHierarchyParameterQuery";

export const LOCATION_HIERARCHY_SEARCH_MAX_PAGE_SIZE = 500;
export const LOCATION_HIERARCHY_SEARCH_QUERY_PAGE_SIZE = 500;
export const SEARCH_BOX_DEBOUNCE_TIME = 500;

const parameterType = ParameterId.LocationHierarchy;

export const LocationHierarchyParameter = () => {
  const dispatch = useDispatch();

  const items = useSelector((state: RootState) =>
    selectHierarchyItems(parameterType, state)
  );

  const searchString = useSelector((state: RootState) =>
    selectSearchString(parameterType, state)
  );

  const isSelectedItemsShown = useSelector((state: RootState) =>
    selectIsSelectedItemsShown(parameterType, state)
  );

  const selectedRowsFiltered = useSelector((state: RootState) =>
    selectHierarchySelectedItemsFiltered(parameterType, state)
  );

  const disabledLevelShortNames = useSelector((state: RootState) =>
    selectHierarchyDisabledLevelShortNames(parameterType, state)
  );

  const searchHasNextPage = useSelector((state: RootState) =>
    selectSearchHasNextPage(parameterType, state)
  );

  const triggerSearch = useSelector((state: RootState) =>
    selectTriggerSearchState(parameterType, state)
  );

  const filterRules = useSelector((state: RootState) =>
    selectFilterRules(parameterType, state)
  );

  const handleSearchChange = useCallback(
    (searchText: string) => {
      dispatch(
        onSearchChange({
          parameterType,
          searchString: searchText,
          filterRules,
        })
      );
    },
    [dispatch, filterRules]
  );

  const showSearchResultMessage = useMemo(
    () => !triggerSearch && searchString !== "",
    [searchString, triggerSearch]
  );

  const resultCountText = useMemo(() => {
    const amount = isSelectedItemsShown
      ? selectedRowsFiltered.length
      : items.length;
    if (searchHasNextPage && !isSelectedItemsShown) {
      return `${LOCATION_HIERARCHY_SEARCH_MAX_PAGE_SIZE}+ matches found. Showing first ${LOCATION_HIERARCHY_SEARCH_QUERY_PAGE_SIZE}, please refine your search further`;
    } else {
      return `${amount} match${amount === 1 ? "" : "es"} found`;
    }
  }, [
    searchHasNextPage,
    items.length,
    selectedRowsFiltered.length,
    isSelectedItemsShown,
  ]);

  /**
   * Reset the search state when the component is unmounted
   */
  useEffect(
    () => () => {
      dispatch(resetSearch(parameterType));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.locationHierarchyParameter}>
      <div
        className={
          showSearchResultMessage
            ? styles.searchFilterRow
            : styles.emptySearchFilterRow
        }
      >
        <div className={styles.searchBox}>
          <SearchBox
            debounceTimeMs={SEARCH_BOX_DEBOUNCE_TIME}
            disabled={
              items.length === 1 &&
              items.every((item) =>
                disabledLevelShortNames.includes(item.shortName)
              )
            }
            enableDebounce
            onChange={handleSearchChange}
          />
        </div>
        {showSearchResultMessage && (
          <div className={styles.resultCount}>{resultCountText}</div>
        )}
      </div>
      <div className={styles.contentRow}>
        <LocationHierarchyParameterQuery />
      </div>
    </div>
  );
};

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  DialogWidth,
  FormBlock,
  FormBlockStatus,
  FormBlockType,
  Icon,
  IconGlyph,
  InputStatus,
  MessageVariant,
  QbitEmitToast,
  QbitToastMessage,
  Text,
  TextInput,
} from "@qbit/react";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from "./RenameModal.module.scss";

type MoveItemModalProps = {
  currentName: string;
  headerOverride?: string;
  itemType?: string;
  onClose: () => void;
  onSubmit: (value: string) => Promise<unknown>;
  show: boolean;
};

export const RenameModal = ({
  currentName,
  show,
  itemType = "Report",
  headerOverride,
  onClose,
  onSubmit,
}: MoveItemModalProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const MAX_INPUT_LENGTH = 255;

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    onSubmit(text)
      .then(() => {
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={<span>{`${itemType} has been renamed`}</span>}
            showCloseButton
            showIcon
            variant={MessageVariant.Success}
          />
        );
        onClose();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) =>
        QbitEmitToast(
          <QbitToastMessage
            content={<span />}
            heading={
              <span>
                {error.status === 409
                  ? `A ${itemType.toLocaleLowerCase()} with the same name already
  exists.`
                  : `Failed to rename ${itemType}`}
              </span>
            }
            showCloseButton
            showIcon
            variant={MessageVariant.Danger}
          />
        )
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [text, onClose, onSubmit, setIsLoading, itemType]);

  useEffect(() => {
    setText(currentName);
  }, [currentName]);

  const remainingChars = useMemo(
    () => MAX_INPUT_LENGTH - text.length,
    [MAX_INPUT_LENGTH, text]
  );

  return (
    <>
      {/* React portal used to have modal appear over the entire page and grey out everything */}
      {createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          aria-hidden="true"
          className={styles.dialogContainer}
          onClick={(event) => event.stopPropagation()}
        >
          <Dialog
            footer={
              <div className={styles.footerContainer}>
                <Button
                  className={styles.submitButton}
                  disabled={!text || text === currentName}
                  height={ButtonHeight.XSmall}
                  loading={isLoading}
                  onClick={() => handleSubmit()}
                  text="OK"
                  variant={ButtonVariant.Primary}
                />
                <Button
                  height={ButtonHeight.XSmall}
                  onClick={() => onClose()}
                  text="Cancel"
                  variant={ButtonVariant.Secondary}
                />
              </div>
            }
            header={
              <div className={styles.headerContainer}>
                <Text>{headerOverride ?? `Rename ${itemType}`}</Text>
                <Button
                  // @ts-expect-error property works, but not specified in type
                  autofocus
                  height={ButtonHeight.Small}
                  onClick={() => onClose()}
                  variant={ButtonVariant.Stealth}
                >
                  <Icon
                    glyph={IconGlyph.DeleteAndCloseClose}
                    text="Close selection"
                  />
                </Button>
              </div>
            }
            onClose={onClose}
            // @ts-expect-error onKeyDown property works, but not specified in type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onKeyDown={(event: any) => {
              if (event?.key === "Escape") {
                onClose();
              }
            }}
            show={show}
            titleId="share-report-modal"
            triggeredBy={triggerRef}
            width={DialogWidth.XXSmall}
          >
            <FormBlock
              blockStatus={FormBlockStatus.Default}
              blockType={FormBlockType.Text}
              // @ts-expect-error property works, but not specified in type
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onKeyDown={(event: any) => {
                if (event?.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <TextInput
                id="youridhere"
                // @ts-expect-error property works, but not specified in type
                maxLength={MAX_INPUT_LENGTH}
                onChange={(event) => setText(event.target.value)}
                value={text}
              />
              <InputStatus
                className={classNames(styles.inputStatus, {
                  [styles.inputErrorState]: remainingChars === 0,
                })}
                id="error-status"
                text={`${remainingChars} characters left`}
              />
            </FormBlock>
          </Dialog>
        </div>,
        document.body
      )}
    </>
  );
};

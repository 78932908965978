import {
  FormBlock,
  FormBlockType,
  Input,
  Label,
  Text,
  Textarea,
} from "@qbit/react";
import { useCallback } from "react";
import styles from "./TextAreaParameter.module.css";

type TextAreaParameterProps = {
  handleChange: (input: string) => void;
  label: string;
  optional?: boolean;
  placeholder?: string;
  value: string;
};

export const TextAreaParameter = ({
  label,
  placeholder,
  optional = false,
  handleChange,
  value,
}: TextAreaParameterProps) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      handleChange(event.target.value);
    },
    [handleChange]
  );

  return (
    <FormBlock blockType={FormBlockType.Textarea}>
      <Label
        className={styles.parameterLabel}
        htmlFor={`${label}-textarea`}
        required={!optional}
        text={
          <span className={styles.parameterLabelText}>
            <Text>{label}</Text>
            {optional && (
              <Text className={styles.optionalTag}> (optional)</Text>
            )}
          </span>
        }
      />
      <Input>
        <Textarea
          id={`${label}-textarea`}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      </Input>
    </FormBlock>
  );
};

import { Button, ButtonVariant, Text } from "@qbit/react";
import styles from "./MonthPicker.module.css";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type MonthPickerProps = {
  displayMonth: Date;
  fromDate: Date | undefined;
  onSelect: (date: Date) => void;
  toDate?: Date;
};

const defaultToDate = new Date();
export const MonthPicker = ({
  displayMonth,
  fromDate,
  onSelect,
  toDate = defaultToDate,
}: MonthPickerProps) => {
  const currentYear: number = displayMonth.getFullYear();
  const today = new Date();

  const isDisabled = (date: Date) => {
    if (!fromDate) {
      return false;
    }

    const isBeforeEarliestDate =
      date.getMonth() < fromDate.getMonth() &&
      date.getFullYear() === fromDate.getFullYear();

    const isAfterToday =
      date.getMonth() > today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    const isAfterLastestDate =
      date.getMonth() > toDate.getMonth() &&
      date.getFullYear() === toDate.getFullYear();

    return isBeforeEarliestDate || isAfterToday || isAfterLastestDate;
  };

  const renderMonth = (monthName: string, monthIndex: number) => {
    // Declare new month
    const month = new Date(currentYear, monthIndex);

    return (
      <Button
        className={styles.monthPickerBtn}
        disabled={isDisabled(month)}
        key={monthName}
        onClick={() => onSelect(new Date(month.getFullYear(), monthIndex))}
        variant={ButtonVariant.Stealth}
      >
        <Text>{monthName}</Text>
      </Button>
    );
  };

  return (
    <div className={styles.monthButtonGroup}>
      {months.map((month, index) => renderMonth(month, index))}
    </div>
  );
};

export default MonthPicker;

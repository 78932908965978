import {
  Group,
  GroupGutters,
  Item,
  ItemWidth,
  FormBlock,
  FormBlockType,
  FormBlockDesignVariant,
  Input,
  QSearchInput,
  FormInputHeight,
} from "@qbit/react";
import { useDebounce } from "@quantium-enterprise/hooks-ui";
import { useEffect, useState } from "react";
import styles from "./DebouncedSearchBar.module.css";

export type DebouncedSearchBarProps = {
  disabled?: boolean;
  initialQuery?: string;
  onChanged?: (query: string) => void;
};

export const DebouncedSearchBar = ({
  disabled,
  initialQuery,
  onChanged,
}: DebouncedSearchBarProps) => {
  const [searchQuery, setSearchQuery] = useState<string>(initialQuery ?? "");
  useEffect(() => {
    setSearchQuery(initialQuery ?? "");
  }, [initialQuery]);

  const debouncedSearchQuery = useDebounce(searchQuery, 500) as string;
  useEffect(() => {
    if (onChanged) {
      onChanged(debouncedSearchQuery);
    }
  }, [onChanged, debouncedSearchQuery]);

  return (
    <Group className={styles.searchbarSearch} gutters={GroupGutters.XSmall}>
      <Item width={ItemWidth.Fill}>
        <FormBlock
          blockType={FormBlockType.Search}
          designvariant={FormBlockDesignVariant.Default}
        >
          <Input>
            <QSearchInput
              autofocus
              disabled={disabled}
              height={FormInputHeight.XSmall}
              id="add-items-hierarchy-search-input"
              onChange={(event) => setSearchQuery(event.target.value)}
              placeholder="Search"
              value={searchQuery}
            />
          </Input>
        </FormBlock>
      </Item>
    </Group>
  );
};

export default DebouncedSearchBar;

import {
  FormBlock,
  FormBlockType,
  FormInputWidth,
  Input,
  Select,
  SelectOption,
  SelectOptionGroup,
} from "@qbit/react";
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { type SeriesOptionsType } from "highcharts";
import { type ReactNode, useEffect, useMemo, useState } from "react";
import { HighchartsNpbTooltip } from "../../highcharts-custom-tooltip/HighchartsCustomTooltip";
import { CustomLegend } from "../CustomLegend";
import { ChartFooterWrapper } from "../chart-footer-wrapper/ChartFooterWrapper";
import {
  defaultOptions,
  HighchartsReact,
  type HighchartsReactProps,
} from "../highcharts-react/HighchartsReact";
import styles from "./NpbChart.module.css";
import { type MetricDefinitions } from "./npb-chart-utils";

export type ChartDataSeries = {
  colour?: string;
  data: number[];
  isBenchmark?: boolean;
  name: string;
};
export type parameterSummary = {
  name: string;
  value: string;
};
type NpbChartProps = {
  benchmarkData?: ChartDataSeries[];
  categories: number[] | string[];
  chartContainerRef?: React.MutableRefObject<HTMLElement | undefined>;
  chartData: ChartDataSeries[];
  chartDataFormatter: FormatterFunction;
  hiddenBenchmarkSeries: number[];
  hiddenSeries: number[];
  metricDefinitions: MetricDefinitions;
  onLegendChanged?: (legend: ReactNode) => void;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  parameterSummary?: parameterSummary[];
  percentFormatter: FormatterFunction;
  selectMetric: (metric: string) => void;
  selectedMetric: string;
  showChartDataLables: boolean;
  toggleBenchmarks: (index: number) => void;
  toggleSeries: (index: number) => void;
  yAxisLabel: string;
};

const convertChartDataToSeries = (
  series: ChartDataSeries,
  hiddenSeries: number[],
  index: number
): SeriesOptionsType => ({
  data: series.data.slice(),
  name: series.name,
  color: series.colour,
  dashStyle: series.isBenchmark ? "ShortDashDot" : "Solid",
  lineWidth: 3,
  visible: !hiddenSeries.includes(index),
  type: "line",
  showInLegend: !hiddenSeries.includes(index),
  yAxis: 0,
  // a second x axis is needed for the double crosshairs and it needs to have at least one series allocated to it
  xAxis: index === 1 ? 1 : 0,
});

const defaultEmptyList: ChartDataSeries[] = [];

export const NpbChart = ({
  benchmarkData = defaultEmptyList,
  categories,
  chartData,
  metricDefinitions,
  selectedMetric,
  selectMetric,
  showChartDataLables,
  percentFormatter,
  chartDataFormatter,
  hiddenSeries,
  hiddenBenchmarkSeries,
  toggleSeries,
  toggleBenchmarks,
  yAxisLabel,
  parameterSummary,
  chartContainerRef,
  onLegendChanged,
  onOptionsChanged,
}: NpbChartProps) => {
  const benchmarkSeriesNames = useMemo(
    () => benchmarkData.map((bench) => bench.name),
    [benchmarkData]
  );
  const [isBenchmarkPanelExpanded, setIsBenchmarkPanelExpanded] =
    useState(true);

  const convertedCategories = useMemo(
    () => categories.map((cat) => cat.toString()),
    [categories]
  );
  const chartSeries = useMemo(
    () =>
      chartData.map((dataPoint, index) =>
        convertChartDataToSeries(dataPoint, hiddenSeries, index)
      ),
    [chartData, hiddenSeries]
  );
  const benchmarkSeries = useMemo(
    () =>
      benchmarkData.map((dataPoint, index) =>
        convertChartDataToSeries(dataPoint, hiddenBenchmarkSeries, index)
      ),
    [benchmarkData, hiddenBenchmarkSeries]
  );

  const optionsState: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        borderWidth: 0,
        marginRight: 10,
        plotBorderWidth: 0,
        spacingLeft: 0,
        style: {
          fontFamily: `var(--qbit-font-family)`,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
            symbol: "circle",
          },
        },
        series: {
          dataLabels: {
            enabled: showChartDataLables,
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return chartDataFormatter(this.y);
            },
          },
        },
      },
      series: chartSeries.concat(benchmarkSeries),
      tooltip: {
        ...defaultOptions.tooltip,
        stickOnContact: true,
        ReactFormatter: (ttData) =>
          HighchartsNpbTooltip({
            chartDataFormatter,
            hasSentiment: false,
            percentFormatter,
            ttData,
            benchmarkSeriesNames,
            isBenchmarkPanelExpanded,
            toggleIsBenchmarkPanelExpanded: () =>
              setIsBenchmarkPanelExpanded(!isBenchmarkPanelExpanded),
          }),
        shared: true,
      },
      xAxis: [
        {
          categories: convertedCategories,
          crosshair: {
            className: "trends-chart-crosshairs",
            color: "var(--qbit-colour-shade-2)",
            zIndex: 0,
          },
          labels: {
            distance: 100,
            style: {
              fontSize: "1em",
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          tickLength: 7,
          tickmarkPlacement: "on",
          tickWidth: 1,
          title: {
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: "var(--qbit-font-weight-medium)",
              fontSize: "0.875rem",
            },
            text: "Weeks in Market",
          },
          visible: true,
        },
        {
          categories: convertedCategories,
          crosshair: {
            dashStyle: "Dash",
            color: "var(--qbit-colour-shade-4)",
            width: 2,
            zIndex: 1,
          },
          linkedTo: 0,
          visible: false,
        },
      ],
      yAxis: [
        {
          ...defaultOptions.yAxis,
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return chartDataFormatter(this.value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          title: {
            style: {
              color: "var(--qbit-colour-text-primary)",
              fontWeight: "var(--qbit-font-weight-medium)",
              fontSize: "0.875rem",
            },
            text: yAxisLabel,
          },
        },
        {
          labels: {
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return chartDataFormatter(this.value, false, "", true);
            },
            style: {
              color: "var(--qbit-colour-text-secondary)",
            },
          },
          opposite: true,
          title: {
            style: {
              fontWeight: "bold",
              color: "var(--qbit-colour-text-primary)",
            },
            text: "Benchmark",
          },
        },
      ],
    }),
    [
      benchmarkSeries,
      benchmarkSeriesNames,
      chartDataFormatter,
      chartSeries,
      convertedCategories,
      isBenchmarkPanelExpanded,
      percentFormatter,
      showChartDataLables,
      yAxisLabel,
    ]
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    selectMetric(event.target.value);
  };

  // Because the chart needs a fixed height (for the export), if there are a lot of items in the parameters summary,
  // the legend will be cut off the bottom. This is a workaround to make sure the legend is visible. (CO3-5440)
  // This is just a temporary fix until https://quantium.atlassian.net/browse/CO3-5905 is implemented.
  // This will add a preview of the export before downloading. Remove this code when that time comes.
  const lineHeight = 25;
  // really guessing at how long an average product name is.
  const approxItemsPerRow = 5;
  const chartHeight =
    450 +
    Math.ceil(parameterSummary?.length ?? 0 / approxItemsPerRow) * lineHeight;

  const chartLegendArea = useMemo(
    () => (
      <>
        <div className={styles.legendContainer}>
          <CustomLegend
            chartData={chartSeries}
            hasLegendTitleIcon
            iconShortName="PD"
            legendTitle="Products"
            noSelectionMessage="Select focal items to view products against benchmarks"
            toggleSeriesVisibility={toggleSeries}
          />
        </div>
        <div className={styles.legendContainer}>
          <CustomLegend
            chartData={benchmarkSeries}
            hasLegendTitleIcon
            isBenchmark
            legendTitle="Benchmarks"
            toggleSeriesVisibility={toggleBenchmarks}
          />
        </div>
      </>
    ),
    [benchmarkSeries, chartSeries, toggleBenchmarks, toggleSeries]
  );

  useEffect(() => {
    if (onLegendChanged) {
      onLegendChanged(chartLegendArea);
    }
  }, [onLegendChanged, chartLegendArea]);

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(optionsState);
    }
  }, [onOptionsChanged, optionsState]);

  return (
    <div>
      <FormBlock blockType={FormBlockType.Select}>
        <Input>
          <Select
            id="metric-select"
            onChange={handleOnChange}
            value={selectedMetric}
            width={FormInputWidth.XSmall}
          >
            {metricDefinitions.map(({ groupName, metrics }) => (
              <SelectOptionGroup key={`${groupName}-group`} label={groupName}>
                {metrics.map((metric) => (
                  <SelectOption
                    key={`${metric.name}-option`}
                    text={metric.name}
                    value={metric.name}
                  />
                ))}
              </SelectOptionGroup>
            ))}
          </Select>
        </Input>
      </FormBlock>
      <ChartFooterWrapper
        height={`${chartHeight}px`}
        parameters={parameterSummary ?? []}
        ref={chartContainerRef}
      >
        <div className={styles.parentContainer}>
          <HighchartsReact options={optionsState} />
          {chartLegendArea}
        </div>
      </ChartFooterWrapper>
    </div>
  );
};

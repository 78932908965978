/* eslint-disable no-console -- should probably log though */
export type StorageType = Storage;

type StorageConfig = {
  namespace?: string;
  storageType: StorageType;
  version: string;
};

type StorageItem<T> = {
  timestamp: number;
  value: T;
  version: string;
};

// Interface for storage service
export type StorageService = {
  clear: () => void;
  get: <T>(key: string) => T | undefined;
  has: (key: string) => boolean;
  remove: (key: string) => void;
  set: <T>(key: string, value: T) => void;
};

class BrowserStorageService implements StorageService {
  private readonly version: string;

  private readonly storage: StorageType = localStorage;

  private readonly namespace: string;

  public constructor(config: StorageConfig) {
    this.version = config.version;
    this.storage = config.storageType;
    this.namespace = config.namespace ?? "checkout";
  }

  /**
   * Generates a namespaced key to avoid conflicts with other applications
   * using localStorage on the same domain
   */
  private getNamespacedKey(key: string): string {
    return `${this.namespace}:${key}`;
  }

  /**
   * Sets an item in localStorage with versioning
   */
  public set<T>(key: string, value: T): void {
    try {
      const item: StorageItem<T> = {
        version: this.version,
        value,
        timestamp: Date.now(),
      };

      this.storage.setItem(this.getNamespacedKey(key), JSON.stringify(item));
    } catch (error) {
      console.error("Error setting localStorage item:", error);
    }
  }

  /**
   * Gets an item from localStorage, checking version compatibility
   * Returns undefined if version mismatch or item doesn't exist
   */
  public get<T>(key: string): T | undefined {
    try {
      const rawItem = this.storage.getItem(this.getNamespacedKey(key));

      if (!rawItem) {
        return undefined;
      }

      const item: StorageItem<T> = JSON.parse(rawItem);

      // Return undefined if versions don't match (cache invalidation)
      if (item.version !== this.version) {
        this.remove(key);
        return undefined;
      }

      return item.value;
    } catch (error) {
      console.error("Error getting localStorage item:", error);
      return undefined;
    }
  }

  /**
   * Removes an item from localStorage
   */
  public remove(key: string): void {
    try {
      this.storage.removeItem(this.getNamespacedKey(key));
    } catch (error) {
      console.error("Error removing localStorage item:", error);
    }
  }

  /**
   * Clears all items under the current namespace
   */
  public clear(): void {
    try {
      const keys = [];
      for (let index = 0; index < this.storage.length; index++) {
        keys.push(this.storage.key(index));
      }

      const namespacedKeys = keys.filter(
        (key) => Boolean(key) && key?.startsWith(`${this.namespace}:`)
      );

      for (const key of namespacedKeys) {
        this.storage.removeItem(key as string);
      }
    } catch (error) {
      console.error("Error clearing localStorage:", error);
    }
  }

  /**
   * Check if an item exists and is valid (correct version)
   */
  public has(key: string): boolean {
    try {
      const rawItem = this.storage.getItem(this.getNamespacedKey(key));
      if (!rawItem) return false;

      const item: StorageItem<unknown> = JSON.parse(rawItem);
      return item.version === this.version;
    } catch {
      return false;
    }
  }
}

// This is a factory function to create the service
export const createBrowserStorage = (config: StorageConfig): StorageService =>
  new BrowserStorageService(config);

/*
    Configured like this:
    export const configureStorage = (): StorageService => {
      const storage = createBrowserStorage({
        // Increment this when data structure changes
        version: "1.0.0",
        storageType: localStorage,
        namespace: "checkout",
      });

      return storage;
    };

     - Version is because this could use localStorage, which persists in the users browser until they decide to clear it, or they change machines, or the heat death of the universe, whichever comes first.
    This gives us a way of invalidating the cache if we change the data structure to prevent deserializing bugs.

    - Namespace is in case we have other applications using localStorage on the same domain (eg Asda runs on a subpath), we don't want to mess with other devs stuff
*/

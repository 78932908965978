import tokens from "@qbit/core/dist/tokens.json";

const RED_WEIGHT = 0.212_6;
const GREEN_WEIGHT = 0.715_2;
const BLUE_WEIGHT = 0.072_2;
const RGB_MAX_VALUE = 255;

const DEFAULT_LIGHT_COLOUR = tokens.colour.white;
const DEFAULT_DARK_COLOUR = tokens.colour["brand-black-palette"].text;
const DEFAULT_LIGHT_LUMINANCE = 1;
const DEFAULT_DARK_LUMINANCE = 0.000_131_487_889_273_356_4;

// adjustable to create different breakpoints between light/dark text
const CONSTRAST_WEIGHT = 0.18;

// value between 0 and 1 to represent luminance
export const getLuminance = (hexColor: string): number => {
  const color = hexColor.startsWith("#") ? hexColor.slice(1, 7) : hexColor;
  // accounts for 3 or 6 letter hex digits
  const hexR =
    color.length === 3
      ? color.slice(0, 1) + color.slice(0, 1)
      : color.slice(0, 2);
  const hexG =
    color.length === 3
      ? color.slice(1, 2) + color.slice(1, 2)
      : color.slice(2, 4);
  const hexB =
    color.length === 3
      ? color.slice(2, 3) + color.slice(2, 3)
      : color.slice(4, 6);
  // numbers below come from here:
  // https://www.w3.org/TR/WCAG20/#relativeluminancedef
  const red = Number.parseInt(hexR, 16) / RGB_MAX_VALUE;
  const green = Number.parseInt(hexG, 16) / RGB_MAX_VALUE;
  const blue = Number.parseInt(hexB, 16) / RGB_MAX_VALUE;
  const uicolors = [red, green, blue];
  const rgb = uicolors.map((col) =>
    col <= 0.039_28 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4
  );

  return RED_WEIGHT * rgb[0] + GREEN_WEIGHT * rgb[1] + BLUE_WEIGHT * rgb[2];
};

export const getTextColor = (
  bgColor: string,
  darkColor?: string,
  lightColor?: string
): string => {
  const bgLuminance = getLuminance(bgColor);
  const lightLuminance = lightColor
    ? getLuminance(lightColor)
    : DEFAULT_LIGHT_LUMINANCE;
  const darkLuminance = darkColor
    ? getLuminance(darkColor)
    : DEFAULT_DARK_LUMINANCE;

  return bgLuminance >
    Math.sqrt(
      (lightLuminance + CONSTRAST_WEIGHT) * (darkLuminance + CONSTRAST_WEIGHT)
    ) -
      CONSTRAST_WEIGHT
    ? darkColor ?? DEFAULT_DARK_COLOUR
    : lightColor ?? DEFAULT_LIGHT_COLOUR;
};

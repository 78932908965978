import { Group, GroupGutters, Item, Text, ItemValign } from "@qbit/react";
import { HierarchyItemType } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { type SeriesOptionsType } from "highcharts";
import { HierarchyLevelIcon } from "../hierarchy-level-icon/HierarchyLevelIcon";
import styles from "./CustomLegend.module.scss";
import { SeriesIcon } from "./SeriesIcon";

type CustomLegendProps = {
  chartData: SeriesOptionsType[];
  dashed?: boolean;
  hasLegendTitleIcon?: boolean;
  iconShortName?: string;
  isBenchmark?: boolean;
  legendTitle?: string;
  noSelectionMessage?: string;
  toggleSeriesVisibility: Function;
};

export const CustomLegend = ({
  dashed,
  isBenchmark,
  legendTitle,
  hasLegendTitleIcon,
  iconShortName,
  noSelectionMessage = "",
  chartData,
  toggleSeriesVisibility,
}: CustomLegendProps) => (
  <div className={styles.legendContainer}>
    {legendTitle && (
      <div className={styles.legendTitleContainer}>
        {hasLegendTitleIcon && (
          <div className={styles.legendTitleIcon}>
            <SeriesIcon
              benchmark={isBenchmark}
              color="#000"
              seriesType="line"
            />
          </div>
        )}
        <Text className={styles.legendTitle}>{legendTitle}</Text>
      </div>
    )}
    <Group className={styles.groupContainer} wrap>
      {chartData.length === 0 && (
        <span className={styles.noSelectionMessage}>{noSelectionMessage}</span>
      )}

      {chartData.map((series: SeriesOptionsType, index: number) => (
        <button
          className={styles.legendItemWrapper}
          key={`${series.name} - ${index + 1}`}
          onClick={() => {
            toggleSeriesVisibility(index);
          }}
          type="button"
        >
          <Group
            className={classNames(styles.legendItem, {
              [styles.inactive]: !series.visible,
            })}
            gutters={GroupGutters.XSmall}
          >
            <Item valign={ItemValign.Middle}>
              <SeriesIcon
                benchmark={isBenchmark}
                color={series.color?.toString()}
                dashed={dashed}
                seriesType={series.type}
              />
            </Item>
            {iconShortName && (
              <span className={styles.legendIcon}>
                <HierarchyLevelIcon
                  shortName={iconShortName}
                  type={HierarchyItemType.Leaf}
                />
              </span>
            )}
            <Item valign={ItemValign.Middle}>
              <Text>{series.name ?? "-"}</Text>
            </Item>
          </Group>
        </button>
      ))}
    </Group>
  </div>
);

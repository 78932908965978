import {
  Button,
  ButtonVariant,
  Dialog,
  DialogWidth,
  Icon,
  IconGlyph,
  QbitEmitToast,
  QbitToastMessage,
  MessageVariant,
  Spinner,
  SpinnerSize,
} from "@qbit/react";
import {
  EMIT_TOAST_DURATION,
  FeatureFlag,
  isParameterConfigurationDto,
  useGetUserQuery,
  useLazyGetRecentReportIdByTypeQuery,
  useLazyGetRerunParameterConfigurationsQuery,
  useLazyGetSavedParametersDetailsQuery,
  type ParameterConfigurationsDto,
} from "@quantium-enterprise/common-ui";
import {
  MY_REPORTS_PATH,
  useEventTrackingServiceContext,
  TrackingComponent,
  TrackingEvent,
  GenericTrackingProperties,
  ddLog,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  useCreateParametersMutation,
  useLazyGetAvailableReportsQuery,
  useLazyGetParameterConfigurationsQuery,
} from "../../../common/services/ReportParametersService";
import { useGroupsValidator } from "../../../parameters/groups/GroupValidator";
import {
  onSavedParametersReceived,
  setRerunConfig,
  showDataEntitlements,
  type ServerErrorState,
} from "../../../states/report-wizard-slice";
import { onServerError } from "../../../states/report-wizard-slice";
import { getGroupIsValid } from "../../../states/validation-utils";
import { type RootState } from "../../../store";
import {
  type StoreReportParametersResponseDto,
  type StoreParametersRequestDto,
  type StoreParametersErrorResponse,
} from "../../model/StoreParametersDto";
import { buildParameterGroupSelectionDtos } from "../../services/SelectionDtoBuilder";
import { SummaryPanelBody } from "../../summary/SummaryPanelBody";
import { SummaryPanel } from "../../summary-panel/SummaryPanel";
import { Wizard } from "../Wizard";
import ParameterGroupTab from "../parameter-groups/ParameterGroupTab";
import { resetAndSetTabs, setTitle } from "../wizard-slice";
import styles from "./ParametersBody.module.css";

const icon = (
  <Icon
    className={styles.summaryIcon}
    glyph={IconGlyph.ChartsChartBar}
    text="Report summary icon"
  />
);

export type ReportParametersBodyProps = {
  reportUri: string;
};

export type ServerError = {
  errorMessage: string;
  errorTitle: string[];
  show: boolean;
};

export const ReportParametersBody = ({
  reportUri,
}: ReportParametersBodyProps) => {
  const eventTrackingService = useEventTrackingServiceContext();
  const flags = useFlags();
  const location = useLocation();
  const { id } = useParams();
  const initialreRunConfig = location.state?.reRunConfig;
  const { data: user } = useGetUserQuery();
  // reference to persist reRunConfig from location.state across renders
  const reRunConfigRef = useRef<ParameterConfigurationsDto | undefined>(
    initialreRunConfig && isParameterConfigurationDto(initialreRunConfig)
      ? initialreRunConfig
      : undefined
  );
  const reRunConfig = reRunConfigRef.current;

  const isReset = useRef<boolean>(location.state?.reset);

  const dispatch = useDispatch();
  const { name: divisionName } = useDivision();

  const {
    parameterGroups,
    parameters,
    transactionSources,
    reportName,
    isServerError,
  } = useSelector((state: RootState) => ({
    parameterGroups: state.reportParameter.parameterGroups,
    parameters: state.reportParameter.parameters,
    transactionSources: state.reportParameter.transactionSources,
    reportName: state.wizard.title,
    isServerError: state.reportParameter.serverError.isError,
  }));
  useEffect(() => {
    if (reRunConfig?.parameterGroups) {
      dispatch(
        resetAndSetTabs(
          reRunConfig.parameterGroups.map(
            (parameterGroup) => parameterGroup.label
          )
        )
      );
      dispatch(
        setRerunConfig({
          parameterConfig: reRunConfig,
        })
      );
    }
  }, [dispatch, reRunConfig]);

  const featureFlags = useFlags();
  const isLoadRecentReportEnabled =
    featureFlags[FeatureFlag.LoadRecentReportInReportWizard] ?? false;

  const [persistedSelection, setPersistedSelection] =
    useState<ParameterConfigurationsDto | null>(null);

  const [persistedSelectionProcessed, setPersistedSelectionProcessed] =
    useState(false);

  const [triggerGetMostRecentReportByType] =
    useLazyGetRecentReportIdByTypeQuery();

  const [triggerGetRerunParameterConfigurations] =
    useLazyGetRerunParameterConfigurationsQuery();

  const fetchAndApplyRecentReportParameters = useCallback(
    async (division: string, reportType: string) => {
      if (!id) {
        try {
          const reportId = await triggerGetMostRecentReportByType({
            divisionName: division,
            reportType,
          }).unwrap();

          if (reportId) {
            const reportParameters =
              await triggerGetRerunParameterConfigurations({
                divisionName: division,
                reportType,
                rerunReportId: reportId,
              }).unwrap();

            if (reportParameters.parameterGroups) {
              dispatch(
                resetAndSetTabs(
                  reportParameters.parameterGroups.map(
                    (parameterGroup) => parameterGroup.label
                  )
                )
              );
              dispatch(
                setRerunConfig({
                  parameterConfig: reportParameters,
                })
              );
              setPersistedSelection(reportParameters);
            }
          }
        } catch (error) {
          ddLog(
            "Error refilling report parameters selections from the most recent report",
            {},
            "error",
            error as Error
          );
        } finally {
          setPersistedSelectionProcessed(true);
        }
      }
    },
    [
      dispatch,
      id,
      triggerGetMostRecentReportByType,
      triggerGetRerunParameterConfigurations,
    ]
  );

  useEffect(() => {
    if (
      isLoadRecentReportEnabled &&
      divisionName &&
      reportUri &&
      !reRunConfig &&
      !isReset.current
    ) {
      void fetchAndApplyRecentReportParameters(divisionName, reportUri);
    }
  }, [
    divisionName,
    fetchAndApplyRecentReportParameters,
    isLoadRecentReportEnabled,
    reRunConfig,
    reportUri,
  ]);

  // Fetch the available reports
  const [triggerGetAvailableReportsQuery] = useLazyGetAvailableReportsQuery();

  const fetchReportTitle = useCallback(async () => {
    const { data: reportMetaData } = await triggerGetAvailableReportsQuery({
      divisionName,
      reportUri,
    });
    const reportMeta = reportMetaData?.availableReports.find(
      (availableReport) => availableReport.name === reportUri
    );

    if (reportMeta?.displayName) {
      dispatch(setTitle(reportMeta.displayName));
    }
  }, [triggerGetAvailableReportsQuery, dispatch, divisionName, reportUri]);

  useEffect(() => {
    if (reportUri && divisionName) {
      fetchReportTitle().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [divisionName, reportUri, fetchReportTitle]);

  // Fetch saved parameters
  const [triggerSavedParametersDetails, { isSuccess: hasSavedParameters }] =
    useLazyGetSavedParametersDetailsQuery();

  const fetchSavedParametersDetails = useCallback(async () => {
    if (id && flags[FeatureFlag.SavedSelections]) {
      const { data: savedParametersDetails } =
        await triggerSavedParametersDetails({
          divisionName,
          savedParametersId: id,
        });
      if (savedParametersDetails) {
        dispatch(
          onSavedParametersReceived({ savedParameters: savedParametersDetails })
        );
      }
    }
  }, [dispatch, divisionName, flags, id, triggerSavedParametersDetails]);

  useEffect(() => {
    if (divisionName) {
      fetchSavedParametersDetails().catch((error) =>
        ddLog("ERROR", {}, "error", error)
      );
    }
  }, [divisionName, fetchSavedParametersDetails, id]);

  // Fetch the parameter config
  const [triggerQuery, { data, isSuccess }] =
    useLazyGetParameterConfigurationsQuery();

  const fetchParameterConfigurations = useCallback(async () => {
    if (
      (!reRunConfig && persistedSelectionProcessed && !persistedSelection) ||
      isReset.current ||
      (id && hasSavedParameters) ||
      (!isLoadRecentReportEnabled && !reRunConfig)
    ) {
      const requestDto = {
        divisionName,
        reportPath: reportUri,
      };

      await triggerQuery(requestDto);
      if (user?.isSupplier) {
        dispatch(showDataEntitlements());
      }
    }
  }, [
    dispatch,
    divisionName,
    hasSavedParameters,
    id,
    isLoadRecentReportEnabled,
    persistedSelection,
    persistedSelectionProcessed,
    reRunConfig,
    reportUri,
    triggerQuery,
    user?.isSupplier,
  ]);

  useEffect(() => {
    if (
      reportUri &&
      divisionName &&
      (id === undefined || (id && hasSavedParameters))
    ) {
      fetchParameterConfigurations().catch((error) => {
        // FIXME: throw this somewhere
        ddLog("ERROR", {}, "error", error);
      });
    }
  }, [
    divisionName,
    fetchParameterConfigurations,
    hasSavedParameters,
    id,
    reportUri,
  ]);

  useEffect(() => {
    if (reportName !== "" && isSuccess) {
      eventTrackingService.trackEvent(
        [TrackingComponent.ReportCreator, TrackingComponent.Wizard],
        TrackingEvent.Opened,
        new GenericTrackingProperties({
          reportName,
        })
      );
    }
  }, [eventTrackingService, isSuccess, reportName]);

  useGroupsValidator();

  // store the current config data
  const configData = useMemo(() => {
    if (reRunConfig) return reRunConfig;

    if (persistedSelectionProcessed && persistedSelection)
      return persistedSelection;

    return data;
  }, [data, persistedSelection, persistedSelectionProcessed, reRunConfig]);

  const isParametersSuccess = useMemo(
    () => Boolean(reRunConfig) || persistedSelectionProcessed || isSuccess,
    [isSuccess, persistedSelectionProcessed, reRunConfig]
  );

  const selectedTransactionSourceIds = useMemo(
    () =>
      transactionSources
        .filter((source) => source.isSelected)
        .map((source) => source.idOverwrite ?? source.id),
    [transactionSources]
  );

  const disableCreateReportButton =
    Object.keys(parameterGroups).some((key) => {
      const keyState = parameterGroups[key];

      if (!keyState) {
        return true;
      }

      return !getGroupIsValid(keyState);
    }) || isServerError;

  const [serverError, setServerError] = useState({
    show: false,
    errorTitle: [],
    errorMessage: "",
  } as ServerError);

  const [showProcessing, setShowProcessing] = useState(false);
  const handleServerErrorClose = useCallback(() => {
    setServerError({ ...serverError, show: false });
  }, [serverError]);

  const onServerErrorReceived = useCallback(
    (errorMessage: String, errorParameters: string[]) => {
      dispatch(
        onServerError({
          isError: true,
          errorMessage,
          parameters: errorParameters,
        } as ServerErrorState)
      );
    },
    [dispatch]
  );

  const navigate = useNavigate();
  const myReportPath = `/${divisionName}/${MY_REPORTS_PATH}`;

  const [createParameters] = useCreateParametersMutation();
  const createReport = useCallback(async () => {
    if (!configData) {
      throw new Error(
        "ReportParametersBody error: no data available to make request"
      );
    }

    setShowProcessing(true);
    const parameterGroupSelections = buildParameterGroupSelectionDtos(
      parameters,
      configData
    );

    const requestDto: StoreParametersRequestDto = {
      parameterGroupSelections,
      transactionSources: selectedTransactionSourceIds,
    };

    let reportId = "";
    let reportCreateSuccess = false;
    try {
      const result = await createParameters({
        divisionName,
        reportUri,
        requestDto,
      });

      if (`data` in result) {
        const payload = result.data as StoreReportParametersResponseDto;
        navigate(`${myReportPath}/${payload.reportId}?reload=true`);
        QbitEmitToast(
          <QbitToastMessage
            content={<p>You'll be notified when it's ready to view</p>}
            heading={<h5>Report in progress</h5>}
            showIcon
            variant={MessageVariant.Notification}
          />,
          {
            autoClose: EMIT_TOAST_DURATION,
          }
        );
        reportCreateSuccess = true;
        reportId = payload.reportId;
      } else {
        reportCreateSuccess = false;
        const errorResponse = result.error as StoreParametersErrorResponse;
        if (errorResponse.data.errorCode === "NO_PRODUCTS_LAUNCHED") {
          onServerErrorReceived(
            errorResponse.data.errorMessage,
            errorResponse.data.parameters
          );
          setServerError({
            show: true,
            errorTitle: ["Report creation failed."],
            errorMessage: errorResponse.data.errorMessage,
          } as ServerError);
        } else {
          throw new Error(
            "Unhandled errorCode. Default to generic error in catch"
          );
        }
      }
    } catch {
      setServerError({
        show: true,
        errorTitle: ["Something went wrong", "please try again."],
        errorMessage: "Contact support if error persists.",
      } as ServerError);
    }

    eventTrackingService.trackEvent(
      [TrackingComponent.MyReports, TrackingComponent.Report],
      TrackingEvent.Submitted,
      new GenericTrackingProperties({
        reportId,
        reportName,
        reportGlobalParameters: requestDto.parameterGroupSelections,
        reportDataType: requestDto.transactionSources,
        reportSubmitStatus: reportCreateSuccess ? "Success" : "Fail",
      })
    );

    setShowProcessing(false);
  }, [
    createParameters,
    configData,
    divisionName,
    myReportPath,
    navigate,
    parameters,
    reportUri,
    reportName,
    eventTrackingService,
    selectedTransactionSourceIds,
    onServerErrorReceived,
  ]);

  return (
    <>
      {isParametersSuccess && configData ? (
        <>
          <Wizard.Content>
            {configData.parameterGroups?.map((parameterGroup) => (
              <ParameterGroupTab
                key={parameterGroup.label}
                parameterGroupDto={parameterGroup}
              />
            ))}
          </Wizard.Content>

          <Wizard.Sidebar>
            <SummaryPanel>
              <SummaryPanel.Header
                icon={icon}
                title="Report summary"
                transactionSources={selectedTransactionSourceIds}
              />
              <SummaryPanelBody
                parameterGroupsConfiguration={configData.parameterGroups}
              />
              <SummaryPanel.Footer
                createText="Create report"
                isDisabled={disableCreateReportButton}
                isProcessing={showProcessing}
                onCreateClick={createReport}
              />
            </SummaryPanel>
          </Wizard.Sidebar>
        </>
      ) : (
        <div className={styles.loading}>
          <Spinner size={SpinnerSize.Medium} text="Loading" />
        </div>
      )}

      <Dialog
        className={styles.serverErrorDialog}
        header={
          <div className={styles.dialogHeader}>
            <Icon
              className={styles.dialogIcon}
              glyph={IconGlyph.AlertsAndNotificationsWarning}
              text="Error"
            />
            <h2>
              {serverError.errorTitle.map((line, index) => (
                <Fragment key={line}>
                  {line}
                  {index !== serverError.errorTitle.length - 1 && <br />}
                </Fragment>
              ))}
            </h2>
          </div>
        }
        onClose={handleServerErrorClose}
        show={serverError.show}
        titleId="dialog-top-right-control"
        topRightControls={
          <Button
            className={styles.dialogClose}
            onClick={handleServerErrorClose}
            variant={ButtonVariant.Stealth}
          >
            <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
          </Button>
        }
        triggeredBy={useRef(null)}
        width={DialogWidth.XXXSmall}
      >
        <p>{serverError.errorMessage}</p>
      </Dialog>
    </>
  );
};

import {
  Button,
  ButtonVariant,
  Dropdown,
  DropdownWidth,
  Icon,
  IconGlyph,
  Menu,
  MenuItemButton,
  MenuSection,
  Text,
  DropdownDisplay,
} from "@qbit/react";
import { type TextProps } from "@qbit/react/dist/text";
import React, { type Key, useState, useRef } from "react";
import styles from "./DashboardParameterSelector.module.css";

export type DashboardParameterSelectorProps<TItem> = {
  buttonText: string;
  defaultSelection: TItem[];
  disableLastSelected: boolean;
  isMultiSelect: boolean;
  itemDisplaySelector: (
    item: TItem
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | React.ReactElement<TextProps, React.JSXElementConstructor<any> | string>
    | undefined;
  itemKeySelector: (item: TItem) => Key;
  items: TItem[];
  onSelectionChanged: (items: TItem[]) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const DashboardParameterSelector = <T extends unknown>({
  disableLastSelected,
  buttonText,
  items,
  defaultSelection,
  isMultiSelect,
  onSelectionChanged,
  itemDisplaySelector,
  itemKeySelector,
  ...properties
}: DashboardParameterSelectorProps<T>) => {
  const dropdownRef = useRef<Dropdown>();
  const [selected, setSelected] = useState<T[]>(defaultSelection);

  const isItemSelected = (item: T) => selected.includes(item);

  const closeDropdown = () => {
    // @ts-expect-error Qbit dropdown currently has no public method to close itself
    if (dropdownRef.current?.state.isOpen && dropdownRef.current.toggleState) {
      // @ts-expect-error Bug ticket to fix this: https://jira.quantium.com.au/browse/QDS-386
      dropdownRef.current.toggleState();
    }
  };

  const itemSelectionToggled = (item: T) => {
    if (isMultiSelect) {
      if (isItemSelected(item)) {
        const itemIndex = selected.indexOf(item);
        const newSelection = selected.slice();
        newSelection.splice(itemIndex, 1);
        setSelected(newSelection);
        onSelectionChanged(newSelection);
      } else {
        const newSelection = [...selected, item];
        setSelected(newSelection);
        onSelectionChanged(newSelection);
      }
    } else {
      const newSelection = [item];
      setSelected(newSelection);
      onSelectionChanged(newSelection);
      closeDropdown();
    }
  };

  return (
    <Dropdown
      className={styles.dashboardParameterSelector}
      contentWidth={DropdownWidth.Medium}
      display={DropdownDisplay.Inline}
      ref={dropdownRef as React.RefObject<Dropdown>}
      trigger={
        <Button className={styles.triggerButton} variant={ButtonVariant.Link}>
          <Text>{buttonText}</Text>
          <Icon glyph={IconGlyph.ArrowsChevronDown} text="" />
        </Button>
      }
      {...properties}
    >
      <Menu>
        <MenuSection>
          {items.map((item) => (
            <MenuItemButton
              data-key={itemKeySelector(item)}
              data-selected={isItemSelected(item)}
              disabled={
                disableLastSelected &&
                isItemSelected(item) &&
                selected.length <= 1
              }
              iconEnd={
                isItemSelected(item) ? (
                  <Icon glyph={IconGlyph.SelectionSuccess} text="Selected" />
                ) : undefined
              }
              key={itemKeySelector(item)}
              onClick={() => itemSelectionToggled(item)}
              selected={isItemSelected(item)}
              text={itemDisplaySelector(item)}
            />
          ))}
        </MenuSection>
      </Menu>
    </Dropdown>
  );
};

export default DashboardParameterSelector;

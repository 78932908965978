import { Toggle } from "@qbit/react";
import { HierarchyItemType, ddLog } from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { type RootState } from "../../store";
import { ProductSubstitutabilityFeatureFlags } from "../constants/product-substitutability-feature-flags";
import {
  DEFAULT_PAGINATION,
  type TableRowsRequestDto,
} from "../models/ProductSubstitutabilityTableRowsRequestDto";
import {
  useLazyDownloadTableQuery,
  useLazyGetTableRowsQuery,
} from "../services/product-substitutability-data-table-api-slice";
import {
  onTopDrawerTableResponseReceived,
  selectFocalItems,
  selectLocation,
  selectPagination,
  selectReportId,
  selectTime,
  selectTimePeriodLength,
  toggleExcludeDeletedLineFilter,
  toggleSelectedItemsOnlyFilter,
} from "../services/product-substitutability-slice";
import { ProductSubstitutabilityModal } from "./ProductSubstitutabilityModal/ProductSubstitutabilityModal";
import { ProductSubstitutabilityTopDrawerProductTable } from "./ProductSubstitutabilityTopDrawerProductTable.tsx/ProductSubstitutabilityTopDrawerProductTable";

export type ProductSubstitutabilityTopDrawerProps = {
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const ProductSubstitutabilityTopDrawer = ({
  renameReport,
  reportType,
  reportName,
}: ProductSubstitutabilityTopDrawerProps) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { name: divisionName } = useDivision();
  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[ProductSubstitutabilityFeatureFlags.ReportExport] ?? false;

  const focalItems = useSelector(selectFocalItems);
  const location = useSelector(selectLocation);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const pagination = useSelector(selectPagination);
  const reportId = useSelector(selectReportId);

  const {
    enableExcludeDeletedLineFilter,
    enableSelectedItemsOnlyFilter,
    searchQuery,
  } = useSelector((state: RootState) => ({
    enableExcludeDeletedLineFilter:
      state.productSubstitutability.enableExcludeDeletedLineFilter,
    enableSelectedItemsOnlyFilter:
      state.productSubstitutability.enableSelectedItemsOnlyFilter,
    searchQuery: state.productSubstitutability.searchQuery,
  }));

  const [getTableRows, { isFetching }] = useLazyGetTableRowsQuery();

  const [downloadTableTrigger] = useLazyDownloadTableQuery();

  const fetchData = useCallback(
    async (division: string, payload: TableRowsRequestDto) => {
      await getTableRows({ division, payload })
        .unwrap()
        .then((response) => {
          dispatch(onTopDrawerTableResponseReceived(response));
        })
        .catch((error) => {
          ddLog(
            "Error retrieving product substitutability table data",
            {},
            "error",
            error
          );
          dispatch(
            onTopDrawerTableResponseReceived({
              attributes: [],
              metrics: [],
              pagination: DEFAULT_PAGINATION,
              tableRows: [],
            })
          );
        });
    },
    [dispatch, getTableRows]
  );

  useEffect(() => {
    if (id && id === reportId && divisionName) {
      const payload: TableRowsRequestDto = {
        includeMeta: true,
        offset: pagination.offset,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        reportId: id,
        searchQuery,
      };

      fetchData(divisionName, payload).catch((error) => {
        ddLog(
          "Error retrieving product substitutability table data",
          {},
          "error",
          error
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, divisionName, fetchData, reportId, searchQuery]);

  const fileName = useMemo(
    () =>
      cleanFilename(
        `Product_Substitutability_${timePeriodLength}_${location.split(" ")[1]}`
      ),
    [timePeriodLength, location]
  );

  const parameterSummary = useMemo(
    () => [
      { name: "Time", value: time },
      { name: "Location", value: location },
    ],
    [time, location]
  );

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        localParameters: parameterSummary,
        fileName,
        reportId: id ?? "",
      },
    });
  }, [downloadTableTrigger, divisionName, fileName, id, parameterSummary]);

  return (
    <ReportTopDrawer
      controls={[
        <ProductSubstitutabilityModal key="attribute-modal" />,
        <Toggle
          checked={enableExcludeDeletedLineFilter}
          key="deleted-line-toggle"
          label="Exclude deleted line"
          onClick={() => {
            dispatch(toggleExcludeDeletedLineFilter());
          }}
        />,
        <Toggle
          checked={enableSelectedItemsOnlyFilter}
          key="selected-items-only-toggle"
          label="Selected items only"
          onClick={() => {
            dispatch(toggleSelectedItemsOnlyFilter());
          }}
        />,
        <div key="data-table-options">
          <DataTableOptions
            filename={fileName}
            invokeCSVDownload={handleDownloadButtonClick}
            isFeatureEnabled={isExportEnabled}
            isInFocalItemHeader
            key={id}
          />
        </div>,
      ]}
      items={focalItems.map((item) => ({
        code: item.shortName,
        displayName: item.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      legendTitle="Uniqueness value:"
      renameReport={renameReport}
      reportId={id}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ProductSubstitutabilityTopDrawerProductTable
          isQuerySuccess={!isFetching}
        />
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default ProductSubstitutabilityTopDrawer;

import { Checkbox } from "@qbit/react";
import styles from "./GroupHierarchyTableHeader.module.css";

type HeaderProps = {
  handleToggleAllRowsSelected: (event: unknown) => void;
  isChecked: boolean;
  showCheckbox: boolean;
  title: string;
};

export const GroupHierarchyTableHeader = ({
  handleToggleAllRowsSelected,
  isChecked,
  showCheckbox,
  title,
}: HeaderProps) => (
  <div className={styles.groupHierarchyTableHeader}>
    {showCheckbox && (
      <Checkbox
        assistiveLabel
        checked={isChecked}
        data-testid="header-checkbox"
        label="Toggle selecting all rows"
        name="selectallrows"
        onChange={handleToggleAllRowsSelected}
      />
    )}
    <span
      className={styles.headerText}
      style={{ marginLeft: "var(--qbit-space-xlarge)" }}
    >
      {title}
    </span>
  </div>
);

import { ButtonGroup, ButtonGroupVariant } from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import { type PropsWithChildren } from "react";
import { ReportButtonTabItem } from "./ReportButtonTabItem";
import styles from "./ReportButtonTabs.module.css";

export type ReportButtonTabsProps = {
  activeTab: string;
  onClickButtonTabItem: (value: string) => void;
};

export const ReportButtonTabs = ({
  activeTab,
  children,
  onClickButtonTabItem,
}: PropsWithChildren<ReportButtonTabsProps>) => {
  if (!children || !Array.isArray(children)) {
    return null;
  }

  return (
    <div className={styles.reportButtonTabs}>
      <ButtonGroup
        className={styles.reportButtonTabList}
        variant={ButtonGroupVariant.Segmented}
      >
        {children.map((child) => {
          const { label, value, icon, defaultImgIcon, selectedImgIcon } =
            child.props;

          return icon ? (
            <ReportButtonTabItem
              icon={icon}
              key={`${value}-${uniqueId()}`}
              label={label}
              selected={value === activeTab}
              setSelectedButton={(value_: string) =>
                onClickButtonTabItem(value_)
              }
              value={value}
            />
          ) : (
            <ReportButtonTabItem
              defaultImgIcon={defaultImgIcon}
              key={`${value}-${uniqueId()}`}
              label={label}
              selected={value === activeTab}
              selectedImgIcon={selectedImgIcon}
              setSelectedButton={(value_: string) =>
                onClickButtonTabItem(value_)
              }
              value={value}
            />
          );
        })}
      </ButtonGroup>

      <div className={styles.reportButtonTabContent}>
        {children.map((child) => {
          if (child.props.value !== activeTab) {
            return null;
          }

          return child.props.children;
        })}
      </div>
    </div>
  );
};

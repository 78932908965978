import {
  Nav,
  NavVariant,
  NavFontWeight,
  NavButton,
  NavSize,
} from "@qbit/react";
import { type PropsWithChildren, useMemo } from "react";
import styles from "./ReportTabs.module.css";

export type ReportTabsProps = {
  activeTab: string;
  onClickTabItem: (value: string) => void;
};

export const ReportTabs = ({
  activeTab,
  children,
  onClickTabItem,
}: PropsWithChildren<ReportTabsProps>) => {
  const memoizedActiveTabIndex = useMemo(() => {
    let activeTabIndex = 0;

    if (!children || !Array.isArray(children)) {
      return activeTabIndex;
    }

    for (const [index, child] of children.entries()) {
      if (child.props.value === activeTab) {
        activeTabIndex = index;
        break;
      }
    }

    return activeTabIndex;
  }, [activeTab, children]);

  if (!children || !Array.isArray(children)) {
    return null;
  }

  return (
    <div className={styles.reportTabs}>
      <Nav
        activeIndex={memoizedActiveTabIndex}
        className={styles.reportTabList}
        fontWeight={NavFontWeight.Medium}
        size={NavSize.Small}
        variant={NavVariant.Tab}
      >
        {children.map((child) => {
          const { label, value } = child.props;

          return (
            <NavButton
              current={value === activeTab}
              key={value}
              onClick={() => onClickTabItem(value)}
            >
              {label}
            </NavButton>
          );
        })}
      </Nav>

      <div className={styles.reportTabContent}>
        {children.map((child) => {
          if (child.props.value !== activeTab) {
            return null;
          }

          return child.props.children;
        })}
      </div>
    </div>
  );
};

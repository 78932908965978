import tokens from "@qbit/core/dist/tokens.json";
import classNames from "classnames";

export const ShareIconColours = {
  black: tokens.colour["text-primary"],
  blue: tokens.colour["brand-blue-palette"]["500"],
};

export type ShareIconProps = {
  color?: string;
};

export const ShareIcon = ({
  color = ShareIconColours.black,
}: ShareIconProps) => {
  const classes = classNames("q-icon", "q-icon-medium");
  return (
    <span className={classes}>
      <svg fill="none" viewBox="-4 -4 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22 20.12C21.24 20.12 20.56 20.42 20.04 20.89L12.91 16.74C12.96 16.51 13 16.28 13 16.04C13 15.8 12.96 15.57 12.91 15.34L19.96 11.23C20.5 11.73 21.21 12.04 22 12.04C23.66 12.04 25 10.7 25 9.04004C25 7.38004 23.66 6.04004 22 6.04004C20.34 6.04004 19 7.38004 19 9.04004C19 9.28004 19.04 9.51004 19.09 9.74004L12.04 13.85C11.5 13.35 10.79 13.04 10 13.04C8.34 13.04 7 14.38 7 16.04C7 17.7 8.34 19.04 10 19.04C10.79 19.04 11.5 18.73 12.04 18.23L19.16 22.39C19.11 22.6 19.08 22.82 19.08 23.04C19.08 24.65 20.39 25.96 22 25.96C23.61 25.96 24.92 24.65 24.92 23.04C24.92 21.43 23.61 20.12 22 20.12Z"
          fill={color}
        />
      </svg>
    </span>
  );
};

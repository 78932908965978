import { Text, Icon, IconGlyph, IconSize } from "@qbit/react";
import {
  type CustomerGroupDtoWithDisplayName,
  formatShortDate,
  TransactionSource,
  CustomerGroupStatus,
} from "@quantium-enterprise/common-ui";
import { useDivision, useNumberFormat } from "@quantium-enterprise/hooks-ui";
import { CustomerGroupIcon } from "components-ui/src/icons";
import { transformLocationParameterSelection } from "components-ui/src/info-panel/InfoPanel";
import { getParameterSummary } from "components-ui/src/info-panel/info-panel-body/InfoPanelBody";
import React from "react";
import {
  TransactionSourceDisplayNames,
  TransactionSourceIcon,
} from "../icons/transaction-source-icon/TransactionSourceIcon";
import styles from "./CustomerGroupSummary.module.css";

type CustomerGroupSummaryProps = {
  group: CustomerGroupDtoWithDisplayName | undefined;
  title?: string;
};

export const CustomerGroupSummary = ({
  group,
  title,
}: CustomerGroupSummaryProps) => {
  const { currencyFormatter } = useNumberFormat();
  const { transactionSources: availableTransactionSources } = useDivision();

  const parameterGroups = group?.parameters
    ? transformLocationParameterSelection(group.parameters)
    : [];

  const sortedSegments =
    group?.segments.slice().sort((a, b) => a.ordinal - b.ordinal) ?? [];

  const thresholdSegments = sortedSegments.filter(
    (segment) =>
      segment.maximumValue !== undefined || segment.minimumValue !== undefined
  );

  return (
    <div className={styles.groupSummary}>
      <div className={styles.summarySection}>
        <div className={styles.sectionHeader}>
          <h6>
            <Text>Dataset</Text>
          </h6>
        </div>

        <div className={styles.sectionBody}>
          <span className={styles.dataTypeContent}>
            <TransactionSourceIcon
              availableTransactionSources={availableTransactionSources}
              transactionSource={TransactionSource.Customer}
            />
            <Text>
              {TransactionSourceDisplayNames[TransactionSource.Customer]}
            </Text>
          </span>
        </div>
      </div>
      {group && (
        <div className={styles.summarySection}>
          <div className={styles.sectionHeader}>
            <h6>{title ?? group.name}</h6>
          </div>
          <div className={styles.sectionBody}>
            <div>
              Template:
              <span className={styles.typeIcon}>
                <CustomerGroupIcon type={group.type} />
              </span>
              {group.typeDisplayName}
            </div>

            <Text>{`Created: ${formatShortDate(group.createDateUtc)}`}</Text>
            <Text>{`Updated: ${formatShortDate(group.updateDateUtc)}`}</Text>
            <Text>{`Last refreshed: ${formatShortDate(
              group.refreshDateUtc
            )}`}</Text>
          </div>
        </div>
      )}

      {!group && <div>No group selected.</div>}

      {group?.status !== CustomerGroupStatus.Invalid && (
        <>
          <div className={styles.summarySection}>
            <div className={styles.sectionHeader}>
              <h6>
                <Text>Customer count</Text>
                <Icon
                  className={styles.infoIcon}
                  glyph={IconGlyph.AlertsAndNotificationsInform}
                  size={IconSize.Small}
                  text="Customer count"
                />
              </h6>
            </div>
            <div className={styles.sectionBody}>
              {sortedSegments.map((segment) => (
                <Text
                  key={`${segment.customerGroupId}__${segment.ordinal}`}
                >{`${segment.name}: ${segment.count.toLocaleString()}`}</Text>
              ))}
            </div>
          </div>

          {thresholdSegments.length > 0 && (
            <div className={styles.summarySection}>
              <div className={styles.sectionHeader}>
                <h6>
                  <Text>Threshold value</Text>
                </h6>
              </div>
              <div className={styles.sectionBody}>
                {thresholdSegments.map((segment, index) => {
                  const previousSegment = thresholdSegments[index - 1];
                  const value =
                    segment.key === "1"
                      ? `${currencyFormatter(
                          segment.minimumValue
                        )} - ${currencyFormatter(segment.maximumValue)}`
                      : `${currencyFormatter(
                          segment.minimumValue
                        )} - ${currencyFormatter(
                          previousSegment.minimumValue
                        )}`;

                  return (
                    <Text
                      key={`${segment.customerGroupId}__${segment.ordinal}`}
                    >{`${segment.name}: ${value}`}</Text>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}

      {parameterGroups?.map((parameterGroup) => (
        <div className={styles.summarySection} key={parameterGroup.name}>
          <div className={styles.sectionHeader}>
            <h6>{parameterGroup.name}</h6>
          </div>
          <div className={styles.sectionBody}>
            {parameterGroup.parameterSelections.map((parameter) => (
              <React.Fragment key={parameter.name}>
                {getParameterSummary(parameter)}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

import { Checkbox, Tag, TagVariant, Toggle, ToggleSize } from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import { FeatureFlag } from "@quantium-enterprise/common-ui";
import { useFlags } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import styles from "./ProductHierarchyParameterTableHeader.module.scss";

type SelectedItemsToggleConfig = {
  count: number;
  show: boolean;
  toggleShow: () => void;
};

type HeaderProps = {
  handleToggleAllRowsSelected: (event: unknown) => void;
  isChecked: boolean;
  isIndeterminate: boolean;
  isVisible: boolean;
  selectedItemsToggleConfig?: SelectedItemsToggleConfig;
  title: string;
};

export const ProductHierarchyParameterTableHeader = ({
  isChecked,
  isIndeterminate,
  isVisible,
  handleToggleAllRowsSelected,
  title,
  selectedItemsToggleConfig,
}: HeaderProps) => {
  const featureFlags = useFlags();

  return (
    <div className={styles.productHierarchyParameterTableHeader}>
      <div className={styles.hierarchyCheckbox}>
        {isVisible && (
          <Checkbox
            assistiveLabel
            checked={isChecked}
            data-testid="tooltip-checkbox"
            indeterminate={isIndeterminate}
            label="Select / deselect all product hierarchy items"
            name={`checkbox-tooltip-${uniqueId()}`}
            onChange={handleToggleAllRowsSelected}
          />
        )}
        <span className={styles.headerText}>{title}</span>
      </div>
      {selectedItemsToggleConfig &&
        Boolean(
          featureFlags[FeatureFlag.ProductHierarchySelectedItemsToggle]
        ) && (
          <div className={styles.selectedItemsToggle}>
            <Toggle
              checked={selectedItemsToggleConfig.show}
              disabled={
                selectedItemsToggleConfig.count === 0 &&
                !selectedItemsToggleConfig.show
              }
              label="Show selected item(s)"
              onClick={() => selectedItemsToggleConfig.toggleShow()}
              size={ToggleSize.XSmall}
            />
            <Tag
              className={classNames(styles.selectedItemsCount, {
                [styles.selectedItemsCountDisabled]:
                  selectedItemsToggleConfig.count === 0,
                [styles.selectedItemsCountEnabled]:
                  selectedItemsToggleConfig.count,
              })}
              text={selectedItemsToggleConfig.count.toString()}
              variant={TagVariant.Lozenge}
            />
          </div>
        )}
    </div>
  );
};

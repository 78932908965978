import {
  Nav,
  NavFontWeight,
  NavVariant,
  NavButton,
  Spinner,
  SpinnerSize,
} from "@qbit/react";
import {
  ReportType,
  type HierarchyValue,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  getNumberFormat,
  useDivision,
  useFlags,
  useNumberFormat,
} from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { ContributionDriversChart } from "components-ui/src/charts/contribution-drivers-chart/ContributionDriversChart";
import { type HighchartsReactProps } from "components-ui/src/charts/highcharts-react/HighchartsReact";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportIcon } from "components-ui/src/icons";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { EMPTY_NODE_NUMBER } from "../../common/constants";
import {
  isTimePeriodParameter,
  isPlainTextContentParameter,
  isMinMaxRangeParameter,
} from "../../common/utils/local-parameters/LocalParametersUtils";
import { type RootState } from "../../store";
import { KeyDriverTreeFeatureFlags } from "../constants/key-driver-tree-feature-flags";
import {
  type ContributionBreakdownResponseDto,
  type ContributionMeasuresResponseDto,
} from "../models/ContributionChartDriverDto";
import { useGetKeyDriverTreeContributionDriversChartDataQuery } from "../services/key-driver-tree-contribution-drivers-chart-api-slice";
import {
  onActiveContributionIndexChange,
  onContributionChartDataReceived,
  onLegendToggle,
} from "../services/key-driver-tree-slice";
import {
  selectFocalItem,
  selectLocalParameterSelections,
  selectLocalParametersInitialised,
} from "../services/key-driver-tree-slice-selectors";
import {
  reshapeChartData,
  reshapedMeasuresData,
  reshapeTableData,
} from "../utils/getReshapedChartData";
import getUserSelections from "../utils/getUserSelections";
import styles from "./KeyDriverTreeReport.module.css";
import {
  csvBreakdownTransformation,
  csvMeasuresTransformation,
} from "./csvTransformation";

type KeyDriverTreeContributionDriversProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

type ContributionDriversTabProps = {
  onLegendChanged?: (legend: ReactNode) => void;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  tabName: string;
};
export const ContributionDriversTab = ({
  tabName,
  onOptionsChanged,
  onLegendChanged,
}: ContributionDriversTabProps) => {
  const { name: activeDivisionName } = useDivision();
  const dispatch = useDispatch();
  const {
    percentFormatter,
    currencyFormatter,
    metricFormatter,
    decimalFormatter,
  } = useNumberFormat();

  const focalItem = useSelector(selectFocalItem);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const localParameterSelections = useSelector(selectLocalParameterSelections);

  const { contributionData, hiddenSeries, reportId } = useSelector(
    (state: RootState) => ({
      activeContributionIndex: state.keyDriverTree.activeContributionIndex,
      contributionData: state.keyDriverTree.contributionChartData,
      hiddenSeries: state.keyDriverTree.contributionChartOptions.hiddenSeries,
      localParameterConfig: state.keyDriverTree.localParameterConfig,
      reportId: state.keyDriverTree.metaData.reportId,
    })
  );

  const chartRequestPayload = {
    focalItem: {
      itemCode: focalItem.code,
      name: focalItem.name,
      nodeNumber: focalItem.nodeNumber,
      shortName: focalItem.shortName,
    } as HierarchyValue,
    localSelectedValues: getUserSelections(localParameterSelections),
    reportId,
    tabName,
  };

  const isDataLoaded =
    localParametersInitialised &&
    reportId &&
    focalItem.nodeNumber !== EMPTY_NODE_NUMBER;
  const {
    data: chartDataQryResponse,
    isFetching: isChartDataFetching,
    refetch: refetchChartData,
  } = useGetKeyDriverTreeContributionDriversChartDataQuery(
    {
      divisionName: activeDivisionName,
      requestPayload: chartRequestPayload,
    },
    {
      skip: !isDataLoaded,
    }
  );

  // when tab changes, call for new chart data to corresponding tab
  useEffect(() => {
    if (chartDataQryResponse) {
      dispatch(onContributionChartDataReceived(chartDataQryResponse));
    }
  }, [dispatch, chartDataQryResponse, tabName]);

  // when parameter selection, call for new chart data, re-render chart with new data and yAxis label
  useEffect(() => {
    refetchChartData();
  }, [refetchChartData, localParameterSelections]);

  const toggleSeriesVisibility = useCallback(
    (index: number) => {
      const newHiddenSeries = [...hiddenSeries];
      const targetIndex = newHiddenSeries.indexOf(index);
      if (targetIndex === -1) {
        newHiddenSeries.push(index);
      } else {
        newHiddenSeries.splice(targetIndex, 1);
      }

      dispatch(onLegendToggle(newHiddenSeries));
    },
    [hiddenSeries, dispatch]
  );
  const noop = useCallback(() => undefined, []);

  const noneContributionMeasuresData =
    contributionData as ContributionMeasuresResponseDto;
  const noneChartData = useMemo(
    () =>
      noneContributionMeasuresData.data
        ? reshapedMeasuresData(noneContributionMeasuresData.data)
        : undefined,
    [noneContributionMeasuresData.data]
  );

  const notNoneContributionMeasuresData =
    contributionData as ContributionBreakdownResponseDto;
  const notNoneChartData = useMemo(
    () =>
      notNoneContributionMeasuresData.chartData
        ? reshapeChartData(
            notNoneContributionMeasuresData.chartData,
            metricFormatter
          )
        : undefined,
    [metricFormatter, notNoneContributionMeasuresData.chartData]
  );

  const noHiddenSeriesValue = useMemo(() => [], []);
  const percentFormatterCallback = useCallback(
    (value?: number | string | null) => percentFormatter(value, true),
    [percentFormatter]
  );

  const notNoneTableData = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- this is undefined before the data is loaded
      notNoneContributionMeasuresData.tableData
        ? reshapeTableData(
            notNoneContributionMeasuresData.tableData,
            metricFormatter
          )
        : undefined,
    [metricFormatter, notNoneContributionMeasuresData.tableData]
  );

  if (isChartDataFetching || !isDataLoaded) {
    return (
      <div className={styles.chartContainer}>
        <Spinner
          data-testid="data-table-loading-icon"
          size={SpinnerSize.Large}
        />
      </div>
    );
  }

  if (tabName === "Summary" && noneChartData) {
    const chartDataFormatter =
      notNoneContributionMeasuresData.categories[0] === "Volume"
        ? decimalFormatter
        : currencyFormatter;
    return (
      <ContributionDriversChart
        categories={notNoneContributionMeasuresData.categories}
        chartData={noneChartData}
        chartDataFormatter={chartDataFormatter}
        hiddenSeries={noHiddenSeriesValue}
        onLegendAreaChanged={onLegendChanged}
        onOptionsChanged={onOptionsChanged}
        percentFormatter={percentFormatterCallback}
        tableData={undefined}
        toggleSeriesVisibility={noop}
        tooltipLabel="Contribution breakdown"
        yAxisLabel="Contribution"
      />
    );
  }

  if (
    tabName !== "Summary" &&
    notNoneContributionMeasuresData.chartData &&
    notNoneContributionMeasuresData.chartData.length > 0 &&
    notNoneChartData &&
    notNoneTableData
  ) {
    const chartDataFormatter =
      notNoneContributionMeasuresData.chartData[0].label === "Volume"
        ? decimalFormatter
        : currencyFormatter;
    return (
      <ContributionDriversChart
        categories={notNoneContributionMeasuresData.categories}
        chartData={notNoneChartData}
        chartDataFormatter={chartDataFormatter}
        hiddenSeries={hiddenSeries}
        onLegendAreaChanged={onLegendChanged}
        onOptionsChanged={onOptionsChanged}
        percentFormatter={percentFormatterCallback}
        tableData={notNoneTableData}
        toggleSeriesVisibility={toggleSeriesVisibility}
        tooltipLabel={notNoneContributionMeasuresData.label}
        yAxisLabel={notNoneContributionMeasuresData.label}
      />
    );
  }

  return (
    <div className={styles.chartContainer}>
      <span>No data to display</span>
    </div>
  );
};

export const KeyDriverTreeContributionDrivers = ({
  infoPanelSummary,
}: KeyDriverTreeContributionDriversProps) => {
  const dispatch = useDispatch();
  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[KeyDriverTreeFeatureFlags.ReportExport] ?? false;

  const focalItem = useSelector(selectFocalItem);
  const localParameterSelections = useSelector(selectLocalParameterSelections);

  const { activeContributionIndex, contributionData, localParameterConfig } =
    useSelector((state: RootState) => ({
      activeContributionIndex: state.keyDriverTree.activeContributionIndex,
      contributionData: state.keyDriverTree.contributionChartData,
      localParameterConfig: state.keyDriverTree.localParameterConfig,
    }));

  const chartContainerRef = useRef<HTMLElement>();
  const exportFilename = useMemo(
    () =>
      cleanFilename(
        `Contribution_drivers_Chart_${localParameterSelections.TimePeriodLength}_${localParameterSelections.LocationHierarchy.name}`
      ),
    [localParameterSelections]
  );
  const parameterSummary = useMemo(
    () => [
      {
        name: "Focal item",
        value: `(${hierarchyLevelDisplayLabel(focalItem.shortName)}) ${
          focalItem.name
        }`,
      },
      { name: "Time", value: localParameterSelections.Time },
      { name: "Key driver", value: localParameterSelections.KeyDriver.label },
      { name: "Channel", value: localParameterSelections.Channel.label },
      { name: "Promotion", value: localParameterSelections.Promotion.label },
      {
        name: "Segmentation",
        value: localParameterSelections.Segmentation.label,
      },
      {
        name: "Customer segment",
        value: localParameterSelections.Segment.label,
      },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(
          localParameterSelections.LocationHierarchy.shortName
        )}) ${localParameterSelections.LocationHierarchy.name}`,
      },
    ],
    [localParameterSelections, focalItem]
  );

  const tabNames = ["Segmentation", "Channel", "Promotion"];
  const supportedTabNames = localParameterConfig
    .filter(
      (a) =>
        tabNames.includes(a.id) &&
        !isTimePeriodParameter(a) &&
        !isPlainTextContentParameter(a) &&
        !isMinMaxRangeParameter(a) &&
        a.selections.length > 1
    )
    .sort((a, b) => tabNames.indexOf(a.id) - tabNames.indexOf(b.id))
    .map((tab) => tab.name)
    .concat(["Summary"]);

  const chartMeasuresCsvTransformationCallback = useCallback(
    () =>
      csvMeasuresTransformation(
        contributionData as ContributionMeasuresResponseDto,
        currencySymbol
      ),
    [contributionData, currencySymbol]
  );

  const chartBreakdownCsvTransformationCallback = useCallback(
    () =>
      csvBreakdownTransformation(
        contributionData as ContributionBreakdownResponseDto,
        currencySymbol,
        supportedTabNames[activeContributionIndex]
      ),
    [
      contributionData,
      currencySymbol,
      activeContributionIndex,
      supportedTabNames,
    ]
  );

  let reportletContent = (
    <div className={styles.chartContainer}>
      <Spinner data-testid="data-table-loading-icon" size={SpinnerSize.Large} />
    </div>
  );

  const [currentOptions, setCurrentOptions] = useState<HighchartsReactProps>();
  const [currentLegend, setCurrentLegend] = useState<ReactNode>();
  const reportName = useSelector(
    (state: RootState) => state.keyDriverTree.metaData.reportName
  );

  reportletContent = (
    <>
      <div
        className={classNames(styles.chartOptionsNavContainer, {
          [styles.tabsHidden]: supportedTabNames.length <= 1,
        })}
      >
        <Nav
          activeIndex={activeContributionIndex}
          fontWeight={NavFontWeight.Regular}
          variant={NavVariant.Tab}
        >
          {supportedTabNames.map((tabName, index) => (
            <NavButton
              key={`${tabName + index}`}
              onClick={() => dispatch(onActiveContributionIndexChange(index))}
            >
              {tabName}
            </NavButton>
          ))}
        </Nav>
        <ChartOptions
          downloadWizardOptions={
            currentOptions
              ? {
                  chartOptions: currentOptions,
                  reportIcon: <ReportIcon type={ReportType.KeyDriverTree} />,
                  chartTitle: `Contribution drivers - ${reportName}`,
                  reportTitle: reportName,
                  customLegend: currentLegend,
                }
              : undefined
          }
          filename={exportFilename}
          getCSVData={
            supportedTabNames[activeContributionIndex] === "Summary"
              ? chartMeasuresCsvTransformationCallback
              : chartBreakdownCsvTransformationCallback
          }
          getElementToExport={() => chartContainerRef.current}
          isFeatureEnabled={isExportEnabled}
          localParameters={parameterSummary}
          reportParameters={infoPanelSummary}
        />
      </div>
      <ChartFooterWrapper
        height="600px"
        parameters={parameterSummary}
        ref={chartContainerRef}
      >
        <ContributionDriversTab
          onLegendChanged={setCurrentLegend}
          onOptionsChanged={setCurrentOptions}
          tabName={supportedTabNames[activeContributionIndex]}
        />
      </ChartFooterWrapper>
    </>
  );

  return (
    <Accordion
      subtitle="Understand the biggest drivers of growth or decline for your focal item."
      title="Contribution drivers"
    >
      <ErrorBoundary>{reportletContent}</ErrorBoundary>
    </Accordion>
  );
};

import {
  FormBlock,
  FormBlockType,
  Input,
  RadioButton,
  Typeahead,
} from "@qbit/react";
import { type GroupedOption, type Option } from "@qbit/react/dist/typeahead";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import CollapsiblePanel from "./CollapsiblePanel";
import styles from "./CollapsiblePanel.module.scss";
import {
  getDefaultItem,
  isTypeaheadDisabled,
  isValidSelectedLevel,
  levelHasValidOptions,
  findOption,
  findSelectedLevelIndex,
} from "./utils";

export type HierarchySelectLevel = {
  name: string;
  options: HierarchySelectGroupedOption[];
};

export type HierarchySelectOption = Option & {
  isDefault: boolean;
};

export type HierarchySelectGroupedOption = Omit<GroupedOption, "options"> & {
  options: HierarchySelectOption[];
};

export type HierarchySelectFilterProps = {
  isCollapsed: boolean;
  levels: HierarchySelectLevel[];
  onSelection: (item: string, level?: string) => void;
  selectedItem: string | undefined;
  title: string;
  toggleCollapsed: () => void;
};

export const HierarchySelectFilter = ({
  title,
  isCollapsed,
  levels,
  toggleCollapsed,
  selectedItem = "",
  onSelection,
}: HierarchySelectFilterProps) => {
  // which top level radio button to select
  const [selectedLevel, setSelectedLevel] = useState(() =>
    Math.max(0, findSelectedLevelIndex(levels, selectedItem))
  );
  // which typeahead value to set
  // const [selectedValue, setSelectedValue] = useState(selectedItem);
  // whether the typeahead has more than one valid option
  const [typeaheadDisabled, setTypeaheadDisabled] = useState(false);

  // when the top level options change, re-render
  useEffect(() => {
    // const defaultValue = getDefaultItem(levels, selectedLevel);
    // setSelectedValue(defaultValue?.value.toString() ?? "");
    setTypeaheadDisabled(isTypeaheadDisabled(levels, selectedLevel));
  }, [levels, selectedLevel, selectedItem]);

  const selectedItemFromStringValue = useMemo(() => {
    const option = findOption(levels, selectedItem);

    return option ?? getDefaultItem(levels, selectedLevel);
  }, [levels, selectedItem, selectedLevel]);

  // const initialTypeaheadOption = useMemo(
  //   () => findOption(levels, selectedItem),
  //   [levels, selectedItem]
  // );

  // when a new top level is set
  const setSelectedLevelWithCallback = (index: number) => {
    setSelectedLevel(index);
    onSelection(
      getDefaultItem(levels, index)?.value.toString() ?? "",
      levels[index]?.name
    );
  };

  // when a new option is set, call onSelection
  const setValueWithCallback = (option: Option | null) => {
    const defaultValue = option?.value.toString() ?? "";
    // setSelectedValue(defaultValue);
    onSelection(defaultValue);
  };

  const content = (
    <FormBlock>
      <Input>
        {levels.map((level, index) => (
          <FormBlock
            blockType={FormBlockType.Radio}
            key={`radio-${level.name}`}
          >
            <Input>
              <RadioButton
                checked={selectedLevel === index}
                className={styles.radioButton}
                data-testid="hierarchy-select-radio-button"
                id={`radio-button-${level.name}`}
                key={level.name}
                label={level.name}
                name={`${title}-radio-form`}
                onChange={() => {
                  setSelectedLevelWithCallback(index);
                }}
              />
            </Input>
          </FormBlock>
        ))}
      </Input>
      <p className={styles.title} data-testid="hierarchy-select-level-label">
        {levels[selectedLevel].name}
      </p>
      <Input data-testid="hierarchy-select-typeahead">
        <Typeahead
          alwaysShowAllOptions
          disabled={typeaheadDisabled}
          id={`hierarchy-select-${title}`}
          initialInputValue={selectedItem}
          onChange={(newItem) => setValueWithCallback(newItem)}
          // onSelect={(newItem) => setValueWithCallback(newItem)}
          options={
            isValidSelectedLevel(levels, selectedLevel)
              ? levels[selectedLevel].options
              : []
          }
          placeholder={
            levelHasValidOptions(levels, selectedLevel)
              ? "Make a selection..."
              : "No selections available"
          }
          selectedItem={selectedItemFromStringValue}
        />
      </Input>
    </FormBlock>
  );

  const summary = (
    <div className={classNames(styles.collapsedSummary, styles.uppercase)}>
      {selectedItemFromStringValue?.label ?? "No selection"}
    </div>
  );

  return (
    <CollapsiblePanel
      isCollapsed={isCollapsed}
      isDisabled={false}
      title={title}
      toggleCollapsed={toggleCollapsed}
    >
      {{
        content,
        summary,
      }}
    </CollapsiblePanel>
  );
};

import { type PayloadAction } from "@reduxjs/toolkit";
import { type RangePerformanceParametersDto } from "../models/RangePerformanceParametersDto";
import { type SelectedParameters } from "../models/SelectedParameters";
import { type RangePerformanceState } from "./range-performance-slice";

const hasSelectedParameters = (selectedParameters: SelectedParameters) =>
  selectedParameters.focusPeriod !== undefined &&
  selectedParameters.comparisonPeriod !== undefined &&
  selectedParameters.location !== undefined &&
  selectedParameters.metrics !== undefined;

export const getParametersMatchAction = (
  state: RangePerformanceState,
  action: PayloadAction<RangePerformanceParametersDto>
) => {
  state.parameters.parameterOptions = action.payload;

  // selected parameters could be loaded from the browser localStorage in the initial state
  // if they haven't been loaded, then we need to set them to the default values
  if (!hasSelectedParameters(state.parameters.selectedParameters)) {
    state.parameters.selectedParameters = {
      comparisonPeriod: action.payload.comparisonPeriods.find(
        (x) => x.isDefault
      ),
      focusPeriod: action.payload.focusPeriods.find((x) => x.isDefault),
      location: action.payload.locations[0],
      metrics: action.payload.metrics.filter((x) => x.isDefault),
    };
  }

  state.availableHierarchies = {
    structureOptions: action.payload.tableStructureMetadata.structureOptions,
    hierarchyMetadata: action.payload.tableStructureMetadata.hierarchyMetadata,
  };
};

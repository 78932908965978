/* eslint-disable @typescript-eslint/no-unnecessary-condition -- The highcharts.d.ts file is wrong. Some fields are nullable. */
import { NumberSentiment, Number as NumberElement } from "@qbit/react";
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { type Point, type TooltipFormatterContextObject } from "highcharts";

const DEFAULT_VALUE_NUMBER: number[] = [];
const DEFAULT_VALUE_STRING: string[] = [];

const randomIntFromInterval = (min: number, max: number) =>
  // min and max included
  Math.floor(Math.random() * (max - min + 1) + min);

const randomPercent = () => {
  const sign = Math.random() > 0.5 ? -1 : 1;
  return Math.random() * sign;
};

export const makeData = (weeks: number, type: string) => {
  const data = DEFAULT_VALUE_NUMBER;
  for (let weekNumber = 1; weekNumber <= weeks; weekNumber++) {
    const weekData = type.includes("change")
      ? randomPercent()
      : randomIntFromInterval(0, 20_000_000);
    data.push(weekData);
  }

  return data;
};

export const makeDates = (weeks: number) => {
  const dates = DEFAULT_VALUE_STRING;
  for (let weekNumber = 1; weekNumber <= weeks; weekNumber++) {
    const now = new Date();
    now.setDate(now.getDate() - weekNumber * 7);
    const dateString = now.toLocaleDateString("en-GB");
    dates.push(dateString);
  }

  return dates;
};

export const CustomValueCell = ({
  value,
  alwaysDisplaySign,
  hasSentiment = false,
  formatter,
}: {
  alwaysDisplaySign?: boolean;
  formatter: FormatterFunction;
  hasSentiment?: boolean;
  value?: number | null;
}) => {
  let sentiment = NumberSentiment.None;
  let formattedValue = "-";
  if (value) {
    if (hasSentiment) {
      if (value > 0) {
        sentiment = NumberSentiment.Good;
      } else if (value < 0) {
        sentiment = NumberSentiment.Bad;
      }
    }

    formattedValue = formatter(value, alwaysDisplaySign ?? hasSentiment);
  }

  return <NumberElement sentiment={sentiment}>{formattedValue}</NumberElement>;
};

/**
 * Get point from context object, prioritising the point (singular) object over the points (plural)
 * This priority is based on highcharts modifying point data as the chart is updated which can cause
 * problems when pinning tooltips with HighChartsWithPinnableTooltips.tsx.
 */
export const getPoint: <T>(
  ttData: TooltipFormatterContextObject,
  getProperty?: (point: Point) => T
) => Point | T | undefined = (ttData, getProperty) => {
  if (getProperty) {
    return (
      (ttData.point && getProperty(ttData.point)) ??
      (ttData.points?.[0]?.point && getProperty(ttData.points[0].point))
    );
  }

  return ttData.point ?? ttData.points?.[0]?.point;
};

import tokens from "@qbit/core/dist/tokens.json";
import { Text, Tooltip, TooltipPlacement, TooltipVariant } from "@qbit/react";
import {
  AppContext,
  getUserColour,
  getUserInitials,
  type SharedUserDto,
} from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { useContext } from "react";
import { ShareHorizontalMenuIcon } from "../assets/icons/ShareHorizontalMenuIcon";
import { CircleIcon, CircleIconSize } from "../icons";
import styles from "./SharedUserIconDisplay.module.css";

const MAX_DISPLAYED_USERS = 3;

export type SharedUserIconDisplayProps = {
  className?: string;
  owner?: SharedUserDto | undefined;
  users: SharedUserDto[];
};

export const SharedUserIconDisplay = ({
  className,
  owner,
  users,
}: SharedUserIconDisplayProps) => {
  const { userState } = useContext(AppContext);
  return (
    <div className={classNames(styles.iconDisplayContainer, className)}>
      {owner && (
        <Tooltip
          className={styles.tooltip}
          placement={TooltipPlacement.TopCentre}
          trigger={
            <div className={styles.iconDisplay}>
              <span key={`user-icon-${owner.salesforceUserId}`}>
                <CircleIcon
                  color={getUserColour(owner.salesforceUserId)}
                  content={getUserInitials(owner.firstName, owner.lastName)}
                  size={CircleIconSize.Small}
                />
              </span>
            </div>
          }
          variant={TooltipVariant.ArrowDark}
        >
          <Text className={styles.tooltipText}>{`Owner: ${owner.firstName} ${
            owner.lastName
          }${
            userState.currentUser?.salesForceId === owner.salesforceUserId
              ? " (you)"
              : ""
          }`}</Text>
        </Tooltip>
      )}
      {owner && users.length > 0 && (
        <div className={styles.verticalSeparator} />
      )}
      {users.length > 0 && (
        <Tooltip
          className={styles.tooltip}
          placement={TooltipPlacement.TopCentre}
          trigger={
            <div className={classNames(styles.iconDisplay, className)}>
              {users.slice(0, MAX_DISPLAYED_USERS).map((user) => (
                <span
                  className={styles.iconWrapper}
                  key={`user-icon-${user.salesforceUserId}`}
                >
                  <CircleIcon
                    color={getUserColour(user.salesforceUserId)}
                    content={getUserInitials(user.firstName, user.lastName)}
                    size={CircleIconSize.Small}
                  />
                </span>
              ))}
              {users.length > MAX_DISPLAYED_USERS && (
                <span key="user-icon-more">
                  <CircleIcon
                    color={tokens.colour["shade-2"]}
                    content={
                      <div className={styles.horizontalMenuIcon}>
                        <ShareHorizontalMenuIcon />
                      </div>
                    }
                    size={CircleIconSize.Small}
                  />
                </span>
              )}
            </div>
          }
          variant={TooltipVariant.ArrowDark}
        >
          <Text
            className={styles.tooltipText}
          >{`Shared with ${users.length} users`}</Text>
        </Tooltip>
      )}
    </div>
  );
};

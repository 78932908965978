import { Text } from "@qbit/react";
import classNames from "classnames";
import styles from "./Cell.module.css";

export type TextCellProps = {
  className?: string;
  style?: React.CSSProperties;
  value?: string | null;
};

export const TextCell = ({ value, className, style }: TextCellProps) => (
  <div
    className={classNames([styles.rowValue, styles.cell, className])}
    style={style}
  >
    <div className={classNames(styles.cellText, styles.alignLeft)}>
      <Text>{value ?? ""}</Text>
    </div>
  </div>
);

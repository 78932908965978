import {
  Button,
  ButtonHeight,
  ButtonVariant,
  ButtonWidth,
  Icon,
  IconGlyph,
  Tooltip,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import classNames from "classnames";
import { ReportIcon } from "components-ui/src/icons";
import styles from "./ReportingTab.module.css";

export type AdvancedReportingTabProps = {
  isActive: boolean;
  name: string;
  onRemoveTab: () => void;
  onSetActiveTab: () => void;
  type: string;
};

export const AdvancedReportingTab = ({
  isActive,
  name,
  onSetActiveTab,
  onRemoveTab,
  type,
}: AdvancedReportingTabProps) => {
  const reportTab = (
    <div
      className={classNames(styles.reportTab, {
        [styles.reportTabActive]: isActive,
      })}
      data-testid="advanced-reporting-tab"
    >
      <div className={styles.reportTabLeftContent}>
        <Button
          data-testid="advanced-reporting-tab-active-button"
          onClick={onSetActiveTab}
        >
          <span
            className={classNames(styles.icon, styles.advancedReportingIcon)}
          >
            <ReportIcon coloured={isActive} type={type} />
          </span>
          <div className={styles.text}>{name}</div>
        </Button>
      </div>
      <div className={styles.reportTabRightContent}>
        <Button
          className={styles.close}
          data-testid="advanced-reporting-tab-close-button"
          height={ButtonHeight.XSmall}
          onClick={(event) => {
            event.stopPropagation();
            onRemoveTab();
          }}
          variant={ButtonVariant.Stealth}
          width={ButtonWidth.Fit}
        >
          <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
        </Button>
      </div>
    </div>
  );

  const reportTabWithTooltip = (
    <Tooltip
      data-testid="advanced-reporting-tab-tooltip"
      id={uniqueId()}
      spaceInside={TooltipSpaceInside.Small}
      trigger={reportTab}
      variant={TooltipVariant.ArrowLight}
    >
      <div className={styles.tooltip}>{name}</div>
    </Tooltip>
  );

  return isActive ? reportTab : reportTabWithTooltip;
};

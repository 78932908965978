import {
  Button,
  ButtonHeight,
  ButtonVariant,
  FormInputHeight,
  Nav,
  NavButton,
  NavFontWeight,
  NavSize,
  NavVariant,
  TextInput,
} from "@qbit/react";
import { HierarchyType } from "@quantium-enterprise/common-ui";
import { type LocalHierarchyNodeSelection } from "@quantium-enterprise/common-ui";
import { useMemo, useRef, useState } from "react";
import { LocationSelectGrid } from "../../../hierarchy-select-grid/LocationSelectGrid";
import CollapsiblePanel from "../../CollapsiblePanel";
import LocationHierarchyFilterModal from "../common/LocationHierarchyFilterModal";
import { buildNestedSelections } from "../common/utils";
import styles from "./MultiLocationHierarchyFilter.module.css";
import MultiLocationHierarchyFilterSummary from "./summary/MultiLocationHierarchyFilterSummary";

export type MultiLocationHierarchyFilterProps = {
  allSelections: LocalHierarchyNodeSelection[];
  isCollapsed: boolean;
  isDisabled?: boolean;
  onSubmit: (selections: LocalHierarchyNodeSelection[]) => void;
  parameterTrackingService: Function;
  selectedRows: LocalHierarchyNodeSelection[];
  title: string;
  toggleCollapsed: () => void;
};

export const MultiLocationHierarchyFilter = ({
  allSelections,
  isCollapsed,
  isDisabled,
  selectedRows,
  onSubmit,
  title,
  toggleCollapsed,
  parameterTrackingService,
}: MultiLocationHierarchyFilterProps) => {
  // Row expansion state
  const [expandedRows, setExpandedRows] = useState<
    LocalHierarchyNodeSelection[]
  >(allSelections.slice());

  const onExpandedBridge = (items: LocalHierarchyNodeSelection[]) => {
    setExpandedRows(items);
  };

  // Search handling
  const [searchString, setSearchString] = useState("");

  // Selection handling
  const [localSelectedRows, setLocalSelectedRows] =
    useState<LocalHierarchyNodeSelection[]>(selectedRows);

  const handleRowSelection = (item: LocalHierarchyNodeSelection) => {
    // Handle deselect
    if (
      localSelectedRows.some(
        (selection) => selection.nodeNumber === item.nodeNumber
      )
    ) {
      const filteredSelections = localSelectedRows.filter(
        (selection) => selection.nodeNumber !== item.nodeNumber
      );
      if (filteredSelections.length > 0) {
        setLocalSelectedRows(filteredSelections);
      }

      parameterTrackingService(localSelectedRows.slice());

      return;
    }

    // Handle select
    setLocalSelectedRows([...localSelectedRows, item]);
  };

  const nestedSelections = useMemo(
    () => buildNestedSelections(allSelections),
    [allSelections]
  );

  const gridData = {
    type: HierarchyType.Location,
    items: searchString
      ? allSelections.filter((sel) =>
          sel.name
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : nestedSelections,
  };

  // Modal controls
  const modalRef = useRef();
  const [isModalShown, setIsModalShown] = useState(false);

  const headerContent = (
    <div className={styles.headerContent}>
      <Nav
        activeIndex={0}
        fontWeight={NavFontWeight.Regular}
        size={NavSize.Medium}
        variant={NavVariant.Tab}
      >
        <NavButton onClick={() => undefined}>Location</NavButton>
      </Nav>
    </div>
  );

  const trigger = useMemo(
    () => (
      <Button
        height={ButtonHeight.XSmall}
        onClick={() => {
          setIsModalShown(true);
        }}
        ref={modalRef}
        variant={ButtonVariant.Stealth}
      >
        <span>Change Location</span>
      </Button>
    ),
    []
  );

  const handleOnModalClose = (showModal: boolean) => {
    setIsModalShown(showModal);

    // Reset and remove any temporary states
    setLocalSelectedRows(selectedRows);
    setSearchString("");
  };

  return (
    <>
      <CollapsiblePanel
        isCollapsed={isCollapsed || isDisabled}
        isDisabled={isDisabled}
        title={title}
        toggleCollapsed={toggleCollapsed}
      >
        {{
          content: (
            <>
              <MultiLocationHierarchyFilterSummary
                allSelections={allSelections}
                isCollapsed={isCollapsed}
                selections={selectedRows}
              />
              {trigger}
            </>
          ),
          summary: (
            <MultiLocationHierarchyFilterSummary
              allSelections={allSelections}
              isCollapsed={isCollapsed}
              selections={selectedRows}
            />
          ),
        }}
      </CollapsiblePanel>
      <LocationHierarchyFilterModal
        header={headerContent}
        isShown={isModalShown}
        onClose={handleOnModalClose}
        triggerRef={modalRef}
      >
        <>
          <div>
            <TextInput
              className={styles.searchInput}
              height={FormInputHeight.XSmall}
              id="Search Location"
              onChange={(event) => {
                setSearchString(event.target.value);
              }}
              placeholder="Search Locations..."
            />
          </div>
          <div className={styles.locationGridContainer}>
            <LocationSelectGrid
              data={gridData}
              expandedRows={expandedRows}
              onExpandedChange={onExpandedBridge}
              onRowSelection={handleRowSelection}
              // Without slice() when we use the search bar the top result is always selected
              selectedRows={localSelectedRows.slice()}
              title="Location"
            />
          </div>
          <Button
            className={styles.saveChangesButton}
            height={ButtonHeight.XSmall}
            onClick={() => {
              onSubmit(
                localSelectedRows.sort(
                  (v1, v2) => v1.nodeNumber - v2.nodeNumber
                )
              );
              setIsModalShown(false);
              setSearchString("");
            }}
            text="Save changes"
            variant={ButtonVariant.Primary}
          />
        </>
      </LocationHierarchyFilterModal>
    </>
  );
};

import tokens from "@qbit/core/dist/tokens.json";
import { type UserDto, type SharedUserDto } from "../models";
import { getSeededNumberGenerator } from "./seeded-number-generator";

const UserColourList = [
  tokens.colour["brand-black-palette"]["200"],
  tokens.colour["brand-black-palette"].A200,
  tokens.colour["brand-blue-palette"]["200"],
  tokens.colour["brand-blue-palette"].A200,
  tokens.colour["brand-cyan-palette"]["200"],
  tokens.colour["brand-cyan-palette"].A200,
  tokens.colour["brand-turquoise-palette"]["200"],
  tokens.colour["brand-turquoise-palette"].A200,
  tokens.colour["brand-green-palette"]["200"],
  tokens.colour["brand-green-palette"].A200,
  tokens.colour["brand-yellow-palette"]["200"],
  tokens.colour["brand-yellow-palette"].A400,
  tokens.colour["brand-orange-palette"]["200"],
  tokens.colour["brand-orange-palette"].A200,
  tokens.colour["brand-coral-palette"]["200"],
  tokens.colour["brand-coral-palette"].A200,
  tokens.colour["brand-burgundy-palette"]["200"],
  tokens.colour["brand-burgundy-palette"].A200,
  tokens.colour["brand-violet-palette"]["200"],
  tokens.colour["brand-violet-palette"].A200,
];

export const getUserColour = (salesforceUserId: string): string => {
  const index = Math.floor(
    getSeededNumberGenerator(salesforceUserId)() * UserColourList.length
  );

  return UserColourList[index];
};

export const getUserInitials = (firstName: string, lastName: string): string =>
  `${firstName.length > 0 ? firstName.charAt(0).toLocaleUpperCase() : ""}${
    lastName.length > 0 ? lastName.charAt(0).toLocaleUpperCase() : ""
  }`;

export const convertUserDtoToSharedUserDto = (
  user: UserDto | undefined
): SharedUserDto => ({
  emailAddress: user?.email ?? "",
  firstName: user?.firstName ?? "",
  lastName: user?.lastName ?? "",
  salesforceUserId: user?.salesForceId ?? "",
});

import { uniqueId } from "@qbit/react/dist/common";
import {
  isBandSelectionDto,
  type ParameterDto,
} from "@quantium-enterprise/common-ui";
import { createSelector } from "@reduxjs/toolkit";
import { type ParameterState } from "../../states/ParameterState";
import { type RootState } from "../../store";
import { isBetweenMinMax } from "../parameter-validation/ValidationState";

export enum BandError {
  InvalidNumber = "Enter a valid number between 0 and 100",
  Overlap = "Segments range must not overlap",
}

export type BandSelection = {
  errors: BandError[];
  id: string;
  maximum: number;
  minimum: number;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface BandsParameterState extends ParameterState {
  bands: BandSelection[];
}

export const isBandsParameterState = (
  state: ParameterState | undefined
): state is BandsParameterState =>
  state !== undefined && Object.hasOwn(state, "bands");

export const validateBands = (bands: BandSelection[]) =>
  bands.map((band, index) => {
    const errors: BandError[] = [];

    if (
      band.minimum < 0 ||
      band.maximum < 0 ||
      band.minimum > 100 ||
      band.maximum > 100
    ) {
      errors.push(BandError.InvalidNumber);
    }

    const previousBand = index === 0 ? undefined : bands[index - 1];
    if (
      (previousBand && band.minimum < previousBand.maximum) ||
      band.minimum > band.maximum
    ) {
      errors.push(BandError.Overlap);
    }

    band.errors = errors;
    return band;
  });

export const newBandsParameterState = (
  parameterConfig: ParameterDto,
  parameterGroup: string
): BandsParameterState => {
  const existingBands = parameterConfig.selections
    ? (parameterConfig.selections
        .map((selection) => {
          if (isBandSelectionDto(selection)) {
            return {
              id: uniqueId(),
              minimum: selection.minimum * 100,
              maximum: selection.maximum * 100,
              errors: [],
            } as BandSelection;
          }

          return undefined;
        })
        .filter(Boolean) as BandSelection[])
    : [];

  const bands =
    existingBands.length > 0
      ? validateBands(existingBands)
      : [
          {
            id: uniqueId(),
            minimum: 0,
            maximum: 10,
            errors: [],
          },
        ];

  return {
    isValid: isBetweenMinMax(bands.length, parameterConfig),
    parameterConfig,
    parameterGroup,
    bands,
  };
};

export const getBandsParameterState = (parameterId: string) =>
  createSelector(
    (state: RootState) => state.reportParameter.parameters[parameterId],
    (state) => state as BandsParameterState
  );

export const handleBandsParameterSelections = (
  parameterState: ParameterState,
  bands: BandSelection[]
): ParameterState => {
  if (isBandsParameterState(parameterState)) {
    const validatedBands = validateBands(bands);
    const isSelectionCountValid = isBetweenMinMax(
      validatedBands.length,
      parameterState.parameterConfig
    );
    const isSelectionsValid = validatedBands.every(
      (band) => band.errors.length === 0
    );
    return {
      ...parameterState,
      isValid: isSelectionCountValid && isSelectionsValid,
      bands: validatedBands,
    } as BandsParameterState;
  }

  return { ...parameterState };
};

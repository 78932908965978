import { Spinner } from "@qbit/react";
import {
  type HierarchyGroupRuleWithIdAndName,
  formatHierarchyName,
  HierarchyGroupRuleOperator,
  type HierarchyType,
  type SearchRequestDto,
  useSearchCountQuery,
  useSearchQuery,
  HierarchyItemType,
} from "@quantium-enterprise/common-ui";
import {
  useDebounce,
  useDivision,
  useScrollIntoViewport,
} from "@quantium-enterprise/hooks-ui";
import { type Getter, type ColumnDef, type Row } from "@tanstack/react-table";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import {
  type Hierarchy,
  type HierarchyGridItem,
} from "components-ui/src/hierarchy-select-grid/models/hierarchy";
import { EmptyGroup } from "components-ui/src/search/EmptyGroup";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NULL_SHORT_NAME } from "../constants";
import styles from "./DynamicGroupLeafsTable.module.css";

const DEBOUNCE_TIME_MS = 1_500;
const SEARCH_PAGE_SIZE = 100;

const TableCell = ({
  name,
  shortNameString,
}: {
  name: string;
  shortNameString: string;
}) => (
  <div className={styles.leafCell}>
    <div className={styles.leafIcon}>
      <HierarchyLevelIcon
        shortName={shortNameString}
        type={HierarchyItemType.Leaf}
      />
    </div>
    <span>{name}</span>
  </div>
);

export type DynamicGroupLeafsTableProps = {
  hierarchyType: HierarchyType;
  leafShortName: string;
  rules: HierarchyGroupRuleWithIdAndName[];
  setIsLeafItemsTableLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setLeafItemsCount: React.Dispatch<React.SetStateAction<number>>;
};

export const DynamicGroupLeafsTable = ({
  hierarchyType,
  leafShortName,
  rules,
  setIsLeafItemsTableLoaded,
  setLeafItemsCount,
}: DynamicGroupLeafsTableProps) => {
  const { name: activeDivisionName } = useDivision();
  const groupRulesDebounced = useDebounce(rules, DEBOUNCE_TIME_MS);

  const scrollTriggerRef = useRef(null);
  const scrollRootRef = useRef(null);
  const isScrollVisible = useScrollIntoViewport(scrollTriggerRef, {
    threshold: 1,
    root: scrollRootRef.current,
  });

  const rulesExist = rules.some(
    (rule) => rule.shortName !== NULL_SHORT_NAME && rule.values.length > 0
  );

  const rulesAreCorrectAndStable =
    rules === groupRulesDebounced &&
    rules.length > 0 &&
    rules.every(
      (rule) => rule.shortName !== NULL_SHORT_NAME && rule.values.length > 0
    );

  const [groupItemsState, setGroupItemsState] = useState({
    items: [],
    type: hierarchyType,
    disabledLevelShortNames: [],
  } as Hierarchy);

  const [searchPage, setSearchPage] = useState(0);

  const includeFilters = rules
    .filter(
      (rule) =>
        rule.shortName !== NULL_SHORT_NAME &&
        rule.operator === HierarchyGroupRuleOperator.Is
    )
    .map((rule) => ({
      codes: rule.values.map((x) => x.code),
      shortName: rule.shortName,
    }));

  const excludeFtilers = rules
    .filter(
      (rule) =>
        rule.shortName !== NULL_SHORT_NAME &&
        rule.operator === HierarchyGroupRuleOperator.IsNot
    )
    .map((rule) => ({
      codes: rule.values.map((x) => x.code),
      shortName: rule.shortName,
    }));

  const searchRequest = useMemo(
    () =>
      ({
        filters: includeFilters,
        excludeFilters: excludeFtilers,
        focalAttributes: [leafShortName],
        includeCodes: true,
        page: searchPage,
        pageSize: SEARCH_PAGE_SIZE,
        query: undefined,
      } as SearchRequestDto),
    [leafShortName, searchPage, excludeFtilers, includeFilters]
  );

  const searchCountRequest = useMemo(
    () =>
      ({
        filters: includeFilters,
        excludeFilters: excludeFtilers,
        focalAttributes: [leafShortName],
        includeCodes: true,
        page: 0,
      } as SearchRequestDto),
    [leafShortName, includeFilters, excludeFtilers]
  );

  const {
    currentData: itemsData,
    isFetching: isGetItemsQueryFetching,
    isSuccess: isGetItemsQuerySuccess,
  } = useSearchQuery(
    {
      division: activeDivisionName,
      hierarchyType,
      payload: searchRequest,
    },
    {
      skip: !activeDivisionName || !leafShortName || !rulesAreCorrectAndStable,
    }
  );

  const { currentData: itemsCount, isFetching: isGetItemsCountFetching } =
    useSearchCountQuery(
      {
        division: activeDivisionName,
        hierarchyType,
        payload: searchCountRequest,
      },
      {
        skip:
          !activeDivisionName || !leafShortName || !rulesAreCorrectAndStable,
      }
    );

  useEffect(() => {
    setGroupItemsState((state) => {
      let newItems: HierarchyGridItem[] = state.items;
      if (isGetItemsQuerySuccess && !isGetItemsQueryFetching) {
        if (searchPage === 0) {
          newItems = itemsData.results as HierarchyGridItem[];
        } else {
          newItems = state.items.concat(
            itemsData.results as HierarchyGridItem[]
          );
        }
      }

      return {
        ...state,
        items: newItems,
      };
    });
  }, [itemsData, isGetItemsQuerySuccess, isGetItemsQueryFetching, searchPage]);

  // if they have hit the bottom of the leaf list, we need to get more results
  useEffect(() => {
    if (!isGetItemsQueryFetching && isScrollVisible && itemsData?.hasNextPage) {
      setSearchPage(searchPage + 1);
    }
  }, [
    isGetItemsQueryFetching,
    isScrollVisible,
    itemsData?.hasNextPage,
    searchPage,
  ]);

  // if group rules change, clear the search
  useEffect(() => {
    setSearchPage(0);
    setGroupItemsState((state) => ({
      ...state,
      items: [],
    }));
  }, [rules]);

  const showTable = useMemo(
    () => groupItemsState.items.length !== 0,
    [groupItemsState.items.length]
  );

  useEffect(() => {
    setIsLeafItemsTableLoaded(
      rulesAreCorrectAndStable &&
        !isGetItemsCountFetching &&
        (itemsCount ?? 0) > 0
    );
  }, [
    rulesAreCorrectAndStable,
    isGetItemsCountFetching,
    itemsCount,
    setIsLeafItemsTableLoaded,
  ]);

  useEffect(() => {
    setLeafItemsCount(itemsCount ?? 0);
  }, [itemsCount, setLeafItemsCount]);

  type ProductCellProps = {
    getValue: Getter<HierarchyGridItem>;
    row: Row<HierarchyGridItem>;
  };

  const leafCell = useCallback(
    ({ getValue, row }: ProductCellProps) => (
      <TableCell
        name={String(getValue())}
        shortNameString={String(row.original.shortName)}
      />
    ),
    []
  );

  const leafTableColumns = [
    {
      id: "identifier",
      accessorFn: (item: HierarchyGridItem) => item.name,
      header: formatHierarchyName(hierarchyType, true, true),
      cell: leafCell,
    },
  ] as Array<ColumnDef<HierarchyGridItem>>;

  return (
    <div className={styles.dynamicGroupLeafsTable} ref={scrollRootRef}>
      {isGetItemsQueryFetching && !showTable && (
        <div className={styles.initialLoadingContainer}>
          <Spinner />
        </div>
      )}
      {((!isGetItemsCountFetching && itemsCount === 0) || !rulesExist) && (
        <div className={styles.emptyImageContainer}>
          <EmptyGroup type={hierarchyType} />
        </div>
      )}
      {showTable && (
        <div className={styles.dataTable}>
          <BasicTable
            columns={leafTableColumns}
            data={groupItemsState.items}
            pinFirstColumn
          />
        </div>
      )}
      {showTable && !isGetItemsQueryFetching && itemsData?.hasNextPage && (
        <div
          className={styles.scrollTrigger}
          id="scrolltrigger"
          ref={scrollTriggerRef}
        />
      )}
      {showTable && (itemsData?.hasNextPage || isGetItemsQueryFetching) && (
        <div className={styles.moreLoadingContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
  Module,
  ModuleVariant,
} from "@qbit/react";
import classNames from "classnames";
import { type PropsWithChildren, useState } from "react";
import styles from "./Accordion.module.css";

export type AccordionProps = {
  accordionState?: {
    isAccordionOpen: boolean;
    toggleOpen: (open: boolean) => void;
  };
  className?: string;
  subtitle?: string;
  title: JSX.Element | string;
};

export const Accordion = ({
  title,
  children,
  accordionState,
  subtitle,
  className,
}: PropsWithChildren<AccordionProps>) => {
  // This way we can use either local state or manage state from parent component(s)
  // Depending on how we go on the review I can look into abstracting this pattern to other components
  // NOTE: Open to naming suggestions
  const [isOpen, setIsOpen] = useState(
    accordionState ? accordionState.isAccordionOpen : true
  );
  const isOpenLocal = accordionState ? accordionState.isAccordionOpen : isOpen;
  const toggleOpenLocal = accordionState
    ? accordionState.toggleOpen
    : setIsOpen;

  return (
    <Module
      className={classNames(className, styles.accordion)}
      content={
        <div
          className={classNames(styles.accordionContent, {
            [styles.slideDown]: isOpenLocal === true,
            [styles.slideUp]: isOpenLocal === false,
          })}
        >
          {children}
        </div>
      }
      header={
        <div className={styles.accordionHeader}>
          <div className={styles.accordionHeaderLeftContent}>
            <h6 className={styles.accordionTitle}>{title}</h6>
            <p
              className={classNames(styles.accordionSubtitle, {
                [styles.subtitleExpand]: isOpenLocal === true,
              })}
            >
              {subtitle}
            </p>
          </div>
          <div className={styles.accordionHeaderRightContent}>
            <Button
              className={styles.accordionChevronBtn}
              onClick={() => {
                toggleOpenLocal(!isOpenLocal);
              }}
              variant={ButtonVariant.Stealth}
            >
              {isOpenLocal ? (
                <Icon glyph={IconGlyph.ArrowsChevronUp} text="Collapse" />
              ) : (
                <Icon glyph={IconGlyph.ArrowsChevronDown} text="Expand" />
              )}
            </Button>
          </div>
        </div>
      }
      variant={ModuleVariant.Default}
    />
  );
};

import { uniqueId } from "@qbit/react/dist/common";
import {
  GenericTrackingProperties,
  HierarchyItemType,
  ReportType,
  TrackingComponent,
  TrackingEvent,
  ddLog,
  useEventTrackingServiceContext,
} from "@quantium-enterprise/common-ui";
import {
  useFlags,
  type DeferredFormatFunction,
} from "@quantium-enterprise/hooks-ui";
import { useDivision, useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type CellContext,
  type GroupColumnDef,
  type ColumnResizeMode,
  createColumnHelper,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTable } from "components-ui/src/tables/report-hierarchy-table/ReportHierarchyTable";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { BasketQuantitiesFeatureFlags } from "../constants/basket-quantities-feature-flags";
import {
  type MetricMetadata,
  type BasketQuantitiesMetadata,
  type TableRow,
} from "../models/basket-quantities-data-table-models";
import {
  useLazyGetTableQuery,
  useLazyDownloadTableQuery,
} from "../services/basket-quantities-data-table-api-slice";
import {
  selectFocalItem,
  selectLocationHierarchy,
  selectChannel,
  selectPromotion,
  selectSegmentation,
  selectFocalItemTableSelectionState,
  onFocalItemChange,
  selectLocalParametersInitialised,
  selectReportName,
  selectSegment,
  selectBreakdown,
  selectTime,
  selectViewAs,
  selectTimePeriodLength,
  selectReportId,
} from "../services/basket-quantities-slice";
import { getHierarchyValueId } from "../utils/getHierarchyValueId";
import { getFocalItemTableLocalSelections } from "../utils/getReportletLocalSelections";
import styles from "./BasketQuantitiesTopDrawer.module.css";

const columnResizeMode: ColumnResizeMode = "onChange";

const getRowId = (row: TableRow) => getHierarchyValueId(row.product);

type GroupedMetricsMetadata = {
  metricGroup?: string;
  metrics: Array<{
    index: number;
    metadata: MetricMetadata;
  }>;
};

const columnHelper = createColumnHelper<TableRow>();

// Export to enable testing
export const measuresColumnDefs = (
  formatter: DeferredFormatFunction,
  metadata?: BasketQuantitiesMetadata
): Array<GroupColumnDef<TableRow, number>> => {
  if (!metadata) {
    return [];
  }

  const groupedMetrics: GroupedMetricsMetadata[] = [];
  let metricIndex = 0;
  for (const metric of metadata.metricMetadata) {
    if (
      groupedMetrics.length === 0 ||
      groupedMetrics.at(-1)?.metricGroup !== metric.metricGroup
    ) {
      groupedMetrics.push({
        metricGroup: metric.metricGroup,
        metrics: [],
      });
    }

    groupedMetrics.at(-1)?.metrics.push({
      metadata: metric,
      index: metricIndex,
    });
    ++metricIndex;
  }

  return groupedMetrics.map((groupedMetric) => ({
    columns: groupedMetric.metrics.map((metric) =>
      columnHelper.accessor(
        (row: TableRow) => row.metrics[metric.index].metricValue,
        {
          cell: ({ getValue }: CellContext<TableRow, number>) =>
            ValueCell({
              formatter: formatter(metric.metadata.format),
              value: getValue(),
            }),
          header: metric.metadata.metricLabel,
          id: metric.metadata.metricLabel,
        }
      )
    ),
    header: groupedMetric.metricGroup,
    id: groupedMetric.metricGroup ?? uniqueId(),
  }));
};

export const BasketQuantitiesTopDrawer = ({
  renameReport,
}: {
  renameReport: (newItemName: string, itemId: string) => Promise<void>;
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { id: reportId } = useParams();
  const { name: divisionName } = useDivision();
  const dispatch = useDispatch();
  const formatter = useFormatter();

  const reportName = useSelector(selectReportName);
  const time = useSelector(selectTime);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const viewAs = useSelector(selectViewAs);
  const breakdown = useSelector(selectBreakdown);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const focalItem = useSelector(selectFocalItem);
  const location = useSelector(selectLocationHierarchy);
  const channel = useSelector(selectChannel);
  const promotion = useSelector(selectPromotion);
  const segmentation = useSelector(selectSegmentation);
  const segment = useSelector(selectSegment);
  const focalItemTableSelectionState = useSelector(
    selectFocalItemTableSelectionState
  );
  const id = useSelector(selectReportId);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[BasketQuantitiesFeatureFlags.ReportExport] ?? false;

  const eventTrackingService = useEventTrackingServiceContext();

  const focalItemSummary = useMemo(
    () =>
      focalItem.itemCode
        ? [
            {
              code: focalItem.shortName,
              displayName: focalItem.name,
              // TODO: temp until the backend can return this
              type: HierarchyItemType.Attribute,
            },
          ]
        : [],
    [focalItem]
  );

  const onSearchChange = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  const [getTableQuery, { data: response, isFetching: isTableFetching }] =
    useLazyGetTableQuery();

  const fetchTableQuery = useCallback(async () => {
    await getTableQuery({
      divisionName,
      payload: {
        parameterSelections: getFocalItemTableLocalSelections(
          location,
          channel.value as string,
          promotion.value as string,
          segmentation.value as string,
          segment.value as string
        ),
        reportId: reportId ?? "",
        searchQuery,
      },
    });
  }, [
    channel.value,
    divisionName,
    getTableQuery,
    location,
    promotion.value,
    reportId,
    searchQuery,
    segment.value,
    segmentation.value,
  ]);

  useEffect(() => {
    if (reportId === id && localParametersInitialised) {
      fetchTableQuery().catch((error) => {
        ddLog(
          "Error retrieving basket quantities table query data",
          {},
          "error",
          error
        );
      });
    }
  }, [fetchTableQuery, id, localParametersInitialised, reportId]);

  const tableMetadata = response?.tableMetadata;
  const tableRows = useMemo(() => response?.tableRows ?? [], [response]);
  const success = Boolean(response);

  const matchesFound = useMemo(() => {
    if (!searchQuery) {
      return undefined;
    }

    return tableRows.length;
  }, [tableRows, searchQuery]);

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchBox}>
        <SearchBox
          enableDebounce
          onChange={onSearchChange}
          placeholder="Type to search"
          resultCount={matchesFound}
        />
      </div>
    ),
    [onSearchChange, matchesFound]
  );

  const displayEmptySearch: boolean =
    success && searchQuery.length > 0 && tableRows.length === 0;

  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<TableRow, string>) => (
      <ExpandableNameCell
        canExpand={false}
        depth={0}
        handleToggleExpanded={row.getToggleExpandedHandler()}
        handleToggleSelected={row.getToggleSelectedHandler()}
        isCheckboxHidden
        isExpanded={false}
        isSelected={row.getIsSelected()}
        name={row.original.product.name}
        onClick={() => {
          if (!row.getIsSelected()) {
            eventTrackingService.trackEvent(
              [
                TrackingComponent.MyReports,
                TrackingComponent.Report,
                TrackingComponent.FocalItemTableRow,
              ],
              TrackingEvent.Selected,
              new GenericTrackingProperties({
                reportType: "Basket quantities",
                reportId,
                selection: row.original.product,
              })
            );
            dispatch(onFocalItemChange(row.original.product));
          }
        }}
        shortName={row.original.product.shortName}
        // TODO: temp until the backend can return this
        type={HierarchyItemType.Attribute}
        value={getValue()}
      />
    ),
    [dispatch, eventTrackingService, reportId]
  );
  // `any` can be `string | number`. single type to make types work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: Array<GroupColumnDef<TableRow, any>> = useMemo(
    () => [
      {
        columns: [
          columnHelper.accessor((row) => row.product.name, {
            cell: createNameCell,
            enableHiding: false,
            enableResizing: true,
            enableSorting: false,
            // @ts-expect-error This works just fine, but I haven't figured out how to make the type checker happy with it.
            header: memoizedSearchBox,
            id: "FocalItemTableLowerHeader",
          }),
        ],
        header: "Item description",
        id: "FocalItemTableUpperHeader",
      },
      ...measuresColumnDefs(formatter, tableMetadata),
    ],
    [createNameCell, formatter, memoizedSearchBox, tableMetadata]
  );

  const [downloadTableTrigger] = useLazyDownloadTableQuery();

  const filename = useMemo(
    () =>
      cleanFilename(`Basket_Quantities_${timePeriodLength}_${location.name}`),
    [location, timePeriodLength]
  );

  const parameterSummary = useMemo(
    () => [
      { name: "Focal item", value: focalItem.name },
      { name: "Time", value: time },
      { name: "View as", value: viewAs.label },
      { name: "Breakdown", value: breakdown.label },
      { name: "Channel", value: channel.label },
      { name: "Promotion", value: promotion.label },
      { name: "Segmentation", value: segmentation.label },
      { name: "Customer segment", value: segment.label },
      {
        name: "Location",
        value: `(${hierarchyLevelDisplayLabel(location.shortName)}) ${
          location.name
        }`,
      },
    ],
    [
      time,
      focalItem,
      viewAs,
      breakdown,
      channel,
      promotion,
      segmentation,
      segment,
      location,
    ]
  );

  const handleDownloadButtonClick = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        localParameters: parameterSummary,
        localParameterSelections: getFocalItemTableLocalSelections(
          location,
          channel.value as string,
          promotion.value as string,
          segmentation.value as string,
          segment.value as string
        ),
        filename,
        reportId: reportId ?? "",
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    location,
    channel,
    promotion,
    segmentation,
    segment,
    filename,
    reportId,
    parameterSummary,
  ]);

  return (
    <ReportTopDrawer
      controls={[
        <DataTableOptions
          filename={filename}
          invokeCSVDownload={handleDownloadButtonClick}
          isFeatureEnabled={isExportEnabled}
          isInFocalItemHeader
          key={reportId}
        />,
      ]}
      items={focalItemSummary}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={ReportType.BasketQuantities}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper isSuccess={!isTableFetching && success}>
          <ReportHierarchyTable
            columnResizeMode={columnResizeMode}
            columns={columns}
            data={tableRows}
            disableSorting
            getRowId={getRowId}
            getSubRows={() => undefined}
            pinFirstColumn
            rowExpandedState={{ "0": true }}
            rowSelectionState={focalItemTableSelectionState}
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default BasketQuantitiesTopDrawer;

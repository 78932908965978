import { Group, Item, ItemValign } from "@qbit/react";
import classNames from "classnames";
import { type MouseEvent, useState } from "react";
import { SubDirectoryIcon } from "../../../assets/icons/SubDirectoryIcon";
import { ConditionalTooltipCheckbox } from "../../../checkboxes/ConditionalTooltipCheckbox";
import { EditableField } from "../../../editable-field/EditableField";
import { ExpandChevron } from "../../../icons/expand-chevron/ExpandChevron";
import { RenameItem } from "../../../rename-item/RenameItem";
import { TestId } from "../../report-hierarchy-table/constants";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../constants";
import styles from "./Cell.module.css";

type MyReportsNameCellProps = {
  canExpand: boolean;
  depth: number;
  editableFieldState?: {
    isEditing: boolean;
    toggleEditing: (editing: boolean) => void;
  };
  handleToggleExpanded: (event: MouseEvent<HTMLElement>) => void;
  handleToggleSelected: (event: unknown) => void;
  icon: JSX.Element;
  id?: string;
  isCheckboxDisabled?: boolean;
  isCheckboxHidden?: boolean;
  isExpanded: boolean;
  isSelected: boolean;
  itemType: string;
  minWidth?: number;
  nameCellClassName?: string;
  preventBlur?: boolean;
  renameItem: (newItemName: string, itemId: string) => Promise<unknown>;
  stopEditing?: () => void;
  triggerLazyReload?: () => Promise<void>;
  value: string;
};

// eslint-disable-next-line complexity -- This component has a lot of optional properties
export const MyReportsNameCell = ({
  canExpand,
  nameCellClassName,
  depth,
  handleToggleExpanded,
  handleToggleSelected,
  icon,
  id,
  isCheckboxHidden = true,
  isCheckboxDisabled = false,
  isExpanded,
  isSelected,
  itemType,
  minWidth,
  value,
  editableFieldState,
  renameItem,
  stopEditing,
  triggerLazyReload,
  preventBlur,
}: MyReportsNameCellProps) => {
  const { handleRename, isProcessing } = RenameItem({
    itemType,
    renameItem,
    triggerLazyReload,
  });

  const [isSelectedInternal, setIsSelectedInternal] =
    useState<boolean>(isSelected);

  const toggleSelectedInternal = (event: unknown) => {
    const newIsSelectedInternalValue = !isSelectedInternal;
    setIsSelectedInternal(newIsSelectedInternalValue);
    handleToggleSelected(event);
  };

  return (
    <Group>
      <Item valign={ItemValign.Middle}>
        <div
          className={classNames(styles.flex, nameCellClassName, styles.cursor)}
          role="presentation"
          style={{
            minWidth: minWidth ? minWidth : DEFAULT_COLUMN_MIN_WIDTH,
          }}
        >
          <div className={styles.myReportsNameCellFlex}>
            {isSelected && (
              <div
                className={styles.selectedHighlight}
                data-testid={TestId.SelectedHighlight}
              />
            )}
            <div
              className={classNames(styles.flex, {
                [styles.marginLeft]: !isSelected && isCheckboxHidden,
                [styles.marginLeftSelected]: isSelected && isCheckboxHidden,
                [styles.xsmallMarginLeft]: !isSelected && !isCheckboxHidden,
                [styles.xsmallMarginLeftSelected]:
                  isSelected && !isCheckboxHidden,
              })}
            >
              <div className={styles.nameCellActiveArea}>
                {isCheckboxHidden || (
                  <ConditionalTooltipCheckbox
                    className="name-cell-checkbox"
                    data-testid={TestId.Checkbox}
                    hideTooltip={!isCheckboxDisabled}
                    isChecked={isSelected}
                    isDisabled={isCheckboxDisabled}
                    label="Toggle row"
                    onChange={toggleSelectedInternal}
                    tooltipText="This report is not available for selection at this time."
                  />
                )}
              </div>

              <div
                className={classNames(styles.flex)}
                data-testid={TestId.IconGroup}
                style={{
                  paddingLeft: `${depth * 32}px`,
                }}
              >
                {canExpand ? (
                  <ExpandChevron
                    isCompact={false}
                    isExpanded={isExpanded}
                    isLoading={false}
                    onClick={handleToggleExpanded}
                  />
                ) : (
                  <span
                    className={classNames({
                      [styles.marginLeftNoExpand]: isCheckboxHidden,
                      [styles.xsmallMarginLeftNoExpand]: !isCheckboxHidden,
                    })}
                  />
                )}
                {depth > 0 && (
                  <div className={styles.subDirectoryIcon}>
                    {" "}
                    <SubDirectoryIcon />
                  </div>
                )}

                <div
                  className={classNames(styles.flex, styles.paddingLeft)}
                  role="presentation"
                >
                  {icon}
                </div>
              </div>
            </div>
            <span
              className={styles.nameCellValue}
              data-testid={TestId.ItemName}
              role="presentation"
            >
              <EditableField
                className={styles.myReportsNameCellValue}
                editableFieldState={editableFieldState}
                isProcessing={isProcessing}
                onlyExternalState
                preventBlur={preventBlur}
                save={async (newName: string) => {
                  const data = await handleRename(newName, id).then(
                    () => true,
                    () => false
                  );

                  return data;
                }}
                stopEditing={stopEditing}
                value={value}
              />
            </span>
          </div>
        </div>
      </Item>

      {isSelectedInternal}
    </Group>
  );
};

import { Toggle } from "@qbit/react";
import {
  ddLog,
  type ReportParametersDto,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  getNumberFormat,
  useDivision,
} from "@quantium-enterprise/hooks-ui";
import { ChartFooterWrapper } from "components-ui/src/charts/chart-footer-wrapper/ChartFooterWrapper";
import { ChartOptions } from "components-ui/src/charts/chart-options/ChartOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { type ProductInteractionData } from "components-ui/src/tables/matrix/Matrix";
import { Matrix } from "components-ui/src/tables/matrix/Matrix";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { NoDataChartWrapper } from "../../../common/components/NoDataChartWrapper";
import { isfeatureEnable } from "../../../common/utils/feature-flag-utils";
import { HEAT_MAP_COLOURS } from "../../../common/utils/heat-map-colours";
import { CrossShopFeatureFlags } from "../../constants/cross-shop-feature-flags";
import { type CrossShopTableItem } from "../../models/CrossShopDataTableResponseDto";
import { useLazyGetInteractionsMatrixDataQuery } from "../../services/customer-cross-shop-interactions-matrix-api-slice";
import {
  selectChannel,
  selectFocalItems,
  selectLocalParametersInitialised,
  selectLocalSelections,
  selectLocation,
  selectProductInteraction,
  selectReportId,
  selectSegmentation,
  selectTimePeriodLength,
} from "../../services/customer-cross-shop-slice";
import getLocalParametersSummary from "../../utils/getLocalParametersSummary";
import styles from "./CrossShopInteractionsMatrix.module.css";
import { CrossShopInteractionsMatrixCsvTransformation } from "./CrossShopInteractionsMatrixCsvTransformation";

enum ProductInteractionLabels {
  CustomersL = "customers (L)",
  SalesL = "sales (L)",
}

export type CrossShopInteractionsMatrixProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

export const CrossShopInteractionsMatrix = ({
  infoPanelSummary,
}: CrossShopInteractionsMatrixProps) => {
  const { name: divisionName } = useDivision();
  const { id } = useParams();

  const { metricFormatter } = getNumberFormat();

  const [showDetails, setShowDetails] = useState(false);

  const focalItems = useSelector(selectFocalItems);
  const channel = useSelector(selectChannel);
  const location = useSelector(selectLocation);
  const productInteraction = useSelector(selectProductInteraction);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const segmentation = useSelector(selectSegmentation);
  const timePeriodLength = useSelector(selectTimePeriodLength);
  const localSelections = useSelector(selectLocalSelections);
  const reportId = useSelector(selectReportId);

  const isfeatureEnabled = isfeatureEnable(CrossShopFeatureFlags.ReportExport);
  // Matrix Query
  const [triggerMatrixDataQuery, { isSuccess, data }] =
    useLazyGetInteractionsMatrixDataQuery();

  const fetchData = useCallback(async () => {
    await triggerMatrixDataQuery({
      divisionName,
      payload: {
        parameterSelections: {
          channel: channel.value as string,
          segmentation: segmentation.map((item) => item.value as string),
          locationNodeNumber: location.nodeNumber,
        },
        reportId: id ? id : "",
      },
    });
  }, [
    channel.value,
    divisionName,
    id,
    location.nodeNumber,
    segmentation,
    triggerMatrixDataQuery,
  ]);

  useEffect(() => {
    if (
      divisionName &&
      focalItems.length >= 0 &&
      localParametersInitialised &&
      reportId === id
    ) {
      fetchData()
        // make sure to catch any error
        .catch((error) => {
          // FIXME throw this somewhere
          ddLog("ERROR", {}, "error", error);
        });
    }
  }, [
    divisionName,
    focalItems,
    fetchData,
    localParametersInitialised,
    reportId,
    id,
  ]);

  const matrixProperties = useMemo(() => {
    const emptyMatrixProperties = {
      headers: [] as CrossShopTableItem[],
      values: [],
      productInteractionData: {
        displayName: "",
        format: "Decimal",
        values: [],
      } as ProductInteractionData,
    };

    if (!data) {
      return null;
    }

    const matched = data.find(
      (entry) => entry.productInteraction === productInteraction.value
    );

    return matched
      ? {
          headers: matched.itemMetadata,
          productInteractionData: matched.matrixData.productInteractionData,
          values: matched.matrixData.values,
        }
      : emptyMatrixProperties;
  }, [data, productInteraction]);

  const label = useMemo(() => {
    const productInteractionLabel =
      ProductInteractionLabels[
        productInteraction.value as keyof typeof ProductInteractionLabels
      ];

    return productInteractionLabel;
  }, [productInteraction]);

  const chartContainerRef = useRef<HTMLElement>();
  const parameterSummary = useMemo(
    () => getLocalParametersSummary(localSelections, focalItems),
    [localSelections, focalItems]
  );

  const { locale, currency } = useDivision();
  const currencySymbol = useMemo(() => {
    const { getCurrencySymbol } = getNumberFormat(locale, currency);
    return getCurrencySymbol();
  }, [locale, currency]);

  const MatrixCsvTransformationCallback = useCallback(
    () =>
      CrossShopInteractionsMatrixCsvTransformation(
        matrixProperties,
        currencySymbol,
        showDetails
      ),
    [matrixProperties, currencySymbol, showDetails]
  );

  const exportChartFilename = useMemo(
    () =>
      cleanFilename(
        `Cross_Shop_Interactions_Chart_${timePeriodLength}_${location.name}`
      ),
    [timePeriodLength, location]
  );
  const [height, setHeight] = useState("400");

  useEffect(() => {
    const childElement =
      chartContainerRef.current?.querySelector(".matrixChartData");
    if (childElement) {
      const clientHeight = childElement.clientHeight;
      setHeight(clientHeight.toString());
    }
  }, [showDetails, isSuccess]);

  return (
    <div className={styles.crossShopInteractionsMatrix}>
      <div className={styles.chartToggleOptionsContainer}>
        <div className={styles.detailsToggleWrapper}>
          <Toggle
            checked={showDetails}
            label={`Show ${label}`}
            onClick={() => setShowDetails(!showDetails)}
          />
        </div>
        <div className={styles.chartOptionsContainer}>
          <ChartOptions
            filename={exportChartFilename}
            getCSVData={MatrixCsvTransformationCallback}
            getElementToExport={() => chartContainerRef.current}
            isFeatureEnabled={isfeatureEnabled}
            localParameters={parameterSummary}
            reportParameters={infoPanelSummary}
          />
        </div>
      </div>
      <ErrorBoundary>
        <NoDataChartWrapper
          isLoading={!(isSuccess && localParametersInitialised)}
          noData={!data || data.length === 0}
        >
          <ChartFooterWrapper
            height={height + "px"}
            parameters={parameterSummary}
            ref={chartContainerRef}
          >
            <div className="matrixChartData">
              {matrixProperties ? (
                <Matrix
                  colorRange={HEAT_MAP_COLOURS}
                  detailsFormatter={(value: number | string | null) =>
                    metricFormatter(
                      matrixProperties.productInteractionData.format,
                      value
                    )
                  }
                  formatter={(value: number | string | null) =>
                    metricFormatter(MetricTypes.Percentage, value)
                  }
                  headers={matrixProperties.headers}
                  productInteractionData={
                    matrixProperties.productInteractionData
                  }
                  selectedItemCodes={focalItems.map((x) => x.itemCode)}
                  showDetails={showDetails}
                  values={matrixProperties.values}
                  xAxisLabel="Cross shop items"
                  yAxisLabel="Focal items"
                />
              ) : null}
            </div>
          </ChartFooterWrapper>
        </NoDataChartWrapper>
      </ErrorBoundary>
    </div>
  );
};

import { Tag, TagColour, TagTextTransform, TagVariant } from "@qbit/react";
import classNames from "classnames";
import styles from "./CustomTimePeriod.module.css";

export type CustomTimePeriodDisplayWeeksProps = {
  center?: boolean;
  weeks: number;
};

export const CustomTimePeriodDisplayWeeks = ({
  weeks,
  center = true,
}: CustomTimePeriodDisplayWeeksProps) => (
  <Tag
    className={classNames(styles.customPeriodWeekDisplay, {
      [styles.customPeriodWeekDisplayCenter]: center,
    })}
    colour={TagColour.Neutral}
    text={weeks > 1 ? `${weeks} weeks selected` : `${weeks} week selected`}
    textTransform={TagTextTransform.None}
    variant={TagVariant.Default}
  />
);

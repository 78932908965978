import {
  FormBlock,
  FormBlockType,
  FormInputHeight,
  Input,
  Select,
  SelectOption,
  SelectOptionGroup,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import React from "react";
import { type PanelOption } from "../local-parameters-panel/FixedSidePanel";

export type GroupOption = {
  groupName: string;
  values: PanelOption[];
};

export type SingleSelectDropdownWithGroupsProps = {
  height?: FormInputHeight;
  isDisabled?: boolean;
  onSelection?: (value: string) => unknown;
  selectOptions: GroupOption[];
  selectedValue?: string;
  title?: string;
};

const defaultOnSelection = () => {};

export const SingleSelectDropdownWithGroups = ({
  height = FormInputHeight.Small,
  isDisabled,
  onSelection = defaultOnSelection,
  selectOptions,
  selectedValue,
  title,
}: SingleSelectDropdownWithGroupsProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelection(event.target.value);
  };

  return (
    <FormBlock blockType={FormBlockType.Select}>
      <Input>
        <Select
          disabled={isDisabled}
          height={height}
          id={title + " dropdown"}
          onChange={handleOnChange}
          value={selectedValue}
        >
          {selectOptions.map((group) => (
            <SelectOptionGroup key={group.groupName} label={group.groupName}>
              {group.values.map((option) => (
                <SelectOption
                  key={uniqueId()}
                  text={option.label || option.value.toString()}
                  value={option.value.toString()}
                />
              ))}
            </SelectOptionGroup>
          ))}
        </Select>
      </Input>
    </FormBlock>
  );
};

export default SingleSelectDropdownWithGroups;

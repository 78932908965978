import tokens from "@qbit/core/dist/tokens.json";
import {
  ButtonHeight,
  Tooltip,
  TooltipPlacement,
  TooltipSpaceInside,
  TooltipVariant,
  type IconGlyph,
} from "@qbit/react";
import { Button, ButtonVariant, ButtonWidth, Icon } from "@qbit/react";
import { ButtonDropdown } from "../button-dropdown/ButtonDropdown";
import styles from "./ButtonList.module.css";

export type ButtonContent = {
  buttonColour?: string;
  buttonIcon?: JSX.Element;
  buttonQbitIcon?: IconGlyph;
  buttonTextColour?: string;
  disabled?: boolean;
  handleClick?: () => unknown;
  name: string;
  testId?: string;
  text: string;
  title?: string;
};

type ButtonIconProps = {
  button: ButtonContent;
};

export const ButtonIcon = ({ button }: ButtonIconProps) => {
  if (button.buttonQbitIcon) {
    return (
      <Icon
        colour={button.buttonColour ?? tokens.colour["text-primary"]}
        glyph={button.buttonQbitIcon}
        text={button.text}
      />
    );
  } else if (button.buttonIcon) {
    return button.buttonIcon;
  } else {
    return <div />;
  }
};

export type ButtonListProps = {
  // the first list of icons is displayed,
  // the following lists are separated into sections within the dropdown
  buttons: ButtonContent[][];
};

export const ButtonList = ({ buttons }: ButtonListProps) => {
  const displayedButtons = buttons[0];
  const dropdownButtons = buttons.slice(1);
  return (
    <div className={styles.horizontalButtonList}>
      {displayedButtons.map((button) => {
        const buttonElement = (
          <Button
            aria-label={button.text}
            className={styles.horizontalButtonListButtons}
            data-testid={button.testId}
            disabled={button.disabled}
            height={ButtonHeight.Small}
            key={button.name}
            onClick={button.handleClick}
            variant={ButtonVariant.Stealth}
            width={ButtonWidth.Fit}
          >
            <ButtonIcon button={button} />
          </Button>
        );
        return button.title ? (
          <Tooltip
            key={`${button.name}-tooltip`}
            placement={TooltipPlacement.TopCentre}
            spaceInside={TooltipSpaceInside.Small}
            trigger={buttonElement}
            variant={TooltipVariant.ArrowDark}
          >
            {button.title}
          </Tooltip>
        ) : (
          buttonElement
        );
      })}
      {dropdownButtons.length > 0 && (
        <ButtonDropdown
          buttons={dropdownButtons}
          className={styles.horizontalButtonListButtons}
        />
      )}
    </div>
  );
};

import { Nav, NavSize, NavVariant, NavButton } from "@qbit/react";
import {
  type ReportParametersDto,
  type LocalSelectedValueDto,
  ParameterId,
  ddLog,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import { ChartDataWrapper } from "../../common/components/ChartDataWrapper";
import { ReportletAccordion } from "../../common/components/ReportletAccordion";
import { EMPTY_NODE_NUMBER } from "../../common/constants";
import styles from "../GainsAndLossReport.module.css";
import { GainAndLossFeatureFlags } from "../constants/gain-and-loss-feature-flags";
import { type GainsAndLossOverviewReportletRequestDto } from "../models/GainsAndLossOverviewRequestDto";
import { useLazyGetGainsAndLossOverviewReportletQuery } from "../services/gains-and-loss-overview-api-slice";
import {
  selectFocalItem,
  selectLocalSelections,
  selectReportId,
} from "../services/gains-and-loss-slice";
import { GainsAndLossOverviewDataTable } from "./GainsAndLossOverviewDataTable";
import { GainsAndLossOverviewWaterfall } from "./GainsAndLossOverviewWaterfall";

const enum OVERVIEW_REPORTLET_TABS {
  DataTable = "DataTable",
  Waterfall = "Waterfall",
}

const tabs = [
  OVERVIEW_REPORTLET_TABS.Waterfall,
  OVERVIEW_REPORTLET_TABS.DataTable,
];

export const GainsAndLossOverviewReportlet = ({
  reportParameters,
}: {
  reportParameters?: ReportParametersDto;
}) => {
  const { name: divisionName } = useDivision();
  const { id } = useParams();
  const flags = useFlags();
  const isExportEnabled = flags[GainAndLossFeatureFlags.ReportExport] ?? false;
  const [currentNavIndex, setCurrentNavIndex] = useState<number>(0);

  const reportId = useSelector(selectReportId);
  const focalItem = useSelector(selectFocalItem);
  const localSelections = useSelector(selectLocalSelections);

  const [getReportletData, { data, isError, isSuccess, isFetching }] =
    useLazyGetGainsAndLossOverviewReportletQuery();

  const fetchData = useCallback(
    async (division: string) => {
      const localSelectedValues: LocalSelectedValueDto[] = [
        {
          id: ParameterId.Channel,
          values: [localSelections.channel.value as string],
        },
        {
          id: ParameterId.LocationHierarchy,
          values: [localSelections.location.nodeNumber.toString()],
        },
        {
          id: ParameterId.Segmentation,
          values: [
            localSelections.segmentation.value as string,
            localSelections.segment.value as string,
          ],
        },
      ];

      await getReportletData({
        division,
        payload: {
          focalItem,
          localSelectedValues,
          reportId: id ?? "",
        } as GainsAndLossOverviewReportletRequestDto,
      });
    },
    [
      focalItem,
      getReportletData,
      id,
      localSelections.channel,
      localSelections.location,
      localSelections.segmentation,
      localSelections.segment,
    ]
  );

  const attemptFetch = useCallback(() => {
    if (
      reportId === id &&
      focalItem?.itemCode &&
      localSelections.location.nodeNumber !== EMPTY_NODE_NUMBER
    ) {
      fetchData(divisionName)
        // make sure to catch any error
        .catch((error) => {
          // FIXME throw this somewhere
          ddLog("ERROR", {}, "error", error);
        });
    }
  }, [
    divisionName,
    focalItem,
    fetchData,
    localSelections.location.nodeNumber,
    id,
    reportId,
  ]);

  useEffect(() => {
    attemptFetch();
  }, [attemptFetch]);

  return (
    <ReportletAccordion
      subtitle="Understand how customer movement is driving performance."
      title="Gains and loss overview"
    >
      <Nav
        activeIndex={currentNavIndex}
        size={NavSize.Medium}
        variant={NavVariant.Tab}
      >
        {tabs.map((tab, index) => (
          <NavButton key={tab} onClick={() => setCurrentNavIndex(index)}>
            {tab}
          </NavButton>
        ))}
      </Nav>
      <ErrorBoundary key={currentNavIndex}>
        <ChartDataWrapper
          isError={isError}
          isSuccess={isSuccess && !isFetching}
          minHeight="400px"
          retry={attemptFetch}
          showNoDataAvailable={
            !data || localSelections.location.nodeNumber === EMPTY_NODE_NUMBER
          }
        >
          {currentNavIndex ===
          tabs.indexOf(OVERVIEW_REPORTLET_TABS.Waterfall) ? (
            <GainsAndLossOverviewWaterfall
              className={styles.reportletModule}
              data={data}
              isExportEnabled={isExportEnabled}
              key="overview-waterfall-tab"
              measureChosen={localSelections.metricSet.value as string}
              reportParameters={reportParameters}
            />
          ) : null}
          {currentNavIndex ===
          tabs.indexOf(OVERVIEW_REPORTLET_TABS.DataTable) ? (
            <GainsAndLossOverviewDataTable
              className={styles.dataTableReportletModule}
              isExportEnabled={isExportEnabled}
              items={data ? data.overviewItems : []}
              key="overview-data-table-tab"
              reportParamters={reportParameters}
            />
          ) : null}
        </ChartDataWrapper>
      </ErrorBoundary>
    </ReportletAccordion>
  );
};

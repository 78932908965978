import { getBaseQuery } from "@quantium-enterprise/common-ui";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  type KeyDriverTreeRequest,
  type KeyDriverTreeResponse,
} from "../models/keyDriverTreeDtos";

export const keyDriverTreeApi = createApi({
  baseQuery: getBaseQuery(`/api/fast-reporting`),
  endpoints: (builder) => ({
    keyDriverTreeMeasures: builder.query<
      KeyDriverTreeResponse,
      KeyDriverTreeRequest & { division: string }
    >({
      keepUnusedDataFor: 60,
      providesTags: ["KeyDriverTreeReport"],
      query: (request) => ({
        body: request,
        method: "POST",
        url: `/${request.division}/key-driver-tree-report`,
      }),
    }),
  }),
  reducerPath: "keyDriverTreeApi",
  tagTypes: ["KeyDriverTreeReport"],
});

export const { useKeyDriverTreeMeasuresQuery } = keyDriverTreeApi;

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  ButtonWidth,
  Icon,
  IconGlyph,
  Tooltip,
  TooltipSpaceInside,
  TooltipVariant,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import { type FocalItem } from "@quantium-enterprise/common-ui";
import { isHierarchyFocalItem } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { FocalItemIcon } from "./FocalItemIcon";
import styles from "./ReportingTab.module.css";

export type FastReportingTabProps = {
  isActive: boolean;
  item: FocalItem;
  onRemoveTab: () => void;
  onSetActiveTab: () => void;
};

export const FastReportingTab = ({
  item,
  isActive,
  onSetActiveTab,
  onRemoveTab,
}: FastReportingTabProps) => {
  const reportTab = (
    <div
      className={classNames(styles.reportTab, {
        [styles.reportTabActive]: isActive,
      })}
      data-testid="fast-reporting-tab"
    >
      <div className={styles.reportTabLeftContent}>
        <Button
          data-testid="fast-reporting-tab-active-button"
          onClick={onSetActiveTab}
        >
          <span
            className={classNames(styles.icon, {
              [styles.hierarchicalIcon]: isHierarchyFocalItem(item),
            })}
          >
            <FocalItemIcon focalItem={item} isActive={isActive} />
          </span>
          <span className={styles.text}>{item.displayName}</span>
        </Button>
      </div>
      <div className={styles.reportTabRightContent}>
        <Button
          className={styles.close}
          data-testid="fast-reporting-tab-close-button"
          height={ButtonHeight.XSmall}
          onClick={(event) => {
            event.stopPropagation();
            onRemoveTab();
          }}
          variant={ButtonVariant.Stealth}
          width={ButtonWidth.Fit}
        >
          <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
        </Button>
      </div>
    </div>
  );

  const reportTabWithTooltip = (
    <Tooltip
      data-testid="fast-reporting-tab-tooltip"
      id={uniqueId()}
      spaceInside={TooltipSpaceInside.Small}
      trigger={reportTab}
      variant={TooltipVariant.ArrowLight}
    >
      <div className={styles.tooltip}>{item.displayName}</div>
    </Tooltip>
  );

  return isActive ? reportTab : reportTabWithTooltip;
};

import {
  FormInputHeight,
  FormInputWidth,
  Select,
  SelectOption,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import {
  HierarchyItemType,
  TrackingComponent,
  TrackingEvent,
  ddLog,
  type LocalHierarchyNodeSelection,
  type LocalSegmentationSelection,
  type LocalStringSelection,
} from "@quantium-enterprise/common-ui";
import {
  MetricTypes,
  useDivision,
  useFlags,
  useFormatter,
} from "@quantium-enterprise/hooks-ui";
import {
  type CellContext,
  type ColumnDef,
  type ExpandedState,
} from "@tanstack/react-table";
import { DataTableOptions } from "components-ui/src/data-table-options/DataTableOptions";
import { cleanFilename } from "components-ui/src/export/export-functions";
import { ReportTopDrawer } from "components-ui/src/report-top-drawer/ReportTopDrawer";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../../../../../apps/checkout-ui/src/components/error-boundary/ErrorBoundary";
import {
  getDescendantRows,
  getParentRows,
} from "../../../common/utils/data-table-utils";
import { CompareMetricsFeatureFlags } from "../../constants/compare-metrics-feature-flags";
import {
  type CompareMetricsTableRow,
  type FetchChildrenTableRequest,
  type RefetchTableRequest,
} from "../../models/compare-metrics-data-table-models";
import {
  useGetInitialTableQuery,
  useLazyDownloadTableQuery,
  useLazyGetChildrenTableDataQuery,
  useLazyGetTableDataQuery,
} from "../../services/compare-metrics-data-table-api-slice";
import {
  onFocalItemChange,
  onInitialTableDataReceived,
  onSearchChange,
} from "../../services/compare-metrics-slice";
import {
  selectChartContentSelection,
  selectContributionMetrics,
  selectFocalItems,
  selectFocalItemTableRowSelectionState,
  selectInitialDataRecieved,
  selectLocalParametersConfig,
  selectLocalParametersInitialised,
  selectLocalParametersSelections,
  selectMetricSelectionSelection,
  selectSearchQuery,
  selectTableMetaData,
  selectTableRows,
  selectXAxisSelection,
} from "../../services/compare-metrics-slice-selectors";
import { getLocationForFileName } from "../../utils/getExportfunctions";
import {
  getUserSelections,
  getUserSelectionsForExport,
} from "../../utils/local-parameter-utils";
import styles from "./CompareMetricsTopDrawer.module.css";

export type CompareMetricsTopDrawerProps = {
  eventTrackingService: Function;
  renameReport: (newItemName: string, itemId: string) => Promise<void>;

  reportName: string;
  reportType: string;
};

export const CompareMetricsTopDrawer = ({
  eventTrackingService,
  renameReport,
  reportType,
  reportName,
}: CompareMetricsTopDrawerProps) => {
  const { name: divisionName } = useDivision();
  const { id: reportId } = useParams();
  const dispatch = useDispatch();
  const formatter = useFormatter();

  const searchQuery = useSelector(selectSearchQuery);
  const contributionMetrics = useSelector(selectContributionMetrics);
  const focalItems = useSelector(selectFocalItems);
  const xAxisSelection = useSelector(selectXAxisSelection);
  const chartContentSelection = useSelector(selectChartContentSelection);
  const metricSelection = useSelector(selectMetricSelectionSelection);
  const initialDataRecieved = useSelector(selectInitialDataRecieved);
  const localParameterSelections = useSelector(selectLocalParametersSelections);
  const localParametersConfig = useSelector(selectLocalParametersConfig);
  const localParametersInitialised = useSelector(
    selectLocalParametersInitialised
  );
  const tableMetaData = useSelector(selectTableMetaData);
  const tableRows = useSelector(selectTableRows);
  const rowSelection = useSelector(selectFocalItemTableRowSelectionState);

  const [expandState, setExpandState] = useState<ExpandedState>(true);

  const [focalItemDropdownIndex, setFocalItemDropdownIndex] =
    useState<number>(0);

  const featureFlags = useFlags();
  const isExportEnabled =
    featureFlags[CompareMetricsFeatureFlags.ReportExport] ?? false;

  const actualMetric = useMemo(
    () =>
      contributionMetrics.find((x) => x.groupName === metricSelection)
        ?.metricNames[focalItemDropdownIndex] ?? metricSelection,
    [contributionMetrics, focalItemDropdownIndex, metricSelection]
  );

  const getFormattedHeader = useCallback(
    (header: string, levelType: string) => {
      const localParameterOptions = localParametersConfig.find(
        (x) => x.id === levelType
        // @ts-expect-error: handling undefined case in if condition below
      )?.selections;

      if (!localParameterOptions) return header;

      if (levelType === "LocationHierarchy") {
        return localParameterOptions?.find(
          (x: LocalHierarchyNodeSelection) => x.nodeNumber === Number(header)
        ).name;
      } else if (levelType === "Segmentation") {
        return localParameterOptions?.find(
          (x: LocalSegmentationSelection) => x.segmentValue === header
        ).segmentLabel;
      } else {
        return localParameterOptions?.find(
          (x: LocalStringSelection) => x.value === header
        )?.label;
      }
    },
    [localParametersConfig]
  );

  const isContributionMetric = useMemo(
    () => contributionMetrics.some((x) => x.groupName === metricSelection),
    [contributionMetrics, metricSelection]
  );

  // reset focal item dropdown each time user changes Metric reportlet dropdown
  useEffect(() => {
    setFocalItemDropdownIndex(0);
  }, [metricSelection]);

  const genericColumnProperties = useMemo(
    () => ({
      cell: ({ getValue }: CellContext<CompareMetricsTableRow, unknown>) =>
        ValueCell({
          formatter: formatter(
            tableMetaData.metric.format === ""
              ? MetricTypes.None
              : tableMetaData.metric.format
          ),
          hasSentiment: isContributionMetric,
          value: String(getValue()),
        }),
      enableHiding: false,
      enableResizing: true,
      enableSorting: false,
    }),
    [formatter, isContributionMetric, tableMetaData.metric.format]
  );

  const dataColumns = useMemo(
    () => ({
      ...genericColumnProperties,
      header:
        (isContributionMetric ? metricSelection + " - " : "") + actualMetric,
      accessorFn: (row: CompareMetricsTableRow) => row.data[0],
      id: uniqueId(),
      columns: tableMetaData.headers[1]?.options.map(
        (headerLevel1, index1) => ({
          ...genericColumnProperties,
          id: uniqueId(),
          header: getFormattedHeader(
            headerLevel1,
            tableMetaData.headers[1].levelType
          ),
          accessorFn: (row: CompareMetricsTableRow) => row.data[index1],
          columns: tableMetaData.headers[2]?.options.map(
            (headerLevel2, index2) => ({
              ...genericColumnProperties,
              id: uniqueId(),
              header: getFormattedHeader(
                headerLevel2,
                tableMetaData.headers[2].levelType
              ),
              accessorFn: (row: CompareMetricsTableRow) =>
                row.data[
                  index1 * tableMetaData.headers[2]?.options.length + index2
                ],
            })
          ),
        })
      ),
    }),
    [
      actualMetric,
      genericColumnProperties,
      getFormattedHeader,
      isContributionMetric,
      metricSelection,
      tableMetaData.headers,
    ]
  );

  // Initial Table
  const { data: initialTableData, isFetching: isInitialTableFetching } =
    useGetInitialTableQuery(
      {
        divisionName,
        payload: {
          localSelectedValues: getUserSelections(localParameterSelections),
          defaultFocalItemNodes: focalItems,
          reportId: reportId ?? "",
          isContributionMetric,
          xAxisSelection,
          chartContentSelection,
          metric: actualMetric,
        },
      },
      {
        skip: !localParametersInitialised || initialDataRecieved,
      }
    );

  const [itemsToRefetch, setItemsToRefetch] = useState<number[]>([]);

  // Set the initial displayed nodes
  useEffect(() => {
    if (initialTableData) {
      dispatch(onInitialTableDataReceived(initialTableData));
      setItemsToRefetch(getParentRows(initialTableData.tableRows));
    }
  }, [dispatch, initialTableData]);

  // Refetch Table
  const [refetchTableQueryTrigger, { isFetching: refetchTableFetching }] =
    useLazyGetTableDataQuery({
      refetchOnFocus: true,
    });

  const fetchTable = useCallback(async () => {
    const payload: RefetchTableRequest = {
      localSelectedValues: getUserSelections(localParameterSelections),
      parentNodes: itemsToRefetch,
      reportId: reportId ?? "",
      isContributionMetric,
      xAxisSelection,
      chartContentSelection,
      metric: actualMetric,
      searchQuery,
    };

    await refetchTableQueryTrigger({
      divisionName,
      payload,
    });
  }, [
    localParameterSelections,
    itemsToRefetch,
    reportId,
    isContributionMetric,
    xAxisSelection,
    chartContentSelection,
    actualMetric,
    searchQuery,
    refetchTableQueryTrigger,
    divisionName,
  ]);

  useEffect(() => {
    if (initialDataRecieved && localParametersInitialised) {
      fetchTable().catch((error) => {
        ddLog("ERROR", {}, "error", error);
      });
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * only trigger refetch for table when local parameters, xAxis, chart selection or actual metric changes
     * everything else should be ignored
     */
  }, [
    localParameterSelections,
    xAxisSelection,
    chartContentSelection,
    actualMetric,
    searchQuery,
  ]);

  // Fetch Children
  const [isRowFetchingChildren, setIsRowFetchingChildren] = useState<{
    [id: number]: boolean;
  }>({});

  const [fetchChildrenQueryTrigger] = useLazyGetChildrenTableDataQuery({
    refetchOnFocus: false,
  });

  const fetchChildren = useCallback(
    async (parentNode: CompareMetricsTableRow) => {
      const payload: FetchChildrenTableRequest = {
        localSelectedValues: getUserSelections(localParameterSelections),
        parentNode: parentNode.hierarchyItem.nodeNumber,
        reportId: reportId ?? "",
        isContributionMetric,
        xAxisSelection,
        chartContentSelection,
        metric: actualMetric,
      };

      setIsRowFetchingChildren({ [parentNode.hierarchyItem.nodeNumber]: true });

      await fetchChildrenQueryTrigger({
        divisionName,
        payload,
      });

      setIsRowFetchingChildren({
        [parentNode.hierarchyItem.nodeNumber]: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chartContentSelection,
      isContributionMetric,
      localParameterSelections,
      reportId,
      fetchChildrenQueryTrigger,
      divisionName,
      xAxisSelection,
    ]
  );

  const collapseRow = useCallback(
    (row: CompareMetricsTableRow) => {
      const rowsToCollapse = getDescendantRows(row);
      rowsToCollapse.push(row.hierarchyItem.nodeNumber);
      const filtered = itemsToRefetch.filter(
        (item) => !rowsToCollapse.includes(item)
      );
      setItemsToRefetch(filtered);
    },
    [itemsToRefetch]
  );

  const expandRow = useCallback(
    (row: CompareMetricsTableRow) => {
      setItemsToRefetch([...itemsToRefetch, row.hierarchyItem.nodeNumber]);
    },
    [itemsToRefetch]
  );

  const isSearchMode = useMemo(() => searchQuery.length > 0, [searchQuery]);

  // Create the expandable cells for the first columns
  const createNameCell = useCallback(
    ({ row, getValue }: CellContext<CompareMetricsTableRow, unknown>) => (
      <ExpandableNameCell
        canExpand={!row.original.hierarchyItem.isLeaf && !isSearchMode}
        depth={row.depth}
        handleToggleExpanded={(event) => {
          event.stopPropagation();
          if (itemsToRefetch.includes(row.original.hierarchyItem.nodeNumber)) {
            collapseRow(row.original);
          } else {
            if (row.subRows.length === 0) {
              fetchChildren(row.original).catch((error) => {
                ddLog("ERROR", {}, "error", error);
              });
              // when fetching children, the row doesn't expand on first click so
              // force it to open manually
              setExpandState((previousState) =>
                typeof previousState === "boolean"
                  ? true
                  : {
                      ...previousState,
                      [row.original.hierarchyItem.nodeNumber]: true,
                    }
              );
            }

            expandRow(row.original);
          }

          row.getToggleExpandedHandler()();
        }}
        handleToggleSelected={(event) => {
          row.getToggleSelectedHandler()(event);

          const currentFocalItems = row.getIsSelected()
            ? focalItems.filter(
                (value) =>
                  value.nodeNumber !== row.original.hierarchyItem.nodeNumber
              )
            : [...focalItems, row.original.hierarchyItem];
          eventTrackingService(
            [
              TrackingComponent.MyReports,
              TrackingComponent.Report,
              TrackingComponent.FocalItemTableRow,
            ],
            row.getIsSelected()
              ? TrackingEvent.Unselected
              : TrackingEvent.Selected,
            {
              selection: currentFocalItems.map(
                ({ shortName, name }) => `${shortName}:${name}`
              ),
            }
          );

          dispatch(onFocalItemChange(row));
        }}
        isExpanded={row.getIsExpanded() && row.original.subRows.length > 0}
        isExpanding={
          isRowFetchingChildren[row.original.hierarchyItem.nodeNumber] &&
          row.original.subRows.length === 0
        }
        isSelected={row.getIsSelected()}
        name={row.original.hierarchyItem.name}
        shortName={row.original.hierarchyItem.shortName}
        type={HierarchyItemType.Hierarchy}
        value={String(getValue())}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collapseRow,
      dispatch,
      expandRow,
      itemsToRefetch,
      fetchChildren,
      focalItems,
      isRowFetchingChildren,
      isSearchMode,
    ]
  );

  const displayEmptySearch =
    isSearchMode && !refetchTableFetching && tableRows.length === 0;

  const memoizedSearchBox = useMemo(
    () => (
      <div className={styles.searchbox}>
        <SearchBox
          enableDebounce
          onChange={(value: string) => dispatch(onSearchChange(value))}
          placeholder="Type to search"
          resultCount={isSearchMode ? tableRows.length : undefined}
          searchQuery={searchQuery}
        />
      </div>
    ),
    [isSearchMode, tableRows.length, dispatch, searchQuery]
  );

  const FocalItemTableDropdown = useMemo(
    () => {
      const dropdownItems = contributionMetrics
        .find((x) => x.groupName === metricSelection)
        ?.metricNames.map((option) => (
          <SelectOption key={uniqueId()} text={option} value={option} />
        ));

      return (
        <Select
          height={FormInputHeight.XSmall}
          id="contribution-metric-select"
          key="contribution-metric-select"
          onChange={(event) => {
            setFocalItemDropdownIndex(
              contributionMetrics
                .find((x) => x.groupName === metricSelection)
                ?.metricNames.indexOf(event.target.value) ?? 0
            );
          }}
          value={actualMetric}
          width={FormInputWidth.XSmall}
        >
          {dropdownItems}
        </Select>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metricSelection, actualMetric]
  );

  const [downloadTableTrigger] = useLazyDownloadTableQuery();
  const fileName = useMemo(
    () =>
      cleanFilename(
        `Compare_Metrics_${
          localParameterSelections.Time[0].value
        }_${getLocationForFileName(localParameterSelections.LocationHierarchy)}`
      ),
    [localParameterSelections]
  );

  const exportToCsvCallback = useCallback(async () => {
    await downloadTableTrigger({
      divisionName,
      payload: {
        localSelectedValues: getUserSelections(localParameterSelections),
        localParameters: getUserSelectionsForExport(
          localParameterSelections,
          localParametersConfig
        ),
        fileName,
        reportId: reportId ?? "",
        isContributionMetric,
        xAxisSelection,
        chartContentSelection,
        metric: actualMetric,
        metricSelection,
      },
    });
  }, [
    downloadTableTrigger,
    divisionName,
    fileName,
    reportId,
    localParameterSelections,
    isContributionMetric,
    xAxisSelection,
    chartContentSelection,
    actualMetric,
    metricSelection,
    localParametersConfig,
  ]);

  const columnDefs: Array<ColumnDef<CompareMetricsTableRow>> = useMemo(
    () => [
      {
        ...genericColumnProperties,
        id: uniqueId(),
        cell: createNameCell,
        // eslint-disable-next-line react/no-unstable-nested-components
        header: ({ header }) =>
          header.depth === 1 ? memoizedSearchBox : <div />,
        accessorFn: (row: CompareMetricsTableRow) => row.hierarchyItem.name,
      },
      dataColumns,
    ],
    [createNameCell, dataColumns, genericColumnProperties, memoizedSearchBox]
  );

  return (
    <ReportTopDrawer
      controls={[
        isContributionMetric && FocalItemTableDropdown,
        <div key={reportId}>
          <DataTableOptions
            filename={fileName}
            invokeCSVDownload={exportToCsvCallback}
            isFeatureEnabled={isExportEnabled}
            isInFocalItemHeader
            key={reportId}
          />
        </div>,
      ]}
      items={focalItems.map((row) => ({
        code: row.shortName,
        displayName: row.name,
        type: HierarchyItemType.Hierarchy,
      }))}
      renameReport={renameReport}
      reportId={reportId}
      reportName={reportName}
      reportType={reportType}
    >
      <ErrorBoundary>
        <ReportHierarchyTableWrapper
          isSuccess={!isInitialTableFetching && initialDataRecieved}
        >
          <VirtuosoTableComponent
            className={styles.focalItemTable}
            columns={columnDefs}
            data={tableRows}
            getRowId={(row: CompareMetricsTableRow) =>
              row.hierarchyItem.nodeNumber.toString()
            }
            getSubRows={(row: CompareMetricsTableRow) => row.subRows}
            onExpandedChange={setExpandState}
            pinFirstColumn
            refreshingData={refetchTableFetching}
            rowExpandedState={expandState}
            rowSelectionState={rowSelection}
            showCheckboxesOnlyOnHover
          />
          {displayEmptySearch && (
            <div className={styles.emptySearch}>
              <EmptySearch />
            </div>
          )}
        </ReportHierarchyTableWrapper>
      </ErrorBoundary>
    </ReportTopDrawer>
  );
};

export default CompareMetricsTopDrawer;

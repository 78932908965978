import { type FormBlockEditability } from "@qbit/react";
import { type Option } from "@qbit/react/dist/typeahead";
import {
  ParameterId,
  type HierarchySliceNodeDto,
  type LocalStringSelection,
  type LocalHierarchyNodeSelection,
} from "@quantium-enterprise/common-ui";
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import { SingleSelectType } from "components-ui/src/local-filters/SingleSelectFilter";
import { type IndexAgainstOption } from "components-ui/src/local-filters/indexedMetricFilter/IndexedMetricFilter";
import { type SegmentOption } from "components-ui/src/local-filters/segmentFilter/SegmentFilter";
import {
  type PanelOption,
  type FixedLocationHierarchyPanel,
  type PlainTextContentPanel,
  type HierarchySelectPanel,
  type IndexedMetricPanel,
  type SegmentationSelectPanel,
  type SingleSelectPanel,
  type MultiSelectPanel,
  PanelType,
  type TimePeriodPanel,
  type MultiCheckboxPanel,
  type SliderPanel,
} from "components-ui/src/local-parameters-panel/FixedSidePanel";
import {
  type SidePanelSegmentationGroupSelectionParameter,
  type SidePanelGroupParameter,
  type SidePanelPlainTextContentParameter,
  type SidePanelHierarchySelectionParameter,
  type SidePanelIndexedMetricParameter,
  type SidePanelSegmentationParameter,
  type SidePanelTimePeriodParameter,
  type SidePanelSingleSelectionParameter,
  type SidePanelLocationHierarchyParameter,
  type SidePanelMultiSelectionParameter,
  type SidePanelSliderParameter,
} from "../../models/local-parameters/SidePanelParameters";
import { LocalParametersTooltip } from "./LocalParametersTooltip";

export const getSingleSelectLabelElement = (
  parameterId: string,
  selection: LocalStringSelection
) => {
  if (
    (parameterId === ParameterId.Channel ||
      parameterId === ParameterId.Promotion) &&
    selection.value === "All"
  ) {
    return (
      <LocalParametersTooltip
        label={selection.label}
        parameterId={parameterId}
      />
    );
  } else {
    return undefined;
  }
};

export const buildLocationHierarchyPanel = (
  localParameter: SidePanelLocationHierarchyParameter,
  onSelection: (selection: LocalHierarchyNodeSelection) => void,
  selectedValue: LocalHierarchyNodeSelection
) => ({
  allSelections: localParameter.selections,
  id: localParameter.id,
  label: localParameter.name,
  onSelection,
  panelType: PanelType.LOCATION,
  selectedValue,
});

export const buildNoDataAvailableLocationHierarchyPanel = (
  parameterId: string
): PlainTextContentPanel => ({
  id: parameterId,
  label: "Location",
  plainTextContent: "No data exists for report parameter selections.",
  panelType: PanelType.PLAIN_TEXT_CONTENT,
});

export const buildFixedLocationHierarchyPanel = (
  locationHierarchyParameter: SidePanelHierarchySelectionParameter,
  locationGroupParameter?: SidePanelGroupParameter
): FixedLocationHierarchyPanel => {
  let panel: FixedLocationHierarchyPanel = {
    id: locationHierarchyParameter.id,
    label: locationHierarchyParameter.name,
    locationHierarchySelections: locationHierarchyParameter.selections,
    panelType: PanelType.FIXED_LOCATION,
  };

  if (locationGroupParameter) {
    panel = {
      ...panel,
      locationGroupSelections: locationGroupParameter.selections,
    };
  }

  return panel;
};

export const buildFixedLocationHierarchyPanelWithPrefix = (
  locationHierarchyParameter: SidePanelHierarchySelectionParameter,
  locationGroupParameter?: SidePanelGroupParameter
): FixedLocationHierarchyPanel => {
  let panel: FixedLocationHierarchyPanel = {
    id: locationHierarchyParameter.id,
    label: locationHierarchyParameter.name,
    locationHierarchySelections: locationHierarchyParameter.selections,
    panelType: PanelType.FIXED_LOCATION,
    hasShortNamePrefix: true,
  };

  if (locationGroupParameter) {
    panel = {
      ...panel,
      locationGroupSelections: locationGroupParameter.selections,
    };
  }

  return panel;
};

export const buildHierarchySelectPanel = (
  localParameter: SidePanelHierarchySelectionParameter,
  onSelection: (item: string, level?: string) => void,
  selectedValues: string[]
): HierarchySelectPanel => {
  const selections = localParameter.selections;
  const levelNames = [
    ...new Set(
      selections.map((selection) => selection.hierarchyItem.shortName)
    ),
  ];
  const levels = levelNames.map((levelName) => {
    const selectionsForLevel = selections
      .filter((selection) => selection.hierarchyItem.shortName === levelName)
      .map((selection) => ({
        isDefault: selection.isDefault,
        label: selection.hierarchyItem.name,
        value: selection.hierarchyItem.itemCode,
      }));
    return {
      name: levelName,
      options: [{ options: selectionsForLevel }],
    };
  });

  return {
    id: localParameter.id,
    label: localParameter.name,
    levels,
    onSelection,
    panelType: PanelType.HIERARCHY,
    selectedLevel: selectedValues[0],
    selectedValue: selectedValues[1],
  };
};

export const buildIndexedMetricPanel = (
  localParameter: SidePanelIndexedMetricParameter,
  onSelection: (metric: PanelOption, indexedAgainst: PanelOption) => void,
  selectedIndexedAgainstValue: string,
  selectedMetricValue: PanelOption,
  focalItems: HierarchySliceNodeDto[],
  filterIndexAgainst?: (option: IndexAgainstOption) => boolean
): IndexedMetricPanel => ({
  filterIndexAgainst,
  focalItems,
  id: localParameter.id,
  indexAgainstOptions: localParameter.selections.flatMap((selection) =>
    selection.indexedAgainst
      ? {
          hierarchyLabel: selection.indexedAgainst.label,
          label: selection.label,
          value: selection.value,
          shortName: selection.indexedAgainst.value,
        }
      : []
  ),
  label: localParameter.name,
  metricOptions: localParameter.selections
    .map((selection) => ({
      label: selection.label,
      value: selection.indexedAgainst ? selection.label : selection.value,
      enableIndexAgainst: Boolean(selection.indexedAgainst),
    }))
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (selection) =>
            selection.label === value.label && selection.value === value.value
        )
    ),
  onSelection,
  panelType: PanelType.INDEXED_METRIC,
  secondaryTitle: localParameter.secondaryTitle,
  selectedIndexedAgainstValue,
  selectedMetricValue,
});

export const buildSegmentationPanel = (
  localParameter: SidePanelSegmentationParameter,
  onSelection: (values: SegmentOption) => void,
  selectedValues: string[]
): SegmentationSelectPanel => ({
  id: localParameter.id,
  label: localParameter.name,
  isDisabled: localParameter.isDisabled,
  secondaryTitle: localParameter.secondaryTitle,
  onSelection,
  panelType: PanelType.SEGMENTATION,
  segmentOptions: localParameter.selections.map((selection) => ({
    segmentationLabel: selection.label,
    segmentationValue: selection.value,
    segmentLabel: selection.segmentLabel,
    segmentValue: selection.segmentValue,
    optionDisabled: selection.optionDisabled ?? false,
  })),
  selectedValues,
});

export const buildSliderPanel = (
  localParameter: SidePanelSliderParameter,
  onChange: (value: number) => void,
  editability: FormBlockEditability,
  currentValue?: number,
  summary?: string,
  stepSize?: number,
  formatter?: FormatterFunction,
  percentageThresholds?: { maximum: number; minimum: number }
): SliderPanel => ({
  id: localParameter.id,
  label: localParameter.name,
  panelType: PanelType.SLIDER,
  maxValue: localParameter.maximumPossibleValue,
  minValue: localParameter.minimumPossibleValue,
  editability,
  currentValue,
  onChange,
  stepSize,
  summary,
  formatter,
  percentageThresholds,
});

export const buildSegmentationGroupPanel = (
  localParameter: SidePanelSegmentationGroupSelectionParameter,
  onSelection: (value: PanelOption) => void,
  selectedValue: string
): SingleSelectPanel => ({
  displayFieldType:
    localParameter.displayType === "Radio"
      ? SingleSelectType.Radio
      : SingleSelectType.Dropdown,
  id: localParameter.id,
  label: localParameter.name,
  onSelection,
  panelType: PanelType.SINGLE,
  selectedValue,
  selections: localParameter.selections.map((selection) => ({
    label: selection.name,
    value: selection.id,
    optionDisabled: selection.optionDisabled,
  })),
});

export const buildSingleSelectPanel = (
  localParameter: SidePanelSingleSelectionParameter,
  onSelection: (value: PanelOption) => void,
  selectedValue: string
): SingleSelectPanel => ({
  displayFieldType:
    localParameter.displayType === "Radio"
      ? SingleSelectType.Radio
      : SingleSelectType.Dropdown,
  id: localParameter.id,
  label: localParameter.name,
  onSelection,
  panelType: PanelType.SINGLE,
  selectedValue,
  selections: localParameter.selections.map((selection) => ({
    label: selection.label,
    labelElement: getSingleSelectLabelElement(localParameter.id, selection),
    value: selection.value,
    optionDisabled: selection.optionDisabled,
  })),
});

export const buildMultiSelectPanel = (
  localParameter: SidePanelMultiSelectionParameter,
  onSelection: (value: Option[]) => void,
  selectedValues: string[]
): MultiSelectPanel => ({
  id: localParameter.id,
  label: localParameter.name,
  maxSelected: localParameter.maxSelections,
  onSelection,
  panelType: PanelType.MULTI,
  selectedValues,
  selections: localParameter.selections.map((selection) => ({
    label: selection.label,
    value: selection.value,
  })),
});

export const buildMultiCheckboxPanel = (
  isDisabled: (value: string) => boolean,
  localParameter: SidePanelSingleSelectionParameter,
  onSelection: (value: PanelOption) => void,
  selected: string[],
  subtitle: string,
  summary: string
): MultiCheckboxPanel => ({
  id: localParameter.id,
  label: localParameter.name,
  options: localParameter.selections,
  panelType: PanelType.MULTI_CHECKBOX,
  onSelection,
  isDisabled,
  selected,
  subtitle,
  summary,
});

export const buildMultiSegmentationCheckboxPanel = (
  isDisabled: (value: string) => boolean,
  localParameter: SidePanelSegmentationGroupSelectionParameter,
  onSelection: (value: PanelOption) => void,
  selected: string[],
  subtitle: string,
  summary: string
): MultiCheckboxPanel => ({
  id: localParameter.id,
  label: localParameter.name,
  options: localParameter.selections.map((selection) => ({
    label: selection.name,
    value: selection.id,
  })),
  panelType: PanelType.MULTI_CHECKBOX,
  onSelection,
  isDisabled,
  selected,
  subtitle,
  summary,
});

export const buildTimePeriodPanel = (
  localParameter: SidePanelTimePeriodParameter
): TimePeriodPanel => ({
  comparisonPeriod: localParameter.comparisonPeriod,
  focusPeriod: localParameter.focusPeriod,
  id: localParameter.id,
  label: localParameter.name,
  panelType: PanelType.TIME_PERIOD,
  rollingPeriod: localParameter.rollingPeriod,
  leadPeriod: localParameter.leadPeriod,
});

export const buildPlainTextContentPanel = (
  localParameter: SidePanelPlainTextContentParameter
): PlainTextContentPanel => ({
  id: localParameter.id,
  label: localParameter.name,
  plainTextContent: localParameter.plainTextContent,
  panelType: PanelType.PLAIN_TEXT_CONTENT,
});

import {
  IconSize,
  InlineIconGlyph,
  StealthInlineIconButton,
} from "@qbit/react";
import { useContext } from "react";
import { ReportViewContext } from "../report/ReportView";
import styles from "./FixedSidePanel.module.scss";

export const CollapseSidePanelIcon = () => {
  const { collapseSidePanel, setCollapseSidePanel } =
    useContext(ReportViewContext);

  const handleClick = (collapsed: boolean) => {
    setCollapseSidePanel(collapsed);
  };

  const glyph = collapseSidePanel
    ? InlineIconGlyph.ArrowsChevronRight
    : InlineIconGlyph.ArrowsChevronLeft;
  return (
    <div className={styles.collapseSidePanelIcon}>
      <StealthInlineIconButton
        iconGlyph={glyph}
        iconSize={IconSize.Medium}
        iconText={collapseSidePanel ? "Expand" : "Close"}
        onClick={() => {
          handleClick(!collapseSidePanel);
        }}
      />
    </div>
  );
};

export default CollapseSidePanelIcon;

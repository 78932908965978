import {
  FormBlock,
  FormBlockType,
  Input,
  NumberInput,
  Text,
  Group,
  Item,
  ItemValign,
  FormInputHeight,
  GroupGutters,
} from "@qbit/react";
import { useNumberFormat } from "@quantium-enterprise/hooks-ui";
import { type ColumnDef } from "@tanstack/react-table";
import classNames from "classnames";
import { BasicTable } from "components-ui/src/tables/basic-table/BasicTable";
import { useMemo, useCallback } from "react";
import { type AggregateRankMetricWeight } from "../../models/aggregate-rank-common-models";
import styles from "./AggregateRankModal.module.css";

export const AggregateRankWeightsContent = ({
  metricWeights,
  updateWeights,
}: {
  metricWeights: AggregateRankMetricWeight[];
  updateWeights: (metricName: string, newWeight: number) => void;
}) => {
  const { percentFormatter } = useNumberFormat();

  // true if at least one row in error state
  const globalErrorState = metricWeights.some((x) => x.errorState);

  const aggregateWeight = metricWeights.reduce(
    (partialSum, row) => partialSum + row.metricWeight,
    0
  );

  const headerCell = useCallback(
    (title: string) => <span className={styles.tableHeaderCell}>{title}</span>,
    []
  );

  const weightCell = useCallback(
    (rowData: AggregateRankMetricWeight) => (
      <div className={styles.weightInputCell}>
        <Group gutters={GroupGutters.Large}>
          <Item fixedWidth="5rem" valign={ItemValign.Middle}>
            <FormBlock blockType={FormBlockType.Number}>
              <Input>
                <NumberInput
                  className={classNames({
                    [styles.weightInputError]: rowData.errorState,
                  })}
                  // @ts-expect-error -property works, but undefined in NumberInput type
                  defaultValue={rowData.metricWeight}
                  height={FormInputHeight.XSmall}
                  min={1}
                  onBlur={(event) => {
                    updateWeights(
                      rowData.metricLabel,
                      event.target.value === "" ? 1 : Number(event.target.value)
                    );
                  }}
                  onChange={(event) => {
                    // this if condition prevents weights being updated after every keypress
                    if (!event.nativeEvent.inputType) {
                      updateWeights(
                        rowData.metricLabel,
                        event.target.value === ""
                          ? 1
                          : Number(event.target.value)
                      );
                    }
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onKeyDown={(event: any) => {
                    if (event?.key === "Enter") event.target.blur();
                    // prevent non-numeric input
                    else if (
                      (event?.keyCode < 48 || event?.keyCode > 57) &&
                      !(
                        event?.key === "Backspace" ||
                        event?.key === "Delete" ||
                        event?.key === "Tab"
                      )
                    )
                      event.preventDefault();
                  }}
                  step={1}
                />
              </Input>
            </FormBlock>
          </Item>
          <Item valign={ItemValign.Middle}>
            <Text className={styles.weightPercentage}>
              {globalErrorState
                ? "-"
                : percentFormatter(rowData.metricWeight / aggregateWeight)}
            </Text>
          </Item>
        </Group>
        {rowData.errorState && (
          <div className={styles.weightInputErrorMessage}>
            Number must be ≥ 1
          </div>
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalErrorState, aggregateWeight, percentFormatter, updateWeights]
  );

  const memoizedDefaultColumnDefs: Array<ColumnDef<AggregateRankMetricWeight>> =
    useMemo(
      () => [
        {
          id: "Ranked metrics",
          header: () => headerCell("Ranked metrics"),
          enableResizing: false,
          accessorFn: (row: AggregateRankMetricWeight) => row,
          cell: ({ getValue }: { getValue: Function }) =>
            getValue().metricLabel,
          enableSorting: false,
          size: 300,
        },
        {
          id: "Weights",
          header: () => headerCell("Weights"),
          enableResizing: false,
          accessorFn: (row: AggregateRankMetricWeight) => row,
          cell: ({ getValue }: { getValue: Function }) =>
            weightCell(getValue()),
          enableSorting: false,
          maxSize: 80,
        },
      ],
      [weightCell, headerCell]
    );

  return (
    <div
      className={classNames(
        styles.modalContentContainer,
        styles.weightsContainer
      )}
    >
      <BasicTable columns={memoizedDefaultColumnDefs} data={metricWeights} />
    </div>
  );
};

export default AggregateRankWeightsContent;

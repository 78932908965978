import { IconSize, InlineIconGlyph, InlineIcon } from "@qbit/react";
import styles from "./ListHeader.module.css";

export type ListHeaderProps = {
  headerText: string;
  infoIcon?: string;
};

export const ListHeader = ({ headerText, infoIcon }: ListHeaderProps) => (
  <div className={styles.container}>
    <div className={styles.textContainer}>
      <h6 className={styles.headerText}>{headerText}</h6>
    </div>

    {infoIcon && (
      <div>
        <InlineIcon
          className={styles.infoIcon}
          data-testid="info-icon"
          glyph={InlineIconGlyph.AlertsAndNotificationsInform}
          size={IconSize.Small}
          text={infoIcon}
        />
      </div>
    )}
  </div>
);

export default ListHeader;

import tokens from "@qbit/core/dist/tokens.json";
import {
  type PlotLineDataLabelsOptions,
  type SeriesLineOptions,
} from "highcharts";
import { type PercentileSeries } from "../models/PercentileSeries";

const DEFAULT_DATA_LABEL_STYLE = {
  color: tokens.colour.black,
  textOutline: "none",
  fontWeight: tokens.font.body.weight.medium.toString(),
  fontFamily: tokens.font.body.family,
};

export const VERTICAL_DATA_LABELS: PlotLineDataLabelsOptions[] = [
  {
    verticalAlign: "bottom",
    inside: true,
    format: "{point.series.name}",
    allowOverlap: true,
    crop: false,
    rotation: -90,
    align: "left",
    x: -10,
    y: -160,
    style: DEFAULT_DATA_LABEL_STYLE,
  },
];

export const HORIZONTAL_DATA_LABELS: PlotLineDataLabelsOptions[] = [
  {
    align: "right",
    inside: true,
    format: "{point.series.name}",
    style: DEFAULT_DATA_LABEL_STYLE,
    allowOverlap: true,
    crop: false,
  },
];

export const PERCENTILES_CHART_CONFIG: SeriesLineOptions = {
  type: "line",
  dashStyle: "Dot",
  color: "black",
  marker: {
    enabled: false,
  },
  states: {
    hover: {
      lineWidth: 0,
    },
  },
  enableMouseTracking: false,
};

export const getPercentileConfig = (
  percentiles: PercentileSeries[],
  vertical?: boolean,
  color?: string
): SeriesLineOptions[] => {
  let config = percentiles.map((percentile) => ({
    ...PERCENTILES_CHART_CONFIG,
    custom: { key: percentile.key },
    name: percentile.name,
    visible: percentile.visible,
    // highcharts requires that you make a copy of this instead of passing it along via pointer
    data: [...percentile.data],
  }));

  if (color) {
    config = config.map((configItem) => ({ ...configItem, color }));
  }

  if (vertical) {
    config = config.map((configItem) => ({
      ...configItem,
      dataLabels: VERTICAL_DATA_LABELS,
      label: {
        enabled: false,
      },
    }));
  } else {
    config = config.map((configItem) => ({
      ...configItem,
      dataLabels: HORIZONTAL_DATA_LABELS,
      label: {
        enabled: false,
      },
    }));
  }

  return config;
};

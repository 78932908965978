import {
  FormBlock,
  FormBlockType,
  FormInputHeight,
  FormInputWidth,
  Input,
  InputInfo,
  RangeInput,
  TextInput,
} from "@qbit/react";
import { useState } from "react";
import { type ToggleButton } from "../../../button-toggle-group/ButtonToggleGroup";
import { ButtonToggleGroup } from "../../../button-toggle-group/ButtonToggleGroup";
import styles from "./ChartSizeControl.module.css";

export type ChartSize = { heightPx: number; widthPx: number };

export type ChartSizeControlProps = {
  currentChartSize: ChartSize;
  setChartSize: (size: ChartSize) => void;
};

const PresetSizePicker = ({
  currentChartSize,
  setChartSize,
}: ChartSizeControlProps) => {
  const availablePresetSizeOptions = [
    { widthPx: 720, heightPx: 480 },
    { widthPx: 900, heightPx: 600 },
    { widthPx: 960, heightPx: 640 },
    { widthPx: 1_080, heightPx: 720 },
    { widthPx: 1_920, heightPx: 1_080 },
  ];

  const defaultPresetSizeIndex = 2;
  const defaultPresetSize = availablePresetSizeOptions[defaultPresetSizeIndex];

  let selectedPresetSizeIndex: number;

  if (
    availablePresetSizeOptions.some(
      (preset) =>
        currentChartSize.heightPx === preset.heightPx &&
        currentChartSize.widthPx === preset.widthPx
    )
  ) {
    selectedPresetSizeIndex = availablePresetSizeOptions
      .map((preset) => preset.widthPx)
      .indexOf(currentChartSize.widthPx);
  } else {
    // If we already have a size which isn't part of our presets, set a default.
    setChartSize(defaultPresetSize);
    selectedPresetSizeIndex = defaultPresetSizeIndex;
  }

  const selectedOption = availablePresetSizeOptions[selectedPresetSizeIndex];
  return (
    <FormBlock blockType={FormBlockType.Range}>
      <Input>
        <RangeInput
          ariaDescribedby="chartpngexportsizeinfo"
          id="chartpngexportsize"
          max={availablePresetSizeOptions.length}
          min={1}
          onChange={(event) => {
            setChartSize(availablePresetSizeOptions[event.target.value - 1]);
          }}
          step={1}
          value={(selectedPresetSizeIndex + 1).toString()}
          // WARNING: The qbit RangeInput runs on a 1 based index...
        />
        <InputInfo id="chartpngexportsizeinfo">
          <span>{`${selectedOption.widthPx} x ${selectedOption.heightPx}`}</span>
        </InputInfo>
      </Input>
    </FormBlock>
  );
};

const isValidDimension = (input: string) => {
  if (Number.isNaN(Number(input))) return false;

  const inputAsNumber = Number(input);

  if (inputAsNumber < 0) return false;

  return true;
};

const CustomSizePicker = ({
  currentChartSize,
  setChartSize,
}: ChartSizeControlProps) => (
  <div className={styles.customSizePickerContainer}>
    <div className={styles.inputContainer}>
      <span>W</span>
      <TextInput
        height={FormInputHeight.XSmall}
        id="customWidthInput"
        onChange={(event) =>
          isValidDimension(event.target.value)
            ? setChartSize({
                ...currentChartSize,
                widthPx: Math.min(Number(event.target.value), 5_000),
              })
            : undefined
        }
        value={currentChartSize.widthPx.toString()}
        width={FormInputWidth.XXLarge}
      />
    </div>
    <div className={styles.inputContainer}>
      <span>H</span>
      <TextInput
        height={FormInputHeight.XSmall}
        id="customHeightInput"
        onChange={(event) =>
          isValidDimension(event.target.value)
            ? setChartSize({
                ...currentChartSize,
                heightPx: Math.min(Number(event.target.value), 5_000),
              })
            : undefined
        }
        value={currentChartSize.heightPx.toString()}
        width={FormInputWidth.XXLarge}
      />
    </div>
  </div>
);

enum AvailableButtons {
  Custom = "Custom",
  Preset = "Preset",
}

export const ChartSizeControl = ({
  currentChartSize,
  setChartSize,
}: ChartSizeControlProps) => {
  const [selectedButton, setSelectedButton] = useState<AvailableButtons>(
    AvailableButtons.Preset
  );

  return (
    <div>
      <span className={styles.chartSizeHeader}>Chart size</span>
      <ButtonToggleGroup
        buttonSelected={selectedButton}
        buttons={
          [
            {
              displayText: AvailableButtons.Preset,
              id: AvailableButtons.Preset,
            },
            {
              displayText: AvailableButtons.Custom,
              id: AvailableButtons.Custom,
            },
          ] as Array<ToggleButton<string>>
        }
        setButtonSelected={(button) => {
          setSelectedButton(button as AvailableButtons);
        }}
      />
      {selectedButton === AvailableButtons.Preset && (
        <PresetSizePicker
          currentChartSize={currentChartSize}
          setChartSize={setChartSize}
        />
      )}
      {selectedButton === AvailableButtons.Custom && (
        <CustomSizePicker
          currentChartSize={currentChartSize}
          setChartSize={setChartSize}
        />
      )}
    </div>
  );
};

import {
  AnchorButton,
  ButtonVariant,
  Icon,
  IconGlyph,
  Text,
} from "@qbit/react";
import {
  getUserColour,
  getUserInitials,
  MY_DASHBOARD_PATH,
  type Division,
  type UserDto,
} from "@quantium-enterprise/common-ui";
import { FastReportingSearchSection } from "@quantium-enterprise/fast-reporting-ui";
import { CircleIcon, CircleIconSize } from "components-ui/src/icons";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReportTabsHeaderSection } from "report-tabs-ui";
import styles from "./AppHeader.module.css";

type HeaderProps = {
  activeDivision: Division;
  user: UserDto;
};

export const AppHeader = ({ user, activeDivision }: HeaderProps) => {
  const location = useLocation();

  const [fastReportingSearchVisible, setFastReportingSearchVisible] =
    useState<boolean>(false);

  // hook for handling toggling the visiblity of fast reporting search box header
  useEffect(() => {
    // if we are on the dashboard or being forced to the dashboard make the  fast reporting search box header not visible
    if (
      location.pathname.includes(MY_DASHBOARD_PATH) ||
      location.pathname.endsWith(activeDivision.name)
    ) {
      setFastReportingSearchVisible(false);
    } else {
      setFastReportingSearchVisible(true);
    }
  }, [location, activeDivision, setFastReportingSearchVisible]);

  return (
    <div className={styles.headerBox}>
      <div className={styles.headerDisplay}>
        <Link
          className="header-logo"
          title="Home"
          to={`/${activeDivision.name}`}
        >
          <img
            alt={activeDivision.displayName}
            className={activeDivision.name}
            data-testid="logo"
          />
        </Link>
        <div className={styles.headerFastReporting}>
          <FastReportingSearchSection
            placeholder="Search for products, brands, product groups, etc."
            visible={fastReportingSearchVisible}
          />
        </div>

        <ReportTabsHeaderSection />

        <div className={styles.headerProfile}>
          <div className={styles.headerProfileUser} title={user.fullName}>
            <CircleIcon
              color={getUserColour(user.salesForceId)}
              content={getUserInitials(user.firstName, user.lastName)}
              size={CircleIconSize.XSmall}
            />
          </div>
          <AnchorButton
            data-testid="logout"
            href="/sso-proxy/single-signout"
            onClick={() => sessionStorage.clear()}
            variant={ButtonVariant.Stealth}
          >
            <Icon glyph={IconGlyph.AccountAndUserLogout} text="Log out" />
            <Text>Log out</Text>
          </AnchorButton>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;

import { Button, ButtonVariant, Icon, IconGlyph, Text } from "@qbit/react";

type AddItemsButtonProps = {
  onClick: () => void;
};

export const AddItemsButton = ({ onClick }: AddItemsButtonProps) => (
  <Button
    data-cy="AddWatchlistItemButton"
    onClick={onClick}
    variant={ButtonVariant.Primary}
  >
    <Icon glyph={IconGlyph.AddAndPlusAddPlus} text="Add item" />
    <Text>Add item(s)</Text>
  </Button>
);

export default AddItemsButton;

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  Dialog,
  DialogWidth,
  EmptyState,
  Icon,
  IconGlyph,
  IllustrationSize,
  MessageVariant,
  Nav,
  NavFontWeight,
  NavLink,
  NavSize,
  NavVariant,
  QbitEmitToast,
  QbitToastMessage,
  SpotColour,
  StateType,
  Text,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import { EMIT_TOAST_DURATION } from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from "./MoveitemModal.module.scss";

const DEFAULT_FOLDER_COLOR = "#BFD0E0";

export type Folder = {
  color?: string;
  creationDateUTC: string;
  id: string;
  name: string;
  parentId?: string;
};

type DirectoryItem = {
  id?: string;
  name: string;
};

export type MoveItemModalSubmitProps = {
  isReport: boolean;
  itemId: string;
  newParentFolderId?: string;
};

export type MoveItemModalProps = {
  folders: Folder[];
  onClose: Function;
  onSubmit: (submitProps: MoveItemModalSubmitProps) => Promise<unknown>;
  rootFolderName?: string;
  selectedItemFolderId?: string;
  selectedItemId: string;
  show: boolean;
};

const Breadcrumbs = ({
  items,
  setCurrentFolderId,
}: {
  items: DirectoryItem[];
  setCurrentFolderId: Function;
}) => {
  const maxBreadcrumbCount = 3;

  const allBreadcrumbs = useMemo(() => {
    const firstBreadcrumb = (
      <NavLink className={styles.elipsisItem}>
        <div>
          <label htmlFor="directoryselect">...</label>
          <select
            id="directoryselect"
            onChange={(event) => {
              setCurrentFolderId(
                event.target.value === "root" ? undefined : event.target.value
              );
            }}
          >
            <option style={{ display: "none" }} />
            {items
              .slice(0, Math.max(0, items.length - maxBreadcrumbCount))
              .map(({ id, name }) => (
                // the root folder has id of null so we set the id manually to be root to be used in the onchange event above
                <option key={`bc-${id}`} value={id ?? "root"}>
                  {name}
                </option>
              ))}
          </select>
        </div>
      </NavLink>
    );

    return [firstBreadcrumb].concat(
      items
        .slice(Math.max(0, items.length - maxBreadcrumbCount))
        .map(({ id, name }) => (
          <NavLink
            className={styles.breadcrumbItem}
            key={`bc-${id}`}
            // @ts-expect-error: not valid type, but works
            onClick={() => setCurrentFolderId(id)}
          >
            {name}
          </NavLink>
        ))
    );
  }, [items, setCurrentFolderId]);

  return (
    <Nav
      className={classNames(styles.directoryBreadcrumbs, {
        [styles.hideElipsis]: items.length <= maxBreadcrumbCount,
      })}
      fontWeight={NavFontWeight.Regular}
      size={NavSize.Medium}
      variant={NavVariant.LocationBreadcrumb}
    >
      {allBreadcrumbs.map((breadcrumb) => (
        <div key={breadcrumb.key}>{breadcrumb}</div>
      ))}
    </Nav>
  );
};

export const MoveItemModal = ({
  folders,
  rootFolderName = "Generated Reports",
  selectedItemId,
  selectedItemFolderId,
  show,
  onClose,
  onSubmit,
}: MoveItemModalProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [currentFolderId, setCurrentFolderId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentFolderId(selectedItemFolderId);
  }, [selectedItemFolderId]);

  const directoryStructure: DirectoryItem[] = useMemo(() => {
    const directoryStack: DirectoryItem[] = [];
    const getSubFolders = (folderId: string) =>
      folders.find((folder) => folder.id === folderId);
    if (folders.length) {
      let currentId: string | undefined = currentFolderId;
      while (currentId) {
        const targetFolder = getSubFolders(currentId);
        currentId = targetFolder?.parentId;
        if (targetFolder) {
          directoryStack.push({
            id: targetFolder.id,
            name: targetFolder.name,
          });
        }
      }
    }

    directoryStack.push({ id: undefined, name: rootFolderName });

    return directoryStack.reverse();
  }, [currentFolderId, folders, rootFolderName]);

  const subFolders = useMemo(
    () =>
      folders.filter(
        (folder) =>
          // eslint-disable-next-line eqeqeq
          (folder.parentId == currentFolderId &&
            folder.id !== selectedItemId) ||
          (!currentFolderId && !folder.parentId)
      ),

    [currentFolderId, folders, selectedItemId]
  );

  return (
    <>
      {/* React portal used to have modal appear over the entire page and grey out everything */}
      {createPortal(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div aria-hidden="true" onClick={(event) => event.stopPropagation()}>
          <Dialog
            className={styles.modal}
            footer={
              <div className={styles.modalFooter}>
                <Button
                  className={styles.moveToFolderButton}
                  disabled={
                    directoryStructure.at(-1)?.id === selectedItemFolderId
                  }
                  height={ButtonHeight.XSmall}
                  loading={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    onSubmit({
                      itemId: selectedItemId,
                      isReport: false,
                      newParentFolderId: currentFolderId,
                    })
                      .then(() => {
                        QbitEmitToast(
                          <QbitToastMessage
                            content={<span />}
                            heading={<span>1 item moved</span>}
                            showCloseButton
                            showIcon
                            variant={MessageVariant.Success}
                          />,
                          {
                            autoClose: EMIT_TOAST_DURATION,
                          }
                        );
                        onClose();
                      })
                      .catch(() =>
                        QbitEmitToast(
                          <QbitToastMessage
                            content={<span />}
                            heading={<span>Failed to move item</span>}
                            showCloseButton
                            showIcon
                            variant={MessageVariant.Danger}
                          />,
                          {
                            autoClose: EMIT_TOAST_DURATION,
                          }
                        )
                      )
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                  text="Move to folder"
                  variant={ButtonVariant.Primary}
                />
                <Button
                  className={styles.marginLeft}
                  height={ButtonHeight.XSmall}
                  onClick={() => onClose()}
                  text="Cancel"
                  variant={ButtonVariant.Secondary}
                />
              </div>
            }
            header={
              <div>
                <div>
                  <div className={styles.modalHeader}>
                    <Text>Move 1 item</Text>
                    <Button
                      className={styles.closeDialogButton}
                      height={ButtonHeight.Small}
                      onClick={() => onClose()}
                      variant={ButtonVariant.Stealth}
                    >
                      <Icon
                        glyph={IconGlyph.DeleteAndCloseClose}
                        text="Close selection"
                      />
                    </Button>
                  </div>
                </div>
                <Breadcrumbs
                  items={directoryStructure}
                  setCurrentFolderId={setCurrentFolderId}
                />
              </div>
            }
            onClose={() => onClose()}
            show={show}
            titleId="move-item-modal"
            triggeredBy={triggerRef}
            width={DialogWidth.Small}
          >
            <div
              className={classNames(styles.folderWindow, {
                [styles.emptyFolder]: subFolders.length === 0,
              })}
            >
              {subFolders.length ? (
                subFolders.map((folder) => (
                  <button
                    className={styles.subFolder}
                    key={uniqueId()}
                    onClick={() => setCurrentFolderId(folder.id)}
                    type="button"
                  >
                    <div className={styles.lhsContent}>
                      <Icon
                        colour={folder.color ?? DEFAULT_FOLDER_COLOR}
                        glyph={IconGlyph.FilesAndFoldersFolder}
                        text="folder icon"
                      />
                      <Text className={styles.folderName}>{folder.name}</Text>
                    </div>
                    <Icon
                      glyph={IconGlyph.ArrowsChevronRight}
                      text="Open folder chevron"
                    />
                  </button>
                ))
              ) : (
                <EmptyState
                  description=""
                  heading="This folder is empty"
                  size={IllustrationSize.Large}
                  spotColour={SpotColour.Theme}
                  stateType={StateType.Folders}
                />
              )}
            </div>
          </Dialog>
        </div>,
        document.body
      )}
    </>
  );
};

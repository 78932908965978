import {
  Icon,
  IconColour,
  IconGlyph,
  Message,
  MessageVariant,
  Nav,
  NavButton,
  NavVariant,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  type CustomerGroupWithSharingDto,
  FeatureFlag,
  useGetCustomerGroupSharingActivitiesQuery,
  useGetUsersByIdQuery,
  CustomerGroupStatus,
  type CustomerGroupDtoWithDisplayName,
  useGetCustomerGroupQuery,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import { CustomerGroupSegments } from "components-ui/src/customer-group-segments/CustomerGroupSegments";
import { CustomerGroupSummary } from "components-ui/src/customer-group-summary/CustomerGroupSummary";
import { InfoPanelActivity } from "components-ui/src/info-panel/info-panel-body/InfoPanelActivity";
import { PanelWithSideDrawer } from "components-ui/src/panel-with-side-drawer/PanelWithSideDrawer";
import { useState, type PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { GroupType } from "../../enums/group-type";
import styles from "./GroupInfoPanel.module.css";
import { GroupInfoPanelHeader } from "./group-info-panel-header/GroupInfoPanelHeader";

export type CustomerGroupInfoPanelProps = {
  focalGroup: CustomerGroupWithSharingDto | undefined;
  onClose: () => void;
  showPanel: boolean;
};

export const CustomerGroupInfoPanel = ({
  children,
  onClose,
  showPanel,
  focalGroup,
}: PropsWithChildren<CustomerGroupInfoPanelProps>) => {
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const flags = useFlags();
  const division = useDivision();
  const divisionName = division.name;
  const { groupId } = useParams();

  const { data: customerGroupData } = useGetCustomerGroupQuery(
    {
      divisionName,
      groupId: groupId ?? "",
    },
    {
      skip: !(Boolean(divisionName) && Boolean(groupId)),
    }
  );

  const customerGroupDataWithDisplayName:
    | CustomerGroupDtoWithDisplayName
    | undefined = customerGroupData
    ? {
        ...customerGroupData,
        typeDisplayName: focalGroup?.typeDisplayName ?? "",
      }
    : undefined;

  const { data: sharingActivities, isLoading: isActivityLoading } =
    useGetCustomerGroupSharingActivitiesQuery(
      {
        divisionName,
        groupId: groupId ?? "",
      },
      {
        skip: !(
          Boolean(divisionName) &&
          Boolean(groupId) &&
          flags[FeatureFlag.SharingGroups]
        ),
      }
    );
  const { data: reportSharedUsers, isLoading: isReportSharedUsersLoading } =
    useGetUsersByIdQuery(
      {
        payload: {
          SalesforceUserIds:
            sharingActivities?.flatMap(
              (sharingActivity) => sharingActivity.salesforceUserIds
            ) ?? [],
        },
      },
      {
        skip: !(
          Boolean(divisionName) &&
          Boolean(groupId) &&
          Boolean(sharingActivities) &&
          Boolean(sharingActivities?.length) &&
          flags[FeatureFlag.SharingGroups]
        ),
      }
    );

  const label = (
    <div className={styles.infoPanelLabel}>
      <Icon
        colour={IconColour.Notification}
        glyph={IconGlyph.AccountAndUserAccount}
        text="Customer group"
      />
      <Text>{`${GroupType.Customer} group`}</Text>
    </div>
  );

  const infoPanelTabs = [
    {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          <CustomerGroupSummary
            group={customerGroupDataWithDisplayName}
            title="Group info"
          />
        </div>
      ),
      title: "Summary",
    },
  ];

  const showSegmentBuilder =
    flags[FeatureFlag.SegmentBuilder] &&
    division.moduleAccess.groups.customerGroups.segmentBuilder.hasAccess;

  if (showSegmentBuilder) {
    const segmentsTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          <CustomerGroupSegments
            focalGroup={customerGroupDataWithDisplayName}
          />
        </div>
      ),
      title: "Segments",
    };

    infoPanelTabs.push(segmentsTab);
  }

  if (flags[FeatureFlag.SharingGroups]) {
    const activityTab = {
      content: (
        <div className={styles.groupInfoPanelBodyContent}>
          {isActivityLoading || isReportSharedUsersLoading ? (
            <Spinner className={styles.spinner} size={SpinnerSize.Large} />
          ) : (
            <InfoPanelActivity
              resourceType="group"
              sharedUsers={reportSharedUsers}
              sharingActivities={sharingActivities}
            />
          )}
        </div>
      ),
      title: "Activity",
    };
    infoPanelTabs.push(activityTab);
  }

  const header = (
    <>
      <GroupInfoPanelHeader
        focalGroup={customerGroupDataWithDisplayName}
        groupStatus={customerGroupData?.status}
        groupType={GroupType.Customer}
        onClose={onClose}
        templateType={customerGroupData?.type}
      />
      {customerGroupData?.status === CustomerGroupStatus.Invalid && (
        <Message
          className={styles.groupSummaryMessage}
          heading={<h5>Customer group is invalid</h5>}
          showIcon
          text="One or more segments have a customer count less than 100."
          variant={MessageVariant.Danger}
        />
      )}
      <Nav activeIndex={currentNavIndex} variant={NavVariant.Tab}>
        {infoPanelTabs.map((tab, index) => (
          <NavButton key={tab.title} onClick={() => setCurrentNavIndex(index)}>
            {tab.title}
          </NavButton>
        ))}
      </Nav>
    </>
  );

  const body = infoPanelTabs[currentNavIndex].content;

  return (
    <PanelWithSideDrawer
      closeFunc={onClose}
      drawerContent={{
        body,
        header,
        label,
      }}
      isOverlay={false}
      showDrawer={showPanel}
    >
      {children}
    </PanelWithSideDrawer>
  );
};

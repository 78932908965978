import {
  Button,
  Dropdown,
  DropdownWidth,
  ButtonVariant,
  Text,
  FormBlock,
  FormBlockType,
  FormBlockVariant,
  Label,
  Icon,
  IconGlyph,
  IconSize,
  Input,
} from "@qbit/react";
import { type DatePickerProps } from "components-ui/src/date-picker/DatePicker";
import DatePicker from "components-ui/src/date-picker/DatePicker";
import React, { useCallback, useRef, useState } from "react";
import { shiftDays, datetimeToIsoNoOffset, dateToString } from "../utilities";
import styles from "./CustomTimePeriod.module.css";
import { CustomTimePeriodDisplayWeeks } from "./CustomTimePeriodDisplayWeeks";
import RequiredFieldError from "./shared/required-field-error/RequiredFieldError";
import { disableDatesEnd, disableDatesStart } from "./utilities";

export type CustomTimePeriodOption = {
  value: string;
  weekEndDate: Date;
  weekStartDate: Date;
};

export type CustomTimePeriodProps = {
  beginningOfEndDate?: Date;
  endDate: Date | undefined;
  endDateHandler: (startDate: string) => void;
  endOfStartDate?: Date;
  errors: Record<string, boolean>;
  isEndDateSelectionVisible: boolean;
  isVisited: boolean;
  maxEndDate: Date | undefined;
  maxStartDate: Date | undefined;
  minEndDate: Date | undefined;
  minStartDate: Date | undefined;
  options: CustomTimePeriodOption[];
  startDate: Date | undefined;
  startDateHandler: (endDate: string) => void;
  weeks: number | undefined;
};

export enum DateSelectionStatus {
  Default = "default",
  Error = "error",
}

const PLACEHOLDER_DATE_STRING = "Select date";

// Dropdown bar for the Time Period
// eslint-disable-next-line complexity
export const CustomTimePeriod = ({
  errors,
  startDate,
  endDate,
  minStartDate,
  maxStartDate,
  minEndDate,
  maxEndDate,
  options,
  isVisited,
  startDateHandler,
  endDateHandler,
  weeks,
  beginningOfEndDate,
  endOfStartDate,
  isEndDateSelectionVisible = true,
}: CustomTimePeriodProps) => {
  // minimum date is the first option in custom time period subtract 6 days to get the starting day
  const minDate = options[0]?.weekStartDate;
  const maxDate = options[options.length - 1]?.weekEndDate;

  // CUSTOM CALENDAR TOGGLERS
  const endDateDropdownRef = useRef<Dropdown>(null);
  const startDateDropdownRef = useRef<Dropdown>(null);

  const handleDropdownClose = (dropdownToClose: React.RefObject<Dropdown>) => {
    if (
      dropdownToClose.current?.state.isOpen &&
      // @ts-expect-error toggleState is private however we use it to open the open the dropdown of the other calendar on selection
      dropdownToClose.current.toggleState
    ) {
      // @ts-expect-error same as above
      dropdownToClose.current.toggleState();
    }
  };

  const handleDropdownOpen = (
    dropdownToOpen: React.RefObject<Dropdown>,
    otherDropdown: React.RefObject<Dropdown>
  ) => {
    if (
      dropdownToOpen.current &&
      otherDropdown.current &&
      otherDropdown.current.state.isOpen &&
      !dropdownToOpen.current.state.isOpen &&
      // @ts-expect-error same as above
      dropdownToOpen.current.toggleState
    ) {
      // @ts-expect-error same as above
      dropdownToOpen.current.toggleState();
    }
  };

  // ERROR VALIDATION
  // The Dirty states are used in the display behaviour on the inline errors
  const [startDateDirty, setStartDateDirty] = useState(false);
  const [endDateDirty, setEndDateDirty] = useState(false);

  // Pre-open behaviour done with callback refs
  const startDateOnOpen = useCallback(() => {
    setStartDateDirty(true);
  }, []);

  const endDateOnOpen = useCallback(() => {
    setEndDateDirty(true);
  }, []);

  // HANDLERS
  const handleStartSelection = (day: Date | undefined) => {
    startDateHandler(datetimeToIsoNoOffset(day));
    if (day) {
      handleDropdownClose(startDateDropdownRef);
      if (!weeks && !endDate) {
        handleDropdownOpen(endDateDropdownRef, startDateDropdownRef);
      }
    }
  };

  const handleEndSelection = (day: Date | undefined) => {
    endDateHandler(datetimeToIsoNoOffset(day));
    if (day) {
      handleDropdownClose(endDateDropdownRef);
      if (!weeks && !startDate) {
        handleDropdownOpen(startDateDropdownRef, endDateDropdownRef);
      }
    }
  };

  // CHILDREN PROP CONSTRUCTORS
  const startDatePickerProps = {
    disabled: (day: Date) => disableDatesStart(day, endDate, options),
    endDate,
    focusDate: startDate ?? shiftDays(endDate, -6) ?? new Date(),
    fromDate: minStartDate ?? minDate,
    onSelect: (day: Date | undefined) => handleStartSelection(day),
    selected: startDate,
    startDate,
    toDate: endOfStartDate ?? maxStartDate ?? maxDate,
  } as DatePickerProps;

  const endDatePickerProps = {
    disabled: (day: Date) => disableDatesEnd(day, startDate, options),
    endDate,
    focusDate: endDate ?? shiftDays(startDate, 6) ?? new Date(),
    fromDate: beginningOfEndDate ?? minEndDate ?? minDate,
    onSelect: (day: Date | undefined) => handleEndSelection(day),
    selected: endDate,
    startDate,
    toDate: maxEndDate ?? maxDate,
  } as DatePickerProps;

  return (
    <div className={styles.customTimePeriodContainer}>
      <FormBlock
        blockType={FormBlockType.Select}
        variant={FormBlockVariant.TopLabel}
      >
        <>
          {isEndDateSelectionVisible && (
            <Label htmlFor="start-date" text="Start date" />
          )}
          <Input>
            <Dropdown
              contentWidth={DropdownWidth.Medium}
              ref={startDateDropdownRef}
              trigger={
                <Button
                  className={styles.datePickerNavBtn}
                  data-testid="start-date"
                  variant={ButtonVariant.Secondary}
                >
                  <Text>
                    {dateToString(startDate) || PLACEHOLDER_DATE_STRING}
                  </Text>
                  <Icon
                    glyph={IconGlyph.DateAndTimeCalendarRange}
                    size={IconSize.Small}
                    text="Select start date"
                  />
                </Button>
              }
            >
              <div className={styles.datePickerContainer} ref={startDateOnOpen}>
                <div className={styles.datePicker}>
                  <DatePicker {...startDatePickerProps} />
                </div>
              </div>
            </Dropdown>
          </Input>
          {errors.startDate && (startDateDirty || isVisited) && (
            <RequiredFieldError />
          )}
        </>
      </FormBlock>
      {isEndDateSelectionVisible && (
        <FormBlock
          blockType={FormBlockType.Select}
          variant={FormBlockVariant.TopLabel}
        >
          <Label htmlFor="end-date" text="End date" />
          <Input>
            <Dropdown
              contentWidth={DropdownWidth.Medium}
              ref={endDateDropdownRef}
              trigger={
                <Button
                  className={styles.datePickerNavBtn}
                  data-testid="end-date"
                  variant={ButtonVariant.Secondary}
                >
                  <Text>
                    {dateToString(endDate) || PLACEHOLDER_DATE_STRING}
                  </Text>
                  <Icon
                    glyph={IconGlyph.DateAndTimeCalendarRange}
                    size={IconSize.Small}
                    text="Select end date"
                  />
                </Button>
              }
            >
              <div className={styles.datePickerContainer} ref={endDateOnOpen}>
                <div className={styles.datePicker}>
                  <DatePicker {...endDatePickerProps} />
                </div>
              </div>
            </Dropdown>
          </Input>
          <>
            {errors.endDate && (endDateDirty || isVisited) && (
              <RequiredFieldError />
            )}
          </>
        </FormBlock>
      )}
      {weeks && startDate && endDate && (
        <CustomTimePeriodDisplayWeeks
          center={isEndDateSelectionVisible}
          weeks={weeks}
        />
      )}
    </div>
  );
};

export default CustomTimePeriod;

import { FormBlockEditability } from "@qbit/react";
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";

export const isInvalidRange = (range: {
  maximum?: number;
  minimum?: number;
}) => {
  if (
    range.maximum === undefined ||
    range.minimum === undefined ||
    range.maximum < range.minimum
  ) {
    return true;
  }

  return false;
};

export const shouldBeReadOnly = (
  range: { maximum: number; minimum: number },
  defaultValue: number
) => {
  if (range.maximum === range.minimum && range.maximum === defaultValue) {
    return true;
  } else return false;
};

export const getEditability = (
  range: { maximum?: number; minimum?: number },
  value?: number
) => {
  if (
    isInvalidRange({
      maximum: range.maximum,
      minimum: range.minimum,
    })
  ) {
    return FormBlockEditability.Disabled;
  }

  if (
    shouldBeReadOnly(
      {
        maximum: range.maximum ?? 0,
        minimum: range.minimum ?? 0,
      },
      value ?? 0
    )
  ) {
    return FormBlockEditability.Readonly;
  }

  return FormBlockEditability.Editable;
};

export const getDisplayedRange = (
  editability: FormBlockEditability,
  range: { maximum?: number; minimum?: number }
) => {
  let displayedRange = {
    maximum: range.maximum,
    minimum: range.minimum,
  };
  if (editability === FormBlockEditability.Disabled) {
    displayedRange = { maximum: undefined, minimum: undefined };
  }

  if (editability === FormBlockEditability.Readonly) {
    displayedRange = { ...displayedRange, minimum: 0 };
  }

  return displayedRange;
};

export const getDisplayedThreshold = (
  editability: FormBlockEditability,
  maximum?: number,
  threshold?: number
) => {
  let displayedThreshold: number | undefined = threshold;

  if (editability === FormBlockEditability.Disabled) {
    displayedThreshold = undefined;
  }

  if (editability === FormBlockEditability.Readonly) {
    displayedThreshold = maximum;
  }

  return displayedThreshold;
};

export const getSummaryText = (
  editability: FormBlockEditability,
  formatter: FormatterFunction,
  maximum?: number
) => {
  if (editability === FormBlockEditability.Disabled) {
    return "";
  }

  if (editability === FormBlockEditability.Readonly) {
    return formatter(maximum);
  }

  return undefined;
};

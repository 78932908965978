import {
  Nav,
  NavButton,
  NavFontWeight,
  NavSize,
  NavVariant,
} from "@qbit/react";
import { type ReportParametersDto } from "@quantium-enterprise/common-ui";
import { Accordion } from "components-ui/src/accordion/Accordion";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../store";
import { onChartTabChange } from "../services/time-of-day-day-of-week-slice";
import styles from "./TimeOfDayDayOfWeekReportlets.module.css";
import DayOfWeekReportlet from "./trends-chart/day-of-week/DayOfWeekReportlet";
import TimeOfDayReportlet from "./trends-chart/time-of-day/TimeOfDayReportlet";

export type TodDowChartReporletProps = {
  infoPanelSummary: ReportParametersDto | undefined;
};

const ReportletContent = ({ infoPanelSummary }: TodDowChartReporletProps) => {
  const dispatch = useDispatch();

  const toddowTabs = [
    {
      content: <DayOfWeekReportlet infoPanelSummary={infoPanelSummary} />,
      title: "Day of Week",
    },
    {
      content: <TimeOfDayReportlet infoPanelSummary={infoPanelSummary} />,
      title: "Time of Day",
    },
  ];

  const selectedTab = useSelector(
    (state: RootState) => state.timeOfDayDayOfWeek.chartSelections.tabSelected
  );

  const selectedTabIndex = toddowTabs.findIndex(
    (tab) => tab.title === selectedTab
  );

  const onTabChange = useCallback(
    (event: string) => {
      dispatch(onChartTabChange(event));
    },
    [dispatch]
  );

  return (
    <>
      <Nav
        activeIndex={selectedTabIndex}
        fontWeight={NavFontWeight.Regular}
        size={NavSize.Medium}
        variant={NavVariant.Tab}
      >
        {toddowTabs.map((tab) => (
          <NavButton key={tab.title} onClick={() => onTabChange(tab.title)}>
            {tab.title}
          </NavButton>
        ))}
      </Nav>
      <>{toddowTabs[selectedTabIndex].content}</>
    </>
  );
};

export const TimeOfDayDayOfWeekChartReportlet = ({
  infoPanelSummary,
}: TodDowChartReporletProps) => (
  <div className={styles.reportlet}>
    <Accordion
      subtitle="Understand the sales patterns of products, brands and categories on a particular day or time."
      title="Time of day, day of week trends"
    >
      <ReportletContent infoPanelSummary={infoPanelSummary} />
    </Accordion>
  </div>
);

export default TimeOfDayDayOfWeekChartReportlet;

import {
  Group,
  Item,
  ItemWidth,
  GroupGutters,
  ItemValign,
  ItemHalign,
  GroupRowspace,
  Text,
} from "@qbit/react";
import { type FormatterFunction } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { type Dictionary, type SeriesOptionsType } from "highcharts";
import { type ReactNode, useEffect, useMemo } from "react";
import { HighchartsCustomTooltip } from "../../highcharts-custom-tooltip/HighchartsCustomTooltip";
import { SeriesIcon } from "../SeriesIcon";
import { type HighchartsReactProps } from "../highcharts-react/HighchartsReact";
import {
  HighchartsReact,
  defaultOptions,
} from "../highcharts-react/HighchartsReact";
import { CustomValueCell } from "../utils";
import styles from "./ContributionDriversChart.module.scss";

type TableDataSeries = {
  data: number[];
  formatter: FormatterFunction;
  hasSentiment?: boolean;
  name: string;
};

export type ChartDataSeries = {
  color: string;
  data:
    | Array<{
        change: number;
        custom?: Dictionary<unknown>;
        y: number;
      }>
    | number[];
  name: string;
  type: "column" | "line";
};

type ContributionBreakdownChartProps = {
  categories: string[];
  chartData: ChartDataSeries[];
  chartDataFormatter: FormatterFunction;
  hiddenSeries: number[];
  onLegendAreaChanged?: (legend: ReactNode) => void;
  onOptionsChanged?: (options: HighchartsReactProps) => void;
  percentFormatter: FormatterFunction;
  tableData?: TableDataSeries[];
  toggleSeriesVisibility: Function;
  tooltipLabel: string;
  yAxisLabel: string;
};

const XaxisTable = ({ tableData }: { tableData?: TableDataSeries[] }) => (
  <>
    {tableData && (
      <div className={styles.xaxisTable}>
        {tableData.map((series: TableDataSeries, index) => (
          <Group
            key={`${series.name}-${index + 1}`}
            rowspace={GroupRowspace.Small}
          >
            <div className={styles.xaxisTableHeader}>{series.name}</div>
            {series.data.map((dataSeries, dataSeriesIndex) => (
              <Item
                halign={ItemHalign.Centre}
                key={`${series.name}-${dataSeries}-${dataSeriesIndex + 1}`}
                width={ItemWidth.Equal}
              >
                <Item width={ItemWidth.Equal}>
                  <CustomValueCell
                    formatter={series.formatter}
                    hasSentiment={series.hasSentiment}
                    value={dataSeries}
                  />
                </Item>
              </Item>
            ))}
          </Group>
        ))}
      </div>
    )}
  </>
);

const CustomLegend = ({
  chartData,
  toggleSeriesVisibility,
}: {
  chartData: SeriesOptionsType[];
  toggleSeriesVisibility: Function;
}) => (
  <Group>
    {chartData.map((series: SeriesOptionsType, index: number) => (
      <button
        className={styles.legendItemWrapper}
        key={`${series.name} - ${index + 1}`}
        onClick={() => {
          toggleSeriesVisibility(index);
        }}
        type="button"
      >
        <Group
          className={classNames(styles.legendItem, {
            [styles.inactive]: !series.visible,
          })}
          gutters={GroupGutters.XSmall}
        >
          <Item valign={ItemValign.Middle}>
            <SeriesIcon
              color={series.color?.toString()}
              seriesType={series.type}
            />
          </Item>
          <Item valign={ItemValign.Middle}>
            <Text>{series.name ?? "-"}</Text>
          </Item>
        </Group>
      </button>
    ))}
  </Group>
);

export const ContributionDriversChart = ({
  chartData,
  tableData,
  hiddenSeries,
  categories,
  chartDataFormatter,
  percentFormatter,
  toggleSeriesVisibility,
  tooltipLabel,
  yAxisLabel,
  onOptionsChanged,
  onLegendAreaChanged,
}: ContributionBreakdownChartProps) => {
  const optionsState: HighchartsReactProps = useMemo(
    () => ({
      ...defaultOptions,
      chart: {
        borderWidth: 0,
        marginRight: 0,
        plotBorderWidth: 0,
        spacingLeft: 0,
        style: {
          fontFamily: `var(--qbit-font-family)`,
        },
      },
      legend: { enabled: false },
      plotOptions: {
        column: {
          dataLabels: [
            {
              crop: false,
              enabled: true,

              formatter() {
                // eslint-disable-next-line react/no-this-in-sfc
                return chartDataFormatter(this.y);
              },
              inside: false,
              style: {
                color: "var(--qbit-colour-text-primary)",
                fontSize: "0.75rem",
                fontWeight: "var(--qbit-font-weight-medium)",
                textOutline: "white",
              },
            },
            {
              crop: false,
              enabled: true,
              formatter() {
                // eslint-disable-next-line react/no-this-in-sfc
                const customOptions = this.point.options.custom;
                return customOptions?.change && customOptions.change < 0
                  ? percentFormatter(customOptions.change, true)
                  : "";
              },
              inside: false,
              overflow: "allow",
              style: {
                color: "var(--qbit-colour-status-bad-text)",
                fontSize: "0.75rem",
                fontWeight: "var(--qbit-font-weight-medium)",
              },
              y: 15,
            },
            {
              crop: false,
              enabled: true,
              formatter() {
                // eslint-disable-next-line react/no-this-in-sfc
                const customOptions = this.point.options.custom;
                return customOptions?.change && customOptions.change > 0
                  ? percentFormatter(customOptions.change, true)
                  : "";
              },
              inside: false,
              overflow: "allow",
              style: {
                color: "var(--qbit-colour-status-good-text)",
                fontSize: "0.75rem",
                fontWeight: "var(--qbit-font-weight-medium)",
              },
              y: -15,
            },
          ],
          // sets width of bar chart
          pointPadding: 0.1,
          stacking: "normal",
        },
        line: {
          marker: {
            enabled: false,
          },
          shadow: {
            color: "white",
            offsetX: 0,
            offsetY: 0,
            opacity: 100,
            width: 3,
          },
          zIndex: 1,
        },
        series: {
          lineWidth: 4,
        },
      },

      series: chartData.map(
        (series: ChartDataSeries, index): SeriesOptionsType => ({
          ...series,
          visible: !hiddenSeries.includes(index),
        })
      ),

      tooltip: {
        ...defaultOptions.tooltip,
        ReactFormatter: (ttData) =>
          HighchartsCustomTooltip({
            chartDataFormatter,
            hasSentiment: true,
            percentFormatter,
            ttData,
            yAxisTitle: tooltipLabel,
          }),
      },
      xAxis: {
        categories,
        crosshair: {
          color: "var(--qbit-colour-chrome-background)",
          zIndex: 0,
        },
        labels: {
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: { color: "var(--qbit-colour-text-primary)" },
        },
        visible: false,
      },
      yAxis: {
        ...defaultOptions.yAxis,
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return chartDataFormatter(this.value, true, "", true);
          },
          style: {
            color: "var(--qbit-colour-text-secondary)",
          },
        },
        title: {
          style: {
            fontWeight: "bold",
            color: "var(--qbit-colour-text-primary)",
          },
          text: yAxisLabel,
        },
        visible: true,
      },
    }),
    [
      chartData,
      hiddenSeries,
      categories,
      chartDataFormatter,
      percentFormatter,
      tooltipLabel,
      yAxisLabel,
    ]
  );

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged(optionsState);
    }
  }, [onOptionsChanged, optionsState]);

  const xAxisBorder = useMemo(
    () => ({
      borderBottom:
        chartData.length > 1 ? "1px solid var(--qbit-colour-shade-3)" : "",
    }),
    [chartData]
  );

  const chartLegendArea = useMemo(
    () => (
      <>
        <div className={styles.xaxisCategories} style={xAxisBorder}>
          <Group>
            {categories.map((category: string) => (
              <Item
                halign={ItemHalign.Centre}
                key={category}
                width={ItemWidth.Equal}
              >
                {category}
              </Item>
            ))}
          </Group>
        </div>
        <XaxisTable tableData={tableData} />

        {chartData.length > 1 && (
          <div className={styles.legendContainer}>
            <CustomLegend
              chartData={optionsState.series ?? []}
              toggleSeriesVisibility={toggleSeriesVisibility}
            />
          </div>
        )}
      </>
    ),
    [
      xAxisBorder,
      categories,
      tableData,
      chartData.length,
      optionsState.series,
      toggleSeriesVisibility,
    ]
  );

  useEffect(() => {
    if (onLegendAreaChanged) {
      onLegendAreaChanged(chartLegendArea);
    }
  }, [onLegendAreaChanged, chartLegendArea]);

  return (
    <div className={styles.parentContainer}>
      <HighchartsReact options={optionsState} />
      {chartLegendArea}
    </div>
  );
};

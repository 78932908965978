import { Spinner, SpinnerSize } from "@qbit/react";
import classNames from "classnames";
import { type PropsWithChildren } from "react";
import styles from "./ReportHierarchyTableWrapper.module.css";

export type ReportHierarchyTableWrapperProps = {
  className?: string;
  isSuccess: boolean;
  showNoDataAvailable?: boolean;
};

// If isSuccess, display content normally.
// Otherwise, hide content and display spinner over the top, but keep content in DOM.
export const ReportHierarchyTableWrapper = ({
  isSuccess,
  className,
  children,
  showNoDataAvailable,
}: PropsWithChildren<ReportHierarchyTableWrapperProps>) => (
  <div
    className={classNames(
      styles.reportHierarchyTableWrapperContentContainer,
      className
    )}
  >
    {isSuccess ? (
      showNoDataAvailable ? (
        <div className={styles.reportHierarchyNoDataAvailable}>
          No data available.
        </div>
      ) : (
        // Wrapping in div so the table doesn't re-render empty table after search
        <div className={styles.reportHierarchyChildrenContainer}>
          {children}
        </div>
      )
    ) : (
      <>
        <div
          className={classNames(
            styles.reportHierarchyTableWrapperHiddenContent
          )}
        >
          {children}
        </div>
        <div
          className={classNames(
            styles.reportHierarchyTableWrapperContentContainer,
            styles.spinnerContainer
          )}
        >
          <Spinner
            data-testid="data-table-loading-icon"
            size={SpinnerSize.Large}
          />
        </div>
      </>
    )}
  </div>
);

import { FormInputHeight, IconGlyph } from "@qbit/react";
import {
  ButtonToggleGroup,
  type ToggleButton,
} from "components-ui/src/button-toggle-group/ButtonToggleGroup";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableIcon from "../../assets/tab-icons/table-black.svg";
import TableIconSelected from "../../assets/tab-icons/table-white.svg";
import { SummaryDisplayType } from "../constants";
import {
  isBreakdownByTotal,
  onSummaryChartAdditionalMetricChange,
  selectSummaryChartAdditionalMetric,
} from "../services/basket-quantities-slice";
import styles from "./BasketQuantitiesSummaryMetrics.module.css";

const createPanelOption = (value: string) => ({
  value,
  label: value,
});

export type BasketQuantitiesSummaryMetricsProps = {
  additionalMetricDropdownValues: string[];
  displayType: SummaryDisplayType;
  onDisplayTypeChanged: (newType: SummaryDisplayType) => void;
};

export const BasketQuantitiesSummaryMetrics = ({
  additionalMetricDropdownValues,
  displayType,
  onDisplayTypeChanged,
}: BasketQuantitiesSummaryMetricsProps) => {
  const viewByButtons: Array<ToggleButton<number>> = [
    {
      displayText: "Chart",
      icon: IconGlyph.ChartsChartBar,
      id: Number(SummaryDisplayType.CHART),
    },
    {
      displayText: "Table",
      icon: {
        iconDefault: TableIcon,
        iconSelected: TableIconSelected,
      },
      id: Number(SummaryDisplayType.TABLE),
    },
  ];

  const additionalMetricOptions =
    additionalMetricDropdownValues.map(createPanelOption);
  const defaultOption = createPanelOption("No additional metric selected");
  const additionalMetricEnabled = useSelector(isBreakdownByTotal);
  const additionalMetricState = useSelector(selectSummaryChartAdditionalMetric);
  const dispatch = useDispatch();
  const onAdditionalMetricChange = useCallback(
    (additionalMetric: PanelOption) => {
      dispatch(
        onSummaryChartAdditionalMetricChange(additionalMetric.value as string)
      );
    },
    [dispatch]
  );

  return (
    <div className={styles.summaryMetricsGrid}>
      <div className={styles.chartToggleTitle}>View by</div>
      <div className={styles.additionalMetricTitle}>
        {displayType === SummaryDisplayType.CHART && "Additional metric"}
      </div>
      <div className={styles.chartToggleButton}>
        <ButtonToggleGroup
          buttonSelected={Number(displayType === SummaryDisplayType.TABLE)}
          buttons={viewByButtons}
          setButtonSelected={(index: number) => {
            onDisplayTypeChanged(
              index === 0 ? SummaryDisplayType.CHART : SummaryDisplayType.TABLE
            );
          }}
        />
      </div>
      <div className={styles.additionalMetricDropdown}>
        {displayType === SummaryDisplayType.CHART && (
          <SingleSelectDropdown
            height={FormInputHeight.XSmall}
            isDisabled={!additionalMetricEnabled}
            onSelection={onAdditionalMetricChange}
            selectOptions={
              additionalMetricEnabled
                ? [defaultOption].concat(additionalMetricOptions)
                : [defaultOption]
            }
            selectedValue={
              additionalMetricEnabled && additionalMetricState !== ""
                ? additionalMetricState
                : defaultOption.value
            }
            title="Additional metric dropdown"
          />
        )}
      </div>
    </div>
  );
};

import {
  Button,
  ButtonVariant,
  Icon,
  IconGlyph,
  Text,
  Dialog,
  DialogHeight,
  DialogWidth,
  Input,
  TextInput,
  ButtonWidth,
  Label,
  TextVariant,
  InputStatus,
  TagColour,
  Tag,
  TagVariant,
  FormBlock,
  FormBlockStatus,
  QbitEmitToast,
  QbitToastMessage,
  MessageVariant,
} from "@qbit/react";
import {
  getErrorsForField,
  isFetchBaseQueryError,
  isProblemDetail,
} from "@quantium-enterprise/common-ui";
import classNames from "classnames";
import { GreySpinner } from "components-ui/src/loader/GreySpinner";
import {
  useRef,
  useCallback,
  type PropsWithChildren,
  useState,
  useEffect,
} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./GroupEditor.module.css";

type BackButtonProps = {
  onClick?: () => void;
  returnPath: string;
  showExitDialog?: boolean;
};

const BackButton = ({
  onClick,
  returnPath,
  showExitDialog = true,
}: BackButtonProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDialogCancel = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleBackButtonClick = useCallback(() => {
    setShowDialog(true);
  }, []);

  useEffect(() => {
    if (showExitDialog) {
      navigate(location.pathname);
    }

    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      if (showExitDialog) {
        navigate(location.pathname);
        setShowDialog(true);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location.pathname, navigate, showExitDialog]);

  return (
    <>
      <Dialog
        className={styles.dialog}
        footer={
          <div className={styles.dialogFooter}>
            <Button
              onClick={handleDialogCancel}
              variant={ButtonVariant.Primary}
            >
              <Text>Stay on page</Text>
            </Button>
            <Link onClick={onClick} to={returnPath}>
              <Button variant={ButtonVariant.Danger}>
                <Text>Leave page</Text>
              </Button>
            </Link>
          </div>
        }
        header={
          <div className={styles.dialogHeader}>
            <h5>Are you sure you want to leave?</h5>
            <Button
              onClick={handleDialogCancel}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
            </Button>
          </div>
        }
        height={DialogHeight.XSmall}
        onClose={handleDialogCancel}
        show={showDialog}
        titleId="exit-dialog"
        triggeredBy={buttonRef}
        width={DialogWidth.XXXSmall}
      >
        <div>
          <p>Leaving the page will remove all your selections.</p>
          <p>This cannot be undone.</p>
        </div>
      </Dialog>
      <div className={styles.backButton}>
        {showExitDialog ? (
          <Button
            onClick={handleBackButtonClick}
            variant={ButtonVariant.Stealth}
          >
            <Icon glyph={IconGlyph.ArrowsChevronLeft} text="Back" />
          </Button>
        ) : (
          <Link onClick={onClick} to={returnPath}>
            <Button variant={ButtonVariant.Stealth}>
              <Icon glyph={IconGlyph.ArrowsChevronLeft} text="Back" />
            </Button>
          </Link>
        )}
      </div>
    </>
  );
};

const GroupEditor = ({ children }: PropsWithChildren) => (
  <div className={styles.groupEditorContainer}>
    <div className={styles.groupEditor}>{children}</div>
  </div>
);

type EditorHeaderProps = {};

const EditorHeader = ({ children }: PropsWithChildren<EditorHeaderProps>) => (
  <div className={styles.header}>{children}</div>
);

type EditorContentProps = {};

const EditorContent = ({ children }: PropsWithChildren<EditorContentProps>) => (
  <div className={styles.content}>{children}</div>
);

export type EditorTitleProps = {
  icon: JSX.Element;
  subtitle: string;
  title: string;
};

const EditorTitle = ({ icon, title, subtitle }: EditorTitleProps) => (
  <div className={styles.titleContainer}>
    <Text className={styles.title}>{title}</Text>
    <span className={styles.titleIcon}>{icon}</span>
    <Text className={styles.subTitle}>{subtitle}</Text>
  </div>
);

export type EditorFooterProps = {};

const EditorFooter = ({ children }: PropsWithChildren<EditorFooterProps>) => (
  <div className={styles.footer}>{children}</div>
);

export type SaveButtonProps = {
  disabled?: boolean;
  groupTypeIcon: JSX.Element;
  groupTypeName: string;
  handleCreateGroup: (name: string) => Promise<void>;
  handleEditGroup?: (name: string) => Promise<void>;
  initialGroupName?: string;
};

const SaveButton = ({
  disabled,
  handleCreateGroup,
  handleEditGroup,
  groupTypeIcon,
  groupTypeName,
  initialGroupName,
}: SaveButtonProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const [createGroupProcessing, setCreateGroupProcessing] = useState(false);
  const [editGroupProcessing, setEditGroupProcessing] = useState(false);
  const [groupName, setGroupName] = useState(initialGroupName ?? "");
  const [groupNameErrors, setGroupNameErrors] = useState<string[]>([]);
  const buttonRef = useRef(null);

  const createOrEditText = handleEditGroup ? "Save changes" : "Create group";

  const handleNameInput = useCallback((value: string) => {
    setGroupName(value);
    setGroupNameErrors([]);
  }, []);

  const handleDialogCancel = useCallback(() => {
    setGroupName(initialGroupName ?? "");
    setGroupNameErrors([]);
    setShowDialog(false);
  }, [setGroupName, setShowDialog, initialGroupName]);

  const handleDialogSubmit = async (promise: Promise<void>) => {
    try {
      await promise;
    } catch (error) {
      let heading = "Something went wrong, please try again";
      let content = "Contact support if error persists.";

      let nameErrors: string[] = [];

      if (isProblemDetail(error)) {
        nameErrors = getErrorsForField(error.errors, "Name");
      } else if (isFetchBaseQueryError(error) && isProblemDetail(error.data)) {
        nameErrors = getErrorsForField(error.data.errors, "Name");
      }

      if (nameErrors.length > 0) {
        heading = nameErrors[0];
        content = "Please enter a unique name.";
        setGroupNameErrors(nameErrors);
      }

      QbitEmitToast(
        <QbitToastMessage
          content={<p>{content}</p>}
          heading={<h5>{heading}</h5>}
          showCloseButton
          showIcon
          variant={MessageVariant.Danger}
        />
      );
    }
  };

  const handleCreateGroupSubmit = useCallback(async () => {
    setCreateGroupProcessing(true);
    try {
      await handleDialogSubmit(handleCreateGroup(groupName));
    } finally {
      setCreateGroupProcessing(false);
    }
  }, [handleCreateGroup, groupName]);

  const handleEditGroupSubmit = useCallback(async () => {
    if (handleEditGroup) {
      setEditGroupProcessing(true);
      try {
        await handleDialogSubmit(handleEditGroup(groupName));
      } finally {
        setEditGroupProcessing(false);
      }
    }
  }, [handleEditGroup, groupName]);

  useEffect(() => {
    if (disabled) {
      setShowDialog(false);
      setCreateGroupProcessing(false);
      setEditGroupProcessing(false);
    }
  }, [disabled]);

  return (
    <>
      <Dialog
        className={styles.dialog}
        footer={
          <div className={styles.dialogFooter}>
            {handleEditGroup && (
              <Button
                className={styles.submitButton}
                disabled={
                  createGroupProcessing || editGroupProcessing || !groupName
                }
                onClick={handleEditGroupSubmit}
                variant={ButtonVariant.Primary}
              >
                {editGroupProcessing ? (
                  <GreySpinner className={styles.loadingIcon} />
                ) : (
                  <Text>{createOrEditText}</Text>
                )}
              </Button>
            )}
            {!handleEditGroup && (
              <Button
                className={styles.submitButton}
                disabled={createGroupProcessing || !groupName}
                onClick={handleCreateGroupSubmit}
                variant={ButtonVariant.Primary}
              >
                {createGroupProcessing ? (
                  <GreySpinner className={styles.loadingIcon} />
                ) : (
                  <Text>{createOrEditText}</Text>
                )}
              </Button>
            )}
            <Button
              disabled={createGroupProcessing || editGroupProcessing}
              onClick={handleDialogCancel}
              variant={ButtonVariant.Secondary}
            >
              <Text>Cancel</Text>
            </Button>
            {handleEditGroup && (
              <Button
                className={classNames(styles.submitButton, styles.rightButton)}
                disabled={
                  createGroupProcessing ||
                  editGroupProcessing ||
                  !groupName ||
                  groupName === initialGroupName
                }
                onClick={handleCreateGroupSubmit}
                variant={ButtonVariant.Stealth}
              >
                {createGroupProcessing ? (
                  <GreySpinner className={styles.loadingIcon} />
                ) : (
                  <>
                    <Icon
                      glyph={IconGlyph.TextAndEditContentCopy}
                      text="Save as copy"
                    />
                    <Text>Save as copy</Text>
                  </>
                )}
              </Button>
            )}
          </div>
        }
        header={
          <div className={styles.dialogHeader}>
            <h5>Name your group</h5>
            <Button
              onClick={handleDialogCancel}
              variant={ButtonVariant.Stealth}
            >
              <Icon glyph={IconGlyph.DeleteAndCloseClose} text="Close" />
            </Button>
          </div>
        }
        height={DialogHeight.Large}
        onClose={handleDialogCancel}
        show={showDialog}
        titleId="save-dialog"
        triggeredBy={buttonRef}
        width={DialogWidth.XXSmall}
      >
        <div className={styles.createGroupContent}>
          <div>
            <Text variant={TextVariant.FormLabel}>Group type: </Text>
            <span className={styles.groupTypeIcon}>{groupTypeIcon}</span>
            <Text>{groupTypeName}</Text>
          </div>
          <FormBlock
            blockStatus={
              groupNameErrors.length > 0
                ? FormBlockStatus.Error
                : FormBlockStatus.Default
            }
          >
            <Label htmlFor="group-name-input" text="Group name" />
            <Input className={styles.groupNameInput}>
              <TextInput
                autofocus
                id="group-name-input"
                onChange={(event) => handleNameInput(event.target.value)}
                placeholder="Give your group a unique name"
                value={groupName}
              />
              <>
                {groupNameErrors.map((error) => (
                  <div className={styles.nameInputError} key={error}>
                    <Tag
                      colour={TagColour.Bad}
                      text={error}
                      variant={TagVariant.Badge}
                    />
                    <InputStatus id={`input-status-${error}`} text={error} />
                  </div>
                ))}
              </>
            </Input>
          </FormBlock>
        </div>
      </Dialog>
      <Button
        disabled={Boolean(disabled)}
        onClick={() => setShowDialog(true)}
        variant={ButtonVariant.Primary}
        width={ButtonWidth.Small}
      >
        <Text>{createOrEditText}</Text>
        <Icon glyph={IconGlyph.ArrowsNext} text={createOrEditText} />
      </Button>
    </>
  );
};

GroupEditor.Header = EditorHeader;
GroupEditor.BackButton = BackButton;
GroupEditor.Title = EditorTitle;
GroupEditor.Content = EditorContent;
GroupEditor.Footer = EditorFooter;
GroupEditor.SaveButton = SaveButton;

export { GroupEditor };

import { Text, TextVariant } from "@qbit/react";
import { type PropsWithChildren } from "react";
import styles from "./ReportletFilterWrapper.module.css";

export type ReportletFilterWrapperProps = {
  label: string;
};

export const ReportletFilterWrapper = ({
  label,
  children,
}: PropsWithChildren<ReportletFilterWrapperProps>) => (
  <div className={styles.reportletFilterWrapper}>
    <Text className={styles.reportletFilterTitle} variant={TextVariant.Default}>
      {label}
    </Text>
    <div className={styles.reportletFilterItem}>{children}</div>
  </div>
);

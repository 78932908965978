import { Spinner, SpinnerSize } from "@qbit/react";
import { NotFoundPage } from "checkout-ui/src/components/not-found-page/NotFoundPage";
import { Outlet } from "react-router-dom";

type ProtectedRouteProps = {
  children?: JSX.Element;
  isAllowed: boolean;
  isError: boolean;
  isLoading: boolean;
};

export const ProtectedRoute = ({
  isAllowed,
  isLoading,
  isError,
  children,
}: ProtectedRouteProps) => {
  if (isLoading) {
    return <Spinner data-testid="admin-spinner" size={SpinnerSize.Large} />;
  }

  if (isError || !isAllowed) {
    return <NotFoundPage />;
  }

  return children ? children : <Outlet />;
};

import {
  Button,
  ButtonHeight,
  ButtonVariant,
  FormInputHeight,
  Icon,
  IconGlyph,
} from "@qbit/react";
import { hierarchyLevelDisplayLabel } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import SingleSelectDropdown from "components-ui/src/local-filters/SingleSelectDropdown";
import SingleSelectDropdownWithGroups from "components-ui/src/local-filters/SingleSelectDropdownWithGroups";
import { type PanelOption } from "components-ui/src/local-parameters-panel/FixedSidePanel";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFocalItemLabels } from "../../common/utils/focal-item-labels-utils";
import {
  onXAxisSelectionChange,
  onChartContentSelectionChange,
  onMetricSelectionChange,
  onFocalItemSelectionChange,
  onXAxisSwitch,
} from "../services/compare-metrics-slice";
import {
  selectChartContentSelection,
  selectFocalItemParents,
  selectFocalItems,
  selectFocalItemSelection,
  selectMetricSelectionSelection,
  selectReportletBreakdownOptions,
  selectReportletMetricOptions,
  selectXAxisSelection,
} from "../services/compare-metrics-slice-selectors";
import styles from "./CompareMetricsReportlet.module.css";
import CompareMetricsReportletFilter from "./CompareMetricsReportletFilter";

export type CompareMetricsReportletFilterGroupProps = {
  isParentNodesFetching: boolean;
};

export const CompareMetricsReportletFilterGroup = ({
  isParentNodesFetching,
}: CompareMetricsReportletFilterGroupProps) => {
  const dispatch = useDispatch();

  const focalItems = useSelector(selectFocalItems);
  const xAxisSelection = useSelector(selectXAxisSelection);
  const chartContentSelection = useSelector(selectChartContentSelection);
  const metricSelection = useSelector(selectMetricSelectionSelection);
  const reportletBreakdownOptions = useSelector(
    selectReportletBreakdownOptions
  );
  const reportletMetricOptions = useSelector(selectReportletMetricOptions);
  const focalItemSelection = useSelector(selectFocalItemSelection);
  const focalItemParents = useSelector(selectFocalItemParents);

  const metricDropdownOptions = useMemo(
    () =>
      reportletMetricOptions.map((group) => ({
        groupName: group.groupName,
        values: group.metricNames.map((metricName) => ({
          label: metricName,
          value: metricName,
        })),
      })),
    [reportletMetricOptions]
  );
  const xAxisDropdownOptions = useMemo(
    () =>
      reportletBreakdownOptions.filter(
        (option) => option.value !== chartContentSelection
      ),
    [chartContentSelection, reportletBreakdownOptions]
  );

  const chartContentDropdownOptions = useMemo(
    () =>
      reportletBreakdownOptions.filter(
        (option) => option.value !== xAxisSelection
      ),
    [xAxisSelection, reportletBreakdownOptions]
  );

  const focalItemDropdownOptions = useMemo(() => {
    const focalItemLabels = getFocalItemLabels(focalItems, focalItemParents);
    const options = focalItems.map((item) => ({
      label: `(${hierarchyLevelDisplayLabel(item.shortName)}) ${
        focalItemLabels[item.nodeNumber]
      }`,
      value: item.nodeNumber.toString(),
    }));
    return options;
  }, [focalItems, focalItemParents]);

  const metricDropdownSelectionHandler = useCallback(
    (event: string) => {
      dispatch(onMetricSelectionChange(event));
    },
    [dispatch]
  );

  const xAxisDropdownSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onXAxisSelectionChange(event.value as string));
    },
    [dispatch]
  );

  const chartContentDropdownSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onChartContentSelectionChange(event.value as string));
    },
    [dispatch]
  );

  const focalItemDropdownSelectionHandler = useCallback(
    (event: PanelOption) => {
      dispatch(onFocalItemSelectionChange(Number(event.value)));
    },
    [dispatch]
  );
  const disabledOption = { value: "-1", label: "All" };

  return (
    <div className={styles.reportletFilterContainer}>
      <CompareMetricsReportletFilter header="Metric">
        <SingleSelectDropdownWithGroups
          height={FormInputHeight.XSmall}
          onSelection={metricDropdownSelectionHandler}
          selectOptions={metricDropdownOptions}
          selectedValue={metricSelection}
        />
      </CompareMetricsReportletFilter>
      <CompareMetricsReportletFilter header="X-Axis">
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          onSelection={xAxisDropdownSelectionHandler}
          selectOptions={xAxisDropdownOptions}
          selectedValue={xAxisSelection}
        />
      </CompareMetricsReportletFilter>
      <div className={styles.switchButton}>
        <Button
          height={ButtonHeight.Small}
          onClick={() => dispatch(onXAxisSwitch())}
          variant={ButtonVariant.Stealth}
        >
          <Icon
            colour="var(--qbit-colour-shade-10)"
            glyph={IconGlyph.ArrowsSwapHoriz}
            text="Switch"
          />
        </Button>
      </div>
      <CompareMetricsReportletFilter header="Chart content">
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          onSelection={chartContentDropdownSelectionHandler}
          selectOptions={chartContentDropdownOptions}
          selectedValue={chartContentSelection}
        />
      </CompareMetricsReportletFilter>
      <CompareMetricsReportletFilter header="Item selection">
        <SingleSelectDropdown
          height={FormInputHeight.XSmall}
          isDisabled={focalItemSelection === undefined || isParentNodesFetching}
          onSelection={focalItemDropdownSelectionHandler}
          selectOptions={
            focalItemSelection === undefined
              ? [disabledOption]
              : focalItemDropdownOptions
          }
          selectedValue={
            focalItemSelection === undefined
              ? disabledOption.value
              : focalItemSelection.toString()
          }
        />
      </CompareMetricsReportletFilter>
    </div>
  );
};

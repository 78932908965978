import { Footer } from "@qbit/react";
import classNames from "classnames";
import styles from "./AppFooter.module.css";

// AppFooter sits in components because we get the desires UX with scrollbars
// by adding the AppFooter to each page individually rather than adding it globally
// in App.tsx which produces 2 side scrollbars
export const AppFooter = () => (
  <Footer className={classNames("q-footer", styles.footerOverrides)}>
    <div className={styles.footerHeader}>
      <span className={styles.footerQuantiumLogo} />
      <a href="https://www.quantium.com/privacy/">Privacy policy</a>
      <a
        href="https://quantium.my.salesforce.com/sfc/p/#7F000003iBJs/a/Od0000003BdF/znmvpJ7qVTW6OyU5HsRhrkCrhNE_DaJ4bEbHQ6fDJS0"
        rel="noreferrer"
        target="_blank"
      >
        Terms and conditions
      </a>
      <a href="https://quantium.com/talk-to-us/ ">Contact us</a>
      <a href="https://quantium.com/">Quantium.com</a>
    </div>
    <p className={styles.footerContent}>
      Any personal information given to Quantium will be dealt with in
      accordance with Quantium&apos;s Privacy Policy. Any information provided
      may be accessed by any employee of Quantium and its related companies, in
      accordance with the requirements of Quantium&apos;s Privacy Policy. In no
      event will Quantium, its related companies, or their directors or
      employees be liable to you or anyone else for any decision made or action
      taken in reliance on the information in this website or for any
      consequential, special or similar damages, even if advised of the
      possibility of such damages.
    </p>
    <div className={styles.footerFooter}>
      <span>Copyright © {new Date().getFullYear()} Quantium</span>
      <a
        className={styles.linkedInIcon}
        href="https://au.linkedin.com/company/quantium"
      >
        <svg
          fill="none"
          height="18"
          viewBox="0 0 18 18"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 0.00012207C16.53 0.00012207 17.04 0.199731 17.41 0.599731C17.79 0.999731 18 1.50012 18 2.00012V16.0001C18 16.5001 17.79 17.0005 17.41 17.4005C17.04 17.8005 16.53 18.0001 16 18.0001H2C1.47 18.0001 0.960999 17.8005 0.585999 17.4005C0.210999 17.0005 0 16.5001 0 16.0001V2.00012C0 1.50012 0.210999 0.999731 0.585999 0.599731C0.960999 0.199731 1.47 0.00012207 2 0.00012207H16ZM15.5 15.5001V10.2003C15.5 9.30032 15.16 8.50051 14.55 7.90051C13.93 7.30051 13.1 6.90051 12.24 6.90051C11.39 6.90051 10.4 7.50032 9.91998 8.20032V7.09973H7.13V15.5001H9.91998V10.5997C9.91998 9.79973 10.54 9.20032 11.31 9.20032C11.68 9.20032 12.04 9.29973 12.3 9.59973C12.56 9.79973 12.71 10.1997 12.71 10.5997V15.5001H15.5ZM3.88 5.59973C4.326 5.59973 4.75299 5.39973 5.06799 5.09973C5.38299 4.79973 5.56 4.30051 5.56 3.90051C5.56 3.00051 4.81 2.20032 3.88 2.20032C3.432 2.20032 3.002 2.40032 2.685 2.70032C2.368 3.00032 2.19 3.40051 2.19 3.90051C2.19 4.80051 2.95 5.59973 3.88 5.59973ZM5.27002 15.5001V7.09973H2.5V15.5001H5.27002Z"
            fill="#333333"
          />
        </svg>
      </a>
    </div>
  </Footer>
);

import {
  FormBlock,
  FormBlockType,
  Label,
  Input,
  RangeInput,
  Form,
  FormDesignVariant,
  FormVariant,
  FormInputWidth,
  FormInputHeight,
  LabelWidth,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import { useDebounce } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onSubstitutabilityReportletSliderChange } from "../../services/product-substitutability-slice";
import styles from "./SubstitutabilitySlider.module.css";

export const DEFAULT_SLIDER_VALUES = {
  initial: 5,
  min: 1,
  max: 20,
};

export const SubstitutabilitySlider = () => {
  const id = uniqueId("substitutability-slider");
  const [sliderValue, setSliderValue] = useState(DEFAULT_SLIDER_VALUES.initial);
  const maxDisplayedSubstitutes = useDebounce(sliderValue, 500);

  const dispatch = useDispatch();
  const handleSliderChange = (newValue: number) => {
    setSliderValue(newValue);
  };

  useEffect(() => {
    dispatch(onSubstitutabilityReportletSliderChange(maxDisplayedSubstitutes));
  }, [dispatch, maxDisplayedSubstitutes]);

  return (
    <Form
      className={styles.substitutabilitySlider}
      designvariant={FormDesignVariant.Default}
      variant={FormVariant.LeftLabel}
    >
      <FormBlock blockType={FormBlockType.Range}>
        <Label
          className={styles.sliderLabel}
          htmlFor={id}
          text="Substitutes per product"
          width={LabelWidth.Small}
        />
        <Input>
          <RangeInput
            height={FormInputHeight.XSmall}
            id={id}
            max={DEFAULT_SLIDER_VALUES.max}
            min={DEFAULT_SLIDER_VALUES.min}
            onChange={(event) => {
              handleSliderChange(event.target.value);
            }}
            value={sliderValue.toString()}
            width={FormInputWidth.Fill}
          />
        </Input>
        <Label
          className={classNames(styles.sliderLabel, styles.sliderLabelValue)}
          htmlFor=""
          text={sliderValue.toString()}
          width={LabelWidth.XSmall}
        />
      </FormBlock>
    </Form>
  );
};

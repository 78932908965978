import { Item, InlineIcon, InlineIconGlyph, Text } from "@qbit/react";
import styles from "./WarningBanner.module.css";

export type WarningBannerProps = {
  text: string;
};

export const WarningBanner = ({ text }: WarningBannerProps) => (
  <Item className={styles.warningBannerContainer}>
    <InlineIcon
      colour="warning"
      glyph={InlineIconGlyph.AlertsAndNotificationsWarning}
      text={text}
    />
    <Text>{text}</Text>
  </Item>
);

export default WarningBanner;

import {
  Tooltip,
  TooltipSpaceInside,
  TooltipPlacement,
  TooltipVariant,
} from "@qbit/react";
import {
  HierarchyItemType,
  TrackingEvent,
  TrackingComponent,
} from "@quantium-enterprise/common-ui";
import { useFormatter } from "@quantium-enterprise/hooks-ui";
import {
  type Row,
  type Table,
  type CellContext,
  type ColumnDef,
} from "@tanstack/react-table";
import { HierarchyLevelIcon } from "components-ui/src/hierarchy-level-icon/HierarchyLevelIcon";
import { EmptySearch } from "components-ui/src/search/EmptySearch";
import { SearchBox } from "components-ui/src/search-box/SearchBox";
import {
  DEFAULT_COLUMN_MAX_WIDTH,
  DEFAULT_COLUMN_MIN_WIDTH,
} from "components-ui/src/tables/common/constants";
import { ExpandableNameCell } from "components-ui/src/tables/common/table-cell/ExpandableNameCell";
import { ValueCell } from "components-ui/src/tables/common/table-cell/ValueCell";
import { ReportHierarchyTableWrapper } from "components-ui/src/tables/report-hierarchy-table/components/ReportHierarchyTableWrapper";
import { VirtuosoTableComponent } from "components-ui/src/tables/virtuoso-table/VirtuosoTableComponent";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MeasureGroups } from "../../../common/models/MeasureGroups";
import { type PricingLaddersTableRow } from "../../models/PricingLaddersDataTableResponseDto";
import {
  onSearchQueryChange,
  selectAllRows,
  selectFocalItems,
  selectMetrics,
  selectRowSelection,
  selectSearchQuery,
  selectTableRows,
  selectTopDrawerActiveTab,
  setFocalItems,
} from "../../services/pricing-ladders-slice";
import styles from "./PricingLaddersTopDrawerDataTable.module.css";
import { SalesHeader } from "./SalesHeader";

const MAX_SELECTIONS = 200;

const getRowId = (row: PricingLaddersTableRow) => row.hierarchyItem.itemCode;

export type PricingLaddersTopDrawerProductTableProps = {
  eventTrackingService: Function;
  isQuerySuccess: boolean;
};

export const PricingLaddersTopDrawerItemTable = ({
  isQuerySuccess,
  eventTrackingService,
}: PricingLaddersTopDrawerProductTableProps) => {
  const dispatch = useDispatch();
  const formatter = useFormatter();
  const metrics = useSelector(selectMetrics);
  const rowSelection = useSelector(selectRowSelection);
  const allRows = useSelector(selectAllRows);
  const tableRows = useSelector(selectTableRows);
  const searchQuery = useSelector(selectSearchQuery);
  const topDrawerActiveTab = useSelector(selectTopDrawerActiveTab);
  const focalItems = useSelector(selectFocalItems);

  const onSearchChange = useCallback(
    (value: string) => {
      if (searchQuery !== value) {
        dispatch(onSearchQueryChange(value));
      }
    },
    [dispatch, searchQuery]
  );

  const matches = tableRows.length;
  const matchCountText = `${matches} match${matches > 1 ? "es" : ""} found`;

  const memoizedHeader = useMemo(
    () => (
      <div className={styles.firstColumnHeaderParent}>
        <div className={styles.hierarchySearch}>
          <span className={`${styles.flex}`}>
            <HierarchyLevelIcon
              shortName={topDrawerActiveTab ? topDrawerActiveTab : ""}
              type={HierarchyItemType.Hierarchy}
            />
            <span className={styles.headerValue}> Focal Product </span>
          </span>
          <div className={styles.searchBox}>
            <SearchBox
              enableDebounce
              onChange={onSearchChange}
              placeholder="Type to search"
              searchQuery={searchQuery}
            />
          </div>
          {allRows.length > 0 && searchQuery && matches > 0 && (
            <div className={styles.matchCount}>{matchCountText}</div>
          )}
        </div>
      </div>
    ),
    [
      onSearchChange,
      allRows,
      matchCountText,
      matches,
      searchQuery,
      topDrawerActiveTab,
    ]
  );

  const getIsRowDisabled = useCallback(
    (row: Row<PricingLaddersTableRow>, table: Table<PricingLaddersTableRow>) =>
      !table.getState().rowSelection[row.id] &&
      focalItems.length >= MAX_SELECTIONS,
    [focalItems]
  );

  const createNameCell = useCallback(
    ({ row, table }: CellContext<PricingLaddersTableRow, unknown>) => {
      const isRowDisabled = getIsRowDisabled(row, table);
      const cellBody = (
        <ExpandableNameCell
          canExpand={false}
          depth={0}
          handleToggleExpanded={row.getToggleExpandedHandler()}
          handleToggleSelected={(event) => {
            row.getToggleSelectedHandler()(event);
            const currentFocalItems = row.getIsSelected()
              ? focalItems.filter(
                  (value) =>
                    value.nodeNumber !== row.original.hierarchyItem.nodeNumber
                )
              : [...focalItems, row.original.hierarchyItem];
            eventTrackingService(
              [
                TrackingComponent.MyReports,
                TrackingComponent.Report,
                TrackingComponent.FocalItemTableRow,
              ],
              row.getIsSelected()
                ? TrackingEvent.Unselected
                : TrackingEvent.Selected,
              {
                selection: currentFocalItems.map(
                  ({ shortName, name }) => `${shortName}:${name}`
                ),
              }
            );
            dispatch(
              setFocalItems({
                selectedItem: row.original.hierarchyItem,
                isSelected: row.getIsSelected(),
              })
            );
          }}
          hideTooltip
          isCheckboxDisabled={isRowDisabled}
          isExpanded={false}
          isSelected={row.getIsSelected()}
          name={row.original.hierarchyItem.name}
          shortName={row.original.hierarchyItem.shortName}
          type={HierarchyItemType.Hierarchy}
          value={row.original.hierarchyItem.name}
        />
      );

      return isRowDisabled ? (
        <Tooltip
          placement={TooltipPlacement.RightCentre}
          spaceInside={TooltipSpaceInside.Medium}
          trigger={<div>{cellBody}</div>}
          variant={TooltipVariant.ArrowDark}
        >
          <div className={styles.disabledRowTooltipContent}>
            {`Selection limit of ${MAX_SELECTIONS} items has been reached. Please deselect items in order to select others`}
          </div>
        </Tooltip>
      ) : (
        cellBody
      );
    },
    [dispatch, eventTrackingService, focalItems, getIsRowDisabled]
  );

  const metricColumnsDefs = useMemo(
    (): Array<ColumnDef<PricingLaddersTableRow>> =>
      metrics.map((metric, index) => ({
        accessorKey: metric.displayName,
        cell: ({ row }) =>
          ValueCell({
            formatter: formatter(metric.format),
            value: row.original.metricValues[index],
          }),
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        header:
          metric.displayName === MeasureGroups.Sales && !searchQuery
            ? SalesHeader
            : metric.displayName,
        id: index + metric.displayName,
      })),
    [formatter, metrics, searchQuery]
  );

  const columnDefs: Array<ColumnDef<PricingLaddersTableRow>> = useMemo(
    () => [
      {
        accessorKey: "name",
        cell: createNameCell,
        enableHiding: false,
        enableResizing: true,
        enableSorting: false,
        header: () => memoizedHeader,
      },
      ...metricColumnsDefs,
    ],
    [metricColumnsDefs, memoizedHeader, createNameCell]
  );

  const customColumnWidth = {
    minSize: DEFAULT_COLUMN_MIN_WIDTH,
    size: 200,
    maxSize: DEFAULT_COLUMN_MAX_WIDTH,
  };

  const displayEmptySearch: boolean =
    allRows.length > 0 && searchQuery.length > 0 && tableRows.length === 0;

  return (
    <ReportHierarchyTableWrapper
      className={styles.pricingLaddersReportHierarchyTableWrapperContainer}
      isSuccess={isQuerySuccess}
    >
      <VirtuosoTableComponent
        columns={columnDefs}
        data={tableRows}
        defaultColumnWidthOverride={customColumnWidth}
        getIsRowDisabled={getIsRowDisabled}
        getRowId={getRowId}
        pinFirstColumn
        rowExpandedState={{ "0": true }}
        rowSelectionState={rowSelection}
      />
      {displayEmptySearch && (
        <div className={styles.emptySearch}>
          <EmptySearch />
        </div>
      )}
    </ReportHierarchyTableWrapper>
  );
};

import { hierarchyServiceApi, userApi } from "@quantium-enterprise/common-ui";
import {
  type PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { BrowserStorage } from "./services/browser-storage";
import { keyDriverTreeApi } from "./services/key-driver-tree-api-slice";
import { rangePerformanceApi } from "./services/range-performance-api-slice";
import rangePerformanceReducer from "./services/range-performance-slice";
import { createStorageMiddleware } from "./services/storage-middleware";

const browserStorageMiddleware = createStorageMiddleware(BrowserStorage);

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [hierarchyServiceApi.reducerPath]: hierarchyServiceApi.reducer,
  [rangePerformanceApi.reducerPath]: rangePerformanceApi.reducer,
  [keyDriverTreeApi.reducerPath]: keyDriverTreeApi.reducer,
  rangePerformance: rangePerformanceReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    devTools: { name: "rangePerformance" },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        userApi.middleware,
        hierarchyServiceApi.middleware,
        rangePerformanceApi.middleware,
        keyDriverTreeApi.middleware,
        browserStorageMiddleware
      ),
    preloadedState,
    reducer: rootReducer,
  });

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;

import { type UniqueIdentifier } from "@dnd-kit/core";
import { uniqueId } from "@qbit/react/dist/common";
import { type SimpleItem } from "../models/Item";
import { ContainerIdType, type SimpleZone } from "../models/Zone";

/**
 * Callback to sort hierarchy items based on ordinal
 * Attribute items with the same ordinals are sorted alphabetically
 */
export const sortItems = (itemA: SimpleItem, itemB: SimpleItem) => {
  if (itemA.ordinal === itemB.ordinal) {
    return itemA.name.toLowerCase() <= itemB.name.toLowerCase() ? -1 : 1;
  } else {
    return itemA.ordinal - itemB.ordinal;
  }
};

/**
 * Inserts a new zone into the list of droppable zones
 */
export const insertTemporaryZone = (
  droppableZones: SimpleZone[],
  containerId: ContainerIdType,
  id?: string
) => {
  droppableZones.push({
    id: id ?? uniqueId(),
    hasItem: false,
    isPlaceholder: Boolean(id),
    type: "zone",
    containerId,
  } as SimpleZone);
};

/**
 * Adds highlighted placeholder on LHS at index where activeItem was
 */
export const addPlaceholderItemAtIndex = (
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void,
  index: number
) => {
  const newItems = Array.from(items);
  newItems[index] = {
    ...newItems[index],
    isPlaceholder: true,
  };
  setItems(newItems);
};

/**
 * Removes any placeholder containers on LHS
 * Called on drag end event
 */
export const removePlaceholders = (
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void,
  activeItem?: SimpleItem
) => {
  const newItems = Array.from(items);

  if (activeItem) {
    newItems.push(activeItem);
    newItems.sort(sortItems);
  }

  setItems(newItems.filter((item) => !item.isPlaceholder));
};

export const updateItemColumn = (
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void,
  activeItem: SimpleItem,
  zone?: SimpleZone
) => {
  const index = items.findIndex((item) => item.id === activeItem.id);
  const newItems = Array.from(items);

  newItems[index] = zone
    ? {
        ...newItems[index],
        containerId: zone.containerId,
        zoneId: zone.id,
      }
    : {
        ...newItems[index],
        containerId: ContainerIdType.METRIC,
        zoneId: undefined,
      };

  setItems(newItems);
};

/**
 * Insert the active item into the existing structure
 */
export const insertItemIntoStructure = (
  overId: UniqueIdentifier,
  activeItem: SimpleItem,
  setActiveItem: (item: SimpleItem | undefined) => void,
  droppableZones: SimpleZone[],
  setDroppableZones: (zone: SimpleZone[]) => void,
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void
) => {
  const foundZone = droppableZones.find((zone) => zone.id === overId);

  if (foundZone) {
    const foundZoneIndex = droppableZones.indexOf(foundZone);
    const newItem = activeItem;
    newItem.containerId = foundZone.containerId;
    updateItemColumn(items, setItems, activeItem, foundZone);

    const newDropZone = {
      ...foundZone,
      item: {
        ...activeItem,
        containerId: foundZone.containerId,
        zoneId: foundZone.id,
      },
      hasItem: true,
      isPlaceholder: false,
    };

    // Inject new zone
    const newDroppableZones = Array.from(droppableZones);
    newDroppableZones.splice(foundZoneIndex, 0, newDropZone);

    // Update droppable zones and filter out any empty zones
    setDroppableZones([...newDroppableZones.filter((zone) => zone.item)]);

    removePlaceholders(items, setItems, activeItem);
    setActiveItem(undefined);
  } else {
    removePlaceholders(items, setItems);
  }
};

/**
 * Insert the active item back where it was
 */
export const moveItemBack = (
  setActiveItem: (item: SimpleItem | undefined) => void,
  items: SimpleItem[],
  setItems: (items: SimpleItem[]) => void,
  setZones: (zones: SimpleZone[]) => void,
  activeItem?: SimpleItem,
  savedZoneState?: SimpleZone[]
) => {
  if (activeItem) {
    const newItems = Array.from(items).filter((item) => !item.isPlaceholder);
    newItems.push(activeItem);
    newItems.sort(sortItems);

    if (savedZoneState) {
      setZones(savedZoneState);
    }

    setItems(newItems);
    setActiveItem(undefined);
  }
};

/**
 * Remove all temporary drop zones that do not contain an item
 */
export const removeEmptyDropZones = (
  droppableZones: SimpleZone[],
  setDroppableZones: (zone: SimpleZone[]) => void
) => {
  const newDroppableZones = droppableZones.filter((zone) => zone.item);
  setDroppableZones(newDroppableZones);
};

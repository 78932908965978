import { Text } from "@qbit/react";
import { type ParameterDto } from "@quantium-enterprise/common-ui";
import { getTimePeriodState } from "../../parameters/time/TimePeriodState";
import { timestampToDate, dateToString } from "../../parameters/time/utilities";
import { useAppSelector } from "../../states/hooks";
import styles from "./SummaryPanelLeadPeriod.module.css";

const customDateDisplayWeeks = (
  startDate?: Date,
  endDate?: Date,
  weeks?: number
) =>
  startDate && endDate && weeks ? (
    <Text>
      {weeks > 1 ? ` (${weeks} weeks selected)` : ` (${weeks} week selected)`}
    </Text>
  ) : null;

export type SummaryPanelLeadPeriodProps = {
  parameterDto: ParameterDto;
};

export const SummaryPanelLeadPeriod = ({
  parameterDto,
}: SummaryPanelLeadPeriodProps) => {
  const timePeriod = useAppSelector(getTimePeriodState(parameterDto.id));
  if (!timePeriod) {
    return null;
  }

  const startDate = timestampToDate(timePeriod.startDate);
  const endDate = timestampToDate(timePeriod.endDate);
  const weeks = timePeriod.weeks;

  return (
    <>
      {timePeriod.label && (
        <div>
          <Text className={styles.label}>
            {`${parameterDto.name}: ${timePeriod.label}`}
          </Text>

          {timePeriod.label === "Custom date" && (
            <>
              <Text>{`${dateToString(startDate) || ""}${
                startDate && endDate ? " - " : ""
              }${dateToString(endDate) || ""}`}</Text>

              {customDateDisplayWeeks(startDate, endDate, weeks)}
            </>
          )}
        </div>
      )}
    </>
  );
};

import {
  Group,
  Item,
  ItemHalign,
  ItemWidth,
  Spinner,
  SpinnerSize,
  Text,
} from "@qbit/react";
import {
  type GroupFolderDto,
  type HierarchyGroupDto,
  type ParameterId,
  useGetGroupSummaryQuery,
  type ParameterDto,
  getPreferredTransactionSource,
  HierarchyType,
  useGetGroupsAndFoldersQuery,
  mapFolderDto,
  createRowNameMatchesSearchPredicate,
  createRecord,
  searchGroupsCount,
  AppContext,
  ShareRootFolderName,
  ShareRootFolderId,
  FeatureFlag,
} from "@quantium-enterprise/common-ui";
import { useDivision, useFlags } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { HierarchyGroupSummary } from "components-ui/src/hierarchy-group-summary/HierarchyGroupSummary";
import { EmptyGroup } from "components-ui/src/search/EmptyGroup";
import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptySearch } from "../../../../components/src/search/EmptySearch";
import { SearchBox } from "../../../../components/src/search-box/SearchBox";
import { useAppSelector } from "../../states/hooks";
import {
  groupSelected,
  selectGroupExpandedIds,
  selectIsDataEntitlementsShown,
  selectTransactionSources,
} from "../../states/report-wizard-slice";
import { type GroupParameterState } from "./GroupParameterState";
import { getGroupParameterState } from "./GroupParameterState";
import { type GroupInfo, GroupParameterTable } from "./GroupParameterTable";
import styles from "./GroupsParameterQuery.module.css";

const recursiveGetGroups = (
  folder: GroupFolderDto,
  id: string
): {
  folders: GroupFolderDto[];
  groups: HierarchyGroupDto[];
} => {
  if (folder.id === id) {
    const recursiveGetGroupsInternal = (
      folderInternal: GroupFolderDto
    ): {
      folders: GroupFolderDto[];
      groups: HierarchyGroupDto[];
    } => {
      const groupsCopy = folderInternal.groups?.slice() ?? [];
      // folder will be concatenated as we recurse out.
      // this will always hold true because we have the single root folder.
      const folders = [];
      if (folderInternal.folders) {
        for (const subFolder of folderInternal.folders) {
          const subResult = recursiveGetGroupsInternal(subFolder);
          groupsCopy.push(...subResult.groups);
          folders.push(subFolder);
          folders.push(...subResult.folders);
        }
      }

      return {
        groups: groupsCopy,
        folders,
      };
    };

    return recursiveGetGroupsInternal(folder);
  }

  const foundGroup = folder.groups?.find((group) => group.id === id);
  if (foundGroup) {
    return {
      groups: [foundGroup],
      // folder will be concatenated as we recurse out
      folders: [],
    };
  }

  if (folder.folders) {
    for (const subFolder of folder.folders) {
      const result = recursiveGetGroups(subFolder, id);
      if (result.groups.length > 0) {
        return {
          ...result,
          folders: result.folders.concat(subFolder),
        };
      }
    }
  }

  return {
    groups: [],
    folders: [],
  };
};

const ShareRootFolder: GroupInfo = {
  ancestorIds: [],
  depth: 1,
  hasChildren: true,
  id: ShareRootFolderId,
  indeterminate: false,
  isFolder: true,
  isTransactionSourceLoading: false,
  name: ShareRootFolderName,
  selected: false,
};

export type GroupParameterProps = {
  parameterDto: ParameterDto;
};

// eslint-disable-next-line complexity
export const GroupsParameterQuery = ({ parameterDto }: GroupParameterProps) => {
  const { userState } = useContext(AppContext);
  const flags = useFlags();
  const {
    confirmedSelections,
    pendingSelections,
    knownGroupTransactionSources,
  } = useAppSelector(
    getGroupParameterState(parameterDto.id as ParameterId)
  ) as GroupParameterState;

  const isDataEntitlementsShown = useSelector(selectIsDataEntitlementsShown);
  const transactionSourceConfigs = useSelector(selectTransactionSources);
  const { name: divisionName } = useDivision();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const expandedIds = useSelector(
    selectGroupExpandedIds(parameterDto.id as ParameterId)
  );

  const { data: serverRootFolder, isLoading: isGroupListLoading } =
    useGetGroupsAndFoldersQuery(
      {
        divisionName,
        hierarchyType: parameterDto.hierarchyType,
        includeRules: true,
      },
      { skip: !divisionName }
    );

  const dispatch = useDispatch();

  const [lastSelectedGroupId, setLastSelectedGroupId] = useState<
    string | undefined
  >(confirmedSelections.at(0)?.id);

  const getGroupTransactionSource = useMemo(
    () => (groupId?: string) => {
      if (!groupId) {
        return undefined;
      }

      const knownSources = knownGroupTransactionSources[groupId];
      if (knownSources) {
        return getPreferredTransactionSource(
          transactionSourceConfigs,
          knownSources
        );
      }

      return undefined;
    },
    [knownGroupTransactionSources, transactionSourceConfigs]
  );

  const noGroupSelected = useMemo(
    () => (
      <>
        <div>
          <Text>PRODUCT GROUP DETAILS</Text>
        </div>
        <Text className={styles.noGroupSubtitle}>No group selected</Text>
      </>
    ),
    []
  );

  // these are the groups that the table understands
  const mappedGroups = useMemo(() => {
    const pendingIds = createRecord(
      pendingSelections,
      (group) => group.id ?? ""
    );
    const confirmedIds = createRecord(
      confirmedSelections,
      (group) => group.id ?? ""
    );
    return serverRootFolder
      ? mapFolderDto(
          serverRootFolder,
          userState.currentUser?.salesForceId,
          flags[FeatureFlag.ProductGroupsTrueShare]
            ? ShareRootFolder
            : undefined,
          (folder, depth, ancestorIds, parentId): GroupInfo => {
            const [childrenSelected, childrenPending] = searchGroupsCount(
              folder,
              (group) => confirmedIds[group.id ?? ""],
              (group) => pendingIds[group.id ?? ""]
            );
            return {
              ...folder,
              ancestorIds,
              depth,
              folderColour: folder.color,
              hasChildren:
                Boolean(folder.folders?.length) ||
                Boolean(folder.groups?.length),
              id: folder.id ?? "",
              selected:
                childrenSelected.matched > 0 &&
                childrenSelected.matched === childrenSelected.total,
              indeterminate:
                childrenSelected.matched > 0 &&
                childrenSelected.matched !== childrenSelected.total,
              isFolder: true,
              isTransactionSourceLoading: childrenPending.matched > 0,
              name: folder.name ?? "",
              parentId,
            };
          },
          (group, depth, ancestorIds, parentId): GroupInfo => {
            const transactionSource = getGroupTransactionSource(group.id)?.[0];
            const selected =
              confirmedIds[group.id ?? ""] || pendingIds[group.id ?? ""];
            const isTransactionSourceLoading =
              selected && transactionSource === undefined;
            return {
              ...group,
              ancestorIds,
              depth,
              hasChildren: false,
              id: group.id ?? "",
              indeterminate: false,
              isFolder: false,
              isTransactionSourceLoading,
              parentId,
              selected,
              transactionSource,
            };
          }
        )
      : [];
  }, [
    serverRootFolder,
    getGroupTransactionSource,
    confirmedSelections,
    pendingSelections,
    userState,
    flags,
  ]);
  const filteredGroups = useMemo(() => {
    if (searchTerm) {
      const rowNameMatchesSearchPredicate =
        createRowNameMatchesSearchPredicate(searchTerm);
      // filter out "folders" to match group tab behaviour
      return mappedGroups.filter(
        (group) => !group.isFolder && rowNameMatchesSearchPredicate(group.name)
      );
    } else {
      return mappedGroups.filter(
        (group: GroupInfo) =>
          group.id &&
          (!group.parentId ||
            group.ancestorIds.every((ancestorId) =>
              expandedIds?.includes(ancestorId)
            ))
      );
    }
  }, [mappedGroups, expandedIds, searchTerm]);

  const numberResults: number = useMemo(
    () => filteredGroups.length,
    [filteredGroups]
  );

  const hasGroups = useMemo(
    () =>
      Boolean(serverRootFolder?.groups) || Boolean(serverRootFolder?.folders),
    [serverRootFolder]
  );

  const setLastSelected = useCallback(
    (groupId: string) => {
      const group = mappedGroups.filter((grp) => grp.id === groupId).at(0);
      if (!group?.isFolder) {
        setLastSelectedGroupId(group?.id);
      }
    },
    [mappedGroups]
  );

  const toggleGroupSelection = useCallback(
    (id: string) => {
      if (serverRootFolder === undefined) {
        return;
      }

      const result = recursiveGetGroups(serverRootFolder, id);
      if (result.groups.length === 0) {
        return;
      }

      setLastSelected(id);
      dispatch(
        groupSelected({
          groups: result.groups,
          parameterId: parameterDto.id,
        })
      );
    },
    [dispatch, parameterDto.id, serverRootFolder, setLastSelected]
  );

  const { currentData: groupSummary, isFetching: isSummaryFetching } =
    useGetGroupSummaryQuery(
      {
        divisionName,
        groupId: lastSelectedGroupId ?? "",
        maxItemsInSubset: 32,
      },
      { skip: !divisionName || !lastSelectedGroupId }
    );

  const selectedGroupTransactionSource =
    getGroupTransactionSource(lastSelectedGroupId)?.[0];

  return (
    <div className={styles.groupParameterQueryContainer}>
      <Group className={styles.searchCreateGroup}>
        <SearchBox
          debounceTimeMs={500}
          enableDebounce
          onChange={setSearchTerm}
          placeholder="Search"
          resultCount={searchTerm ? numberResults : undefined}
        />
      </Group>

      <Group
        className={classNames(
          styles.groupList,
          styles.groupParameterQueryContent
        )}
      >
        <Item className={styles.groupTableContainer} width={ItemWidth.Fill}>
          {!isGroupListLoading && !hasGroups ? (
            searchTerm ? (
              <Group className={styles.groupParameterQueryContent}>
                <Item halign={ItemHalign.Centre}>
                  <Group>
                    <Item
                      className={styles.emptyGroupListImageItem}
                      halign={ItemHalign.Centre}
                    >
                      <EmptySearch />
                    </Item>
                  </Group>
                </Item>
              </Group>
            ) : (
              <Group className={styles.groupParameterQueryContent}>
                <Item halign={ItemHalign.Centre}>
                  <Group>
                    <Item
                      className={styles.emptyGroupListImageItem}
                      halign={ItemHalign.Centre}
                    >
                      <EmptyGroup
                        type={
                          HierarchyType[
                            parameterDto.hierarchyType as keyof typeof HierarchyType
                          ]
                        }
                      />
                    </Item>
                  </Group>
                </Item>
              </Group>
            )
          ) : (
            <GroupParameterTable
              groups={filteredGroups}
              headerText={parameterDto.name}
              isDataEntitlementsShown={isDataEntitlementsShown}
              parameterId={parameterDto.id}
              toggleGroupSelection={toggleGroupSelection}
            />
          )}
        </Item>
        <Item fixedWidth="50%" width={ItemWidth.Fit}>
          <div className={styles.groupSummary}>
            {!isSummaryFetching && groupSummary && (
              <HierarchyGroupSummary
                group={groupSummary}
                isDataEntitlementsShown={isDataEntitlementsShown}
                isTransactionSourceLoading={
                  lastSelectedGroupId !== undefined &&
                  selectedGroupTransactionSource === undefined
                }
                transactionSource={selectedGroupTransactionSource}
              />
            )}
            {!isSummaryFetching && !groupSummary && noGroupSelected}
            {isSummaryFetching && (
              <Spinner
                className={styles.loading}
                size={SpinnerSize.Large}
                text="Loading"
              />
            )}
          </div>
        </Item>
      </Group>
      {isGroupListLoading && (
        <div
          className={classNames(
            styles.loading,
            styles.groupParameterQueryContent
          )}
        >
          <Spinner size={SpinnerSize.Large} text="Loading" />
        </div>
      )}
    </div>
  );
};

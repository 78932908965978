import {
  Text,
  Button,
  ButtonHeight,
  ButtonVariant,
  Icon,
  IconGlyph,
} from "@qbit/react";
import classNames from "classnames/bind";
import * as React from "react";
import styles from "./SideDrawer.module.scss";

export type SideDrawerContent = {
  body: JSX.Element;
  header: JSX.Element;
  label: JSX.Element;
};

type SideDrawerProps = {
  closeFunc: () => void;
  content: SideDrawerContent;
  isOverlay: boolean;
  showDrawer: boolean;
};

const boundClassNames = classNames.bind(styles);

export const SideDrawer = ({
  closeFunc,
  isOverlay,
  showDrawer,
  content,
}: SideDrawerProps) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      closeFunc();
    }
  };

  const sideDrawerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // The element is initially tucked away by a margin to get the "fly-in" effect,
    // we need to wait for the transition to complete before adding focus to the element
    // otherwise the focus pans off screen and follows the side drawer as it transitions into view
    let handler: NodeJS.Timeout | undefined;
    if (showDrawer) {
      handler = setTimeout(() => {
        sideDrawerRef.current?.focus();
      }, 120);
    }

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  });

  return (
    <div
      aria-hidden={!showDrawer}
      className={boundClassNames("sideDrawer", {
        sideDrawerActive: showDrawer,
        sideDrawerOverlay: isOverlay,
      })}
      onKeyDown={handleKeyPress}
      ref={sideDrawerRef}
      role="menu"
      tabIndex={0}
    >
      <div className={styles.sideDrawerInner}>
        <div className={styles.sideDrawerHeader}>
          <div className={styles.sideDrawerLabel}>
            <Text>{content.label}</Text>
            <Button
              aria-label="Close side panel"
              className={styles.closeButton}
              data-testid="close-side-panel-button"
              height={ButtonHeight.Small}
              onClick={closeFunc}
              variant={ButtonVariant.Stealth}
            >
              <Icon
                glyph={IconGlyph.DeleteAndCloseClose}
                text="Close side panel"
              />
            </Button>
          </div>
          <div className={styles.headerContent}>{content.header}</div>
        </div>
        <div className={styles.sideDrawerContent}>{content.body}</div>
      </div>
    </div>
  );
};

export default SideDrawer;

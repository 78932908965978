import { Tag, TagColour } from "@qbit/react";
import { type DivisionDto } from "@quantium-enterprise/common-ui";
import { Link } from "react-router-dom";
import styles from "./DashboardNavigationButton.module.css";

type DashboardNavigationButtonProps = {
  altText: string;
  division: DivisionDto;
  imgSrc: string;
  isBeta?: boolean;
  onClick?: () => void;
  path: string;
};

const DashboardNavigationButton = ({
  division,
  onClick,
  path,
  imgSrc,
  altText,
  isBeta = false,
}: DashboardNavigationButtonProps) => (
  <div className={styles.button}>
    <Link onClick={onClick} to={`/${division.name}/${path}`}>
      <img alt={altText} className={styles.buttonImage} src={imgSrc} />
      {isBeta && (
        <Tag
          className={styles.betaTag}
          colour={TagColour.Neutral}
          text="Beta"
        />
      )}
    </Link>
  </div>
);

export default DashboardNavigationButton;

import { FormBlock, FormBlockType, Input, RadioButton } from "@qbit/react";
import { type ParameterDto } from "@quantium-enterprise/common-ui";
import ListHeader from "../list-header/ListHeader";
import styles from "./RadioButtonList.module.css";

export type RadioButtonListProps = {
  onChange: (clickedValue: string) => void;
  parameter: ParameterDto;
  selectedValues: string[];
};

export const RadioButtonList = ({
  onChange,
  parameter,
  selectedValues,
}: RadioButtonListProps) => (
  <div>
    <ListHeader headerText={parameter.name} />
    {parameter.options.map((item) => (
      <div className={styles.container} key={item.label}>
        <FormBlock blockType={FormBlockType.Radio} className={styles.formBlock}>
          <Input className={styles.input}>
            <RadioButton
              checked={selectedValues.includes(item.value)}
              className={styles.radioButtons}
              id={"radio-" + item.value}
              label={item.label}
              name={parameter.name + "-" + item.value}
              onChange={() => onChange(item.value)}
            />
          </Input>
        </FormBlock>
      </div>
    ))}
  </div>
);

export default RadioButtonList;

import {
  Button,
  ButtonVariant,
  ButtonWidth,
  Icon,
  IconGlyph,
  Text,
} from "@qbit/react";
import { uniqueId } from "@qbit/react/dist/common";
import {
  type TransactionSource,
  useEventTrackingServiceContext,
  TrackingComponent,
  TrackingEvent,
  GenericTrackingProperties,
} from "@quantium-enterprise/common-ui";
import { useDivision } from "@quantium-enterprise/hooks-ui";
import classNames from "classnames";
import { TransactionSourceIcon } from "components-ui/src/icons/transaction-source-icon/TransactionSourceIcon";
import { GreySpinner } from "components-ui/src/loader/GreySpinner";
import { type ReactElement, type PropsWithChildren, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../../store";
import { setTabIndex } from "../wizard/wizard-slice";
import styles from "./SummaryPanel.module.css";
import ErroredSVG from "./assets/errored.svg";
import GreenTickSVG from "./assets/green-tick.svg";

type SummaryPanelHeaderProps = {
  icon?: JSX.Element;
  title: string;
  transactionSources: TransactionSource[];
};

const SummaryPanelHeader = ({
  icon,
  title,
  transactionSources,
}: SummaryPanelHeaderProps) => {
  const { transactionSources: availableTransactionSources } = useDivision();

  return (
    <div className={styles.summaryPanelHeader}>
      {icon}
      <Text>{title}</Text>
      <div className={styles.transactionSourceContainer}>
        {transactionSources.map((source) => (
          <TransactionSourceIcon
            availableTransactionSources={availableTransactionSources}
            greyedOut={false}
            key={source}
            transactionSource={source}
          />
        ))}
      </div>
    </div>
  );
};

type ItemProps = {
  isCompleted: boolean;
  isErrored?: boolean;
  isOptional?: boolean;
  isPrefilled?: boolean;
  tabItemIndex: number;
  title: string;
};

const Item = ({
  isCompleted,
  isErrored = false,
  isOptional = false,
  isPrefilled = false,
  tabItemIndex,
  title,
  children,
}: PropsWithChildren<ItemProps>) => {
  const eventTrackingService = useEventTrackingServiceContext();
  const dispatch = useDispatch();
  const { currentTab, tabs, wizardTitle } = useSelector((state: RootState) => ({
    currentTab: state.wizard.currentTab,
    tabs: state.wizard.tabs,
    wizardTitle: state.wizard.title,
  }));

  let parameterLabel: string | undefined;
  if (isOptional) {
    parameterLabel = "Optional";
  } else if (isPrefilled) {
    parameterLabel = "Pre-filled";
  }

  const handleEditClick = useCallback(
    (index: number) => {
      eventTrackingService.trackEvent(
        [TrackingComponent.Wizard, TrackingComponent.ParameterGroup],
        TrackingEvent.Navigated,
        new GenericTrackingProperties({
          wizardTitle,
          navigationMethod: "Summary panel",
          originParameterGroup: tabs[currentTab],
          destinationParameterGroup: tabs[index],
        })
      );
      dispatch(setTabIndex(index));
    },
    [dispatch, currentTab, eventTrackingService, wizardTitle, tabs]
  );

  return (
    <div className={styles.item}>
      <div className={styles.itemHeader}>
        {/* Left item header content */}
        <div className={styles.itemHeaderLeft}>
          <label className={classNames({ [styles.error]: isErrored })}>
            {title}
          </label>
          <Button
            className={styles.editButton}
            disabled={currentTab === tabItemIndex}
            onClick={() => {
              handleEditClick(tabItemIndex);
            }}
            variant={ButtonVariant.Stealth}
          >
            {currentTab === tabItemIndex ? (
              <Text className={styles.textEditing}>Editing</Text>
            ) : (
              <Text className={styles.textEdit}>Edit</Text>
            )}
          </Button>
        </div>
        {/* Right item header content */}
        <div className={styles.itemHeaderRight}>
          {parameterLabel && (
            <Text className={styles.parameterLabelText}>{parameterLabel}</Text>
          )}
          <div className={styles.statusIcon}>
            {isCompleted && !isErrored && (
              <img alt="Completed" src={GreenTickSVG} />
            )}
            {!isCompleted && isErrored && <img alt="Error" src={ErroredSVG} />}
          </div>
        </div>
      </div>
      <div className={styles.itemContent}>{children}</div>
    </div>
  );
};

type SummaryPanelBodyProps = {
  children: Array<ReactElement<typeof Item>>;
};

const SummaryPanelBody = ({ children }: SummaryPanelBodyProps) => (
  <div className={styles.summaryPanelBody}>
    <ul>
      {children.map((child) => (
        <li key={uniqueId()}>{child}</li>
      ))}
    </ul>
  </div>
);

type SummaryPanelFooterProps = {
  buttonVariant?: ButtonVariant;
  createText: string;
  isDisabled: boolean;
  isProcessing: boolean;
  onCreateClick: () => void;
};

const SummaryPanelFooter = ({
  buttonVariant,
  isDisabled,
  isProcessing,
  onCreateClick,
  createText,
}: SummaryPanelFooterProps) => {
  const buttonText = isProcessing ? "Submitting" : createText;
  const tooltipText = "Ensure all non-optional parameters are selected.";

  return (
    <div className={styles.summaryPanelFooter}>
      <Button
        className={styles.createReportButton}
        disabled={isDisabled || isProcessing}
        onClick={onCreateClick}
        variant={buttonVariant ?? ButtonVariant.Primary}
        width={ButtonWidth.Small}
      >
        <Text title={isDisabled && !isProcessing ? tooltipText : ""}>
          {buttonText}
        </Text>
        {isProcessing ? (
          <GreySpinner className={styles.loadingIcon} />
        ) : (
          <Icon glyph={IconGlyph.ArrowsNext} text={buttonText} />
        )}
      </Button>
    </div>
  );
};

const SummaryPanel = ({ children }: PropsWithChildren) => (
  <aside className={styles.summaryPanel}>{children}</aside>
);

SummaryPanel.Item = Item;
SummaryPanel.Header = SummaryPanelHeader;
SummaryPanel.Body = SummaryPanelBody;
SummaryPanel.Footer = SummaryPanelFooter;

export { SummaryPanel };
